<div class="input-data-table">
  <div fxLayout="row" class="sticky-header" #scrollOne>
    <div fxLayout="column" class="index-column">
      <div class="header-type" matTooltipPosition="above" matTooltip="Input Header" matTooltipClass="dark-bg-tooltip">IH
      </div>
      <div class="header-type"></div>
    </div>
    <div *ngFor="let header of inputDataTableHeaders; let headerIndex = index" fxLayout="column"
      style="flex-grow: 1; flex-basis:0" class="each-header-col">
      <ng-container>
        <!-- header line 1-->
        <div matTooltipPosition="above" [matTooltip]="
                 header['input_column']" contentOverflowTooltip #c="isContentOverflowing"
          [matTooltipDisabled]="!c.isContentOverflowing" class="cells truncate data-table-header-1"
          [ngStyle]="{'width.px': inputDataTableHeaders.length <= totalColsInAScreen? 'auto' : 200}">
          {{
                header['input_column']
              }}
        </div>

        <!-- header line 2-->
        <div class="cells truncate data-table-header-2" [id]="'div'+header['column_index']" fxFlex
          fxLayoutAlign="space-between center"
          [ngStyle]="{'width.px': inputDataTableHeaders.length <= totalColsInAScreen? 'auto' : 200}">
          <span>{{
                header['column_index'] 
              }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- body -->
  <div class="data-table-body" fxLayout="column" #scrollTwo (mouseenter)="updateCurrentElement('scrollTwo')"
    (scroll)="updateScroll($event)" [ngStyle]="{'max-height.px': screenHeight >900 ? 525: 350}" fxLayout="row">
    <div class="row-cell-index-wrapper" fxLayout="column">
      <div *ngFor="let row of inputDataTableRows; let i=index" class="row-cell-index">
        {{row.row_no}}
      </div>
    </div>
    <div class="data-row" fxLayout="column">
      <div class="row-cell-wrapper" *ngFor="let row of inputDataTableRows; let i=index" fxLayout="row">
        <ng-container *ngFor="let header of inputDataTableHeaders">
          <div class="row-cell truncate" [attr.data-column-index]="header.column_index" matTooltipPosition="above"
            [matTooltip]="row[header.input_column]" contentOverflowTooltip #c="isContentOverflowing"
            [matTooltipDisabled]="!c.isContentOverflowing" [attr.data-row-index]="i+1"
            [ngStyle]="{'flex-grow': inputDataTableHeaders.length <= totalColsInAScreen? 1 : 0}">
            {{row[header.input_column]}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- </ng-template> -->
</div>


<div class="pagination-wrapper" fxLayoutAlign="end center" *ngIf="inputDataTableRows.length > 0">
  <div class=" page-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
    <span>Page: </span>
    <div class="page-number" fxLayout="row" fxLayoutAlign="center center" contenteditable="true"
      (input)="currentPageValueInputTable = $event.target.textContent" [textContent]="currentPageValueInputTable"
      (keyup.enter)="goToPage($event)" (blur)="goToPage($event)">
    </div>
  </div>
  <mat-paginator class="input-data-table-paginator" [length]="totalRecords" [pageSize]="pageSize" [pageIndex]="page - 1"
    [pageSizeOptions]="[5, 10, 20, 25, ]" (page)="onPaginationEvent($event)" [showFirstLastButtons]="true">
  </mat-paginator>
</div>
