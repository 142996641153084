import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollToBottom]'
})
export class ScrollToBottomDirective implements AfterViewInit {

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isScrollbarVisible()) {
        this.scrollToBottom();
      }
    }, 1000);
  }

  private isScrollbarVisible(): boolean {
    const element = this.el.nativeElement;
    return element.scrollHeight > element.clientHeight;
  }

  public scrollToBottom(): void {
    try {
      if (this.isScrollbarVisible()) {
        this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
      }
    } catch(err) { }
  }
}
