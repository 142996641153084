import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../_services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const permission = localStorage.getItem('permissions');
    const array = permission?.split(',').map((item) => item.trim());
    // Check if the user has at least one of the required permissions
    const hasRequiredPermission = array?.some((permission: any) =>
      this.userService.hasPermission(permission)
    );

    // Check if the user is already present in local storage
    if (localStorage.getItem('user')) {
      // bar user state 'CONFIGURING' from accessing content health route
      if (state.url.includes('/content-health')) {
        if (this.userService.getUser().status === 'CONFIGURING') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      // Return a promise when the 'me' API returns a valid response
      return new Promise<boolean>((resolve, reject) => {
        this.userService.me().subscribe({
          next: (resp) => {
            if (resp) {
              localStorage.setItem('user', JSON.stringify(resp));
              resolve(true);
            }
          },
          error: (err) => {
            reject(err);
            // You can choose to navigate to a different route if there's an error
            // this.router.navigate(['/signin']);
          },
        });
      });
    }
  }
}
