<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <nav
      class="breadcrumb"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
    >
      <span class="link" [routerLink]="'/dashboard'">Dashboard</span>
      <mat-icon>chevron_right</mat-icon>
      <span class="active">See All Invites</span>
    </nav>

    <!-- Notification Section -->
    <div class="container-list">
      <div
        class="notifications"
        [class.unread]="notification.unread"
        *ngFor="
          let notification of notifications;
          let i = index;
          let last = last
        "
      >
        <div
          fxLayout="row"
          class="each-notification"
          fxLayoutAlign="space-between start"
        >
          <div class="msg" fxLayout="row" fxLayoutGap="5px">
            <div
              class="avatar"
              id="user-avatar"
              fxLayout="column"
              fxLayoutAlign="center center"
              style="background-color: '#24a5ce'"
            >
              <span>{{ notification.initial }}</span>
            </div>
            <div fxLayout="column" fxLayoutGap="5px">
              <!-- <span
                innerHTML="{{ notification.message | highlightText: notification.highlight.text: notification.highlight.type : notification.highlight.entity_code  }}">
              </span> -->
              <span
                class="message"
                innerHTML="{{
                  notification.message
                    | highlightText
                      : notification.highlight.text
                      : notification.highlight.type
                }}"
              >
              </span>
              <span class="notify-date"
                >{{ notification.timestamp | date : "MMM d, y" }} at
                {{ notification.timestamp | date : "h:mm a" }}</span
              >
              <div
                *ngIf="notification.invited"
                class="user-actions"
                fxLayout="row"
                fxLayoutAlign="start center"
              >
                <button
                  mat-stroked-button
                  class="reject-button"
                  matTooltip="Reject this request"
                  matTooltipPosition="above"
                >
                  Reject
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  matTooltip="Accept this request"
                  matTooltipPosition="above"
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
          <div
            style="width: 30px; cursor: pointer"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            <!-- <span color="primary" *ngIf="notification.unread" (click)="markAsRead(notification.id, i)"
                class="mark-as-read"></span> -->
            <!-- <mat-icon [matMenuTriggerFor]="menu1" *ngIf="notification.unread && notificationType == 'NOTIFICATION'"
                  svgIcon="more"></mat-icon> -->
            <mat-icon [matMenuTriggerFor]="menu1" svgIcon="more"></mat-icon>
            <mat-menu
              #menu1="matMenu"
              [xPosition]="'before'"
              [yPosition]="'below'"
            >
              <!-- <button mat-menu-item (click)="markAsRead(notification.id, i)">Mark as read</button> -->
              <button mat-menu-item>Mark as read</button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div
        class="notification-paginator"
        fxLayoutAlign="row"
        fxLayoutAlign="end center"
      >
        <span class="show-label">Showing</span>
        <mat-paginator class="" hidePageSize="true" pageSize="10" length="100">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
