<div class="data-table-skeleton" fxLayout="column" fxLayoutAlign="center center">
  <div class="header" fxLayout="column" style="width: 100%" *ngIf="isHeaderLoading || isInputDataTableLoading">
    <div class="header-1" fxLayout="row" style="padding-top: 10px; padding-left: 10px"
      fxLayoutAlign="space-between center">
      <div fxFlex="4">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
          height: '12px',
         'border-radius': '4px',
         'width': '18px',
       }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '120px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '110px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '140px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '110px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '120px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '140px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="6">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '80px',
          }"></ngx-skeleton-loader>
      </div>
    </div>
    <div *ngIf="!isInputDataTableLoading" class="header-2" fxLayout="row"
      style="padding-top: 10px; padding-left: 10px; background-color: #F6F8FF;" fxLayoutAlign="space-between center">
      <div fxFlex="4">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
          height: '12px',
         'border-radius': '4px',
         'width': '18px',
       }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '140px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '70px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '150px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '130px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '120px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="15">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '90px',
          }"></ngx-skeleton-loader>
      </div>
      <div fxFlex="6">
        <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
            height: '12px',
            'border-radius': '4px',
            'width': '120px',
          }"></ngx-skeleton-loader>
      </div>

    </div>
    <div class="header-3" fxLayout="row"
      style="padding-top: 10px; padding-left: 10px; background-color: #F1F5F9; height: 25px"
      fxLayoutAlign="space-between center">
    </div>
  </div>
  <div class="data-table-skeleton-content" fxLayout="column" *ngIf="isTableContentLoading || isInputDataTableLoading">
    <div *ngFor="let i of [1,2,3,4,5,6,7,8,9,1,2,3,4,5,6]" fxLayout="column">
      <div class="each-row" fxLayout="row">
        <div fxFlex="4" style="margin-right: 10px;">
          <div fxFlex="80" style="background-color: #F1F5F9;">
          </div>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '60px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '90px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '40px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '70px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '90px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '85px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="6" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '80px',
                    }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="each-row" fxLayout="row">
        <div fxFlex="4" style="margin-right: 10px;">
          <div fxFlex="80" style="background-color: #F1F5F9;">
          </div>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '80px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '70px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '50px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '80px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '60px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '70px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="6" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '60px',
                    }"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="each-row" fxLayout="row">
        <div fxFlex="4" style="margin-right: 10px;">
          <div fxFlex="80" style="background-color: #F1F5F9;">
          </div>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '75px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '65px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '78px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '90px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '35px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="15" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '60px',
                    }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="6" style="padding-top: 10px;">
          <ngx-skeleton-loader appearance="line" animation="progress" [theme]="{
                      height: '12px',
                      'border-radius': '4px',
                      'width': '30px',
                    }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>


</div>
