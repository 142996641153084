<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <div class="header">
      <div class="title">Manage Accounts</div>

      <div class="subtitle">Update your workspace information.</div>

      <div>
        <mat-form-field
          style="width: 30%"
          appearance="outline"
          *ngIf="selectedOrgCode"
        >
          <mat-select
            placeholder="jeseleos@example.com (Personal)"
            panelClass="drop-down-panel"
            [(value)]="selectedOrg"
            (selectionChange)="onChangeOfUser($event)"
          >
            <mat-option *ngFor="let org of orgList" [value]="org">
              {{ org.organisation_name }}
            </mat-option>
          </mat-select>
          <mat-icon
            matSuffix
            svgIcon="DownArrow"
            class="selection-arrow-icon"
          ></mat-icon>
        </mat-form-field>
      </div>
    </div>

    <mat-tab-group disableRipple animationDuration="0ms">
      <mat-tab
        label="Workspace Information"
        [disabled]="!hasOrganizationReadPermission"
      >
        <ng-template matTabContent>
          <organization-info [selectedOrg]="selectedOrg"></organization-info>
        </ng-template>
      </mat-tab>
      <mat-tab label="Team Members" [disabled]="!hasTeamMembersReadPermission">
        <ng-template matTabContent>
          <app-team-members [selectedOrg]="selectedOrg"></app-team-members>
        </ng-template>
      </mat-tab>
      <mat-tab label="Billing" [disabled]="true">
        <ng-template matTabContent>
          <app-billing></app-billing>
        </ng-template>
      </mat-tab>
      <mat-tab label="Usage" [disabled]="true">
        <ng-template matTabContent>
          <app-usage></app-usage>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
