  <app-top-nav></app-top-nav>
  <div class="page-container">
    <div class="content">
      <div class="upload-area">
        <form fxLayout="column" [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
          <mat-label style="margin-bottom: 4px;color: #1B263B;font-weight: 500;">Batch name
            <span class="required"> *</span>
          </mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="taskName" placeholder="Enter the batch name" 
                   (input)="uploadForm.get('taskName')?.updateValueAndValidity(); uploadForm.get('taskName')?.markAsTouched()" 
                   (keyup)="uploadForm.get('taskName')?.updateValueAndValidity()" />
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('required')">
              Batch Name is required.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('maxlength')">
              Batch name can't be more than 100 characters.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('minLengthWithoutWhitespace')">
              Batch name needs to have at least 3 characters.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('invalidCharacters')">
              Batch name can't have any special characters except for '-'.
            </mat-error>
            <mat-error *ngIf="uploadForm.get('taskName')?.hasError('invalidStart')">
              Batch name can't start or end with a special character.</mat-error>
          </mat-form-field>
          <div style="
            color: #f44336;
            font-size: 14px;
            margin: -15px 0px 15px 14px;
            animation: example2;
            animation-duration: 0.3s;
          " *ngIf="
            !isNameUnique && this.uploadForm.get('taskName')?.valid && showError
          ">
            Please enter a batch name which is unique to this workspace
          </div>

          <mat-label style="margin-bottom: 4px; margin-top: 8px;color: #1B263B;font-weight: 500;">Select pre-defined tag (optional)
          </mat-label>
          <!-- ============================================================================= -->

          <!-- <mat-form-field style="margin-bottom: 10px" appearance="outline" class="app-dropdown">
            <mat-select class="custom-style-for-placeholder" placeholder="{{
                taskNameQueryParam ? taskNameQueryParam : 'Select a tag'
              }}" [disabled]="taskNameQueryParam !== null" panelClass="drop-down-panel" formControlName="batch"
              disableOptionCentering>
              <mat-option *ngIf="batchId != null" (click)="uploadForm.get('batch').reset(null)">None</mat-option>
              <mat-option *ngIf="batchList.length === 0" [value]="'no-batch'" disabled>No batches created</mat-option>
              <mat-option *ngFor="let batch of batchList" [value]="batch.id">
                {{ batch.task_name }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
          </mat-form-field> -->

          <mat-form-field appearance="outline" class="app-dropdown optional-tag-style">
            <mat-select 
              placeholder="Select a tag"
              formControlName="batch"
              panelClass="custom-dropdown-panel"
              (selectionChange)="onSelectionChange($event)">
              <mat-select-trigger>
                <mat-chip-list *ngIf="selectedValue">
                  <mat-chip style="background-color: #E8EDFD !important;
                  border-radius: 4px !important;
                  height: 28px;
                  padding: 4px 8px;" [removable]="true" (removed)="removeSelection()">
                    {{selectedValue.name}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>
              <mat-option style="height: auto;" *ngFor="let option of customTagObject" [value]="option">
                <div class="option-content">
                  <div class="option-title" fxLayout="row" fxLayoutAlign="space-between center">
                    <span style="background-color: #d8d2d2;
                    padding: 4px 8px;
                    border-radius: 4px;
                    font-family: IBM Plex Sans;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: center;
                    color: #0C0E13;">{{option.name}}</span>
                    <span class="option-description" style="color: #B6B8BC !important;"><span>(</span>{{option.input_file_format}}<span>)</span></span>
                  </div>
                  <div class="option-description">
                    <span>{{option.description | truncate : 60}}</span>
                  </div>
                </div>
              </mat-option>
            </mat-select>
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
          </mat-form-field>

          <!-- ============================================================================= -->

          <mat-label style="color: #1B263B;font-weight: 500;">
            Input file
            <span class="required"> *</span>
          </mat-label>
          <div class="info" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              File which needs to be transformed. Supported file <span *ngIf="!isPreDefinedTagSelected">formats&nbsp;</span><span *ngIf="isPreDefinedTagSelected">format&nbsp;</span> <span *ngIf="isPreDefinedTagSelected">{{fileExtensionHolder}}&nbsp;</span><span *ngIf="!isPreDefinedTagSelected">.csv, .xlsx and .zip</span>
            <span *ngIf="!isPreDefinedTagSelected">.</span><span style="font-family: IBM Plex Sans;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #5D6778;" *ngIf="isPreDefinedTagSelected">View guidelines</span>
            </div>
            <!--  -->
            <div style="position: relative;">
              <div style="cursor: pointer; position: relative;" class="flexible-div-subtitle-ask"
              >
              <img (mouseleave)="enableGuidelineIcon=false" *ngIf="isPreDefinedTagSelected && enableGuidelineIcon" src="../../../../assets/images/flexible-div/question_circle.svg"
                alt="questionIcon" srcset="../../../../assets/images/flexible-div/question_circle_primary.svg">
              <img (mouseenter)="enableGuidelineIcon=true"  *ngIf="isPreDefinedTagSelected && !enableGuidelineIcon" src="../../../../assets/images/flexible-div/question_circle.svg"
                alt="questionIcon" srcset="../../../../assets/images/flexible-div/question_circle.svg">
              <div *ngIf="enableGuidelineIcon" class="flexible-div-subtitle-ask-icon-info-tale" style="height: 10px;
                      width: 10px;
                      padding: 12px;
                      background: #ffffff;
                      position: absolute;top: -5px;
                      left: 31px;
                      rotate: 45deg;
                      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);"></div>
              </div>
              <!-- pop-up -->
              <div *ngIf="enableGuidelineIcon" class="flexible-div-subtitle-ask-icon-info" style="height: auto;
              z-index: 9;
              width: 317px;
              padding: 12px;
              border-radius: 6px;
              background: rgb(255, 255, 255);
              position: absolute;top: -122px;
              left: 35px;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
              flex-direction: column;
              box-sizing: border-box;
              display: flex;" fxLayout="column"
                fxLayoutGap="10px">
                <div style="width: 100%;" fxLayout="column">
                  <div class="guidelines-title-style">File Upload Guidelines:</div>
                  <div *ngIf="fileExtensionHolder==='.zip'">
                    <ul>
                      <li class="guidelines-content-style">Please upload a ZIP file containing input files. Each file name must include  the keywords: <span class="guidelines-title-style">B1, B2, B3 and so on</span>.</li>
                      <li class="guidelines-content-style">File names must contain only <span class="guidelines-title-style">one keyword</span>, and <span class="guidelines-title-style">no duplicates</span> are allowed.</li>
                    </ul>
                  </div>
                  <div *ngIf="fileExtensionHolder==='.csv'">
                    <ul>
                      <li class="guidelines-content-style">File name must contain any one of the keywords: <span class="guidelines-title-style">B1, B2, B3 and so on</span>.</li>
                      <li class="guidelines-content-style">File names must contain only <span class="guidelines-title-style">one keyword</span>, and <span class="guidelines-title-style">no duplicates</span> are allowed.</li>
                    </ul>
                  </div>
                  <div *ngIf="fileExtensionHolder==='.xlsx'">
                    <ul>
                      <li class="guidelines-content-style">File name must contain any one of the keywords: <span class="guidelines-title-style">B1, B2, B3 and so on</span>.</li>
                      <li class="guidelines-content-style">File names must contain only <span class="guidelines-title-style">one keyword</span>, and <span class="guidelines-title-style">no duplicates</span> are allowed.</li>
                    </ul>
                  </div>
                  <div class="guidelines-title-style">Examples:</div>
                  <div *ngIf="fileExtensionHolder==='.zip'">
                    <ul style="margin-bottom: 0px !important;">
                      <li class="guidelines-content-style">Siemens - Canada12423_B1.xlsx</li>
                      <li class="guidelines-content-style">Siemens - B3 Canada12423.xlsx</li>
                    </ul>
                  </div>
                  <div *ngIf="fileExtensionHolder==='.csv'">
                    <ul style="margin-bottom: 0px !important;">
                      <li class="guidelines-content-style">Siemens - Canada12423_B1.csv</li>
                      <li class="guidelines-content-style">Siemens - B3 Canada12423.csv</li>
                    </ul>
                  </div>
                  <div *ngIf="fileExtensionHolder==='.xlsx'">
                    <ul style="margin-bottom: 0px !important;">
                      <li class="guidelines-content-style">Siemens - Canada12423_B1.xlsx</li>
                      <li class="guidelines-content-style">Siemens - B3 Canada12423.xlsx</li>
                    </ul>
                  </div>
                  <!-- <div class="dont-show-this-div" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <mat-checkbox color="primary" class="got-it-checkbox">Don't show this again</mat-checkbox>
                    </div>
                    <div>
                      <button type="button" style="height: 32px;cursor: pointer;
                            padding: 8px 16px;border: none;
                            border-radius: 6px;
                            background: var(--Primary-600, #1042F1);color: var(--Gray-white, var(--Surface-50, #FFF));font-family: 'IBM Plex Sans';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 15px;">Got it</button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <!--  -->
          </div>
          
          <input #fileInput (change)="onInputFileSelect($event)" type="file" id="fileInput" [accept]="getAcceptedFileTypes()" hidden />
          <div *ngIf="!inputFileName" class="file-selection" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25px">
            <div (click)="fileInput.click()" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="new-file">
              <img src="../../../../assets/images/upload-screen/Monitor.svg" />
              <span style="font-weight: bold;">Upload new file</span>
            </div>
            <div (click)="openFileSelector('ip')" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="prev-file">
              <img src="../../../../assets/images/upload-screen/previous-file.svg" />
              <span style="font-weight: bold;">Previously uploaded</span>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="gdrive">
              <img src="../../../../assets/images/upload-screen/gdrive.svg" />
              <span style="font-weight: bold;">Google drive <br> (coming soon)</span>
            </div>
          </div>
          
          <div *ngIf="inputFileName" class="chip" matTooltip="{{inputFileName}}" matTooltipPosition="above" matTooltipClass="custom-tooltip">
            <div class="text">
              {{ trimFileName(inputFileName, 20) }}
            </div>
            <span (click)="clearInputFile()" class="closebtn">
              <mat-icon (click)="clearPreviousSelection('ip')">close</mat-icon>
            </span>
          </div>

          <mat-label style="color: #1B263B;font-weight: 500;">Sample output file
          </mat-label>
          <!-- <mat-label *ngIf="fileExtensionHolder==='.zip'" style="color: #1B263B;font-weight: 500;">Select output template
          </mat-label> -->
          <span class="info">Example of the file into which you want your data transformed. <br> Supported file formats are .csv and .xlsx.</span>
            <!-- <span *ngIf="fileExtensionHolder==='.zip'" class="info">Please select a output template</span> -->
          <!--  -->
          <div>
            <input #fileOutput (change)="onOutputFileSelect($event)" type="file" id="fileInput" accept=".csv, .xlsx"
            hidden />
            <div *ngIf="!outputFileName" class="file-selection" fxLayout="row" fxLayoutAlign="space-between center"
              fxLayoutGap="25px">
              <div [class.disableOutputUpload]=" isPreDefinedTagSelected|| isZippedInputFileUploaded || selectedValue" (click)="!isZippedInputFileUploaded && (selectedValue==null) && fileOutput.click()" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
                class="new-file">
                <img src="../../../../assets/images/upload-screen/Monitor.svg" />
                <span><strong>Upload new file</strong></span>
              </div>

              <div [class.disableOutputUpload]="isPreDefinedTagSelected ||isZippedInputFileUploaded || selectedValue" (click)="!isZippedInputFileUploaded && (selectedValue==null)  && openFileSelector('op')" fxLayout="column" fxLayoutGap="10px"
                fxLayoutAlign="space-between center" class="prev-file">
                <img src="../../../../assets/images/upload-screen/previous-file.svg" />
                <span><strong>Previously uploaded</strong></span>
              </div>
              <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center" class="gdrive">
                <img src="../../../../assets/images/upload-screen/gdrive.svg" />
                <span><strong>Google drive <br> (coming soon)</strong></span>
              </div>
            </div>

            <div *ngIf="outputFileName" class="chip" [matTooltip]="outputFileName" matTooltipPosition="above" matTooltipClass="custom-tooltip">
              <div class="text">
                {{ trimFileName(outputFileName, 20) }}
              </div>
              <span (click)="clearOutputFile()" class="closebtn">
                <mat-icon (click)="clearPreviousSelection('op')">close</mat-icon>
              </span>
            </div>
          </div>
          <!-- <div *ngIf="fileExtensionHolder==='.zip'" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div class="client_output_template_holder" fxLayout="column" 
                 [class.selected]="uploadForm.get('chooseSonepar').value"
                 (click)="selectTemplate('chooseSonepar')">
              <div class="client_logo_holder">
                <img src="../../../../assets/images/custom-tags/sonepar.png" alt="sonepar_logo">
              </div>
              <div class="client_title_holder" fxLayout="row" fxLayoutAlign="space-between">
                <div class="client-title">Sonepar</div>
                <div class="client-checkbox">
                  <mat-checkbox formControlName="chooseSonepar"
                                [color]="primary"
                                (change)="chooseThisAsOutputTemplate($event, 'chooseSonepar')">
                  </mat-checkbox>
                </div>
              </div>
              <div class="client_description_holder">There are many variations of a of Lorem Ipsum</div>
            </div>
            <div class="client_output_template_holder" fxLayout="column"
                 [class.selected]="uploadForm.get('chooseHomeDepot').value"
                 (click)="selectTemplate('chooseHomeDepot')">
              <div class="client_logo_holder">
                <img src="../../../../assets/images/custom-tags/home_depot.png" alt="home_depot_logo">
              </div>
              <div class="client_title_holder" fxLayout="row" fxLayoutAlign="space-between">
                <div class="client-title">The Home Depot</div>
                <div class="client-checkbox">
                  <mat-checkbox formControlName="chooseHomeDepot"
                                [color]="primary"
                                (change)="chooseThisAsOutputTemplate($event, 'chooseHomeDepot')">
                  </mat-checkbox>
                </div>
              </div>
              <div class="client_description_holder">There are many variations of a of Lorem Ipsum</div>
            </div>
          </div> -->

          <!--  -->

          <div class="actions" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="buttons">
            <button class="btn" type="button" mat-stroked-button (click)="onReset()"
                    [disabled]="uploadForm.invalid && !inputFileName">
              Clear
            </button>
            <!-- ... existing code ... -->
            <button 
            [disabled]="!uploadForm.valid || !inputFileName || 
                        (!isPreDefinedTagSelected && !outputFileName && 
                        uploadForm.get('taskName')?.value && !isZippedInputFileUploaded)"
            [ngClass]="{'disabled-primary-button-style': !uploadForm.valid || !inputFileName || 
                        (!isPreDefinedTagSelected && !outputFileName && 
                        uploadForm.get('taskName')?.value && !isZippedInputFileUploaded)}"
            class="btn" 
            type="submit" 
            mat-flat-button 
            color="primary">
            Upload
            </button>
<!-- ... existing code ... -->
            <!--  -->
              
            <!--  -->
          </div>
        </form>
      </div>
      <div class="all-fields-text">All fields marked with an asterisk (<span style="color: red;">*</span>) are required
      </div>
    </div>
  </div>
