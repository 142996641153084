import { generate } from 'rxjs';

const ENDPOINTS: any = {
  user: {
    generate_login: '/api/auth/login',
    me: '/api/auth/users/me/',
    sign_in: '/api/accounts/login/',
    sign_up: '/api/auth/users/',
    forgot_password: '/api/accounts/recovery/',
    reset_password: '/api/accounts/password-reset/',
    log_out: '/api/accounts/logout/',
    sign_up_from_invite_link: '/api/accounts/invite-by-email/',
    accept_privacy_policy: '/api/auth/users/accept-privacy-policy/',
  },
  onBoarding: {
    send_mail: '/api/auth/users/send-privacy-policy/',
    profile_update: '/api/onboarding/profile/',
    mark_profile_completed: '/api/onboarding/profile/mark-profile-added/',
  },
  home: {
    batch_stats: '/api/batches/entity/batch_stats/',
    filterList: '/api/batches/distributor_supplier_list/',
    batchHeaders: '/api/batches/batch_list_headers/',
    batchList: '/api/batches/batch_list/',
    update_batch: 'api/batches/',
    sentToList: '/api/batches/',
    sendTo: '/api/batches/send_to/distributors/',
    downloadFile: '/api/batches/download/',
    recommendNewDist: '/api/core/new-feature-request/',
    resendEmail: '/api/auth/users/email-verification/',
    batchListTable: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/batch_list/',
    },

    batchListTableHeaders: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/batch_list_headers/',
    },

    assigneeuploadedbyFilters: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/users_list/',
    },
    // '/api/content-transformation/batches/asd123cvb567/users_list/',
    statusFilter: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/status_list/',
    },
    updateTags: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/add_remove_tags/',
    }, 
    updateTaskName: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/task_rename/',
    }
    ,
    getTags: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/batch/',
      pathC: '/tags/',
    },
    shareBatch: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/share/',
    },
    addAssignee: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/update_assignee/',
    },
  },
  // notifications: {
  //   count: '/api/notifications/count',
  //   list: '/api/notifications/list/',
  //   mark_as_read: '/api/notifications/mark-read/',
  //   send_reminder: '/api/notifications/send-reminder/',
  //   mark_all_as_read: '/api/notifications/mark-all-read-unread/',
  //   accept_reject_request: '/api/notifications/accept-reject-request/',
  // },
  messages: {
    count: {
      pathA: '/api/notification/',
      pathB: '/all-count/',
    },
    mark_as_read: {
      pathA: '/api/notification/mark-as-read/',
      pathB: '/',
    },
    mark_as_unread: {
      pathA: '/api/notification/mark-as-unread/',
      pathB: '/',
    },
    list: '/api/notification/all-notifications/',
    mark_all_as_read: '/api/notification/mark-all-as-read/',
  },
  account_settings: {
    list_memberships: '/api/auth/memberships/',
    organisations: '/api/auth/organisations/',
    invite_user_by_email: {
      pathA: '/api/auth/organisations/',
      pathB: '/invites/',
    },

    org_members_list: {
      pathA: '/api/auth/organisations/',
      pathB: '/members/',
    },
    get_invite_details: {
      pathA: '/api/auth/organisations/invites/',
      pathB: '/',
    },
    accept_or_reject_invite: {
      pathA: '/api/auth/memberships/',
      pathB: '/',
    },
    permissions: '/api/auth/users/permissions/',
    cancel_invite: '/api/auth/organisations/',
    leave_organization: {
      pathA: '/api/auth/organisations/',
      pathB: '/members/leave/',
    },
    remove_org: {
      pathA: '/api/auth/organisations/',
      pathB: '/members/remove/',
    },
    upgrade_plan: {
      pathA: '/api/auth/billing/',
      pathB: '/upgrade/',
    },
  },
  batch_upload: {
    // offline_suppliers: '/api/batches/offline/suppliers/list/',
    create: '/api/batches/create/',
    signed_url: {
      pathA: '/api/content-transformation/batches/upload/',
      pathB: '/upload_signed_url/',
    },
    complete: '/api/batches/upload/status/',
    input_output_list: {
      pathA: 'api/content-transformation/batches/upload/',
      pathB: '/input_output_files/',
    },
    batch_list: {
      pathA: 'api/content-transformation/batches/upload/',
      pathB: '/batch_list/',
    },
    batch_upload_complete: {
      pathA: 'api/content-transformation/batches/upload/',
      pathB: '/upload_complete/',
    },
    task_name_check: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/valid_task_name/',
    },
    interim_output_status: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/interim_output/status/',
      pathC: '/',
    },
    custom_tags: {
      pathA: 'api/content-transformation/batches/upload/',
      pathB: '/recipe_custom_tags/',
    },
  },

  my_profile: {
    edit_current_details: '/api/auth/users/myprofile/',
    change_password: '/api/auth/users/change-password/',
  },

  header_config: {
    get_sheets: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/input_output_files/review/',
      pathC: '/sheets/',
    },
    get_sheet_data: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/input_output_files/review/',
      pathC: '/data/',
    },
    configure_file: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/input_output_files/configure/',
    },
    configure_file_new: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/new_design/input_output_files/configure/',
    },
    saved_config: {
      pathA: 'api/content-transformation/batches/',
      pathB: '/input_output_files/saved_config/',
      pathC: '/',
    },
  },
  jss_output: {
    health: '/api/health',
    batch_schema: {
      pathA: '/api/batch/',
      pathB: '/schema',
    },
    load_output: '/api/load-output/',
    get_batch: {
      pathA: '/api/batch/',
      pathB: '/data',
    },
    data_table_headers: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/new_design/output_mapping/',
      pathC: '/',
    },
    data_table_content: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/new_design/output_data/',
      pathC: '/',
    },
    rename_task: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/task_rename/',
    },
    get_batch_detail: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/batch_detail/',
      pathC: '/',
    },
    input_data_table: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/new_design/input_data/',
      pathC: '/',
    },
    generate_output_file: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/new_design/',
      pathC: '/generate_output_file/',
    },
  },
  interim_output: {
    get_status: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/',
      pathC: '/input_redis_status/',
    },
    get_report: {
      pathA: ' /api/content-transformation/batches/',
      pathB: '/interim_output/previous_settings/summary',
    },
    get_action_history: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/chat_action/history',
    },
    undo_action: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/chat_action/undo/',
    },
    revert_action: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/chat_action/revert/',
    },
    rerun_action: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/chat_action/rerun/',
    },
    get_templates: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/conversations/templates/',
    },
    get_conversations: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/conversations',
    },
    save_chat: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/conversations/',
    },
    save_mapping: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/mapping/',
      pathC: '/change/',
    },
    get_columns: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/mapping',
    },
    get_data: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/data',
    },

    get_valid_values: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/valid_values',
    },
    save_valid_values: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/valid_values/',
      pathC: '/change/',
    },
    get_properties: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/properties',
    },
    save_properties: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/interim_output/properties/',
      pathC: '/change/',
    },
    update_column_mapping: {
      pathA: '/api/content-transformation/batches/',
      pathB: '/column_mapping/update/',
    },
  },
};
export { ENDPOINTS };
