import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { BatchUploadService } from 'src/app/_services/batch-upload.service';
import { LoadingService } from 'src/app/_services/loading.service';

@Component({
  selector: 'app-existing-files',
  templateUrl: './existing-files.component.html',
  styleUrls: ['./existing-files.component.scss'],
})
export class ExistingFilesComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  title: string = 'Select a File';
  inputFiles: any[] = [];
  outputFiles: any[] = [];
  searchResults: any[] = [];
  organisation_id: any;
  fileType: string;
  noFileFoundMsg: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ExistingFilesComponent>,
    private batchUploadService: BatchUploadService,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loadingService.setLoading(true);
    }, 0);
    this.organisation_id = localStorage.getItem('currentOrganizationCode');
    this.getInputOutputFile(this.organisation_id);
    if (this.data.from == 'ip') {
      this.fileType = 'input_file';
    } else {
      this.fileType = 'template_output_file';
    }
  }

  ngAfterViewInit() {
    // Create an observable from the keyup events on the search input field
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(300), // Delay for 300 milliseconds
        distinctUntilChanged(), // Only emit if the text has changed
        switchMap((event: any) => {
          const query = event.target.value;
          return this.batchUploadService.searchFiles(
            query,
            this.organisation_id,
            this.fileType
          );
        })
      )
      .subscribe({
        next: (resp: any) => {
          this.inputFiles = resp.input_files.map((file: any) => ({
            ...file,
            extension: this.getFileExtension(file.file_name),
          }));
          this.outputFiles = resp.template_output_files.map((file: any) => ({
            ...file,
            extension: this.getFileExtension(file.file_name),
          }));
          if (this.inputFiles.length == 0 || this.outputFiles.length == 0) {
            this.noFileFoundMsg = true;
          } else {
            this.noFileFoundMsg = false;
          }
        },
        error: (error: any) => {},
      });
  }

  onFileSelect(file: any) {
    this.dialogRef.close({ data: file });
  }

  // 
  // ======
  trimFileName(fileName: string, maxLength: number): string {
    // Extract the file extension from the fileName
    const extensionIndex = fileName.lastIndexOf('.');
    
    // Handle cases where there is no extension
    if (extensionIndex === -1) {
        return fileName.length > maxLength ? fileName.substring(0, maxLength) + '...' : fileName;
    }
    
    const extension = fileName.substring(extensionIndex);
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    
    // Calculate the maximum length for the base name
    const maxLengthForBaseName = maxLength;

    // Trim the base name if necessary and add ellipsis
    const trimmedBaseName = nameWithoutExtension.length > maxLengthForBaseName
        ? nameWithoutExtension.substring(0, maxLengthForBaseName) + '...'
        : nameWithoutExtension;

    // Return the trimmed base name with the ellipsis (if added) and extension
    return trimmedBaseName + extension;
}




  // ======

  // 

  onClose() {
    this.dialogRef.close({ data: null });
  }
  getInputOutputFile(organisation_id: any) {
    this.batchUploadService.getInputOutputFile(organisation_id).subscribe({
      next: (resp: any) => {
        this.loadingService.setLoading(false);
        this.inputFiles = resp.input_files.map((file: any) => ({
          ...file,
          extension: this.getFileExtension(file.file_name),
        }));
        this.outputFiles = resp.template_output_files.map((file: any) => ({
          ...file,
          extension: this.getFileExtension(file.file_name),
        }));
        // console.log(this.inputFiles);
      },
      error: (error: any) => {
        // Handle error
      },
    });
  }

  getFileExtension(fileName: string): string {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  }
}
