<div style="width: 500px; padding: 16px">
  <mat-icon style="float: right; top: -24px; right: -24px; color: gray; cursor: pointer" (click)="onClose()">close
  </mat-icon>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <mat-icon style="width: 42px; height: 42px" svgIcon="DangerTriangle"></mat-icon>
    <h3 style="margin-bottom: 0px; margin-top: 16px; text-align: center" mat-dialog-title>
      {{
        currentUserRole === "user"
          ? "Are you sure you want to leave  workspace?"
          : "Are you sure you want to remove " + displayName + " from workspace?"
      }}
    </h3>

    <mat-dialog-actions>
      <button mat-stroked-button color="warn" (click)="removeUserFromOrg()">
        Yes, I’m sure
      </button>
      <!-- <button mat-stroked-button *ngIf="data?.from === 'teamMembers'">
        Ok
      </button> -->
      <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
      <button mat-stroked-button (click)="onClose()">No, continue</button>
    </mat-dialog-actions>
  </div>
</div>
