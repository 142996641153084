import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
} from '@angular/forms';
import { ValidationService } from 'src/app/_services/validation.service';
import { MyprofileService } from 'src/app/_services/myprofile.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { UserService } from 'src/app/_services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TopNavComponent } from '../navigation/top-nav/top-nav.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  providers: [FormGroupDirective],
})
export class MyProfileComponent implements OnInit {
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  showPassword3: boolean = false;
  showSaveButton = false;
  myProfileForm: FormGroup;
  changePasswordForm: FormGroup;
  currentProfileDetails: any;
  disableSaveChangePassword: boolean;
  isFormEdited: boolean;
  @ViewChild('topbarComponentRef', { static: true })
  topbarComponentRef: TopNavComponent;
  subscription: Subscription;
  saveInProgress: boolean;
  editMode: boolean = false;
  constructor(
    private fb: FormBuilder,
    private formValidation: ValidationService,
    private myProfileService: MyprofileService,
    private snackBar: SnackbarService,
    private userService: UserService,
    private formDirective: FormGroupDirective
  ) {}

  ngOnInit() {
    this.disableSaveChangePassword = false;
    this.currentProfileDetails = JSON.parse(
      localStorage.getItem('user') || '{}'
    );
    
    this.myProfileForm = this.fb.group({
      display_name: new FormControl('', [
        Validators.pattern(/^[a-zA-Z][a-zA-Z0-9]*$/),
        Validators.maxLength(20),
      ]),
      first_name: new FormControl('', [
        Validators.pattern(/^[a-zA-Z]+$/),
        Validators.maxLength(20),
      ]),
      last_name: new FormControl('', [
        Validators.pattern(/^[a-zA-Z]+$/),
        Validators.maxLength(20),
      ]),

      contact_number: new FormControl('', [
        this.formValidation.numberValidation(),
      ]),
    });
    this.subscription = this.myProfileService.clipboardText$.subscribe(
      (text) => {
        this.myProfileForm.get('contact_number')?.setValue(text);
      }
    );
    // this.myProfileForm.disable();

    this.changePasswordForm = this.fb.group(
      {
        current_password: new FormControl(
          '',
          Validators.compose([Validators.required])
        ),
        new_password: new FormControl(
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(35),
            this.formValidation.patternValidator(),
            this.formValidation.patternValidatorSpecialChar(),
          ])
        ),
        confirm_password: new FormControl('', [Validators.required]),
      },
      {
        validator: this.formValidation.matchPassword(
          'new_password',
          'confirm_password'
        ),
      }
    );
  }

  /**
   * Getter method for accessing form controls of the 'myProfileForm'.
   * Provides a convenient way to access form controls within the class.
   * @returns Form controls of the 'myProfileForm'.
   */
  get resetPasswordFormControl() {
    return this.myProfileForm.controls;
  }
  get resetPasswordFormSecondControl() {
    return this.changePasswordForm.controls;
  }

  /** show/hide password 1 */
  password1() {
    this.showPassword1 = !this.showPassword1;
  }

  /** show/hide password 2 */
  password2() {
    this.showPassword2 = !this.showPassword2;
  }

  /** show/hide password 2 */
  password3() {
    this.showPassword3 = !this.showPassword3;
  }

  /**
   * Determines the appropriate CSS class based on the validity state of a form control.
   *
   * @param control - The name of the form control.
   * @param error - The specific validation error to check for.
   * @returns A CSS class ('text-red' or 'text-green') based on the control's validity.
   */
  imageDependentText(control: string, error: string) {
    if (this.myProfileForm.get(control)?.value) {
      if (this.myProfileForm.get(control)?.hasError(error)) {
        return 'text-red';
      } else {
        return 'text-green';
      }
    }
    return '';
  }

  save() {}
  /**
   * Initiates the process to save and update the user's password.
   * Disables the 'Save Changes' button during the operation.
   */
  saveChangedPassword() {
    this.disableSaveChangePassword = true;
    this.myProfileService
      .editPassword(this.changePasswordForm.value)
      .subscribe({
        next: (resp: any) => {
          this.resetChangePasswordForm();
          this.snackBar.showSnackbar(
            'Password reset successful',
            '',
            'success'
          );
          this.userService.logUserOut();
        },
        error: (error: HttpErrorResponse) => {
          this.disableSaveChangePassword = true;
          this.resetChangePasswordForm();
          this.snackBar.showSnackbar(
            `Please enter the correct password`,
            '',
            'error'
          );
        },
      });
  }

  /**
   * Resets the change password form to its initial state.
   * Enables the 'Save Changes' button and clears form values.
   */
  resetChangePasswordForm = () => {
    this.disableSaveChangePassword = false;

    this.changePasswordForm.reset();
    this.showPassword1 = false;
    this.showPassword2 = false;
    this.showPassword3 = false;
  };
  /**
   * Toggles the visibility of the 'Save' button and enables/disables form fields for profile editing.
   * This function is typically used to switch between editing and non-editing states.
   */
  toggleEditButton() {
    this.showSaveButton = !this.showSaveButton;
    this.editMode = true;
    this.myProfileForm.enable();
    // Keep display_name disabled
    this.myProfileForm.get('display_name')?.disable();
    
    this.validateAllFormFields(this.myProfileForm);
  }
  /**
   * Recursively marks all form fields within a FormGroup as touched to trigger validation.
   *
   * @param formGroup - The FormGroup containing the form controls to be validated.
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Saves the edited values from the 'myProfileForm' by updating the user's profile.
   * Disables the form, triggers UI changes, and communicates with the backend service.
   */
  saveEditedValue() {
    this.saveInProgress = true;
    
    this.myProfileService
      .editCurrentUserProfile(this.myProfileForm.value)
      .subscribe(
        (resp) => {
          if (resp) {
            this.editMode = false;
            this.saveInProgress = false;
            this.userService.me().subscribe({
              next: (resp: any) => {
                this.showSaveButton = !this.showSaveButton;
                // this.myProfileForm.disable();
                localStorage.setItem('user', JSON.stringify(resp));
                this.userService.emitToChangeUserInitials();

                this.snackBar.showSnackbar(
                  'Profile details edited successfully',
                  '',
                  'success'
                );
              },
            });
          }
        },
        (error: HttpErrorResponse) => {
          this.saveInProgress = false;
          this.snackBar.showSnackbar(error.error.detail, '', 'error');
        }
      );
  }
  /**
   * Custom validator function to check if the 'new_password' and 'confirm_password' fields match.
   * Returns a validation error if the passwords do not match.
   *
   * @returns A ValidatorFn function for use in reactive form validation.
   */
  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const newPassword = control.get('new_password');
      const confirmPassword = control.get('confirm_password');

      if (
        newPassword &&
        confirmPassword &&
        newPassword.value !== confirmPassword.value
      ) {
        return { passwordMismatch: true };
      }

      return null;
    };
  }

  // this is to restrict certain speacial char and alphabets letters from contact number.
  onKeyPress(event: KeyboardEvent): void {
    const allowedChars = /[-+()\d\s.]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!allowedChars.test(inputChar)) {
      event.preventDefault();
    }
  }
}
