import { Directive, ElementRef, HostListener } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { MyprofileService } from '../_services/myprofile.service';
import { ValidationService } from '../_services/validation.service';

@Directive({
  selector: '[appCharPrevent]',
})
export class CharPreventDirective {
  canPaste: boolean = false;
  constructor(
    private el: ElementRef,
    private formValidation: ValidationService,
    private myProfileService: MyprofileService
  ) {}
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevent the default paste action
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData ? clipboardData.getData('text') : '';
    // Define your regex pattern here
    const regex =/^[0-9\s().+\-]*$/;
    // Check if the pasted text matches the regex
    if (regex.test(pastedText)) {
      const currentVal = this.el.nativeElement.value;
      this.el.nativeElement.value = currentVal + pastedText; // Append pasted text
      this.myProfileService.setClipboardText(pastedText);
    } else {
      return false;
    }
  }
}
