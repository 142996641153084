import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../_globals/endpoints.global';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  userData: any = { email: '', name: '', status: '' };
  constructor(private http: HttpClient, private globals: Globals) {
    this.cancelInvite('hjhjhhs', 'djhsjhd');
    this.removeUserFromOrgOrganization('sjskskjdj', 'user');
  }

  /**
   * Lists organization memberships for the logged-in user.
   * Constructs the endpoint using the globals service to join the URL parts.
   * Executes an HTTP GET request to retrieve organization memberships.
   * @returns An Observable containing the HTTP response for organization memberships.
   */
  listMemberships = () => {
    const listMembershipsEndpoint = this.globals.urlJoin(
      'account_settings',
      'list_memberships'
    );
    return this.http.get(listMembershipsEndpoint);
  };

  /**
   * Invites users by email to a specific organization.
   * Constructs the endpoint using the globals service to join the URL parts with organization code.
   * Executes an HTTP POST request to invite users with specified email addresses.
   * @param org_code - The organization code to which users are invited.
   * @param emails - An array of email addresses to invite.
   * @returns An Observable containing the HTTP response for the invitation.
   */
  inviteUserByEmail = (org_code: any, emails: string[]) => {
    const inviteUserEndpoint = this.globals.urlJoinWithParam(
      'account_settings',
      'invite_user_by_email',
      org_code
    );
    return this.http.post(inviteUserEndpoint, { emails: emails });
  };
  /**
   * Retrieves a list of organization members with optional search and pagination.
   * Constructs the endpoint using the globals service to join the URL parts with organization ID.
   * Executes an HTTP GET request to retrieve organization members.
   * @param page - The page number for pagination.
   * @param size - The page size for pagination.
   * @param search - The search query for filtering members.
   * @param pagination - A boolean indicating whether to enable pagination.
   * @param org_id - The identifier of the organization.
   * @returns An Observable containing the HTTP response for the organization members list.
   */
  orgMembersList = (
    page: number,
    size: number,
    search: any,
    pagination: boolean,
    org_id: any
  ) => {
    const options = {
      params: new HttpParams()
        .set('page', page)
        .set('page_size', size)
        .set('q', search)
        .set('pagination', pagination),
    };

    const orgMembersListEndpoint = this.globals.urlJoinWithParam(
      'account_settings',
      'org_members_list',
      org_id
    );
    return this.http.get(orgMembersListEndpoint, options);
  };
  /**
   * Retrieves invitation details from a token.
   * Constructs the endpoint using the globals service to join the URL parts with the invitation token.
   * Executes an HTTP GET request to retrieve invitation details.
   * @param token - The token associated with the invitation.
   * @returns An Observable containing the HTTP response for the invitation details.
   */
  getInvitationDetails = (token: any) => {
    const getInvitationDetailsEndpoint = this.globals.urlJoinWithParam(
      'account_settings',
      'get_invite_details',
      token
    );

    return this.http.get(getInvitationDetailsEndpoint);
  };

  /**
   * accept/reject invite
   * @param code
   * @returns
   */
  acceptOrRejectInvite = (code: any, obj: any) => {
    const acceptRejectInviteEndpoint = this.globals.urlJoinWithParam(
      'account_settings',
      'accept_or_reject_invite',
      code
    );
    return this.http.put(acceptRejectInviteEndpoint, obj);
  };
  /**
   * Retrieves organization details based on the organization code.
   * Constructs the endpoint using the globals service to join the URL parts.
   * Executes an HTTP GET request to retrieve organization details.
   * @param org_code - The code of the organization to retrieve.
   * @returns An Observable containing the HTTP response for the organization details.
   */
  getOrganization(org_code: any) {
    const organisationEndPoints = this.globals.urlJoin(
      'account_settings',
      'organisations'
    );
    return this.http.get(organisationEndPoints + org_code + '/');
  }
  /**
   * Edits organization details based on the provided organization code.
   * Constructs the endpoint using the globals service to join the URL parts.
   * Executes an HTTP PUT request to update organization details with the specified form values.
   * @param org_code - The code of the organization to edit.
   * @param formValue - The updated form values for the organization.
   * @returns An Observable containing the HTTP response for the organization edit.
   */
  editOrganization(org_code: any, formValue: any) {
    const organisationEndPoints = this.globals.urlJoin(
      'account_settings',
      'organisations'
    );
    return this.http.put(organisationEndPoints + org_code + '/', formValue);
  }
  /**
   * Retrieves permissions for the specified organization code.
   * Constructs the endpoint using the globals service to join the URL parts.
   * Executes an HTTP GET request to retrieve permissions with optional organization code.
   * @param org_code - Optional organization code to filter permissions.
   * @returns An Observable containing the HTTP response for the permissions.
   */
  getPermissions(org_code?: any): Observable<any> {
    let options;
    options = {
      params: new HttpParams().set('org_code', org_code),
    };

    const permissionEndpoint = this.globals.urlJoin(
      'account_settings',
      'permissions'
    );
    return this.http.get(permissionEndpoint, options);
  }
  /**
   * Cancels an invitation to join an organization.
   * Constructs the endpoint using the globals service to join the URL parts.
   * Executes an HTTP DELETE request to cancel the invitation.
   * @param org_id - The identifier of the organization.
   * @param invite_code - The code of the invitation to cancel.
   * @returns An Observable containing the HTTP response for the invitation cancellation.
   */
  cancelInvite = (org_id: any, invite_code: any) => {
    const cancleInviteEndPoint = this.globals.urlJoin(
      'account_settings',
      'cancel_invite'
    );
    return this.http
      .delete<any>(cancleInviteEndPoint + org_id + '/invites/' + invite_code)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => throwError(error))
      );
  };
  /**
   * Leaves the specified organization.
   * Constructs the endpoint using the globals service to join the URL parts with the organization ID.
   * Executes an HTTP DELETE request to leave the organization.
   * @param org_id - The identifier of the organization to leave.
   * @returns An Observable containing the HTTP response for leaving the organization.
   */
  leaveOrganization = (org_id: any) => {
    const leaveOrgEndPoint = this.globals.urlJoinWithParam(
      'account_settings',
      'leave_organization',
      org_id
    );
    return this.http.delete<any>(leaveOrgEndPoint);
  };
  /**
   * Removes a user from the specified organization.
   * Constructs the endpoint using the globals service to join the URL parts with the organization ID.
   * Executes an HTTP DELETE request to remove the user from the organization with optional user parameter.
   * @param org_id - The identifier of the organization.
   * @param user - Optional user parameter for additional filtering.
   * @returns An Observable containing the HTTP response for removing the user from the organization.
   */
  removeUserFromOrgOrganization = (org_id: any, user: any) => {
    let options;
    options = {
      params: new HttpParams().set('user', user),
    };

    const removeUserOrgEndPoint = this.globals.urlJoinWithParam(
      'account_settings',
      'remove_org',
      org_id
    );

    return this.http
      .delete<any>(removeUserOrgEndPoint, options)
      .pipe(map((resp) => resp));
  };
  
  /**
   * Method for upgrading a plan associated with a specific organization.   *
   * @param {any} org_code - The organization code identifying the organization for which the plan is to be upgraded.
   * @returns {Promise<any>} A promise representing the HTTP POST request to upgrade the plan.
   */
  upgradePlan = (org_code: any) => {
    const upgradePlanEndpoint = this.globals.urlJoinWithParam(
      'account_settings',
      'upgrade_plan',
      org_code
    );
    return this.http.post(upgradePlanEndpoint, null);
  };
}
