<div class="billing-wrapper">
  <div class="search-container-filters" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field style="width: 28%;padding-top: 10px;" appearance="outline" class="search-filter">
      <input id="search" matInput placeholder="Search invoice here..." #searchVal name="searchVal" />
      <mat-icon matPrefix svgIcon="search"></mat-icon>
    </mat-form-field>
    <button mat-raised-button color='primary'>Change plan</button>
  </div>
  <div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource">

      <!-- Billing Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> BILLING DATE </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> NAME </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> AMOUNT </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <!-- no_rows Column -->
      <ng-container matColumnDef="no_rows">
        <th mat-header-cell *matHeaderCellDef> # OF ROWS </th>
        <td mat-cell *matCellDef="let element"> {{element.no_rows}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>ACTION</th>
        <td mat-cell *matCellDef="let element">
            <button mat-stroked-button type="submit" class="upload-btn">
              <span>Download</span>
            </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]"  aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>
