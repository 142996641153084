<div *ngIf="isOnline" class="app-wrapper" (window:resize)="onResize($event)">
  <div class="app-content" *ngIf="isLowerResolution == false">
    <app-spinner></app-spinner>
    <router-outlet> </router-outlet>
  </div>
  <!-- 1280 resolution check  -->

  <!-- <div class="floating-button-div" *ngIf="isLowerResolution == false">
    <button class="help-juice-btn">
      <a
        href="https://help.datax.ai/digital-transformation"
        target="_blank"
        style="
          font-size: 12px;
          font-weight: 1000;
          color: #fff;
          text-decoration: none;
        "
        >HELP</a
      >
    </button>
  </div> -->
  <div class="resolution-container" *ngIf="isLowerResolution == true">
    <div class="info" fxFlexLayout="row" fxLayoutAlign="center">
      <mat-icon>info</mat-icon>
      <h1>Not supported for current resolution.</h1>
    </div>
  </div>
</div>
<!-- app.component.html -->
<div *ngIf="!isOnline" class="no-internet-container">
  <div class="info" fxFlexLayout="row" fxLayoutAlign="center">
    <mat-icon>info</mat-icon>
    <h1>No internet connection.</h1>
  </div>
</div>
