import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoWhitespace]'
})

  export class NoWhitespaceDirective {

    @HostListener('input', ['$event']) onInput(event: any) {
      const inputText = event.target.value;
      const formattedText = inputText.replace(/\s/g, ''); // Remove whitespace characters

      if (inputText !== formattedText) {
        event.target.value = formattedText;
        event.preventDefault();
      }
    }
  }
