import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
})
export class UsageComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'position',
    'name',
    'no_rows',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}

export interface PeriodicElement {
  name: string;
  position: any;
  no_rows: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.281',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.282',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.284',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Distributor 1 SKUs_CAX_nov7.28',
    no_rows: '10000',
  },
];
