import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  /* returns trimmed file name with extension */
  transform(value: string, limit: number): any {
    if (value) {
      const result = value.split('.');
      return result[0].length <= limit ? value : value?.slice(0, limit) + '...' + result[1];
    }
    return value;
  }

}
