<div style="width: 100%; padding: 16px 0;">
  <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <mat-icon style=" width: 42px;height: 42px" svgIcon="userInvitation"></mat-icon>
    <p class="invite-dialog-text"><b>{{invitedBy}}</b> has sent you an invite to join <b>{{orgName}}</b> workspace.</p>
    <mat-dialog-actions class="invite-request">
      <button (click)="acceptOrRejectInvite(false)" matTooltip="Reject this request" matTooltipPosition="above"
        class="change-status-button" [disabled]="!enableInviteActions" mat-button mat-stroked-button>Reject</button>
      <button (click)="acceptOrRejectInvite(true)" matTooltip="Accept this request" matTooltipPosition="above"
        [disabled]="!enableInviteActions" mat-raised-button color="primary">Accept</button>
    </mat-dialog-actions>
  </div>
</div>
