import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Globals } from '../_globals/endpoints.global';

@Injectable({
  providedIn: 'root',
})
export class MyprofileService {
  constructor(private globals: Globals, private http: HttpClient) {}
  /**
   * Updates the current user's profile details with the provided form values.
   *
   * @param formValue - The form values containing the updated profile information.
   * @returns An Observable emitting the response from the profile update API endpoint.
   */
  editCurrentUserProfile(formValue: any): Observable<any> {
    const editProfileEndpoint = this.globals.urlJoin(
      'my_profile',
      'edit_current_details'
    );
    return this.http.put(editProfileEndpoint, formValue);
  }
  /**
   * Updates the current user's password with the provided form values.
   *
   * @param formValue - The form values containing the updated password information.
   * @returns An Observable emitting the response from the password update API endpoint.
   */
  editPassword(formValue: any): Observable<any> {
    const passwordEndpoint = this.globals.urlJoin(
      'my_profile',
      'change_password'
    );
    return this.http.patch(passwordEndpoint, formValue);
  }

  private clipboardTextSubject = new Subject<string>();
  clipboardText$ = this.clipboardTextSubject.asObservable();

  setClipboardText(text: string) {
    this.clipboardTextSubject.next(text);
  }
}
