import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SidePanelService } from 'src/app/_services/side-panel.service';
import { SideNavDirection } from './side-panel-direction';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
  showSideNav: Observable<boolean>;
  /**
   * The SidePanelComponent represents a customizable side panel with dynamic width, direction, and transition.
   * It uses the SidePanelService to manage the visibility state of the side panel.
   *
   * @Input sidenavTemplateRef: The reference to the template of the side panel.
   * @Input duration: The duration of the side panel's transition animation (default is 0.25 seconds).
   * @Input navWidth: The width of the side panel (default is the window's inner width).
   * @Input direction: The direction of the side panel (default is SideNavDirection.Left).
   * @Output close: Event emitter triggered when the side panel is closed.
   */
  @Input() sidenavTemplateRef: any;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: SideNavDirection = SideNavDirection.Left;
  @Output() close = new EventEmitter<boolean>();

  constructor(private sidepanelService: SidePanelService) {}

  ngOnInit(): void {
    this.showSideNav = this.sidepanelService.getShowNav();
  }
  /**
   * Handles the event when the sidebar is closed.
   * Sets the visibility state of the side panel to false and emits the close event.
   */
  onSidebarClose() {
    this.sidepanelService.setShowNav(false);
    this.close.emit(true);
  }
  /**
   * Generates the inline styles for the side panel based on its visibility state.
   * @param showNav - Indicates whether the side panel is visible or not.
   * @returns An object representing the inline styles for the side panel.
   */
  getSideNavBarStyle(showNav: boolean) {
    let navBarStyle: any = {};

    navBarStyle.transition =
      this.direction +
      ' ' +
      this.duration +
      's, visibility ' +
      this.duration +
      's';
    navBarStyle.width = this.navWidth + 'px';
    navBarStyle[this.direction] = (showNav ? 0 : this.navWidth * -1) + 'px';

    return navBarStyle;
  }
}
