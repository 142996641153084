import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications:Array<any> = [
    {
      "id": 1,
      "message": "Processing of the file Distributor 1 SKUs_CAX_nov7.28 uploaded by Robert Brown is complete.",
      "timestamp": "2023-01-02T14:31:07Z",
      "highlight": {
        "text": ["Distributor 1 SKUs_CAX_nov7.28","Robert Brown"],
        // "type": "Distributor 1 SKUs_CAX_nov7.28"
      },
      "initial":"RB",
      "unread": true,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "1",
      "distributor_code": null
    },
    {
      "id": 2,
      "message": "The task Distributor 3 SKUs_CAX_nov7.28 initiated by Leslie Livingston, has not been completed because we encountered an error.",
      "timestamp": "2023-01-01T06:00:00Z",
      "highlight": {
        "text": ["Distributor 3 SKUs_CAX_nov7.28", "Leslie Livingston"],
        // "type": "supplier_signup"
      },
      "initial":"LL",
      "unread": true,
      "accepted": false,
      "send_reminder": "mayuri.jha@crowdanalytix.com",
      "supplier_code": "1",
      "distributor_code": null
    },
    {
      "id": 3,
      "message": "The task Distributor 1 SKUs_CAX_Sep7.28 is in 'Interim Review Output' stage and needs your attention.",
      "timestamp": "2023-01-02T12:00:00Z",
      "highlight": {
        "text": ["Distributor 1 SKUs_CAX_Sep7.28", "Interim Review Output"],
        // "type": "batch_id"
      },
      "initial":"RB",
      "unread": false,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "2",
      "distributor_code": null
    },
    {
      "id": 4,
      "message": "Processing of the file Distributor 1 SKUs_CAX_nov7.28 uploaded by Robert Brown is complete.",
      "timestamp": "2022-12-22T14:30:15Z",
      "highlight": {
        "text": ["Distributor 1 SKUs_CAX_nov7.28", "Robert Brown"],
        // "type": "batch_id"
      },
      "initial":"RB",
      "unread": false,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "1",
      "distributor_code": null
    },
    {
      "id": 5,
      "message": "The task Distributor 3 SKUs_CAX_nov7.28 initiated by Leslie Livingston, has not been completed because we encountered an error.",
      "timestamp": "2022-12-22T14:38:46Z",
      "highlight": {
        "text": ["Distributor 3 SKUs_CAX_nov7.28", "Leslie Livingston"],
        // "type": "supplier"
      },
      "initial":"LL",
      "unread": false,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "2",
      "distributor_code": null
    }
  ];
  isNewVersion: any;

  constructor(private appConfig: AppConfigService) { }

  ngOnInit(): void {
    this.isNewVersion = this.appConfig.getConfig().is_new_version;
  }

}
