<div class="tnc-wrapper">
  <!-- Main wrapper -->
  <div class="page-title tnc-header">
    <h1>Privacy Policy</h1>
    <!-- <h1 *ngIf="finishBtn">2.Terms of Services</h1> -->
    <!-- <p>Last updated Apr 24,2023.</p> -->
  </div>
  <!-- Pdf wrapper -->
  <div class="pdf-wrapper">
    <app-pdf-viewer
      (btnValue)="getBtnValue($event)"
      [src]="privacyPolicySrc"
    ></app-pdf-viewer>
  </div>

  <mat-divider></mat-divider>
  <form [formGroup]="pdfForm" class="privacy-policy">
    <mat-checkbox
      #confirmed
      color="primary"
      class="agree-checkbox"
      [ngClass]="{ hidecheckbox: disableBtn }"
      formControlName="privacyPolicyAgree"
    >
      I agree with privacy policy</mat-checkbox
    >
  </form>

  <!-- Footer -->
  <div
    class="footer-nav"
    fxLayout="row"
    fxLayoutAlign="start end"
    fxLayoutGap="10"
  >
    <div class="email-copy" fxFlex="90">
      <button (click)="logout()" mat-stroked-button>
        <mat-icon matPrefix svgIcon="logout"></mat-icon>
        Logout
      </button>
    </div>

    <div class="email-copy">
      <button (click)="sendMail()" mat-stroked-button>
        Send a copy on my email
      </button>
    </div>

    <button
      class="agree-button"
      mat-raised-button
      [disabled]="!confirmed?.checked"
      type="submit"
      fxLayoutAlign="space-between start"
      (click)="acceptTermsofService()"
      color="primary"
    >
      I agree
    </button>
  </div>
</div>
