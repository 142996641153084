import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ActivatedRoute, Params } from '@angular/router';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';
import { AppConfigService } from 'src/app/_services/app-config.service';
import { JssOutputService } from 'src/app/_services/jss-output.service';
import { LoadingService } from 'src/app/_services/loading.service';

@Component({
  selector: 'app-view-output',
  templateUrl: './view-output.component.html',
  styleUrls: ['./view-output.component.scss'],
})
export class ViewOutputComponent implements OnInit {
  allColumns: Array<any> = []; //column headers
  allFilterColumns: Array<any> = []; //separate list of columns for mat options
  page:number = 1;
  size:number = 100;
  totalItems: number;
  total_pages: number;
  activeOrgID: string | null;
  batchID: string;
  disableSave: boolean = false;
  taskName: string;
  disableEditFlag: boolean;
  disableEditBoolValue: any;
  isNewVersion: any;
  constructor(
    private jssOutputService: JssOutputService,
    private loader: LoadingService,
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.isNewVersion = this.appConfig.getConfig().is_new_version;
    this.isBusy = true;
    this.loader.setLoading(true);
    this.activeOrgID = localStorage.getItem('currentOrganizationCode');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.batchID = params['batch_id'];
      this.taskName = params['task_name'];
      this.disableEditFlag = params['disable_edit'] === 'true' ? true : false;
    });

    this.getBatchSchema(this.batchID);
    const isSampleTask = localStorage.getItem('isSampleTask');
    if (isSampleTask) {
      this.disableSave = true;
    }
  }

  // get column headers fillrates and output data
  getBatchSchema(batchID: any) {
    this.loader.setLoading(true);
    this.jssOutputService.getBatchSchema(batchID).subscribe((res: any) => {
      this.allColumns = JSON.parse(JSON.stringify(res.config));
      this.columnsList = res.config.filter( (obj:any) => obj.name !== "row_no");
      const data = JSON.parse(JSON.stringify(res.config));
      this.allFilterColumns = data.map((each: any) => ({
        ...each,
        disabled: false,
      }));
      this.allFilterColumns = this.allFilterColumns.filter( obj => obj.name !== "row_no")
      this.getFillRates(this.batchID);
      this.batchDetails(this.batchID);
    });
  }

  // Fetch fill rates
  allFillRates = null;
  getFillRates(batchID: any) {
    this.jssOutputService.getFillRates(batchID).subscribe((res: any) => {
      this.allFillRates = res;
    });
  }
  selectedSortColumn = '';
  searchQuery: string | null;
  selectedColumns: any = [];
  conditions: any = [
    { label: 'contains', value: 'contains' },
    { label: 'does not contain', value: 'does not contain' },
    { label: 'is blank', value: 'is blank' },
  ];
  selectedCondition = 'contains';

  isBusy: boolean = false;
  // Fetch final output data
  batchDetails(batchID: any) {
    let payload: any = {};
    this.loader.setLoading(true);
    if (!this.searchQuery && !this.sortedColumnName) {
      payload = {
        search: null,
        sort: null,
      };
    }
    if (this.searchQuery) {
      payload['search'] = {
        searchBy: this.selectedColumns,
        condition: this.selectedCondition,
        query: this.searchQuery,
      };
      if (this.sortedColumnName) {
        payload['sort'] = [
          {
            sortBy: this.sortedColumnName,
            order: this.sorting[this.sortedColumnName],
          },
        ];
      } else {
        payload['sort'] = null;
      }
    }
    if (this.sortedColumnName) {
      payload['sort'] = [
        {
          sortBy: this.sortedColumnName,
          order: this.sorting[this.sortedColumnName],
        },
      ];
      if (this.searchQuery) {
        payload['search'] = {
          searchBy: this.selectedColumns,
          condition: this.selectedCondition,
          query: this.searchQuery,
        };
      } else {
        payload['search'] = null;
      }
    }

    if (this.selectedCondition === 'is blank') {
      payload['search'] = {
        searchBy: this.selectedColumns,
        condition: this.selectedCondition,
        query: null,
      };
      if (this.sortedColumnName) {
        payload['sort'] = [
          {
            sortBy: this.sortedColumnName,
            order: this.sorting[this.sortedColumnName],
          },
        ];
      } else {
        payload['sort'] = null;
      }
    }

    this.jssOutputService
      .batchDetails(payload, batchID, this.page, this.size)
      .subscribe({
        next: (res) => {
          this.isBusy = false;
          this.loader.setLoading(false);
          this.totalItems = res.total_records;
          this.size = res.page_size;
          this.page = res.page;
          this.total_pages = res.total_pages;
          this.tableData = res.output;
        },
        error: (error) => {
          this.isBusy = false;
          this.loader.setLoading(false);
        },
      });
  }

  // Clearing the Sort form field
  clearAll() {
    this.selectedColumns = [];
    this.resetAllFilters();
  }
  /*** on pagination event */
  onPaginateChange = (e: any) => {
    this.page = e.pageIndex + 1;
    this.size = e.pageSize;
    this.batchDetails(this.batchID);
  };

  // Clear filter
  clearSearchFilter(e: any) {
    e.stopPropagation();
    this.searchQuery = '';
    this.selectedColumns = null;
    this.selectedCondition = 'contains';
    this.isSearchEntered = false;
    this.getBatchSchema(this.batchID);
  }

  // Reset filters and update output data
  resetAllFilters() {
    this.allFilterColumns.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled = false;
    });
    this.columnsList.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled = false;
    });
    this.selectedColumns = null;
    this.selectedSortColumn = '';
    if (this.searchQuery) {
      this.searchQuery = '';
      this.batchDetails(this.batchID);
    }
  }

  // Allow max 5 column selections
  onSelectSearchColumn() {
    this.allFilterColumns.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled =
        this.selectedColumns.length >= 5 &&
        !this.selectedColumns.includes(option.name);
    });
    this.columnsList.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled =
        this.selectedColumns.length >= 5 &&
        !this.selectedColumns.includes(option.name);
    });
  }

  // custom table

  columnsList: any[] = [];

  tableData: any[] = [];

  sorting: { [columnName: string]: 'asc' | 'desc' | null } = {};

  sortedColumnName: string | null;
  // Handle sort - defaults to ascending when active
  onSort(columnName: string) {
    this.sortedColumnName = columnName;
    if (!this.sorting[columnName]) {
      // Sort in ascending order
      this.sorting[columnName] = 'asc';
      this.batchDetails(this.batchID);
    } else if (this.sorting[columnName] === 'asc') {
      // Sort in descending order
      this.sorting[columnName] = 'desc';
      this.batchDetails(this.batchID);
    } else {
      // Deactivate sorting
      this.sorting[columnName] = null;
      this.sortedColumnName = null;
      this.batchDetails(this.batchID);
    }

    // Reset sorting for other columns
    Object.keys(this.sorting)
      .filter((col) => col !== columnName)
      .forEach((col) => (this.sorting[col] = null));
  }

  isSearchEntered: boolean = false;
  // Apply filter on enter press
  onEnterSearch() {
    this.isSearchEntered = true;
    this.batchDetails(this.batchID);
  }

  // filter columns
  // filterList(searchText: any) {
  //   const searchKey: string = searchText?.trim();
  //   this.allFilterColumns = this.columnsList.filter((option) =>
  //     option.name.toLowerCase().includes(searchKey.toLowerCase())
  //   );
  // }
  // =======
    filterList(searchText: any) {
      const searchKey: string = searchText?.trim().toLowerCase();
    
      this.allFilterColumns = this.columnsList.sort((a, b) => {
        // Compare the presence of searchText in each column name
        const aMatches = a.name.toLowerCase().includes(searchKey);
        const bMatches = b.name.toLowerCase().includes(searchKey);
    
        // Sort based on matches - bring matching columns to the top
        if (aMatches && !bMatches) {
          return -1;
        } else if (!aMatches && bMatches) {
          return 1;
        } else {
          // Preserve the original order for columns that have the same matching status
          return 0;
        }
      });
    }
  
  // =======

  @ViewChild('columnSelect') columnDropdown: MatSelect;
  openColumnDropdown() {
    if (!this.columnDropdown.panelOpen) this.columnDropdown.open();
  }

  // Apply filter on change of column selections
  onSelectCondition() {
    if (this.searchQuery) {
      this.isSearchEntered = true;
      this.batchDetails(this.batchID);
    }
    if (this.selectedCondition == 'is blank') {
      this.searchQuery = null;
      this.isSearchEntered = false;
      this.batchDetails(this.batchID);
    }
  }

  columnSearchText: string = '';
  // Apply filter when the panel closes
  onOpenCloseColumnPanel(isOpen: boolean) {
    if (!isOpen) {
      if (this.searchQuery) {
        this.batchDetails(this.batchID);
      }
      if (this.selectedCondition == 'is blank') {
        this.batchDetails(this.batchID);
      }
      this.columnSearchText = '';
      this.filterList(this.columnSearchText);
    }
  }

  // Switch to edit field
  editSearch() {
    this.isSearchEntered = false;
  }
}
