<div fxLayout="row" fxLayoutAlign="center center" style="height: 400px">
  <div class="invite-request">
    <div class="app-loader">
      <img src="./assets/images/dataX.ai-black.svg" />
      <div class="progress-bar">
        <div class="indefinite-progress"></div>
      </div>
    </div>
  </div>
</div>
