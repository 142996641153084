import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../_globals/endpoints.global';
import { delay, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InterimOutputService {
  constructor(private http: HttpClient, private globals: Globals) {}


  // Save mapping, apply to all rows
  saveMapping(orgID: any, batchID: any, data: any) {
    const endpoint = this.globals.urlJoinWithTwoParam(
      'interim_output',
      'save_mapping',
      orgID,
      batchID
    );
    return this.http.post(`${endpoint}`, data);
  }

  // get columns 
  getColumns(
    orgID: any,
    batchID: any,
    noInputMapping?: any,
    validValues?: any,
    columnSearchValue?: any,
    msgID?: any
  ) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'get_columns',
      orgID
    );
    return this.http.get(`${endpoint}/${batchID}/`, {
      params: {
        conversation_uid: msgID ? msgID : '',
        q: columnSearchValue ? columnSearchValue : '',
      },
    });
  }

  // get templates
  getTemplates(orgID: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'get_templates',
      orgID
    );
    return this.http.get(`${endpoint}`);
  }

  // get list of conversations (Chat history)
  getConversations(orgID: any, batchID: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'get_conversations',
      orgID
    );
    return this.http.get(`${endpoint}/${batchID}/`);
  }

  // get list of actions (change log)
  getActionHistory(orgID: any, batchID: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'get_action_history',
      orgID
    );
    return this.http.get(`${endpoint}/${batchID}/`);
  }

  // Undo action
  undoAction(orgID: any, data: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'undo_action',
      orgID
    );
    return this.http.put(`${endpoint}`, data);
  }

  // revert action
  revertAction(orgID: any, data: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'revert_action',
      orgID
    );
    return this.http.put(`${endpoint}`, data);
  }

  // rerun action
  rerunAction(orgID: any, data: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'rerun_action',
      orgID
    );
    return this.http.put(`${endpoint}`, data);
  }

  // Save the chat
  saveChat(orgID: any, data: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'save_chat',
      orgID
    );
    return this.http.post(`${endpoint}`, data);
  }

  // get interim output
  getData(
    orgID: any,
    batchID: any,
    noInputMapping?: any,
    validValues?: any,
    msgID?: any
  ) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'get_data',
      orgID
    );
    return this.http.get(`${endpoint}/${batchID}/`, {
      params: {
        conversation_uid: msgID ? msgID : '',
      },
    });
  }


  updateColumnMapping(orgID: any, data: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'update_column_mapping',
      orgID
    );

    return this.http.post(`${endpoint}`, data);
  }

  getReport(orgID: any, batch_id: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'interim_output',
      'get_report',
      orgID
    );
    return this.http.get(`${endpoint}/${batch_id}/`);
  }

  // get redis status 
  getStatus(orgID: any, batchID: any) {
    const endpoint = this.globals.urlJoinWithTwoParam(
      'interim_output',
      'get_status',
      orgID,
      batchID
    );
    return this.http.get(`${endpoint}`);
  }
}
