import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpXsrfTokenExtractor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    private router: Router,
    private userService: UserService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Set Credentials (Cookie)
    const header = 'X-CSRFToken';
    const token: any = this.tokenExtractor.getToken();
    request.clone({
      withCredentials: true,
    });

    if (
      this.tokenExtractor.getToken() &&
      !request.url.startsWith('https://storage.googleapis.com')
    ) {
      let headers: HttpHeaders;
      if (!request.url.includes('/static/email/')) {
        request = request.clone({
          headers: request.headers.set(header, token),
        });
      }
    }

    // error handling
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // if (!navigator.onLine) {
        //   this.router.navigate(['/offline'], {
        //     queryParams: { from: this.router.url },
        //   });
        // }
        // if (error.status === 403 || error.status === 401) {
        //   localStorage.clear();
        //   this.userService.logout();
        // }
        return throwError(error);
      })
    );
  }
}
