<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <div class="email-verification-alert" *ngIf="emailVerify === false" fxLayout="row" fxLayoutAlign="center center">
      <img src="../../../.././assets/images/danger-icon.svg" />A verification
      link has been emailed to you. Please verify to start using the app. To
      resend link, &nbsp;
      <a (click)="reSendEmail()" class="app-click-link">click here</a>
      <span class="email-verification-alert-closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
    </div>
    <!-- Title and subtile -->
    <div class="header">
      <h1 class="title">Dashboard</h1>
      <p class="subtitle">View and download transformed output files</p>
    </div>
    <div fxLayout="column" class="table-container">
      <form [formGroup]="filtersForm">
        <!-- Filters -->
        <div class="filter-section-conatiner" fxLayout="row" fxFlex="100" fxLayoutGap="15px"
          fxLayoutAlign="start center">
          <div class="search-container-filter" fxLayout="row" fxFlex="20">
            <!-- Search Field -->
            <mat-form-field appearance="outline" class="search-filter" fxFlex="70">
              <input id="search" matInput placeholder="Search by batch name & tags..." #searchVal name="searchVal"
                formControlName="searchFilter" (keyup)="search(getValue($event))" />
              <mat-icon matPrefix svgIcon="search"></mat-icon>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxFlex="80" fxLayoutGap="10px" fxLayoutAlign="center start">
            <!-- Uploaded by field -->
            <mat-form-field appearance="outline" fxFlex="16" fxFlex.md="14">
              <mat-select disableOptionCentering="true" name="uploaded-users" placeholder="Uploaded by"
                panelClass="drop-down-panel-upload-assignee" formControlName="uploadFilter"
                (closed)="onDropdownClosed()" multiple>
                <div fxLayout="row" fxLayoutGap="15px" class="filter-options-container"
                  fxLayoutAlign="space-around center">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <input id="search" matInput placeholder="Search by email" #searchUploadedByVal
                      name="searchUploadedByVal" [formControl]="name"
                      (keyup)="searchInUploadedBySelectDropdown($event)" />
                    <mat-icon matPrefix svgIcon="search"></mat-icon>
                    <mat-icon matSuffix style="cursor: pointer;" *ngIf="searchUploadedByVal.value"
                      (click)="clearSearchUploadedBy()">close</mat-icon>
                  </mat-form-field>
                </div>
                <div fxLayout="row" *ngIf="filteredUsers.length>0" fxLayoutAlign="space-between center"
                  class="select-and-clear-all">
                  <mat-checkbox class="custom-checkbox" [disableRipple]="true" formControlName="selectAll"
                    (change)="$event ? selectAllUsersUnderUploadedby($event) : null" [ngStyle]="{
                      color:
                        selectedUploadedByUserIds?.length == 0
                          ? '#506480'
                          : '#1042F1',
                      fontWeight:
                        selectedUploadedByUserIds?.length == 0 ? '500' : '600'
                    }" fxLayoutAlign="start center">
                    Select all
                  </mat-checkbox>
                  <!-- clear all -->
                  <span class="filter-reset-btn" (click)="clearAllUploadedBy()"
                    [ngClass]="{ 'clear-all-disabled': selectedUploadedByUserIds.length == 0 }">Clear all</span>

                </div>

                <div *ngIf="filteredUsers?.length === 0" class="no-search-results-found-msg">
                  No Results Found
                </div>
                <mat-option (onSelectionChange)="getIndividualSelectedValuesForUploadedBy($event)"
                  *ngFor="let user of filteredUsers; let i = index" [value]="user.id">
                  <span matTooltip="{{ user.name }}" matTooltipPosition="above">{{ user.name }}</span>
                </mat-option>
              </mat-select>
              <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
            </mat-form-field>
            <!-- Uploaded by field end -->

            <!-- Assignee field -->
            <mat-form-field appearance="outline" fxFlex="16" fxFlex.md="14">
              <mat-select name="assignee" placeholder="Assignee" panelClass="drop-down-panel-upload-assignee"
                formControlName="assigneeFilter" disableOptionCentering="true" (closed)="onAssigneeDropdownClosed()"
                multiple>
                <div fxLayout="row" fxLayoutGap="15px" class="filter-options-container"
                  fxLayoutAlign="space-around center">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <input id="search" placeholder="Search by email" matInput #searchAssigneeVal
                      name="searchAssigneeVal" [formControl]="assignee" (keyup)="searchAssignee($event)" />
                    <mat-icon matPrefix svgIcon="search"></mat-icon>
                    <mat-icon matSuffix style="cursor: pointer;" *ngIf="searchAssigneeVal.value"
                      (click)="clearSearchAssignee()">close</mat-icon>
                  </mat-form-field>
                </div>

                <div class="select-and-clear-all" *ngIf="filteredAssignees.length>0" fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <mat-checkbox class="custom-checkbox" [disableRipple]="true" formControlName="selectAllAssignee"
                    (change)="$event ? selectAllAssignee($event) : null" [ngStyle]="{
                      color:
                        selectedAssigniUserIds?.length == 0
                          ? '#506480'
                          : '#1042F1',
                      fontWeight:
                        selectedAssigniUserIds?.length == 0 ? '500' : '600'
                    }" fxLayoutAlign="start center">
                    Select all
                  </mat-checkbox>
                  <!-- clear all -->
                  <span class="filter-reset-btn" (click)="clearAllAssignee()"
                    [ngClass]="{ 'clear-all-disabled': selectedAssigniUserIds.length == 0 }">Clear all</span>

                </div>

                <div *ngIf="filteredAssignees?.length === 0" class="no-search-results-found-msg">
                  No Results Found
                </div>

                <mat-option (onSelectionChange)="getIndividualSelectedValuesForAssignee($event)"
                  *ngFor="let assignee of filteredAssignees; let i = index" [value]="assignee.id">
                  <span matTooltip="{{ assignee.name }}" matTooltipPosition="above">{{ assignee.name }}</span>
                </mat-option>
              </mat-select>
              <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
            </mat-form-field>
            <!-- Assignee field ends-->
            <!-- status dropdown -->
            <mat-form-field appearance="outline" fxFlex="16" fxFlex.md="14">
              <mat-select name="status" panelClass="drop-down-panel" placeholder="Status" formControlName="statusFilter"
                (selectionChange)="onChangeStatus()" disableOptionCentering>
                <mat-option *ngFor="let status of statusList" [value]="status.slug">
                  {{ status.display_name }}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
            </mat-form-field>
            <!-- Status Drop down ends -->
            <!-- Date Range-->
            <mat-form-field appearance="none" class="date-range-filter" fxFlex="20" fxFlex.md="14">
              <div (click)="picker.open()" fxLayout="row" fxLayoutAlign="start center" style="padding: 0 2px">
                <div>
                  <mat-datepicker-toggle matSuffix [for]="picker">
                    <mat-icon class="date-range-icon" matDatepickerToggleIcon svgIcon="calender" style="padding: 0;">
                    </mat-icon>
                  </mat-datepicker-toggle>
                </div>
                <span fxLayout="row" *ngIf="!selectedStartDate || !selectedEndDate"
                  class="data-range-pseudo-placeholder" fxFlex="100">
                  <span fxFlex="70" fxLayoutAlign="start center" fxFlex.md="70">Date range</span>
                  <mat-icon fxLayoutAlign="end center" svgIcon="DownArrow" (click)="picker.open()" fxFlex fxFlex.md>
                  </mat-icon>
                </span>
                <span class="selected-date-display" *ngIf="selectedStartDate || selectedEndDate" matTooltip=" {{ selectedStartDate }} -
                  {{ selectedEndDate }}" matTooltipPosition="above">
                  {{ selectedStartDate }} -
                  {{ selectedEndDate }}
                </span>

                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                    <button mat-raised-button color="primary" matDateRangePickerApply
                      (click)="submitSelectedDateRange()">
                      Ok
                    </button>
                    <button mat-button matDateRangePickerCancel (click)="clearDate()">
                      Cancel
                    </button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>

                <mat-date-range-input style="display: none" [formGroup]="filtersForm" [rangePicker]="picker"
                  [max]="maxDate">
                  <input matStartDate readonly formControlName="startDate" [(ngModel)]="selectedStartDate" />

                  <input matEndDate readonly formControlName="endDate" [(ngModel)]="selectedEndDate" />
                </mat-date-range-input>
              </div>
            </mat-form-field>
            <!-- Date range ends -->
            <!-- Clear -->
            <button fxFlex="4" fxFlex.md="4" type="button" mat-raised-button [disabled]="!emailVerify" class="clear-btn"
              (click)="clearAllFilter()">
              Clear
            </button>
            <!-- Clear button ends -->
            <!-- Refresh -->
            <img class="refresh-icon" src="../../../../assets/images/restart.svg" matTooltip="Refresh"
              matTooltipPosition="above" [ngClass]="{
                'disable-profile':
                  !emailVerify ||
                  (modifiedData?.length == 0 &&
                    filtersForm?.get('uploadFilter')?.value?.length! == 0 &&
                    filtersForm?.get('assigneeFilter')?.value?.length! == 0 &&
                    !filtersForm.get('statusFilter')?.value &&
                    !selectedStartDate &&
                    !filtersForm.get('searchFilter')?.value &&
                    !selectedEndDate)
              }" (click)="refreshDashboardTableContent()" />
            <!-- Refresh button ends -->
            <!-- Upload button -->
            <button fxFlex="18" fxFlex.md="18" matTooltip=" Upload files for transformation" matTooltipPosition="above"
              mat-raised-button color="primary" type="button" class="upload-btn" [disabled]="!emailVerify"
              [routerLink]="['/dashboard/upload-input-file']">
              <img src="../../../../assets/images/upload.svg" />
              Upload input file
            </button>
            <!-- Upload button ends -->
          </div>
        </div>
        <!-- Table Begins -->
      </form>
      <div class="table-section">
        <table mat-table [dataSource]="dashboardDataSource">
          <!-- Task Name Column -->
          <ng-container matColumnDef="TaskName">
            <th fxFlex="17" fxFlex.lg="16" fxFlex.xl="16" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[0]?.display_name  }}
            </th>
            <td style="padding-top: 16px; padding-right: 16px;" fxFlex="17" fxFlex.lg="16" fxFlex.xl="16" mat-cell
              *matCellDef="let element">
              <span fxLayout="row" [ngClass]="{ 'disable-profile': !emailVerify }" fxLayoutAlign="center space-between"
                fxLayoutGap="8px">
                <span class="task-name" fxFlex="85" fxLayoutAlign="start center" [ngClass]="{
                    disable:
                      element.status != 'In Review' &&
                      element.status != 'Completed'
                  }"><a [routerLink]="
                      element.status == 'In Review'
                        ? isNewVersion? '/dashboard/view-output-new': '/dashboard/interim-output'
                        : isNewVersion? '/dashboard/view-output-new': '/dashboard/view-output'
                    " [queryParams]="{
                      batch_id: element.batch_id,
                      task_name: element.task_name,
                      task_status: element.status,
                      disable_edit: element.disable_edit
                    }" matTooltip="{{ element.task_name.length > 7 ? element.task_name : ''  }}"
                    matTooltipPosition="above" (click)="onTaskClick(element)">{{ element.task_name }}</a>
                </span>
                <a [href]="element.input_file_url" fxFlex="15" fxLayoutAlign="center center"><img
                    src="../../../../assets/images/download-minimalistic.svg" style="padding-top: 2px"
                    matTooltip="Download input file" matTooltipPosition="above" /></a>
              </span>
              <span class="task-email" matTooltip="{{ element.uploaded_by != null ? element.uploaded_by: '' }}"
                matTooltipPosition="below">{{ element.uploaded_by != null ? (element.uploaded_by ): ' '  }}</span>
            </td>
          </ng-container>

          <!-- Uploaded On Column -->
          <ng-container matColumnDef="UploadedOn">
            <th fxFlex="12" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[1]?.display_name }}
            </th>
            <td style="padding-top: 16px;" fxFlex="12" mat-cell *matCellDef="let element">
              {{ element.uploaded_on | date }}
            </td>
          </ng-container>

          <!-- Tags Column -->
          <ng-container matColumnDef="Tags">
            <th fxFlex="16" fxFlex.lg="18" fxFlex.xl="18" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[2]?.display_name }}
            </th>
            <td style="padding-top: 16px;" fxFlex="16" fxFlex.lg="18" fxFlex.xl="18" mat-cell
              *matCellDef="let element; let indx = index">
              <div [ngClass]="{ 'disable-profile': !emailVerify }">
                <ng-container>
                  <div class="tags-main-container" style="display:flex; flex-direction:row">
                    <div class="tag" *ngFor="let chip of element.tags.visibleChips[0]">{{ chip.tag_name }}</div>
                  </div>
                  <div fxLayout="row">
                    <span class="more-tags" *ngIf="element.tags.hiddenChips[0].length > 0"
                      matTooltip="{{ generateTooltipContent(element.tags.hiddenChips[0]) }}"
                      matTooltipClass="tag-tooltip" matTooltipPosition="below">
                      +{{ element.tags.hiddenChips[0].length }}
                    </span>
                    <span class="tag-edit"
                      [ngStyle]="{'margin-top': element.tags.visibleChips[0].length === 0 ? '0px' : '8px'}"
                      (click)="openTagDialog(element.batch_id)">
                      <img height="22px" width="22px" src="../../../../assets/images/edit-tag.svg"
                        matTooltip="Edit or delete tags" matTooltipPosition="below" />
                    </span></div>

                </ng-container>
                <br>
              </div>
            </td>
          </ng-container>


          <!-- Assignee Column -->
          <ng-container matColumnDef="Assignee">
            <th fxFlex="18" fxFlex.lg="16" fxFlex.xl="18" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[3]?.display_name }}
            </th>
            <td style="padding-top: 16px;" fxFlex="18" fxFlex.lg="16" fxFlex.xl="18" mat-cell *matCellDef="let element">
              <span class="assignee-email" matTooltip="{{ element.assignee[0][0] }}" matTooltipPosition="above">{{
                  element?.assignee[0][0]
                    ? (element?.assignee[0][0])
                    : ""
                }}</span>

              <div class="assignee avatars" fxLayoutGap="8px"
                [ngStyle]="{'margin-top': element?.assignee[0][0] ? '8px' : '0px'}">
                <span *ngIf="element?.assignee[0]?.length > 1">
                  <div class="circle">
                    <span class="tooltip" matTooltip="{{ element.assignee[0].slice(1).join('\n') }}"
                      matTooltipPosition="above"
                      matTooltipClass="my-custom-tooltip">+{{ element?.assignee[0]?.length - 1 }}</span>
                  </div>
                </span>

                <span class="avatar assignee-profile" [ngClass]="{
                    'disable-assignee':
                      !emailVerify ||
                      listOfAssignees?.length === 0 ||
                      listOfAssignees?.length === 1
                  }" (click)="
                    openSidePanel(element?.assignee[0], element?.batch_id)
                  " matTooltip="{{
                    disabledEditAssignee(listOfAssignees?.length <= 1)
                  }}" matTooltipPosition="above">
                  <img src="../../../../assets/images/edit-assignee-col.svg" matTooltip="{{
                      addEditAssignee(listOfAssignees?.length > 1)
                    }}" matTooltipPosition="below" />
                </span>
              </div>
            </td>
          </ng-container>


          <!-- Total Rows -->
          <ng-container matColumnDef="Total Rows">
            <th fxFlex="6" fxFlex.lg="8" fxFlex.xl="10" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[4]?.display_name }}

            </th>
            <td style="padding-top: 16px;" fxFlex="6" fxFlex.lg="8" fxFlex.xl="10" mat-cell *matCellDef="let element">
              {{ element.final_output_row_count }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <th fxFlex="8" fxFlex.lg="10" fxFlex.xl="12" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[5]?.display_name }}
            </th>
            <td style="padding-top: 16px;" fxFlex="8" fxFlex.lg="10" fxFlex.xl="12" class="status-cell" mat-cell
              *matCellDef="let element" [ngClass]="{
                inactive_status:
                  element.status === 'Cancelled' || element.status === 'Error',
                active_status: element.status === 'Completed',
                review_status: element.status === 'In Review'
              }">
              {{ element.status }}
            </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="Action">
            <th fxFlex="24" fxFlex.lg="20" fxFlex.xl="14" mat-header-cell *matHeaderCellDef>
              {{ tableHeaders[6]?.display_name }}
            </th>
            <td style="padding-top: 16px; padding-right: 0px;" fxFlex="24" fxFlex.lg="20" fxFlex.xl="14" mat-cell
              *matCellDef="let element">
              <div class="actionbtn-container" [ngClass]="{ disable: element.status != 'Completed' }">
                <button type="button" (click)="openShareDialog(element?.batch_id)" mat-stroked-button
                  [disabled]="!emailVerify || featureFlagShareButton" class="share-btn">
                  <span>Share</span>
                </button>
                <button type="button" mat-raised-button color="primary" class="download-btn" [disabled]="!emailVerify"
                  (click)="
                    downloadMyOutputFile(element.output_file_url, $event)
                  ">
                  <img class="download-btn-icon" src="../../../../assets/images/download.svg" />
                  <span>Download </span>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div *ngIf="modifiedData?.length === 0" class="no-result-message">
          No Results Found
        </div>

        <!-- <div class="paginator" fxLayoutAlign="space-between center">
          <div class="skip-to-paginator" fxLayoutGap="10px"></div> -->
        <mat-paginator [length]="totalItems" [pageSize]="size" [pageIndex]="page - 1"
          [pageSizeOptions]="[5, 10, 12, 25, 50]" (page)="onPaginateChange($event)"
          [disabled]="!emailVerify || modifiedData?.length == 0">
        </mat-paginator>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
<app-side-panel [sidenavTemplateRef]="navContent" [direction]="'right'" [navWidth]="400" [duration]="0.5"
  *ngIf="loading">
</app-side-panel>
<ng-template #navContent>
  <div class="side-panel-heading">Add/Remove Assignee</div>
  <!-- <div class="assignee-side-pln-container"> -->
  <div fxLayout="column" fxLayoutAlign="start start">
    <div class="filters-section">
      <div class="search-container-filters">
        <mat-form-field style="width: 98%" appearance="outline" class="search-filter">
          <input (keyup)="searchAssigneeInPanel($event)" id="search" matInput placeholder="Search Assignee..."
            #searchVal name="searchVal" [(ngModel)]="searchAssigneeAddRemove" appNoWhitespace />
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="searchAssigneeAddRemove" (click)="clearSearch()">close
          </mat-icon>

          <!-- Display validation error message -->
        </mat-form-field>
      </div>
      <div class="list-of-members" *ngFor="let item of assigneeList; let i = index" fxLayout="row"
        fxLayoutAlign="space-between center" [ngClass]="item.added ? 'email-added' : 'email-not-added'">
        <div fxFlex="75">{{ item.email }}</div>
        <div fxFlex="25">
          <button class="add-btn" *ngIf="!item.added" mat-button (click)="addRemoveAssignee(item, 'add', i)">
            Add
          </button>
          <button *ngIf="showRemove(item)" mat-button class="remove-btn" (click)="addRemoveAssignee(item, 'remove', i)">
            Remove
          </button>
        </div>
      </div>

      <div class="no-search-addOrRemove" *ngIf="assigneeList?.length == 0">
        This assignee is not in your team.
      </div>
      <!-- </div> -->
    </div>
  </div>
  <button (click)="invitemembDialog()" mat-raised-button color="primary" class="invite-btn" [disabled]="
      !hasTeamMembersCreatePermission || !emailVerify || featureFlagInviteButton
    ">
    <span>Invite members</span>
  </button>
</ng-template>
