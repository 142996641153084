<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <nav
      class="breadcrumb"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="5px"
    >
      <span class="link" [routerLink]="'/dashboard'">Dashboard</span>
      <mat-icon>chevron_right</mat-icon>
      <span class="active">See All {{ dialogType | titlecase }}</span>
    </nav>

    <!-- Notification Section -->
    <div class="container-list">
      <div
        class="notifications"
        [class.unread]="!notification.read"
        *ngFor="let notification of dialogData; let i = index; let last = last"
      >
        <div
          fxLayout="row"
          class="each-notification"
          fxLayoutAlign="space-between start"
        >
          <div
            [style.cursor]="notification.read ? 'default' : 'pointer'"
            (click)="
              notification.read
                ? null
                : markAsRead(notification.notification_id)
            "
            class="msg"
            fxLayout="row"
            fxLayoutGap="5px"
          >
            <div
              class="avatar"
              id="user-avatar"
              fxLayout="column"
              fxLayoutAlign="center center"
              style="background-color: #24a5ce"
            >
              <span>{{ notification.initials }}</span>
            </div>
            <div fxLayout="column" fxLayoutGap="5px">
              <!-- <span
                innerHTML="{{ notification.message | highlightText: notification.highlight.text: notification.highlight.type : notification.highlight.entity_code  }}">
              </span> -->
              <span class="message">
                {{ notification.data }}
              </span>
              <span class="notify-date"
                >{{ notification.created_at | date : "MMM d, y" }} at
                {{ notification.created_at | date : "h:mm a" }}</span
              >
              <div
                class="user-actions"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
              >
                <button
                  (click)="
                    handleAction(notification.actions[0], notification.read)
                  "
                  *ngIf="
                    notification.actions && notification.actions.length > 0
                  "
                  mat-raised-button
                  color="primary"
                >
                  <img
                    *ngIf="dialogType === 'inbox'"
                    src="../../../../assets/images/download.svg"
                  />
                  {{ notification.actions[0].label }}
                </button>
                <button
                  (click)="
                    handleAction(notification.actions[1], notification.read)
                  "
                  *ngIf="
                    notification.actions && notification.actions.length > 1
                  "
                  mat-stroked-button
                  class="reject-button"
                >
                  {{ notification.actions[1].label }}
                </button>
              </div>
            </div>
          </div>
          <div
            style="width: 30px; cursor: pointer"
            *ngIf="!notification.read"
            fxLayout=" row"
            fxLayoutAlign="center center"
          >
            <!-- <span color="primary" *ngIf="notification.unread" (click)="markAsRead(notification.id, i)"
                class="mark-as-read"></span> -->
            <!-- <mat-icon [matMenuTriggerFor]="menu1" *ngIf="notification.unread && notificationType == 'NOTIFICATION'"
                  svgIcon="more"></mat-icon> -->

            <mat-icon
              [matMenuTriggerFor]="menu1"
              [class.markread]="notification.read"
              svgIcon="more"
            ></mat-icon>
            <mat-menu
              #menu1="matMenu"
              [xPosition]="'before'"
              [yPosition]="'below'"
            >
              <button
                mat-menu-item
                (click)="markAsRead(notification.notification_id)"
              >
                Mark as read
              </button>
            </mat-menu>
          </div>
          <div
            style="width: 30px; cursor: pointer"
            *ngIf="notification.read"
            fxLayout=" row"
            fxLayoutAlign="center center"
          >
            <!-- <span color="primary" *ngIf="notification.unread" (click)="markAsRead(notification.id, i)"
                class="mark-as-read"></span> -->
            <!-- <mat-icon [matMenuTriggerFor]="menu1" *ngIf="notification.unread && notificationType == 'NOTIFICATION'"
                  svgIcon="more"></mat-icon> -->

            <mat-icon
              [matMenuTriggerFor]="menu1"
              [class.markread]="notification.read"
              svgIcon="more"
            ></mat-icon>
            <mat-menu
              #menu1="matMenu"
              [xPosition]="'before'"
              [yPosition]="'below'"
            >
              <button
                mat-menu-item
                (click)="markAsUnRead(notification.notification_id)"
              >
                Mark as unread
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <!-- <div class="notification-paginator" fxLayoutAlign="row" fxLayoutAlign="end center"> -->
      <div
        class="paginator"
        style="border: 1px solid #ccc"
        fxLayoutAlign="space-between center"
      >
        <div class="skip-to-paginator" fxLayoutGap="10px">
          <!-- <span>Showing</span>
          <input min="1" type="number" [max]="totalPages" [value]="page" (keydown.enter)="goToPage($event)" />
          <span> of</span>
          <span>{{ totalPages }}</span> -->
        </div>
        <mat-paginator
          [length]="totalItems"
          [pageSize]="pageSize"
          [pageIndex]="page - 1"
          [pageSizeOptions]="[5, 10, 20, 25, 50]"
          (page)="onPaginateChange($event)"
        >
        </mat-paginator>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
