import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Globals } from '../_globals/endpoints.global';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AppConfigService } from 'src/app/_services/app-config.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: any;
  routePaths: any[] = [
    'onboarding',
    'select-network-entity',
    'edit-format',
    'signin',
    'signup',
    'password-recovery',
    'reset-password',
    'home',
    'notifications',
    'content-health',
    'discover',
    'account-settings',
    'my-network',
    'account-settings/user-edit',
    'my-network/edit-network',
  ];
  userPermissions: any;
  sharedValue: any;
  isNewVersion: any;

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    public auth: AuthService,
    private appConfig: AppConfigService
  ) {}

  private userInitials = new Subject<void>();
  userInitials$ = this.userInitials.asObservable();
  emitToChangeUserInitials() {
    this.userInitials.next();
  }

  // Change this value to true if you want to test invitation flow for local
  private isLocalSubject = new BehaviorSubject<boolean>(false);

  // Observable to get current isLocal value
  getIsLocal(): Observable<boolean> {
    return this.isLocalSubject.asObservable();
  }

  // Set value to observable when dialog is closed
  private closeDialogSubject = new BehaviorSubject<any>({
    isOpen: true,
    token: null,
  });
  closeDialog$ = this.closeDialogSubject.asObservable();

  // Getter
  getCloseDialog(): Observable<any> {
    return this.closeDialogSubject.asObservable();
  }

  // Setter
  setCloseDialog(value: any) {
    this.closeDialogSubject.next(value);
  }

  //** signUp **/
  signUp(formValue: any): Observable<any> {
    // const data = {
    //   organisation_type: formValue.organization,
    //   email: formValue.email,
    //   password: formValue.password,
    //   confirm_password: formValue.confirmPassword,
    // };
    const signUpEndpoint = this.globals.urlJoin('user', 'sign_up');
    return this.http.post(signUpEndpoint, formValue);
  }

  /*** sign up using invite link */
  signUpUsingInviteLink = (formValue: any, token: string): Observable<any> => {
    const data = {
      password: formValue.password,
      confirm_password: formValue.confirmPassword,
      token: token,
    };
    const signUpEndpoint = this.globals.urlJoin(
      'user',
      'sign_up_from_invite_link'
    );
    return this.http.post(signUpEndpoint, data);
  };

  generateLogin(): Observable<any> {
    const endpoint = this.globals.urlJoin('user', 'generate_login');
    return this.http.get(endpoint);
  }

  //** signIn **/
  signIn(formValue: any): Observable<any> {
    const data = {
      username: formValue.email,
      password: formValue.password,
    };
    const signInEndpoint = this.globals.urlJoin('user', 'sign_in');
    return this.http.post(signInEndpoint, data);
  }

  //** logout **/
  logout(): Observable<any> {
    const endpoint = this.globals.urlJoin('user', 'log_out');
    return this.http.get(endpoint);
  }

  /** ME api **/
  me(): Observable<any> {
    const endpoint = this.globals.urlJoin('user', 'me');
    // if (action == 'skip') {
    //   let headers = new HttpHeaders();
    //   headers = headers.set('skip', 'true');
    //   return this.http.get(endpoint, { headers: headers });
    // } else {
    //   return this.http.get(endpoint);
    // }
    return this.http.get(endpoint);
  }

  //** recover password **/
  recoverPassword(data: any): Observable<any> {
    const endpoint = this.globals.urlJoin('user', 'forgot_password');
    return this.http.get(endpoint, { params: { email: data.email } });
  }

  logUserOut = (inviteToken?: any) => {
    localStorage.clear();
    let returUrl;
    this.getIsLocal().subscribe({
      next: (res) => {
        // if `isLocal` is true and token is available
        if (res && inviteToken) {
          returUrl = `${environment.default_return_url}?local=true&invite=${inviteToken}`;
        }
        // if `isLocal` is true and token is not available
        else if (res && !inviteToken) {
          returUrl = `${environment.default_return_url}?local=true`;
        }
        // if `isLocal` is false and token is available
        else if (!res && inviteToken) {
          returUrl = `${environment.default_return_url}?invite=${inviteToken}`;
        } else {
          returUrl = `${environment.default_return_url}`;
        }
      },
    });
    this.auth.logout({
      logoutParams: { returnTo: `${returUrl}` },
    });
  };

  //** reset password **/
  resetPassword(formValue: any, token: string): Observable<any> {
    const data = {
      new_password: formValue.password,
      confirm_password: formValue.confirmPassword,
      token: token,
    };
    const endpoint = this.globals.urlJoin('user', 'reset_password');
    return this.http.post(endpoint, data);
  }

  /** decide user flow at login based on onboarded or not
   * redirect to `home` if onboarded */
  decideLandingPageOnLogin(
    onboardingCompleted: boolean,
    userPlan: string,
    userStatus: string
  ) {
    // 
    const isNewVersion = this.appConfig.getConfig().is_new_version;
    // 
    if (!onboardingCompleted) {
      this.router.navigate(['/onboarding']);
    } else {
      if (userPlan == 'PAID') {
        if (userStatus == 'CONFIGURING') {
          this.router.navigate(['/my-network']);
        } else {
          // 
          isNewVersion ? this.router.navigate(['/home-update-v1']) : this.router.navigate(['/home']);
          // 
          
        }
      } else {
        this.router.navigate(['/select-network-entity']);
      }
    }
  }

  /** determine user flow when reloading app */
  decideLandingPage(onboardingCompleted: boolean, userPlan: string) {
    if (!onboardingCompleted) {
      this.router.navigate(['/onboarding']);
    } else {
      let path = this.router.url.split('/').join('');
      // navigate to home route if path unavailable/valid
      if (!this.routeExists(path)) {
        this.appConfig.getConfig().is_new_version ? this.router.navigate(['/home-update-v1']): this.router.navigate(['/home']);
        
      }
    }
  }

  /** check if route exists in list of route mentioned above */
  routeExists(path: string): boolean {
    return this.routePaths.some((route) => path.includes(route));
  }

  /*** get user details from local storage */
  getUser = (): any => {
    const userJson = localStorage.getItem('user');
    this.user = userJson !== null ? JSON.parse(userJson) : {};
    return this.user;
  };

  /** recent date filter date format */
  formatDate = (date: Date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  /*** subtract n number of days from date */
  subtractDate = (n: number) => {
    let d = new Date();
    d.setDate(d.getDate() - n);
    return d;
  };

  hasPermission(permission: string): boolean {
    this.userPermissions = localStorage.getItem('permissions');
    // Check if the user has the specified permission
    return this.userPermissions?.includes(permission);
  }

  private dataSubject = new BehaviorSubject<string>('Initial Value');

  // Expose the BehaviorSubject as an observable for other components to subscribe to
  data$ = this.dataSubject.asObservable();

  // Function to update the value of the BehaviorSubject
  updateData(newData: string) {
    this.dataSubject.next(newData);
  }
}
