import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { LoadingService } from 'src/app/_services/loading.service';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Output() btnValue = new EventEmitter<boolean>();
  @Input() src: any;
  // @ViewChild('pdfElement') pdfElement: ElementRef;
  // @Input() src: string;
  disableBtn: boolean = true;
  totalPages: number;
  pdfSrc: any;
  pdfHtml: any;
  externalHtml: any;
  pdfPosition: any;
  policyLoaded: boolean = false;
  constructor(private loadingService: LoadingService) {}
  // src =
  //   'https://storage.googleapis.com/cax-datax-beta/20220323_DATAX.AI_Terms_of_Service.pdf';
  ngOnInit(): void {
    this.btnValue.emit(this.disableBtn);
  }

//   ngAfterViewInit(){
// 	console.log(this.pdfElement);
// 	this.pdfPosition = this.pdfElement.nativeElement
//   }
//   @HostListener("window:scroll", ["$event"])
//   onScroll(event:any){
// 	let pos = (this.pdfPosition.scrollTop || document.body.scrollTop) + this.pdfPosition.offsetHeight +100 ;
// 	let max = this.pdfPosition.scrollHeight;
// 	if (pos >=  max) {
//       this.btnValue.emit(false);
//     } else {
// 		this.btnValue.emit(true);
//     }
//   }
  /** Trigger after pdf completely loaded**/
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.policyLoaded = true;
    this.totalPages = pdf.numPages;
  }
  /** Trigger on every pdf page change**/
  pagechanging(event: Event | any) {
    if (event.pageNumber == this.totalPages) {
      this.btnValue.emit(false);
    } else {
      this.btnValue.emit(true);
    }
  }
}
