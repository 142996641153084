import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Globals } from '../_globals/endpoints.global';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class JssOutputService {
  constructor(private globals: Globals, private http: HttpClient) {}

  //  to get column headers
  getBatchSchema(batch_id: any): Observable<any> {
    return this.http.get('/api/data-explorer/batch/' + batch_id + '/schema');
  }

  // get output data
  batchDetails(
    payload: any,
    batch_id: any,
    page: any,
    size: number = 100
  ): Observable<any> {
    return this.http.post(
      '/api/data-explorer/batch/' + batch_id + '/data',
      payload,
      {
        params: {
          page: page,
          page_size: size,
        },
      }
    );
  }

  // get fill rates for all columns
  getFillRates(batch_id: any): Observable<any> {
    return this.http.get(
      '/api/data-explorer/batch/' + batch_id + '/data-quality'
    );
  }

  /*********** new version **************************** */

  /***** get data table headers******** */

  getDataTableHeaders(org_id: any, batch_id: any): Observable<any> {
    const dataTableHeaderEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'data_table_headers',
      org_id,
      batch_id
    );
    return this.http.get(dataTableHeaderEndpoint);
  }

  /******* get data table content ************ */

  getDataTableContent(org_id: any, batch_id: any, obj: any): Observable<any> {
    const dataTableContentEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'data_table_content',
      org_id,
      batch_id
    );
    const options = {
      params: new HttpParams()
        .set('page', obj.page)
        .set('page_size', obj.page_size)
        .set('conversation_uid', obj.conversation_uid),
    };
    const reqBody = {
      sort: obj.sort,
      column_filter: obj.column_filter,
    };
    return this.http.post(dataTableContentEndpoint, reqBody, options);
  }

  /**************** rename task ******** */

  renameTask = (org_id: any, batch_id: any, task_name: string) => {
    const renameTaskEndpoint = this.globals.urlJoinWithParam(
      'jss_output',
      'rename_task',
      org_id
    );
    let obj = {
      batch_id: batch_id,
      task_name: task_name,
    };
    return this.http.patch(renameTaskEndpoint, obj);
  };

  /********* get batch details  **************************/

  getBatchDetails = (org_id: any, batch_id: any): Observable<any> => {
    const getBatchDetailsEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'get_batch_detail',
      org_id,
      batch_id
    );
    return this.http.get(getBatchDetailsEndpoint);
  };

  /**************** get input data table ***************** */
  getInputDataTable = (
    org_id: any,
    batch_id: any,
    obj: any
  ): Observable<any> => {
    const inputDataTableEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'input_data_table',
      org_id,
      batch_id
    );
    const options = {
      params: new HttpParams()
        .set('page', obj.page)
        .set('page_size', obj.page_size),
    };
    return this.http.get(inputDataTableEndpoint, options);
  };

  /************* generate output file  */
  generateOutputFile = (org_id: any, batch_id: any) => {
    const generateOutputFileEndpoint = this.globals.urlJoinWithTwoParam(
      'jss_output',
      'generate_output_file',
      org_id,
      batch_id
    );
    return this.http.post(generateOutputFileEndpoint, {});
  };
}
