<div class="usage-wrapper">
  <div class="info-cards" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="card" fxLayout="column" fxLayoutAlign="center start">
      <div class="number">182,367</div>
      <p>Total Credit</p>
    </div>
    <div class="card" fxLayout="column" fxLayoutAlign="center start">
      <div class="number">21,367</div>
      <p>Credit Spent</p>
    </div>
    <div class="card" fxLayout="column" fxLayoutAlign="center start">
      <div class="number">10,092</div>
      <p>Credit Spent</p>
    </div>
  </div>

  <div class="table">
    <div class="search-container-filters" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field style="width: 28%;padding-top: 10px;" appearance="outline" class="search-filter">
        <input id="search" matInput placeholder="Search task name here..." #searchVal name="searchVal" />
        <mat-icon matPrefix svgIcon="search"></mat-icon>
      </mat-form-field>
      <button mat-raised-button color='primary'>Buy credit</button>
    </div>
    <div class="mat-elevation-z0">
      <table mat-table [dataSource]="dataSource">

        <!-- Billing Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> UPLOADED ON </th>
          <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>TASK NAME </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- no_rows Column -->
        <ng-container matColumnDef="no_rows">
          <th mat-header-cell *matHeaderCellDef> CREDIT SPENT </th>
          <td mat-cell *matCellDef="let element"> {{element.no_rows}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20]"  aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>
</div>
