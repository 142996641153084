<div id="flexibleDiv"
  style="height: 100vh;width: 30vw;position: fixed;top: 70px;right: 0;background-color: #ffffff;z-index: 999; display: none;">
  <div class="flexible-div-content-container" fxLayout="column" style="height: 90.5vh;
        margin: 24px 24px 24px 24px;">
    <div class="title-and-action" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="flexible-div-title">Version history</div>
      <div class="flexible-div-action" fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer;">
          <img style="width: 24px;
                height: 24px;" id="resizeButton"
            src="../../../../assets/images/flexible-div/double_alt_arrow_right.svg" alt="rightArrow"
            srcset="../../../../assets/images/flexible-div/double_alt_arrow_right.svg">
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" style="cursor: pointer;">
          <img style="width: 24px;
                height: 24px;" src="../../../../assets/images/flexible-div/x.svg" alt="crossArrow"
            srcset="../../../../assets/images/flexible-div/x.svg" (click)="closeVersionHistory()">
        </div>
      </div>
    </div>
    <div class="flexible-div-subtitle" style="position: relative;" fxLayout="row" fxLayoutAlign="start start"
      fxLayoutGap="5px">
      <div class="flexible-div-subtitle-text">A log of all changes made to the output</div>
      <div style="cursor: pointer; position: relative;" class="flexible-div-subtitle-ask"
        (click)="showSubTitleIconInfo()">
        <img *ngIf="enableSubTitleInfoIcon" src="../../../../assets/images/flexible-div/question_circle.svg"
          alt="questionIcon" srcset="../../../../assets/images/flexible-div/question_circle_primary.svg">
        <img *ngIf="!enableSubTitleInfoIcon" src="../../../../assets/images/flexible-div/question_circle.svg"
          alt="questionIcon" srcset="../../../../assets/images/flexible-div/question_circle.svg">
        <div *ngIf="enableSubTitleInfoIcon" class="flexible-div-subtitle-ask-icon-info-tale" style="height: 10px;
                width: 10px;
                padding: 12px;
                background: #f8fafd;
                position: absolute;
                top: 26px;
                left: -7px;
                rotate: 45deg;
                box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);"></div>
      </div>
      <!-- pop-up  -->
      <div *ngIf="enableSubTitleInfoIcon" class="flexible-div-subtitle-ask-icon-info" style="height: auto;
            z-index: 9;
            width: 100%;
            padding: 12px;
            border-radius: 6px;
            background: #F8FAFD;
            position: absolute;
            top: 36px;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);" fxLayout="column"
        fxLayoutGap="10px">
        <div style="width: 100%;" fxLayout="column">
          <div>See the current version at the top, followed by all previous versions.</div>
          <div>
            <ul>
              <li>You can preview an older version in 2 ways - either see the entire data table or just the columns
                that were changed.</li>
              <li>You can restore an older version simply by clicking the 'Restore' option on that version. </li>
            </ul>
          </div>
          <div class="dont-show-this-div" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox color="primary" class="got-it-checkbox">Don’t show this again</mat-checkbox>
            </div>
            <div>
              <button type="button" style="height: 32px;cursor: pointer;
                    padding: 8px 16px;border: none;
                    border-radius: 6px;
                    background: var(--Primary-600, #1042F1);color: var(--Gray-white, var(--Surface-50, #FFF));font-family: 'IBM Plex Sans';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 15px;">Got it</button>
            </div>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
    <!-- ========= Manual Mapping start ============= -->
    <!-- <div class="manual-mapping-section" style="position: relative;height:100vh" fxLayout="column" fxLayoutGap="16">
      <div class="output-header-section" fxLayout="column">
        <div class="output-header-label" fxLayout="row">Output header</div>
        <div class="output-header-content" fxLayout="row">
          <div fxFlex="100">
            <form style="position: relative;" fxFlex="100" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10"
              class="example-form">
              <mat-icon matPrefix svgIcon="search" style="margin-right: 10px;
                        height: 16px;
                        width: 16px;
                        position: absolute;
                        top: 16px;
                        left: 13px;"></mat-icon>
              <input style="
                      padding: 12px 40px;
                      align-self: stretch;border-radius: 8px;
    border: 1px solid var(--Gray-300, #CBD5E1);" type="text" placeholder="Search output header"
                [formControl]="outputHeaderControl" [matAutocomplete]="auto" class="example-input">
              <mat-autocomplete class="autocomplete" #auto="matAutocomplete">
                <mat-option title="{{outputHeader}}" *ngFor="let outputHeader of filteredOutputHeaders | async"
                  [value]="outputHeader">
                  {{outputHeader}}
                </mat-option>
              </mat-autocomplete>
            </form>
          </div>
        </div>
      </div>
      <div class="input-header-section" fxLayout="column">
        <div class="input-header-label" fxLayout="row">Input header</div>
        <div class="input-header-content" fxLayout="row" fxFlex="100">
          <mat-form-field fxFlex="100">
            <mat-select [formControl]="inputHeader" (selectionChange)="checkInputHeaderChange(inputHeader)"
              (openedChange)="onOpenCloseInputHeaderOptionPanel($event)" multiple>
              <mat-select-trigger>
                <ng-container *ngIf="inputHeader.value?.length > 0">
                  <span *ngFor="let value of inputHeader.value" class="selected-option">
                    {{value}}
                  </span>
                </ng-container>
                <ng-container *ngIf="inputHeader.value?.length === 0">
                  Select input headers
                </ng-container>
              </mat-select-trigger>
              <mat-option *ngFor="let inputHead of inputHeaderList" [value]="inputHead">
                {{inputHead}}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
      <div *ngIf="selectedInputHeadersContainer.length!==0" class="mapped-to-section" fxLayout="column">
        <div class="mapped-to-section-label" fxLayout="row">Mapped to</div>
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4" class="example-box"
            *ngFor="let value of selectedInputHeadersContainer" cdkDrag>
            <mat-icon matPrefix svgIcon="dot-six-vertical-icon" style="margin: 0 5px; height: 20px"></mat-icon>
            <div class="moved-to-section-list-value">{{value}}</div>

          </div>
        </div>
      </div>
      <div *ngIf="selectedInputHeadersContainer.length!==0" class="delimeter-section" fxLayout="column">
        <div class="delimeter-section-label" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4">
          <div>Delimeter value</div>
          <mat-icon matPrefix svgIcon="info-circle-icon" style="margin: 0 5px; height: 20px"></mat-icon>
        </div>
        <input type="text" style="height: 42px;
                padding: 12px 16px;border-radius: 8px;
  border: 1px solid var(--Gray-300, #CBD5E1);color: var(--Gray-900, #1B263B);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;">
      </div>
      <div class="action-section" style="width: 100%;position: absolute;bottom: 24px;" fxFlex="100" fxLayout="row"
        fxLayoutGap="16px">
        <button style="flex: 1; width: 50%;display: flex;cursor: pointer;
                padding: 8px 12px;
                justify-content: center;
                align-items: center;border-radius: 8px;
  background: var(--Primary-400, #6E8CF7);color: var(--Gray-white, var(--Surface-50, #FFF));
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;border: none;
                " color="primary">Save</button>
        <button mat-flat-button style="flex: 1; width: 50%;padding: 8px 12px;cursor: pointer;
                justify-content: center;
                align-items: center;border-radius: 8px;
  border: 1px solid var(--Gray-300, #CBD5E1);color: var(--Gray-800, #212C40);font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;">Cancel</button>
      </div>
    </div> -->
    <!-- ========= Manual Mapping ends ============= -->

    <!-- ========= version history section start ============= -->
    <div class="current-version-section" fxLayout="column" style="margin-bottom: 16px;">
      <div class="current-version-label" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);

              font-family: 'IBM Plex Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 21px;">Current version</div>
      <div class="current-version-list-item" fxLayout="column" style="border-radius: 6px;
              border: 1px solid var(--Gray-200, #E2E8F0);
              background: var(--Surface-50, #FFF);padding: 10px 12px;" fxLayoutGap="6px">
        <div class="current-verison-list-item-text" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);

                font-family: 'IBM Plex Sans';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;">
          Update the mapping for 'column3' in the output file from 'unmapped' to 'Mfg Product Number' in the input
          file.
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="current-version-list-item-subtext" style="color: var(--Gray-500, #506480);

                  font-family: 'IBM Plex Sans';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;">
            July 24, 2023, 2:39 PM
          </div>
          <div class="current-version-list-item-label" fxLayout="row" fxLayoutAlign="end center">
            <span style="font-family: 'IBM Plex Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;color: var(--purple-800, #5521B5);padding: 2px 6px;border-radius: 4px;
background: var(--purple-100, #EDEBFE);">Manual mapping</span>
          </div>
        </div>
      </div>
    </div>
    <div class="history-section" fxLayout="column" style="overflow-y: auto;">
      <div class="current-version-label" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);

              font-family: 'IBM Plex Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 21px;">History</div>
      <div fxLayout="column" style="border-radius: 6px;
              border: 1px solid var(--Gray-200, #E2E8F0);margin-bottom: 8px;">
        <div class="current-version-list-item" fxLayout="column" style="border-radius: 6px;
            
                background: var(--Surface-50, #FFF);padding: 10px 12px;" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-verison-list-item-text" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;">
              Add prefix/suffix "ABC" to the values of the column "Mfg Model Number"
            </div>
            <div class="menu-button" fxLayout="column" fxLayoutAlign="start start">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span>Preview Column</span>
                </button>
                <button mat-menu-item>
                  <span>Preview Table</span>
                </button>
                <button mat-menu-item>
                  <span>Restore</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-version-list-item-subtext" style="color: var(--Gray-500, #506480);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;">
              July 24, 2023, 2:38 PM
            </div>
            <div class="current-version-list-item-label" fxLayout="row" fxLayoutAlign="end center">
              <span style="font-family: 'IBM Plex Sans';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;color: var(--Primary-800, #082691);padding: 2px 6px;border-radius: 4px;border-radius: 4px;
                      background: var(--Primary-100, #D7E0FF);">Via Chat</span>
            </div>
          </div>
        </div>
        <div class="stop-preview-section" fxLayout="row" fxLayoutAlign="center center" style="color: #1042F1;
                font-family: 'IBM Plex Sans';padding: 12px 90px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;border-radius: 0px 0px 6px 6px;
border: 1px solid var(--Primary-300, #9FB3F9);
background: var(--Primary-100, #D7E0FF);">Stop previewing</div>
      </div>
      <div fxLayout="column" style="border-radius: 6px;
              border: 1px solid var(--Gray-200, #E2E8F0);margin-bottom: 8px;">
        <div class="current-version-list-item" fxLayout="column" style="border-radius: 6px;
            
                background: var(--Surface-50, #FFF);padding: 10px 12px;" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-verison-list-item-text" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;">
              Merge the columns "Mfg Model Number" and "Base Model Number" into a new column "Product Category". Use
              "|" as delimitter for merging the col...
            </div>
            <div class="menu-button" fxLayout="column" fxLayoutAlign="start start">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span>Preview Column</span>
                </button>
                <button mat-menu-item>
                  <span>Preview Table</span>
                </button>
                <button mat-menu-item>
                  <span>Restore</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-version-list-item-subtext" style="color: var(--Gray-500, #506480);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;">
              July 24, 2023, 2:35 PM
            </div>
            <div class="current-version-list-item-label" fxLayout="row" fxLayoutAlign="end center">
              <span style="font-family: 'IBM Plex Sans';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;color: var(--Primary-800, #082691);padding: 2px 6px;border-radius: 4px;border-radius: 4px;
                      background: var(--Primary-100, #D7E0FF);">Via Chat</span>
            </div>
          </div>
        </div>
        <div class="stop-preview-section" fxLayout="row" fxLayoutAlign="center center" style="color: #1042F1;
                font-family: 'IBM Plex Sans';padding: 12px 90px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;border-radius: 0px 0px 6px 6px;
border: 1px solid var(--Primary-300, #9FB3F9);
background: var(--Primary-100, #D7E0FF);">Stop previewing</div>
      </div>
      <div fxLayout="column" style="border-radius: 6px;
              border: 1px solid var(--Gray-200, #E2E8F0);margin-bottom: 8px;">
        <div class="current-version-list-item" fxLayout="column" style="border-radius: 6px;
            
                background: var(--Surface-50, #FFF);padding: 10px 12px;" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-verison-list-item-text" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;">
              Update the mapping for 'column3' in the output file from 'unmapped' to 'Mfg Product Number' in the input
              file.
            </div>
            <div class="menu-button" fxLayout="column" fxLayoutAlign="start start">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span>Preview Column</span>
                </button>
                <button mat-menu-item>
                  <span>Preview Table</span>
                </button>
                <button mat-menu-item>
                  <span>Restore</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-version-list-item-subtext" style="color: var(--Gray-500, #506480);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;">
              July 24, 2023, 2:35 PM
            </div>
            <div class="current-version-list-item-label" fxLayout="row" fxLayoutAlign="end center">
              <span style="font-family: 'IBM Plex Sans';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;color: var(--purple-800, #5521B5);padding: 2px 6px;border-radius: 4px;border-radius: 4px;
                      background: var(--purple-100, #EDEBFE);">Manual mapping</span>
            </div>
          </div>
        </div>
        <div class="stop-preview-section" fxLayout="row" fxLayoutAlign="center center" style="color: #1042F1;
                font-family: 'IBM Plex Sans';padding: 12px 90px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;border-radius: 0px 0px 6px 6px;
border: 1px solid var(--Primary-300, #9FB3F9);
background: var(--Primary-100, #D7E0FF);">Stop previewing</div>
      </div>
      <div fxLayout="column" style="border-radius: 6px;
              border: 1px solid var(--Gray-200, #E2E8F0);margin-bottom: 8px;">
        <div class="current-version-list-item" fxLayout="column" style="border-radius: 6px;
            
                background: var(--Surface-50, #FFF);padding: 10px 12px;" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-verison-list-item-text" fxLayout="row" fxLayoutAlign="start center" style="color: var(--Gray-900, #1B263B);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;">
              Model output data
            </div>
            <div class="menu-button" fxLayout="column" fxLayoutAlign="start start">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item>
                  <span>Preview Column</span>
                </button>
                <button mat-menu-item>
                  <span>Preview Table</span>
                </button>
                <button mat-menu-item>
                  <span>Restore</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="current-version-list-item-subtext" style="color: var(--Gray-500, #506480);
  
                    font-family: 'IBM Plex Sans';
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;">
              July 24, 2023, 2:35 PM
            </div>
            <div class="current-version-list-item-label" fxLayout="row" fxLayoutAlign="end center">
              <span style="font-family: 'IBM Plex Sans';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px;color: var(--Gray-900, #1B263B);padding: 2px 6px;border-radius: 4px;border-radius: 4px;
  background: var(--Gray-100, #F1F5F9);">Auto mapping</span>
            </div>
          </div>
        </div>
        <div class="stop-preview-section" fxLayout="row" fxLayoutAlign="center center" style="color: #1042F1;
                font-family: 'IBM Plex Sans';padding: 12px 90px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;border-radius: 0px 0px 6px 6px;
border: 1px solid var(--Primary-300, #9FB3F9);
background: var(--Primary-100, #D7E0FF);">Stop previewing</div>
      </div>
    </div>
    <!-- ========= version history section ends ============= -->


    <!--  -->
  </div>
  <!-- ==== -->
  <!-- <div class="action-to-be-taken" fxLayout="row" style="position: fixed;
          bottom: 24px;width: 30vw;">
          <div style="display: flex;width: 100%;
          border: 1px solid red;">
            <button mat-flat-button style="flex: 1; width: 50%;" color="primary">Save</button>
            <button mat-flat-button style="flex: 1; width: 50%;">Cancel</button>
          </div>
        </div> -->
  <!-- ==== -->
  <div id="resizableHandle"></div>
</div>
