import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
// import {
//   MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
//   MatLegacyDialogRef as MatDialogRef,
// } from '@angular/material/legacy-dialog';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HomeService } from 'src/app/_services/home.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';

@Component({
  selector: 'app-edit-tags',
  templateUrl: './edit-tags.component.html',
  styleUrls: ['./edit-tags.component.scss'],
})
export class EditTagsComponent implements OnInit {
  tags: any;
  tagInput = new FormControl('');
  previousEditedTagIndex: number;
  modifiedData: any;
  rowData: any;
  index: number;
  rowIndex: number;
  form: FormGroup;
  modifiedIndex: number;
  batchId: any;
  editedValue: any;
  orgId: any;
  isUpdated = false;
  loading: boolean = false;
  @ViewChild('tagInputField') tagInputField!: ElementRef;
  tagId: number;
  tagValue: any;
  duplicateNameError: boolean = false;
  maxLimitError: boolean = false;
  minLimitError: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public edtTagsDialog: MatDialogRef<EditTagsComponent>,
    private homeService: HomeService,
    private snackbarService: SnackbarService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      tag_name: new FormControl('', [
        Validators.pattern(/^[^!"%&'()*+,-./:$;<=#>?@[\\\]^_`{|}~]+$/),
        Validators.maxLength(10),
        Validators.minLength(2),
        this.noWhiteSpaceValidator(),
        this.noConsecutiveSpacesValidator()
      ]),
    });
  }

  get tagName() {
    return this.form.get('tag_name');
  }

  ngOnInit(): void {
    this.loading = true;
    this.orgId = localStorage.getItem('currentOrganizationCode');
    this.batchId = this.data.batchId;
    this.getTags();
    // this.editButtonDisabled = true;
  }

  // Function to perform input validation
  validateInput(control: AbstractControl) {
    control.updateValueAndValidity(); // Trigger validation
    this.duplicateNameError = false;
  }

  // validate for blank space as tag_name
  // noWhiteSpaceValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const isWhitespace = (control.value || '').trim().length === 0;
  //     const isValid = !isWhitespace;
  //     return isValid ? null : { whitespace: true };
  //   };
  // }

  noWhiteSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const isWhitespace = value.trim().length === 0 && value.length > 0;
  
      // Check if the control is dirty or touched to display error
      const showError = control.dirty || control.touched;
      return isWhitespace && showError ? { whitespace: true } : null;
    };
  }

  // Validator to check for more than one consecutive space
  noConsecutiveSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const hasConsecutiveSpaces = /\s{2,}/.test(value);
    return hasConsecutiveSpaces ? { consecutiveSpaces: true } : null;
  };
}
  // 

  getTags() {
    this.homeService.getTags(this.batchId, this.orgId).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.tags = resp.result;
        this.tagInput.reset();
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
      },
    });
  }

  /**
   * Initiates the addition or removal of tags in the table.
   * Pauses polling to prevent interference during tag modification.
   * If a tag is already in edit mode, resets its editable state.
   * Sets the selected tag's editable state to true for modification.
   * Updates the dashboard data source and records the index of the previously edited tag.
   * @param tags - The array of tags associated with the table row.
   * @param index - The index of the tag being added or removed.
   * @param rowindex - The index of the row in the table.
   */
  // =====
  tagOperation(type: 'add' | 'delete', id?: any) {
        const tagName = this.form.get('tag_name')?.value?.trim() || '';
        let tagActionParams;
        this.loading = true;

        switch (type) {
            case 'add':
                if (tagName && (tagName.length > 1)) {
                    tagActionParams = {
                        action: 'add_tag',
                        batch_id: this.batchId,
                        tag_name: tagName,
                    };
                    // --------------------------------------------
                    this.homeService.updateTags(tagActionParams, this.orgId).subscribe({
                      next: () => {
                          this.tagValue = '';
                          this.getTags();
                          this.isUpdated = true;
                          this.loading = false;
                      },
                      error: (error: HttpErrorResponse) => {
                          this.loading = false;
                          this.snackbarService.showSnackbar(error.error.detail, '', 'error');

                          // this.duplicateNameError = true;
                      },
                  });
                    // --------------------------------------------
                }
                break;
            case 'delete':
                tagActionParams = {
                    action: 'remove_tag',
                    batch_id: this.batchId,
                    tag_id: id,
                };
                // --------------------------------------------
                this.homeService.updateTags(tagActionParams, this.orgId).subscribe({
                  next: () => {
                      this.tagValue = '';
                      this.getTags();
                      this.isUpdated = true;
                      this.loading = false;
                  },
                  error: (error: HttpErrorResponse) => {
                      this.loading = false;
                      this.snackbarService.showSnackbar(error.error.detail, '', 'error');

                      // this.duplicateNameError = true;
                  },
              });
                // --------------------------------------------
                break;
            default:
                this.loading = false;
                return;
        }
    }

  // =====
  // updateTags(type: any, id?: any) {
  //   let tagName;
  //   let tagActionParams;
  //   this.loading = true;
  //   if (this.form.get('tag_name')?.value!='') {
  //     if (type == 'add') {
  //       tagName = this.form.get('tag_name')?.value.trim();
  //       tagActionParams = {
  //         action: 'add_tag',
  //         batch_id: this.batchId,
  //         tag_name: tagName,
  //       };
  //     } else if (type == 'delete') {
  //       tagName = '';
  //       tagActionParams = {
  //         action: 'remove_tag',
  //         batch_id: this.batchId,
  //         tag_id: id,
  //       };
  //     }
  //     if (tagName.length > 1 || type == 'delete') {
  //       this.homeService.updateTags(tagActionParams, this.orgId).subscribe({
  //         next: (resp: any) => {
  //           this.tagValue = '';
  //           this.getTags();
  //           this.isUpdated = true;
  //         },
  //         error: (error: HttpErrorResponse) => {
  //           this.loading = false;
  //           this.duplicateNameError = true;
  //         },
  //       });
  //     }
  //   } else {
  //     this.loading = false;
  //   }
  // }

  // Method to be called on button click to trim whitespace from both start and end
  // trimWhitespace() {
  //   const taskNameControl = this.form.get('tag_name') as FormControl;

  //   if (taskNameControl.value && typeof taskNameControl.value === 'string') {
  //     const trimmedValue = taskNameControl.value.trim(); // Trim from both start and end

  //     if (trimmedValue !== taskNameControl.value) {
  //       // Update the control value with the trimmed value
  //       taskNameControl.setValue(trimmedValue);
  //     }
  //   }
  // }

  /**
   * Handles keypress events to update tags in the table.
   * Pauses polling to prevent interference during tag modification.
   * Initiates the addition or removal of tags based on the 'Enter' keypress event.
   * @param event - The keypress event object.
   * @param batchid - The identifier of the batch associated with the tags.
   * @param add - A boolean indicating whether the action is an addition or removal of tags.
   * @param tagId - The identifier of the tag being added or removed.
   */
  onKeyPressUpdateTags(event: any, add: any, tagId: any) {
    this.editedValue = event?.target?.value;
    this.tagId = tagId;
    if (event.key === 'Enter') {
      this.additionOfTagsAndService(event, this.batchId, add, tagId);
    }
  }

  /**
   * Handles the addition or update of tags and triggers the corresponding service.
   * Sets loading state, starts polling, and constructs the payload for the service call.
   * Calls the homeService to update tags and handles success and error responses.
   * Resets the tag input field and displays a snackbar with appropriate messages.
   * Focuses on the tag input field for user convenience.
   * @param event - The keypress event object (for updating tags).
   * @param batchid - The identifier of the batch.
   * @param add - The action type for the tag operation (e.g., 'add_tag', 'update_tag').
   * @param tagId - The identifier of the tag (for updating tags).
   */
  additionOfTagsAndService = (
    event: any,
    batchid: number,
    add: any,
    tagId?: number
  ) => {
    let tagActionParams;
    if (add === 'update_tag') {
      tagActionParams = {
        action: add,
        tag_name: event?.target?.value ? event?.target?.value : null,
        batch_id: batchid,
        tag_id: tagId,
      };
    } else {
      tagActionParams = {
        action: add,
        tag_name: this.tagInput.value,
        batch_id: batchid,
      };
    }
    this.homeService
      .updateTags(tagActionParams, localStorage.getItem('currentOrganizationCode'))
      .subscribe({
        next: (resp) => {
          this.tagInput.reset();
          this.snackbarService.showSnackbar(resp.detail.detail, '', 'success');
          this.edtTagsDialog.close('close');
        },
        error: (error) => {
          // this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
    this.tagInputField.nativeElement.focus();
  };

  onClose = () => {
    if (!this.loading) {
      if (this.isUpdated) {
        this.edtTagsDialog.close('render');
      } else {
        this.edtTagsDialog.close();
      }
      this.isUpdated = false;
    }
  };

  /**
   * onSave function to update a tag using
   * data from tag input, batch ID, and tag ID
   */
  onSave = () => {
    let tagActionParams;
    tagActionParams = {
      action: 'update_tag',
      tag_name: this.tagInput.value,
      batch_id: this.batchId,
      tag_id: this.tagId,
    };

    this.homeService
      .updateTags(tagActionParams, localStorage.getItem('currentOrganizationCode'))
      .subscribe({
        next: (resp) => {
          this.tagInput.reset();
          this.snackbarService.showSnackbar(resp.detail.detail, '', 'success');
          this.edtTagsDialog.close('close');
        },
        error: (error) => {
          // this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
  };
}
