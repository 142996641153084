import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss'],
})
export class UpgradePlanComponent implements OnInit {
  currentOrganizationCode: string | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public upgradePlanDialog: MatDialogRef<UpgradePlanComponent>,
    private accountSettingsService: AccountSettingsService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.currentOrganizationCode = localStorage.getItem(
      'currentOrganizationCode'
    );
  }

  intrestedToUpgrade = () => {
    this.accountSettingsService
      .upgradePlan(this.currentOrganizationCode)
      .subscribe({
        next: (resp: any) => {
          this.snackbarService.showSnackbar(resp?.detail, '', 'success');
          this.onClose();
        },
        error: (error: HttpErrorResponse) => {
          this.snackbarService.showSnackbar(error.error.detail, '', 'error');
          this.onClose();
        },
      });
  };

  onClose = () => {
    this.upgradePlanDialog.close('close');
  };
}
