import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { JssOutputService } from 'src/app/_services/jss-output.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-data-table',
  templateUrl: './input-data-table.component.html',
  styleUrls: ['./input-data-table.component.scss'],
})
export class InputDataTableComponent implements OnInit {
  activeOrgID: any;
  batchId: any;
  inputDataTableHeaders: any[];
  inputDataTableRows: any[];
  page: number = 1;
  pageSize: number = 199;
  totalPages: number;
  totalRecords: number;
  totalColsInAScreen: number;
  screenWidth: number;
  screenHeight: number;
  currentElement: any;
  currentPageValueInputTable: number;
  @Input() inputDataTableContent: any;
  @Output() inputTablePaginationEvent = new EventEmitter<{
    page: number;
    pageSize: number;
  }>();
  @Output() inputTableGoToPageEvent = new EventEmitter<{
    page: number;
    pageSize: number;
  }>();
  /******* div reference for simultaneous horizontal scrolling ********* */
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;

  constructor(
    private jssOutputService: JssOutputService,
    private snackbar: SnackbarService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = screen.height;
    this.activeOrgID = localStorage.getItem('currentOrganizationCode');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.batchId = params['batch_id'];
    });
    /***
     * get screen width to determine no of cols we can have
     * this is to have flexible width as well as truncate overflowing text
     * */
    let eachColSize = 200;
    this.totalColsInAScreen = Math.round(
      (this.screenWidth - 200) / eachColSize
    );
    // this.getInputTableData(this.activeOrgID, this.batchId);
    /****** input table details  ************/
    this.inputDataTableHeaders = this.inputDataTableContent.input_columns;
    this.inputDataTableRows = this.inputDataTableContent.input_data;
    this.page = this.inputDataTableContent.page;
    this.currentPageValueInputTable = this.inputDataTableContent.page;
    this.pageSize = this.inputDataTableContent.page_size;
    this.totalPages = this.inputDataTableContent.total_pages;
    this.totalRecords = this.inputDataTableContent.total_records;
  }

  /*** on pagination event */

  onPaginationEvent = (e: any) => {
    this.page = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.inputTablePaginationEvent.emit({
      page: this.page,
      pageSize: this.pageSize,
    });
    // this.batchDetails(this.batchID);
    // this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  goToPage = (e: any) => {
    e.preventDefault();
    // to remove focus
    e.target.blur();
    window.getSelection()?.removeAllRanges();
    // console.log(this.currentPageValueInputTable);
    this.page = this.currentPageValueInputTable;
    this.inputTableGoToPageEvent.emit({
      page: this.page,
      pageSize: this.pageSize,
    });
  };

  /***** function to scroll the header and content simultaneously */
  updateScroll = (event: any) => {
    if (this.currentElement === 'scrollTwo') {
      this.scrollOne.nativeElement.scrollLeft = event.target.scrollLeft;
    } else if (this.currentElement === 'scrollOne') {
      this.scrollTwo.nativeElement.scrollLeft = event.target.scrollLeft;
    }
  };

  /***** tracking current element being scrolled */
  updateCurrentElement(element: 'scrollOne' | 'scrollTwo') {
    this.currentElement = element;
  }
}
