<div style="padding: 24px;">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3><strong>Invite Members</strong></h3>
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>

  <form [formGroup]="invitemembForm" class="assignee-form" fxLayout="column"
    style="max-height: 200px; overflow-y: auto">
    <mat-form-field appearance="outline" class="member" *ngFor="let member of getTeamMembersCtrl(); let i = index"
      [formGroup]="member">
      <span fxLayout="row" fxLayoutGap="5px">
        <img src="../../../../assets/images/mail.svg" />
        <input formControlName="email" type="email" matInput placeholder="pat@example.com" />

        <button *ngIf="i!=0" mat-button (click)="removeInputField(i)">
          <mat-icon style="position: absolute;
        right: 2px;top:-5px;">remove</mat-icon>
        </button>
      </span>

      <mat-error *ngIf="members.controls[i].get('email').errors?.required">Email is a required field
      </mat-error>
      <mat-error *ngIf="members.controls[i].get('email').errors?.['pattern']">Please enter a valid email.</mat-error>
    </mat-form-field>
  </form>
  <button mat-button mat-stroked-button (click)="addNewEmail()" class="add-new-email" style="width: 100%" fxLayout="row"
    fxLayoutAlign="space-between center">
    <span>Add new email</span>
    <mat-icon style="position: absolute;
  right: 13px;">add</mat-icon>
  </button>

  <div fxLayoutGap="16px" fxLayout="row" class="invite-actions">
    <button mat-stroked-button class="cancel-btn" (click)="onCancel()">
      Cancel
    </button>
    <button mat-stroked-button color="primary" class="invitememb-btn"
      [disabled]="invitemembForm?.invalid || this.members.value.length < 1" (click)="inviteAllEmails()">
      Invite
    </button>
  </div>
</div>
