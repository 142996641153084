import { DataSharingService } from 'src/app/_services/data-sharing.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ShareDialogComponent } from 'src/app/_dialogs/share-dialog/share-dialog.component';
// import {
//   MatLegacyDialog as MatDialog,
//   MatLegacyDialogRef as MatDialogRef,
// } from '@angular/material/legacy-dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SidePanelService } from 'src/app/_services/side-panel.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { HomeService } from 'src/app/_services/home.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { LoadingService } from 'src/app/_services/loading.service';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InviteRequestDialogComponent } from 'src/app/_dialogs/invite-request-dialog/invite-request-dialog/invite-request-dialog.component';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { InviteMembersComponent } from 'src/app/_dialogs/invite-members/invite-members.component';
import { MatSelect } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { environment } from 'src/environments/environment';
import { EditTagsComponent } from 'src/app/_dialogs/edit-tags/edit-tags.component';
import { AppConfigService } from 'src/app/_services/app-config.service';
import { ValidationService } from 'src/app/_services/validation.service';
import {NotificationsService} from 'src/app/_services/notifications.service';
// import { JssOutputService } from 'src/app/_services/jss-output.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
interface UploadedUsers {
  value: string;
  viewValue: string;
}

interface Status {
  slug: string;
  display_name: string;
}

interface DownloadOptions {
  slug: string;
  display_name: string;
}

interface Assignee {
  id: number;
  name: string;
}
interface OrganizationData {
  organisation_name: string;
  organisation_code: string;
  owner: boolean;
  role: string | null;
  permissions: string[];
}

@Component({
  selector: 'app-home-update-v1',
  templateUrl: './home-update-v1.component.html',
  styleUrls: ['./home-update-v1.component.scss'],
  animations: [
    trigger('errorIconTrigger', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('hidden <=> visible', [
        animate('0.3s ease-in-out')
      ]),
    ])
  ]
})
export class HomeUpdateV1Component implements OnInit {
  // 
  uploadForm: FormGroup;
  // 
  activeButton: string = '';
  isTaskEditingEnabled: boolean = false;
  activeTaskNameForEdit: string = '';
  activeTaskId: number | null = null;
  outputFileUrl: string;
  inputFileUrl: string;
  activeBatchID: any;
  orgId: string | null;
  showFullPageSkeletonLoader: boolean = false;
  showTableSkeletonLoader: boolean = false;
  interacted: boolean = false;
  valueChangesSubscription: Subscription | undefined;
  checkbatchListTableRespLength: any;
  showBatchNameRelatedError: boolean = false;
  disableForEmptyDataTable: boolean = false;

  setActiveButton(button: string) {
    this.activeButton = button;
  }
  // 
  filtersForm = new FormGroup({
    searchFilter: new FormControl(''),
    uploadFilter: new FormControl<number[]>([]),
    assigneeFilter: new FormControl<number[]>([]),
    statusFilter: new FormControl(''),
    startDate: new FormControl<Date | null>(null),
    endDate: new FormControl<Date | null>(null),
    selectAll: new FormControl(false),
    selectAllAssignee: new FormControl(false),
  });
  name = new FormControl('');
  assignee = new FormControl('');
  selected = -1;
  profileJson: any;
  emailVerify: boolean; // Given null initially so it won't show not verified message every render
  customEndDate: Date;
  customStartDate: Date;
  maxDate = new Date();
  minDate: Date;
  dashboardDataSource = new MatTableDataSource();
  page = 1;
  size = 20;
  totalItems: number;
  total_pages: number;
  statusList: Status[] = [];
  downloadOptionsList: DownloadOptions[] = [
    {
      slug: 'download_generated_file',
      display_name: 'Download generated file'
    },
    {
      slug: 'download_input_file',
      display_name: 'Download input file'
    },
  ];
  selectedDownloadOption: string = '';
  uploadedbyUsers: any = [];
  listOfAssignees: Assignee[] = [];
  filteredUsers: Assignee[] = [];
  filteredAssignees: Assignee[] = [];

  displayedColumns: string[] = [
    'task_name', 'batch_id', 'status', 'uploaded_by', 'updated_on', 'tags', 'assignee', 'final_output_row_count', 'actions'
  ];
  assigneePanelFilter: any;
  tableHeaders: any = [];
  searchTaskTag: string;
  modifiedData: any;
  packages$!: Subscription;
  loading: boolean = false;
  private searchText$ = new Subject<string>();
  //membership
  orgList: OrganizationData[] = [];
  selectedOrg: any;
  selectedOrgCode: string;
  orgCode: any;
  inviteCode: any;
  @ViewChild('selectAllCheckboxElem') selectAllCheckboxElem!: ElementRef;
  selectedStartDate: string | null;
  selectedEndDate: string | null;
  selectedUploadedByUserIds: any[] = [];
  @ViewChild('select') select: MatSelect;
  allSelected: boolean = false;
  selectedAssigniUserIds: any[] = [];
  pollingTimer: NodeJS.Timeout;
  previousEditedTagIndex: number;
  tags: string[] = [];
  tagInput = new FormControl('');
  @ViewChild('tagInputField') tagInputField!: ElementRef;
  assigneeList: any[] = [];
  searchAssigneeAddRemove: string;
  isValid: boolean = true;
  edtTagsDialog: MatDialogRef<EditTagsComponent, any>;
  visibleChips: any[] = [];
  hiddenChips: any[] = [];
  tagsObject: any;
  cellWidth: any;
  isNewVersion: boolean;
  selectAction: string = '';
  isNotImplementedYet: boolean = true;

  constructor(
    public dialog: MatDialog,
    private sidePanelService: SidePanelService,
    public auth: AuthService,
    private homeService: HomeService,
    private snackbarService: SnackbarService,
    private loadingService: LoadingService,
    private accountSettingsService: AccountSettingsService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private appConfig: AppConfigService,
    private formBuilder: FormBuilder,
    private formValidation: ValidationService,
    private dataSharingService: DataSharingService,
    private notificationService: NotificationsService
  ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date();
    this.maxDate = new Date();
  }
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    // 
    this.notificationService.notificationReceived.subscribe(() => {
      this.startPolling(); // Call startPolling when a new notification is received
    });

    // 
    const message = this.dataSharingService.getMessageForCustomTags();
    if (message) {
      this.snackbarService.showSnackbar(`Batch \"${message}\" will be generated in a few minutes. We will send you an email once it's done.`, '', 'customTagSnackbar'); // Show the snackbar
      this.startPolling();
      this.dataSharingService.clearMessageForCustomTags(); // Clear the message after showing
    }
    // 

    // get the app config from the service
    this.isNewVersion = this.appConfig.getConfig().is_new_version;
    // console.log(this.isNewVersion);
    // To calculate the tagName cellWidth
    this.cellWidth = ((window.innerWidth - 1080) / 25) * 4 + 162;

    // get the org id
    this.orgId = localStorage.getItem('currentOrganizationCode');

    // Subscribing to query parameters to extract invite code when the route changes
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.inviteCode = params.invite;
    });
    // 
    this.uploadForm = this.formBuilder.group({
      taskName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        this.formValidation.TaskNamePatternValidation(),
        this.formValidation.minLengthWithoutWhitespace(3),
      ]),
    });
    // Subscribe to value changes of the taskName control
    this.valueChangesSubscription = this.uploadForm.get('taskName')?.valueChanges.subscribe(() => {
      // console.log(`${this.uploadForm.get('taskName')?.value}`);
      
      this.interacted = true;
    });
    // 
    // Subscribing to the closeDialog$ observable from userService to handle user invitation logic
    this.userService.closeDialog$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (res) => {
        // Checking if the dialog is open and a valid token is provided
        if (res.isOpen && res.token) {
          if (localStorage.getItem('user')) {
            if (
              res.token &&
              JSON.parse(localStorage.getItem('user')!)
                .privacy_policy_acceptance
            ) {
              // Updating inviteCode with the provided token and opening the invite request dialog
              this.inviteCode = res.token;
              this.openInviteRequestDialog();
            }
          }
        }
      },
    });

    this.orgCode = localStorage.getItem('currentOrganizationCode');

    // Subscribing to user profile changes and updating local variables accordingly
    this.auth.user$.subscribe((profile) => {
      this.profileJson = profile;
      if (profile) {
        this.emailVerify = this.profileJson.email_verified;
        // Disabling the filtersForm if email is not verified
        if (!this.emailVerify) {
          // this.loadingService.setLoading(false);
          this.showFullPageSkeletonLoader = false;
          this.filtersForm.disable();
          // 
          this.disableForEmptyDataTable = true;

          // 
        } else if (localStorage.getItem('user')) {
          // If Policy is not accpeted open Privacy page policy Dialog
          if (
            this.inviteCode &&
            JSON.parse(localStorage.getItem('user')!).privacy_policy_acceptance
          ) {
            this.openInviteRequestDialog();
          } else if (
            JSON.parse(localStorage.getItem('user')!)
              .privacy_policy_acceptance &&
            this.emailVerify
          ) {
            // console.log('polling');
            
            // this.loadingService.setLoading(true);
            this.showFullPageSkeletonLoader = true;
            // commenting loading now
            // If Policy is accpeted then start polling the Dashboard table
            this.startPolling();
          }
        }
        this.initialiseApp();
      }
    });

    // Subscribing to searchText$ changes with debounce and distinctUntilChanged operators
    this.searchText$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((data) => {
        // this.loadingService.setLoading(true);
        this.showFullPageSkeletonLoader = true;
        this.getBatchListTable();
      });
    // Fetching memberships if orgCode is not available
    if (!this.orgCode) {
      this.getMemberships();
    }

    localStorage.removeItem('task_name');
    localStorage.removeItem('report');
    localStorage.removeItem('isPrevSetBatch');
    if (JSON.parse(localStorage.getItem('user')!).privacy_policy_acceptance) {
      // this.loadingService.setLoading(true);
      this.showFullPageSkeletonLoader = true;
    }
  }
  
  /**
   * 
   * @param batchID trying to update the way we show the validation error
   */
    hasError(controlName: string): boolean {
      const control = this.uploadForm.get(controlName);
      return control && control.errors ? true : false;
    }

    getErrorTooltip(controlName: string): string {
      const control = this.uploadForm.get(controlName);
      if (control && control.errors) {
        if (control.errors.required) {
          return 'Batch name is required.';
        }
        if (control.errors.maxlength) {
          return 'Batch name can\'t be more than 100 characters.';
        }
        if (control.errors.minLengthWithoutWhitespace) {
          return 'Batch name needs to have at least 3 characters.';
        }
        if (control.errors.invalidCharacters) {
          return 'Batch name can\'t have any special characters except for \"-\".';
        }
        if (control.errors.invalidStart) {
          return 'Batch name can\'t start with a number or a special character or space';
        }
        if (control.errors.invalidEnd) {
          return 'Batch name can\'t end with a special character or space';
        }
      }
      return '';
    }

    onBlur(): void {
      this.interacted = true;
    }

    showErrorIconMessage = () => {
      this.showBatchNameRelatedError = true;
    }
    hideErrorIconMessage = () => {
      this.showBatchNameRelatedError = false;
    }
  // 

  // 
  saveTaskName = (batchID: any) => {
    this.activeBatchID = batchID;
    this.showFullPageSkeletonLoader = true;

    this.homeService
      .updateBatchName(
        this.activeBatchID,
        this.uploadForm.get('taskName')?.value || '',
        this.orgId
      )
      .subscribe({
        next: (resp) => {
          this.getBatchListTable();
          this.activeTaskId = null;
          this.activeTaskNameForEdit = '';
          this.uploadForm.reset();
          this.interacted = false;
          setTimeout(() => {
            this.snackbarService.showSnackbar(resp.detail, '', 'success');
          }, 2000); // 5 seconds delay
          // this.showFullPageSkeletonLoader = false;
          setTimeout(() => {
            this.showFullPageSkeletonLoader = false;
            this.uploadForm.reset();
            this.interacted = false;
          }, 5000); // 5 seconds delay

        },
        error: (error: any) => {
          this.showFullPageSkeletonLoader = false;
          this.snackbarService.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };
  // 
  
  // 
  onChangeDownloadOption(inputFileUrl: any, outputFileUrl: any): void {
    // 
    this.inputFileUrl = inputFileUrl;
    this.outputFileUrl = outputFileUrl;
    // 
    if (this.selectedDownloadOption === 'download_generated_file') {
      this.downloadFile(this.outputFileUrl);
    } else if (this.selectedDownloadOption === 'download_input_file') {
      this.downloadFile(this.inputFileUrl);
    }
  }

  downloadFile(url: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // 
  editThisTask = (taskID: number) => {
    this.activeTaskId = taskID;
    this.interacted = false;

    // this.isTaskEditingEnabled = true 
  }
  // Add this method in your component class
isOptionDisabled(downloadOption: DownloadOptions, element: any): boolean {
  return downloadOption.slug === 'download_generated_file' && element.status !== 'Completed';
}

  cancelTaskNameEdit = () => {
    // console.log('cancelling task editing...');
    // console.log('activeTaskNameForEdit: ', this.activeTaskNameForEdit);

    this.activeTaskId = null;
    this.activeTaskNameForEdit = '';
    this.uploadForm.reset();
    this.interacted = false;
    // this.isTaskEditingEnabled = false;
  };
  
  // 

  /**
   * Retrieves and updates the batch list table data based on applied filters and pagination parameters.
   * Disables filtersForm if no results and specific filters are applied.
   */
  getBatchListTable = () => {
    if (!this.orgCode) {
      return;
    }
    // Ayushman
    
    let startDate = this.filtersForm.get('startDate')?.value;
    this.selectedStartDate =
        startDate ? startDate.getFullYear() +
        '-' +
        (startDate.getMonth() + 1) +
        '-' +
        startDate.getDate() : '';

    let endDate = this.filtersForm.get('endDate')?.value;
    this.selectedEndDate =
        endDate ? endDate.getFullYear() +
        '-' +
        (endDate.getMonth() + 1) +
        '-' +
        endDate.getDate() : '';

        // console.log('check startDate: ', this.filtersForm.get('startDate')?.value);
        // console.log('check selectedStartDate: ', this.selectedStartDate);
        // console.log('check endDate: ', this.filtersForm.get('endDate')?.value);
        // console.log('check selectedEndDate: ', this.selectedEndDate);
    
    
    // 

    this.homeService
      .batchListTable(
        this.page,
        this.size,
        this.selectedUploadedByUserIds,
        this.selectedAssigniUserIds,
        this.filtersForm.get('statusFilter')?.value,
        this.selectedStartDate,
        this.selectedEndDate,
        this.filtersForm.get('searchFilter')?.value,
        this.orgCode
      )
      .subscribe({
        next: (resp) => {
          // this.loadingService.setLoading(false);
          this.showFullPageSkeletonLoader = false;
          this.loading = true;
          // 
          // resp.result = [];
          // this.checkbatchListTableRespLength = resp.result.length;
          // console.log('selectedStartDate: ', this.selectedStartDate);
          // console.log('selectedEndDate: ', this.selectedEndDate);
          // console.log('typeof selectedEndDate: ', typeof(this.selectedStartDate));
          // console.log('typeof selectedEndDate: ', typeof(this.selectedEndDate));



          
          // 
          if (
            resp.result.length === 0 &&
            this.filtersForm?.get('uploadFilter')?.value?.length! == 0 &&
            this.filtersForm?.get('assigneeFilter')?.value?.length! == 0 &&
            !this.filtersForm.get('statusFilter')?.value &&
            !this.selectedStartDate &&
            !this.filtersForm.get('searchFilter')?.value &&
            !this.selectedEndDate
          ) {
            this.filtersForm.disable();
            this.disableForEmptyDataTable = true;
          } else {
            this.filtersForm.enable();
            this.disableForEmptyDataTable = false;

          }
          // console.log('modifiedt', resp.result);

          this.modifiedData = resp.result;

          this.modifiedData = resp.result.map((dta: any) => {
            this.calculateChips(dta.tags);

            if (dta.tags) {
              if (dta.tags && this.tagsObject) {
                Object.assign(dta, { tags: this.tagsObject });
              }
            }
            return dta;
          });

          // console.log('modifiedt', this.modifiedData);
          this.dashboardDataSource = new MatTableDataSource(this.modifiedData);
          // console.log('check dashboardDataSource: ', this.dashboardDataSource);
          
          this.totalItems = resp.total_items;
          this.size = resp.page_size;
          this.page = resp.page;
          this.total_pages = resp.total_pages;
        },
        error: (error) => {
          // this.loadingService.setLoading(false);
          this.showFullPageSkeletonLoader = false;
          // this.snackbarService.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };

  // rendering tags dynamically with respect to the cell width
  calculateChips(tags: any) {
    this.tagsObject = {
      visibleChips: [],
      hiddenChips: [],
    };
    this.visibleChips = [];
    this.hiddenChips = [];
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.whiteSpace = 'nowrap';
    div.style.lineHeight = '18px';
    document.body.appendChild(div);
    let i = 19;
    let currentWidth = 0;
    tags.forEach((text: any, index: number) => {
      // Add text to div and check width
      div.textContent += text.tag_name; // Add space between words, but not before the first word
      currentWidth = div.offsetWidth + i;
      i += 22;

      // Categorize based on width
      if (currentWidth <= this.cellWidth) {
        this.visibleChips.push(text);
      } else if (
        this.visibleChips.length === 0 ||
        currentWidth > this.cellWidth
      ) {
        // If even the first string exceeds the this.cellWidth or any subsequent addition exceeds this.cellWidth
        this.hiddenChips.push(text);
        return; // Exit the loop early as all remaining strings will be hidden
      }
    });

    if (this.visibleChips.length > 1) {
      currentWidth = div.offsetWidth;
      currentWidth += this.visibleChips.length * 14;
      if (currentWidth > this.cellWidth) {
        this.hiddenChips.push(this.visibleChips.pop());
      }
    }

    this.tagsObject = {
      visibleChips: [this.visibleChips],
      hiddenChips: [this.hiddenChips],
    };

    // Clean up by removing the div
    document.body.removeChild(div);
  }
  /**
   * Retrieves and initializes the list of memberships.
   * Calls the accountSettingsService to list memberships and handles success and error responses.
   * Sets the organization list, selects the first organization by default, and initializes the app.
   */
  getMemberships = () => {
    this.accountSettingsService.listMemberships().subscribe({
      next: (resp: any) => {
        this.orgList = resp.result;
        this.selectedOrg = this.orgList[0];
        this.selectedOrgCode = this.selectedOrg.organisation_code;
        localStorage.setItem('currentOrganizationCode', this.selectedOrgCode);
        this.orgCode = localStorage.getItem('currentOrganizationCode');
        this.initialiseApp();
      },
      error: (error: HttpErrorResponse) => {
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.pollingTimer);
    // Unsubscribe from value changes to prevent memory leaks
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }

  }
  // Returns an interval function that calls table API every 10 seconds
  interval() {
    return setInterval(() => {
      this.getBatchListTable();
    }, 300000);
  }

  stopPolling = () => {
    clearInterval(this.pollingTimer);
  };

  startPolling = () => {
    this.pollingTimer = this.interval();
  };

  initialiseApp() {
    if (!this.emailVerify) {
      return;
    }
    this.getBatchListTable();
    this.getBatchListTableHeaders();
    this.getAssigneeUploadedByFilters();
    this.getStatusFilter();
  }
  // Returns the value of the feature flag for the Share button based on environment configuration
  get featureFlagShareButton(): boolean {
    return environment.feature_flags.SHARE_BATCH;
  }
  // Returns the value of the feature flag for the Invite button based on environment configuration
  get featureFlagInviteButton(): boolean {
    return environment.feature_flags.INVITE_MEMBERS;
  }
  /**
   * Checks if the user has permission to create team members.
   * Uses the userService to verify the presence of the 'member:create' permission.
   * @returns A boolean indicating whether the user has permission to create team members.
   */
  get hasTeamMembersCreatePermission(): boolean {
    return this.userService.hasPermission('member:create');
  }
  /**
   * Resends email verification by calling the homeService with the user's email.
   * Displays a success or error snackbar based on the response.
   */
  reSendEmail = () => {
    this.homeService.resendEmail(this.profileJson.email).subscribe({
      next: (resp) => {
        this.snackbarService.showSnackbar(resp.detail, '', 'success');
      },
      error: (error) => {
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /**
   * Retrieves and sets batch list table headers from the homeService based on the organization code.
   */
  getBatchListTableHeaders = () => {
    this.showFullPageSkeletonLoader = true;
    this.homeService.batchListTableHeaders(this.orgCode).subscribe({
      next: (resp) => {
        this.tableHeaders = resp;
        // optimizing the way we show the columns in the
        // table
        // Extracting all the 'slug' values
        const slugs = this.tableHeaders.map((item: { slug: any; }) => item.slug);

        // Output the result
        // console.log(slugs);
        // this.displayedColumns = slugs;
        // console.log(this.displayedColumns);
        this.showFullPageSkeletonLoader = false;

        // 
      },
      error: (error) => {
        // this.loadingService.setLoading(false);
        this.showFullPageSkeletonLoader = false;
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /**
   * Fetches and updates the list of assignees and uploaders from the homeService based on the organization code.
   */
  getAssigneeUploadedByFilters = () => {
    this.homeService.assigneeUploadedbyFilters(this.orgCode).subscribe({
      next: (resp) => {
        this.listOfAssignees = resp.result;
        this.uploadedbyUsers = resp.result;
        // this.listOfAssignees = [
        //   { id: 863, name: 'mayuri.jha@crowdanalytix.com' },
        //   { id: 864, name: 'astha.s@crowdanalytix.com' },
        // ];
        // this.listOfAssignees = [
        //   { id: 863, name: 'mayuri.jha@crowdanalytix.com' },
        //   { id: 864, name: 'astha.s@crowdanalytix.com' },
        //   { id: 865, name: 'john.s@crowdanalytix.com' },
        //   { id: 866, name: 'rahul.s@crowdanalytix.com' },
        // ];
        // this.uploadedbyUsers = [
        //   { id: 863, name: 'mayuri.jha@crowdanalytix.com' },
        //   { id: 864, name: 'astha.s@crowdanalytix.com' },
        //   { id: 865, name: 'john.s@crowdanalytix.com' },
        //   { id: 866, name: 'rahul.s@crowdanalytix.com' },
        // ];
        this.filteredAssignees = this.listOfAssignees;
        this.filteredUsers = this.uploadedbyUsers;
      },
      error: (error) => {
        // this.loadingService.setLoading(false);
        this.showFullPageSkeletonLoader = false;
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };
  /**
   * Fetches and updates the status filter options from the homeService based on the organization code.
   */
  getStatusFilter = () => {
    this.homeService.statusFilter(this.orgCode).subscribe({
      next: (resp) => {
        this.statusList = resp.result;
      },
      error: (error) => {
        // this.loadingService.setLoading(false);
        this.showFullPageSkeletonLoader = false;
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /**
   * Initiates a search operation by updating the searchText$ observable with the provided packageName.
   * @param packageName - The name of the package to search for.
   */

  search(packageName: string) {
    this.searchText$.next(packageName);
  }
  /**
   * Retrieves the value from the HTML input element associated with the provided event.
   * @param event - The input event triggered by user interaction.
   * @returns The value of the HTML input element.
   */
  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  /******************* `Uploaded by` select functionality begins here ******************** */

  /* search in `uploaded by` dropdown */

  searchInUploadedBySelectDropdown(e: any) {
    const searchTermForUploadedByDropDown = e.target.value.toLowerCase();
    if (searchTermForUploadedByDropDown.trim() !== '') {
      this.filteredUsers = this.uploadedbyUsers.filter((data: any) => {
        return data.name
          .toLowerCase()
          .includes(searchTermForUploadedByDropDown);
      });
    } else {
      // Handle the case when the search term is empty
      this.filteredUsers = this.uploadedbyUsers;
      // set all selected values (including the ones after search) here
      // as form ctrl is not able to retain previous selection due to change in filteredUsers list everytime
      this.filtersForm
        .get('uploadFilter')
        ?.setValue(this.selectedUploadedByUserIds);
    }
  }

  /*** clear the search query in `uploaded by` dropdown */

  clearSearchUploadedBy = () => {
    this.name.reset();
    this.filteredUsers = this.uploadedbyUsers;
    this.filtersForm
      .get('uploadFilter')
      ?.setValue(this.selectedUploadedByUserIds);
  };

  /****  Function to handle the closed event of the `upload` dropdown **/

  onDropdownClosed() {
    if (this.filteredUsers.length == 0) {
      this.filteredUsers = this.uploadedbyUsers;
      // Reset the 'name' FormControl to its initial state (empty string)
      this.name.reset('');
    }
  }

  // 
  checkCharacterLength = (inputValue: any) => {
    // console.log('inputValue length: ', inputValue.length);
    // console.log('inputValue: ', inputValue);

    
  }
  
  // 

  // =====
  /**
   * Handles the selection/deselection of all users in the "uploaded by" filter.
   * Updates the selectedUploadedByUserIds and the corresponding form control based on the 'selectAll' checkbox state.
   * Triggers a batch list table update and sets loading state.
   * @param $event - The event object representing the checkbox state change.
   */

  selectAllUsersUnderUploadedby($event: any) {
    let check = this.filtersForm.get('selectAll')?.value;
    if (check) {
      this.selectedUploadedByUserIds = this.filteredUsers.map((data: any) => {
        return data.id;
      });
      this.filtersForm
        .get('uploadFilter')
        ?.setValue(this.selectedUploadedByUserIds);
    } else {
      this.selectedUploadedByUserIds = [];
      this.filtersForm.get('uploadFilter')?.setValue([]);
    }
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.getBatchListTable();
  }

  /**
   * Handles the change event when the uploaded by filter is modified.
   * Pushes selected users in a separate list as form ctrl `uploadFilter` is not retaining values after search
   * Resets pagination, sets loading state, unchecks the 'selectAll' checkbox, and updates the batch list table.
   */

  getIndividualSelectedValuesForUploadedBy = (event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) => {
    this.showFullPageSkeletonLoader = true;
    if (event.isUserInput) {
      if (
        event.source.selected === true &&
        this.selectedUploadedByUserIds.indexOf(event.source.value) == -1
      ) {
        this.selectedUploadedByUserIds.push(event.source.value);
      } else {
        this.selectedUploadedByUserIds.splice(
          this.selectedUploadedByUserIds.indexOf(event.source.value),
          1
        );
      }
    }
    this.page = 1;
    // trigger table api from here only when options selected one by one
    // this is to avoid multiple calls

    if (
      !this.filtersForm.get('selectAll')?.value &&
      this.selectAction != 'clearAllUploadedBy'
    ) {
      this.getBatchListTable();
    }
    // this.filtersForm.get('selectAll')?.setValue(false);
  };

  /**
   * Clears all filters related to the "uploaded by" category.
   * Resets the name filter, unchecks the 'selectAll' checkbox, clears the uploadFilter, and triggers a batch list table update.
   */
  clearAllUploadedBy() {
    // this.name.reset();
    this.selectAction = 'clearAllUploadedBy';
    this.filtersForm.get('uploadFilter')?.setValue([]);
    this.filtersForm.get('selectAll')?.setValue(false);
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.selectedUploadedByUserIds = [];
    // reinitialize the complete list of users
    // this.filteredUsers = this.uploadedbyUsers;
    this.getBatchListTable();
  }

  /******************* `Assignee` select functionality begins here ******************** */

  /* search in `assignee` dropdown */

  searchAssignee(e: any) {
    const searchTermForAssigneeDropDown = e.target.value.toLowerCase();
    if (searchTermForAssigneeDropDown.trim() !== '') {
      this.filteredAssignees = this.uploadedbyUsers.filter((data: any) =>
        data.name.toLowerCase().includes(searchTermForAssigneeDropDown)
      );
    } else {
      // Handle the case when the search term is empty
      this.filteredAssignees = this.uploadedbyUsers;
      // set all selected values (including the ones after search) here
      // as form ctrl is not able to retain previous selection due to change in filteredUsers list everytime
      this.filtersForm
        .get('assigneeFilter')
        ?.setValue(this.selectedAssigniUserIds);
    }
  }

  /*** clear search entry for `assignee` dropdown */

  clearSearchAssignee = () => {
    this.assignee.reset();
    this.filteredAssignees = this.uploadedbyUsers;
    this.filtersForm
      .get('assigneeFilter')
      ?.setValue(this.selectedAssigniUserIds);
  };

  /****  Function to handle the closed event of the `upload` dropdown **/

  onAssigneeDropdownClosed() {
    if (this.filteredAssignees.length == 0) {
      this.filteredAssignees = this.uploadedbyUsers;
      // Reset the 'assignee' FormControl to its initial state (empty string)
      this.assignee.reset('');
    }
  }

  // =========
  /**
   * Handles the selection/deselection of all users in the "assignee" filter.
   * Updates the selectedAssigniUserIds and the corresponding form control based on the 'selectAllAssignee' checkbox state.
   * Triggers a batch list table update.
   * @param $event - The MatCheckboxChange event object representing the checkbox state change.
   */
  selectAllAssignee($event: MatCheckboxChange) {
    // 
    // console.log('clicked me...');
    
    // 
    this.showFullPageSkeletonLoader = true;

    let check = this.filtersForm.get('selectAllAssignee')?.value;
    if (check) {
      this.selectedAssigniUserIds = this.filteredAssignees.map((data: any) => {
        return data.id;
      });
      this.filtersForm
        .get('assigneeFilter')
        ?.setValue(this.selectedAssigniUserIds);
    } else {
      this.selectedAssigniUserIds = [];
      this.filtersForm.get('assigneeFilter')?.setValue([]);
    }
    // this.loadingService.setLoading(true);
    this.getBatchListTable();
  }

  /**
   * Handles the change event when the uploaded by filter is modified.
   * Pushes selected users in a separate list as form ctrl `uploadFilter` is not retaining values after search
   * Resets pagination, sets loading state, unchecks the 'selectAll' checkbox, and updates the batch list table.
   */

  getIndividualSelectedValuesForAssignee = (event: {
    isUserInput: any;
    source: { value: any; selected: any };
  }) => {
    // 
    // console.log('test');
    this.showFullPageSkeletonLoader = true;

    
    // 
    if (event.isUserInput) {
      if (
        event.source.selected === true &&
        this.selectedAssigniUserIds.indexOf(event.source.value) == -1
      ) {
        this.selectedAssigniUserIds.push(event.source.value);
      } else {
        this.selectedAssigniUserIds.splice(
          this.selectedAssigniUserIds.indexOf(event.source.value),
          1
        );
      }
    }
    this.page = 1;
    // trigger table api from here only when options selected one by one
    // this is to avoid multiple calls
    if (
      !this.filtersForm.get('selectAllAssignee')?.value &&
      this.selectAction != 'clearAllAssignee'
    ) {
      this.getBatchListTable();
    }
    // this.filtersForm.get('selectAll')?.setValue(false);
  };

  /**
   * Clears all filters related to the "assignee" category.
   * Resets the assignee filter, unchecks the 'selectAllAssignee' checkbox, clears the assigneeFilter, and triggers a batch list table update.
   */
  clearAllAssignee() {
    // this.assignee.reset();
    this.selectAction = 'clearAllAssignee';
    this.filtersForm.get('assigneeFilter')?.setValue([]);
    this.filtersForm.get('selectAllAssignee')?.setValue(false);
    // this.filteredAssignees = this.uploadedbyUsers;
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.selectedAssigniUserIds = [];
    this.getBatchListTable();
  }

  /**
   * Handles the change event when the status filter is modified.
   * Resets pagination and sets loading state before updating the batch list table.
   */
  onChangeStatus() {
    this.page = 1;
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.getBatchListTable();
  }
  /**
   * Handles the start and end date filter changes.
   * Parses and formats the selected start and end dates for API compatibility.
   * Resets the start date and triggers a batch list table update if end date is cleared.
   * Updates pagination and sets loading state before updating the batch list table if both start and end dates are selected.
   */
  submitSelectedDateRange() {
    let startDate = this.filtersForm.get('startDate')?.value;
    if (startDate)
      this.selectedStartDate =
        startDate?.getFullYear() +
        '-' +
        (startDate?.getMonth() + 1) +
        '-' +
        startDate?.getDate();
    let endDate = this.filtersForm.get('endDate')?.value;
    if (!endDate) {
      this.filtersForm.get('startDate')?.reset();
      this.selectedStartDate = null;
      this.getBatchListTable();
    }
    if (endDate)
      this.selectedEndDate =
        endDate?.getFullYear() +
        '-' +
        (endDate?.getMonth() + 1) +
        '-' +
        endDate?.getDate();
    if (startDate && endDate) {
      this.page = 1;
      // this.loadingService.setLoading(true);
      this.showFullPageSkeletonLoader = true;
      this.getBatchListTable();
    }
  }
  /**
   * Clears the start and end date filters.
   * Resets the start and end date form controls, sets selected dates to null, and triggers a batch list table update.
   */
  clearDate() {
    this.filtersForm.get('startDate')?.reset();
    this.filtersForm.get('endDate')?.reset();
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.getBatchListTable();
  }
  /**
   * Clears all applied filters and resets the page for batch list table data.
   * Resets name and assignee filters, selected start and end dates, and the entire filtersForm.
   * Triggers a batch list table update after resetting.
   */
  clearAllFilter() {
    this.page = 1;
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.name.reset();
    this.assignee.reset();
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.filtersForm.reset();
    this.selectedUploadedByUserIds = [];
    this.selectedAssigniUserIds = [];
    this.getBatchListTable();
  }
  /**
   * Refreshes the content of the dashboard table.
   * Sets the loading state to true and triggers an update of the batch list table.
   */
  refreshDashboardTableContent = () => {
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    // this.showTableSkeletonLoader = true;
    this.getBatchListTable();
  };
  /**
   * Handles the click event on a task, updating local storage variables based on task properties.
   * Removes 'isSampleTask' and 'isPrevSetBatch' to conditionally enable/disable certain features.
   * Sets 'isSampleTask' to 'true' if the task is a sample, and 'isPrevSetBatch' to 'true' if it has previous settings.
   * @param task - The task object representing the clicked task.
   */
  onTaskClick(task: any) {
    localStorage.removeItem('isSampleTask');
    localStorage.removeItem('isPrevSetBatch'); // remove if exist, to allow / disable conditionally
    if (task.is_sample) {
      localStorage.setItem('isSampleTask', 'true');
    }
    if (task.has_previous_settings) {
      localStorage.setItem('isPrevSetBatch', 'true');
    }
  }
  /**
   * Initiates the addition or removal of tags in the table.
   * Pauses polling to prevent interference during tag modification.
   * If a tag is already in edit mode, resets its editable state.
   * Sets the selected tag's editable state to true for modification.
   * Updates the dashboard data source and records the index of the previously edited tag.
   * @param tags - The array of tags associated with the table row.
   * @param index - The index of the tag being added or removed.
   * @param rowindex - The index of the row in the table.
   */
  addOrRemoveTags(tags: any, index: number, rowindex: number) {
    // Pause polling during tag modification
    this.stopPolling();
    // Reset editable state of the previously edited tag if any
    if (this.previousEditedTagIndex) {
      this.modifiedData[rowindex].tags[this.previousEditedTagIndex].isEditable =
        false;
    } else {
      this.modifiedData[rowindex].tags[0].isEditable = false;
    }
    // Set editable state to true for the selected tag
    this.modifiedData[rowindex].tags[index].isEditable = true;
    this.dashboardDataSource = new MatTableDataSource(this.modifiedData);
    this.previousEditedTagIndex = index;
  }
  /**
   * Handles keypress events to update tags in the table.
   * Pauses polling to prevent interference during tag modification.
   * Initiates the addition or removal of tags based on the 'Enter' keypress event.
   * @param event - The keypress event object.
   * @param batchid - The identifier of the batch associated with the tags.
   * @param add - A boolean indicating whether the action is an addition or removal of tags.
   * @param tagId - The identifier of the tag being added or removed.
   */
  onKeyPressUpdateTags(event: any, batchid: number, add: any, tagId: any) {
    this.stopPolling();
    if (event.key === 'Enter') {
      this.additionOfTagsAndService(event, batchid, add, tagId);
    }
  }

  /**
   * Function to open the edit tags dialog.
   *
   * @param tags - The tags data to be passed to the dialog.
   * @param rowData - The data of the current row.
   * @param rowindex - The index of the current row.
   */
  openEditTagsDialog(tags: any, rowData: any, rowindex: number): void {
    this.stopPolling();
    const dialogRef = this.dialog.open(EditTagsComponent, {
      width: '416px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        rowindex: rowindex,
        rowData: rowData,
        tags: tags,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getBatchListTable();
      this.startPolling();
    });
  }
  /**
   * Extracts and formats the tag names from an array for display.
   * @param remtag - An array of tag objects containing tag information.
   * @returns A formatted string containing tag names separated by newline characters.
   */
  formatTagNames(remtag: any) {
    return remtag
      .map((dta: any) => {
        return dta.tag_name;
      })
      .join('\n');
  }
  // onFocusOutUpdateTags(event: any, batchid: any, add: any) {
  //   // this.stopPolling();
  //   this.additionOfTagsAndService(event, batchid, add);
  // }
  /**
   * Handles the addition or update of tags and triggers the corresponding service.
   * Sets loading state, starts polling, and constructs the payload for the service call.
   * Calls the homeService to update tags and handles success and error responses.
   * Resets the tag input field and displays a snackbar with appropriate messages.
   * Focuses on the tag input field for user convenience.
   * @param event - The keypress event object (for updating tags).
   * @param batchid - The identifier of the batch.
   * @param add - The action type for the tag operation (e.g., 'add_tag', 'update_tag').
   * @param tagId - The identifier of the tag (for updating tags).
   */
  additionOfTagsAndService = (
    event: any,
    batchid: number,
    add: any,
    tagId?: number
  ) => {
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.startPolling();
    let addingTag;
    if (add === 'update_tag') {
      addingTag = {
        action: add,
        tag_name: event?.target?.value ? event?.target?.value : null,
        batch_id: batchid,
        tag_id: tagId,
      };
    } else {
      addingTag = {
        action: add,
        tag_name: this.tagInput.value,
        batch_id: batchid,
      };
    }
    this.homeService.updateTags(addingTag, this.orgCode).subscribe({
      next: (resp) => {
        this.getBatchListTable();
        this.tagInput.reset();
        this.snackbarService.showSnackbar(resp.detail.detail, '', 'success');
      },
      error: (error) => {
        // this.loadingService.setLoading(false);
        this.showFullPageSkeletonLoader = false;
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
    this.tagInputField.nativeElement.focus();
  };
  /**
   * Validates and allows the input of alphanumeric characters, space, and backspace.
   * @param event - The keypress event object.
   * @returns A boolean indicating whether the entered character is valid.
   */
  omit_special_char(event: any) {
    var k;
    k = event.charCode; //
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  /**
   * Opens the side panel for assigning users to a batch.
   * Checks the number of available users and returns if there is only one or none.
   * Initializes the assigneeList based on existing users and sets the side panel filter.
   * Sorts the assigneeList with added users appearing first.
   * Sets the visibility of the side panel.
   * @param existingUser - An array of existing users.
   * @param batchid - The identifier of the batch.
   */
  openSidePanel(existingUser: any[], batchid: number) {
    this.isValid = true;
    this.searchAssigneeAddRemove = '';
    // Check the number of available users, return if there is only one or none
    if (this.listOfAssignees.length <= 1) {
      return;
    }
    // Initialize the assigneeList based on existing users and set the side panel filter
    this.assigneeList = this.listOfAssignees.map((data: any) => {
      if (existingUser.includes(data?.name)) {
        return { email: data.name, added: true, id: data.id, batchid: batchid };
      } else {
        return {
          email: data.name,
          added: false,
          id: data.id,
          batchid: batchid,
        };
      }
    });
    this.assigneePanelFilter = this.assigneeList;
    this.assigneeList.sort((a, b) => {
      if (a?.added) {
        return -1;
      } else {
        return 1;
      }
    });

    this.sidePanelService.setShowNav(true);
  }

  disabledEditAssignee(assignee: any) {
    return assignee ? 'You dont have a team member to assign' : '';
  }

  addEditAssignee(asignee: any) {
    return asignee ? 'Add/Edit Assignee' : '';
  }
  /**
   * Opens the share dialog for a batch, providing batch details and assignee information.
   * Retrieves team member names from the assignee array.
   * Creates and opens the ShareDialogComponent with specified width and data.
   * @param batchid - The identifier of the batch.
   * @param assignee - An array of assignee information.
   */
  openShareDialog(batchid: number) {
    const teamMember = this.listOfAssignees.map((dta: any) => dta.name);
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      width: '30%',
      data: {
        batch_id: batchid,
        assignee: teamMember,
      },
    });
  }
  /**
   * Initiates the download of a file by creating a temporary link element with the provided URL,
   * triggering a click event, and then removing the link from the document.
   * @param url - The URL of the file to be downloaded.
   * @param event - The event triggering the download.
   */
  downloadMyOutputFile(url: any, event: any) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  /**
   * Handles pagination events.
   * Updates page and size variables based on the event's page index and page size.
   * Sets the loading state and triggers an update of the batch list table.
   * @param e - The pagination event object.
   */
  onPaginateChange = (e: any) => {
    this.page = e.pageIndex + 1;
    this.size = e.pageSize;
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.getBatchListTable();
  };
  /******************************** Function for sidebar ********************* */
  searchAssigneeInPanel(e: any) {
    this.searchAssigneeAddRemove = e.target.value;
    // Perform validation and set the validity status
    if (this.searchAssigneeAddRemove != '') {
      this.assigneeList = this.assigneePanelFilter.filter((data: any) => {
        if (data.email.includes(e.target.value)) {
          return data;
        }
      });
    } else {
      this.isValid = true;
      this.assigneeList = this.assigneePanelFilter;
      return this.assigneeList;
    }
  }

  clearSearch() {
    this.searchAssigneeAddRemove = ''; // Reset the search value
    this.isValid = true;
    this.assigneeList = this.assigneePanelFilter;
    return this.assigneeList;
  }
  /**
   * Adds or removes assignee from the side panel.
   * Constructs the payload for the service call based on the action.
   * Calls homeService to perform the add/remove action and handles success and error responses.
   * Updates the assigneeList, triggers a batch list table update, and sorts the list.
   * Displays a snackbar with appropriate messages.
   * @param assigneeInfo - The assignee information.
   * @param action - The action type for add/remove ('add' or 'remove').
   * @param index - The index of the assignee in the assigneeList.
   */
  addRemoveAssignee = (
    assigneeInfo: any,
    action: string,
    index: number
  ): void => {
    // Construct the payload for the service call based on the action
    let payload = {
      batch_id: assigneeInfo.batchid,
      action: action,
      assignee_id: assigneeInfo.id,
      assignee_email: assigneeInfo.email,
    };
    this.homeService.addRemoveAssignee(payload, this.orgCode).subscribe({
      next: (resp) => {
        // this.loadingService.setLoading(false);
        this.showFullPageSkeletonLoader = false;
        this.assigneeList[index].added = !assigneeInfo.added;
        this.getBatchListTable();
        this.assigneeList.sort((a, b) => (a?.added ? -1 : 1));
        this.snackbarService.showSnackbar(resp.detail.detail, '', 'success');
      },
      error: (error) => {
        // this.loadingService.setLoading(false);
        this.showFullPageSkeletonLoader = false;
      },
    });
  };
  /**
   * Determines whether the remove action should be shown for a given data item.
   * Filters the assigneeList to identify added team members.
   * Returns true if the data is added and there is more than one added team member.
   * @param data - The data item to check for the remove action.
   * @returns A boolean indicating whether the remove action should be shown.
   */
  showRemove(data: any) {
    const teamMemb = this.assigneeList.filter((data: any) => {
      return data.added;
    });
    return data.added && teamMemb.length > 1;
  }
  /**
   * Opens the invite members dialog.
   * Initializes the InviteMembersComponent with specified properties.
   * Closes the side panel for user convenience.
   */
  invitemembDialog(): void {
    const dialogRef = this.dialog.open(InviteMembersComponent, {
      width: '440px',
      disableClose: true,
      autoFocus: true,
      hasBackdrop: true,
    });

    this.closeSidePanel();
  }
  closeSidePanel() {
    this.sidePanelService.setShowNav(false);
  }

  openInviteRequestDialog() {
    const dialogRef = this.dialog
      .open(InviteRequestDialogComponent, {
        width: '30%',
        data: {
          isInviteRequest: true,
          code: this.inviteCode,
        },
        autoFocus: false,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res) => {
        // update user data in local storage
        this.userService.me().subscribe({
          next: (resp) => {
            localStorage.setItem('user', JSON.stringify(resp));
            this.startPolling;
          },
        });
      });
  }

  /** jump page  */
  goToPage = (event: any) => {
    event.preventDefault();
    this.page = event.target.value;
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.getBatchListTable();
  };

  //search tasks tag name:
  searchTaskTags(e: any) {
    // this.loadingService.setLoading(true);
    this.showFullPageSkeletonLoader = true;
    this.getBatchListTable();
  }

  selectAllCheckbox() {
    // Trigger click event on the checkbox element
    (this.selectAllCheckboxElem.nativeElement as HTMLInputElement).click();
  }

  openTagDialog(batch_id: any) {
    this.stopPolling();
    const dialogRef = this.dialog
      .open(EditTagsComponent, {
        width: '30%',
        data: {
          batchId: batch_id,
        },
        autoFocus: false,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res: any) => {
        // update table
        if (res == 'render') {
          // this.loadingService.setLoading(true);
          this.showFullPageSkeletonLoader = true;
          this.getBatchListTable();
          this.startPolling();
        }
      });
  }

  generateTooltipContent(tags: any[]): string {
    return tags.map((tag) => tag.tag_name).join('\n');
  }
}
export interface HomeElement {
  Assignee: string;
  Tags: string;
  TaskName: string;
  UploadedOn: string;
  Status: string;
  Action: string;
}
