<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <div class="header">
      <div class="title">My Profile</div>
      <div class="subtitle">Update your personal information.</div>

      <div class="my-profile">
        <div class="my-profile-form">
          <form class="form" [formGroup]="myProfileForm" (ngSubmit)="save()">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <!-- Display Name --> 
              <div fxLayout="column">
                <span class="form-header">Display Name</span>
                <mat-form-field class="example-full-width" appearance="outline">
                  <input type="email" matInput formControlName="display_name" [readonly]="!editMode"
                    placeholder="e.g. JL" [(ngModel)]="currentProfileDetails.display_name" />
                  <!-- <mat-error *ngIf="myProfileForm?.get('display_name').touched">
                    Display Name is required
                  </mat-error> -->
                  <mat-error *ngIf="
                      myProfileForm?.get('display_name').hasError('pattern')
                    ">
                    Display Name must start with a letter and contain only
                    letters and digits.
                  </mat-error>
                  <mat-error *ngIf="
                      myProfileForm?.get('display_name').hasError('maxlength')
                    ">
                    Display Name cannot be longer than 20 characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <button mat-stroked-button class="edit-btn" (click)="toggleEditButton()" *ngIf="!showSaveButton">
                <img src="../../../../assets/images/edit.svg" />
                <span>Edit</span>
              </button>

              <button mat-raised-button color="primary" class="save-btn" (click)="saveEditedValue()" *ngIf="editMode"
                [disabled]="myProfileForm.invalid">
                {{saveInProgress ? 'Saving..':'Save'}}
              </button>
            </div>
            <div fxLayout="row" fxLayoutGap="24">
              <!-- First Name -->
              <div fxLayout="column">
                <span class="form-header">First Name</span>
                <mat-form-field appearance="outline" class="password-form-field">
                  <input type="email" matInput formControlName="first_name" placeholder="e.g. Jese"
                    [(ngModel)]="currentProfileDetails.first_name" [readonly]="!editMode" />
                  <mat-error *ngIf="myProfileForm.get('first_name')?.hasError('pattern')">
                    First name must not contain space or special characters or
                    digits.
                  </mat-error>
                  <mat-error *ngIf="
                      myProfileForm?.get('first_name').hasError('maxlength')
                    ">
                    First name cannot be longer than 20 characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Last Name -->
              <div fxLayout="column">
                <span class="form-header">Last Name</span>
                <mat-form-field appearance="outline" class="password-form-field">
                  <input type="email" matInput formControlName="last_name" placeholder="e.g. Leos"
                    [(ngModel)]="currentProfileDetails.last_name" [readonly]="!editMode" />
                  <mat-error *ngIf="myProfileForm?.get('last_name').hasError('pattern')">
                    Last name must not contain space or special characters or
                    digits.
                  </mat-error>
                  <mat-error *ngIf="
                      myProfileForm?.get('last_name').hasError('maxlength')
                    ">
                    Last name cannot be longer than 20 characters.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="24">
              <!-- Email -->
              <div fxLayout="column">
                <span class="form-header">Email</span>

                <mat-form-field class="password-form-field" appearance="outline">
                  <span fxLayout="row" fxLayoutGap="5px">
                    <img [src]="
                    this.showSaveButton
                      ? '../../../../assets/images/MailRead.svg'
                      : '../../../../assets/images/mail.svg'
                  " />
                    <input type="email" matInput placeholder="e.g.  pat@example.com"
                      [value]="currentProfileDetails.email" [readonly]="true" class="readonly-input" />
                  </span>
                </mat-form-field>
              </div>
              <!-- Contact -->
              <div fxLayout="column">
                <span class="form-header">Contact No</span>
                <mat-form-field class="password-form-field" appearance="outline">
                  <span fxLayout="row" fxLayoutGap="5px">
                    <img [src]="
                      this.showSaveButton
                      ? '../../../../assets/images/call-active.svg'
                          : '../../../../assets/images/call.svg'
                      " />
                    <input type="text" matInput formControlName="contact_number" placeholder="XXX-XXX-XXXX"
                      [(ngModel)]="currentProfileDetails.contact_number" (keypress)="onKeyPress($event)"
                      [readonly]="!editMode" appCharPrevent />
                  </span>

                  <mat-error *ngIf="
                      myProfileForm
                        .get('contact_number')
                        ?.hasError('invalidNumber')
                    ">
                    Please enter a valid US phone number.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>

          <form class="form" [formGroup]="changePasswordForm">
            <!-- (ngSubmit)="saveChangedPassword()" -->
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <!-- Current Password -->
              <div class="current-pwd-field" fxLayout="column">
                <span fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center"><span class="form-header"> Current
                    Password</span>
                </span>
                <mat-form-field appearance="outline" class="password-form-field">
                  <span fxLayout="row" fxLayoutGap="5px">
                    <input matInput formControlName="current_password" [type]="showPassword2 ? 'text' : 'password'" />
                    <img src="../../../../assets/images/eye.svg" (click)="password2()" *ngIf="
                        resetPasswordFormSecondControl.current_password.value !=
                          '' && !showPassword2
                      " />

                    <img src="../../../../assets/images/eye-closed.svg" (click)="password2()" *ngIf="showPassword2" />
                  </span>
                  <mat-error *ngIf="resetPasswordFormSecondControl.current_password.errors?.['required']">Please
                    re-enter password to proceed</mat-error>
                  <!-- <mat-error
                  *ngIf="resetPasswordFormSecondControl.current_password.errors?.['passwordMismatch']"
                  >Please enter correct password
                </mat-error>

                <mat-error
                  *ngIf="
                    myProfileForm
                      .get('current_password')
                      ?.hasError('invalidPassword')
                  "
                >
                  Use at least 8 characters, a mix of upper case and lower case
                  letters, at least one number and a special character
                </mat-error> -->
                </mat-form-field>
              </div>

              <button (click)="saveChangedPassword()" type="reset" mat-raised-button color="primary" class="save-btn"
                [disabled]="
                  changePasswordForm.invalid || disableSaveChangePassword
                ">
                Save
              </button>
            </div>

            <div fxLayout="row" fxLayoutGap="24">
              <!-- Enter New Password -->

              <div class="create-pwd-field" fxLayout="column">
                <span fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center"><span class="form-header">New
                    Password</span>
                </span>
                <mat-form-field appearance="outline" class="password-form-field">
                  <span fxLayout="row" fxLayoutGap="5px">
                    <img [src]="
                        resetPasswordFormSecondControl.new_password.value
                          ? '../../../../assets/images/lock-active.svg'
                          : '../../../../assets/images/lock.svg'
                      " class="lock-icon" />
                    <input matInput formControlName="new_password" placeholder="Password" minlength="8"
                      [type]="showPassword1 ? 'text' : 'password'" />

                    <img src="../../../../assets/images/eye.svg" (click)="password1()" *ngIf="
                        resetPasswordFormSecondControl.new_password.value !=
                          '' && !showPassword1
                      " />

                    <img src="../../../../assets/images/eye-closed.svg" (click)="password1()" *ngIf="showPassword1" />
                  </span>

                  <mat-error *ngIf="resetPasswordFormSecondControl.new_password.errors?.['required']">Password is a
                    required field
                  </mat-error>
                  <mat-error *ngIf="resetPasswordFormSecondControl.new_password.errors?.['maxlength']">Please ensure
                    that the password is within 35 characters.
                  </mat-error>

                  <mat-error class="multi-line-error"
                    *ngIf="resetPasswordFormSecondControl.new_password.errors?.['invalidPassword']">Password is not
                    strong enough. A secure password is at
                    least 8 characters long, must contain atleast a uppercase
                    character, lowercase character, number, and special
                    character.
                  </mat-error>
                </mat-form-field>
                <div class="password-validation-text">
                  <span [ngClass]="imageDependentText('password', 'minlength')" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutGap="4px"><img
                      *ngIf="!myProfileForm.get('new_password')?.value" src="../../../../assets/images/tick.svg" /><img
                      *ngIf="
                        !myProfileForm
                          .get('new_password')
                          ?.hasError('minlength') &&
                        myProfileForm.get('new_password')?.value
                      " src="../../../.././assets/images/success-tick.svg" /><img *ngIf="
                        myProfileForm.get('new_password')?.hasError('minlength')
                      " src="../../../.././assets/images/danger-tick.svg" /><span>Use at least eight characters.</span>
                  </span>

                  <span [ngClass]="
                      imageDependentText('password', 'invalidPasswordChar')
                    " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px"><img
                      src="../../../../assets/images/tick.svg" *ngIf="!myProfileForm.get('new_password')?.value" /><img
                      *ngIf="
                        !myProfileForm
                          .get('current_password')
                          ?.hasError('invalidPasswordChar') &&
                        myProfileForm.get('new_password')?.value
                      " src="../../../.././assets/images/success-tick.svg" />
                    <img *ngIf="
                        myProfileForm
                          ?.get('current_password')
                          ?.hasError('invalidPasswordChar')
                      " src="../../../../assets/images/danger-tick.svg" /><span>A mix of upper case and lower case
                      letters.</span>
                  </span>

                  <span [ngClass]="
                      imageDependentText('password', 'invalidPasswordNum')
                    " fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px"><img
                      src="../../../../assets/images/tick.svg" *ngIf="!myProfileForm.get('new_password')?.value" /><img
                      *ngIf="
                        !myProfileForm
                          .get('current_password')
                          ?.hasError('invalidPasswordNum') &&
                        myProfileForm.get('new_password')?.value
                      " src="../../../.././assets/images/success-tick.svg" /><img *ngIf="
                        myProfileForm
                          ?.get('current_password')
                          ?.hasError('invalidPasswordNum')
                      " src="../../../.././assets/images/danger-tick.svg" /><span>One or more numbers.</span>
                  </span>

                  <span [ngClass]="
                      imageDependentText(
                        'password',
                        'invalidPasswordSpecialChar'
                      )
                    " fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="4px"
                    class="password-validation-specialchar"><img src="../../../../assets/images/tick.svg"
                      *ngIf="!myProfileForm.get('new_password')?.value" /><img *ngIf="
                        !myProfileForm
                          .get('current_password')
                          ?.hasError('invalidPasswordSpecialChar') &&
                        myProfileForm.get('new_password')?.value
                      " src="../../../.././assets/images/success-tick.svg" /><img *ngIf="
                        myProfileForm
                          ?.get('current_password')
                          ?.hasError('invalidPasswordSpecialChar')
                      " src="../../../.././assets/images/danger-tick.svg" /><span>At least one of the following special
                      characters
                      .,!@#$%^&*()-+[]</span>
                  </span>
                </div>
              </div>

              <!-- re-enter password -->
              <div class="re-enter-pwd-field" fxLayout="column">
                <span fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center"><span class="form-header">Re-Enter
                    Password </span>
                  <!-- <mat-icon fontSet="material-icons-outlined" matTooltip="Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
            letter, 1 special character and 1 number" matTooltipPosition="above">info</mat-icon> -->
                </span>
                <mat-form-field appearance="outline" class="password-form-field">
                  <span fxLayout="row" fxLayoutGap="5px">
                    <img [src]="
                        resetPasswordFormSecondControl.confirm_password.value
                          ? '../../../../assets/images/lock-active.svg'
                          : '../../../../assets/images/lock.svg'
                      " class="lock-icon" />

                    <input matInput formControlName="confirm_password" placeholder="Password"
                      [type]="showPassword3 ? 'text' : 'password'" />

                    <img src="../../../../assets/images/eye.svg" (click)="password3()" *ngIf="
                        resetPasswordFormSecondControl.confirm_password.value !=
                          '' && !showPassword3
                      " />
                    <img src="../../../../assets/images/eye-closed.svg" (click)="password3()" *ngIf="showPassword3" />
                  </span>

                  <mat-error *ngIf="resetPasswordFormSecondControl.confirm_password.errors?.['required']">Please
                    re-enter password to proceed</mat-error>
                  <mat-error *ngIf="resetPasswordFormSecondControl.confirm_password.errors?.['passwordMismatch']">
                    Passwords doesn't match</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
