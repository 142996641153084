import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';

@Component({
  selector: 'app-remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.scss'],
})
export class RemoveUserDialogComponent implements OnInit {
  currentUserRole: string | null;

  name: string;
  username: string;
  displayName: string;

  constructor(
    private accountSettingsService: AccountSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public removeConformationDialog: MatDialogRef<RemoveUserDialogComponent>
  ) {}

  ngOnInit(): void {
    let name = this.data.name;
    let username = this.data.username;
    // console.log(name, "name")
    // if (name != '') {
    //   this.name = name.charAt(0).toUpperCase() + name.slice(1);
    // } else {
    //   this.name = username;
    // }
    this.displayName = name
      ? name.charAt(0).toUpperCase() + name.slice(1)
      : username;
    this.currentUserRole = localStorage.getItem('currentUserRole');
  }

  removeUserFromOrg = () => {
    let removeUser = this.data.username.replace(/%/g, '');
    this.accountSettingsService
      .removeUserFromOrgOrganization(
        localStorage.getItem('currentOrganizationCode'),
        decodeURIComponent(removeUser)
      )
      .subscribe({
        next: (resp: any) => {
          if (resp.status == 200) {
            this.removeConformationDialog.close();
          }
        },
        error: (error: HttpErrorResponse) => {
          // console.log(error);
        },
      });
  };

  onClose = () => {
    this.removeConformationDialog.close('close');
  };
}
