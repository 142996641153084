import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Globals } from '../_globals/endpoints.global';
import { AppConfigService } from 'src/app/_services/app-config.service';
@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {
  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router,
    private appConfig: AppConfigService
  ) {}

  /**
   * Retrieves available sheets for a given organization, batch, and file type.
   *
   * @param organisation_id - The identifier for the organization.
   * @param batch_id - The identifier for the batch.
   * @param fileType - The type of the file for filtering sheets.
   * @returns Observable<any> - An observable containing the result of the HTTP GET request.
   */
  getSheets(
    organisation_id: any,
    batch_id: any,
    fileType: string
  ): Observable<any> {
    const options = {
      params: new HttpParams().set('file_type', fileType),
    };
    // if (channel !== undefined && channel.length > 0) {
    //   channel.forEach((item: any) => {
    //     options.params = options.params.append('channel_filter', item);
    //   });
    const endpoint = this.globals.urlJoinWithTwoParam(
      'header_config',
      'get_sheets',
      organisation_id,
      batch_id
    );
    return this.http.get(endpoint, options);
  }
  /**
   * Retrieves data for a specific sheet based on organization, batch, sheet name, and file type.   *
   * @param organisation_id - The identifier for the organization.
   * @param batch_id - The identifier for the batch.
   * @param sheet_name - The name of the sheet for which data is to be retrieved.
   * @param fileType - The type of the file for filtering sheet data.
   * @returns Observable<any> - An observable containing the result of the HTTP GET request.
   */
  getSheetData(
    organisation_id: any,
    batch_id: any,
    sheet_name: any,
    fileType: string
  ): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('file_type', fileType)
        .set('sheet', sheet_name),
    };
    const endpoint = this.globals.urlJoinWithTwoParam(
      'header_config',
      'get_sheet_data',
      organisation_id,
      batch_id
    );
    return this.http.get(endpoint, options);
  }
  /**
   * Configures file headers based on provided parameters
   *
   * @param organisation_id - The identifier for the organization.
   * @param batch_id - The identifier for the batch.
   * @param file_type - The type of the file for configuring headers.
   * @param sheet_name - The name of the sheet for configuring headers.
   * @param rowNo - The row number for configuring headers.
   * @param rowData - The data associated with the selected header row for configuring headers.
   * @returns Observable<any> - An observable containing the result of the HTTP POST request.
   */

  configureHeader = (
    organisation_id: any,
    batch_id: any,
    file_type: any,
    sheet_name: any,
    rowNo: any,
    rowData: any
  ) => {
    const payload: any = {
      batch_id: batch_id,
      file_type: file_type,
      sheet: sheet_name,
      header_row_no: rowNo,
      header_row_data: rowData,
    };
    // get the app config from the service
    const isNewVersion = this.appConfig.getConfig().is_new_version;
    const endpoint = isNewVersion
      ? this.globals.urlJoinWithParam(
          'header_config',
          'configure_file_new',
          organisation_id
        )
      : this.globals.urlJoinWithParam(
          'header_config',
          'configure_file',
          organisation_id
        );
    return this.http.post(endpoint, payload);
  };

  //get saved config for input/output files
  // getSavedConfig(
  //   organisation_id: any,
  //   batch_id: any,
  //   fileType: string
  // ): Observable<any> {
  //   const options = {
  //     params: new HttpParams().set('file_type', fileType),
  //   };
  //   const endpoint = this.globals.urlJoinWithTwoParam(
  //     'header_config',
  //     'saved_config',
  //     organisation_id,
  //     batch_id
  //   );
  //   return this.http.get(endpoint, options);
  // }
}
