import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/_services/loading.service';
import { NotificationsService } from 'src/app/_services/notifications.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { TopNavComponent } from '../navigation/top-nav/top-nav.component';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit {
  otherNotifications: any = [];
  downloadNotifications: any = [];
  inviteNotifications: any = [];
  dialogData: any = [];
  clicked: number = 1;
  notificationType: string;
  notificationSubType: string;
  pollSubscription: Subscription;
  isLoading: boolean = true;
  unreadCount: number;
  userInitials: string;
  dialogType: string = '';
  isVisited: boolean = false;
  page: number = 1;
  pageSize: number = 10;
  orgCode: any;
  totalPages: number;
  totalItems: number;
  isNewVersion: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationsService,
    private snackbarService: SnackbarService,
    private loadingService: LoadingService,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    // 
     this.isNewVersion = this.appConfig.getConfig().is_new_version;
    // 
    this.loadingService.setLoading(true);
    this.orgCode = localStorage.getItem('currentOrganizationCode');
    this.route.queryParams.subscribe((params) => {
      if (this.dialogType != params['type']) {
        this.dialogType = params['type'];
      }
      this.getNotificationsForDialog();
    });
    // this.getNotificationsCount();
    // this.notifications = this.data.content;
    this.notificationType = 'NOTIFICATION';
    this.notificationSubType = 'UNREAD';

    // poll notifications list
    // const poll = of({}).pipe(
    //   mergeMap((_) =>
    //     of(
    //       this.getNotificationsForDialog(
    //         this.notificationType,
    //         this.notificationSubType
    //       )
    //     )
    //   ),
    //   delay(300000),
    //   repeat()
    // );
    // this.pollSubscription = poll.subscribe();
    // this.getNotificationsCount();
    // if (this.notifications) {
    // this.getUserInitials();
    // }
  }

  ngOnDestroy() {
    // this.pollSubscription.unsubscribe();
  }
  // Function to reload the component
  // reloadComponent() {
  //   const currentUrl = this.router.url;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([currentUrl]);
  //   });
  // }
  /****  get notifications */
  getNotificationsForDialog = () => {
    this.loadingService.setLoading(true);
    this.dialogData = [];
    this.otherNotifications = [];
    this.downloadNotifications = [];
    this.inviteNotifications = [];
    this.notificationService
      .getNotifications(this.dialogType, this.page, this.pageSize, this.orgCode)
      .subscribe({
        next: (resp: any) => {
          if (resp && resp.results) {
            this.totalPages = resp.total_pages;
            this.totalItems = resp.total_items;
            for (const notification of resp.results) {
              this.dialogData.push(notification);
            }
          }
          this.loadingService.setLoading(false);
          // console.log(this.dialogType, this.dialogData);
        },
        error: (error: HttpErrorResponse) => {
          this.loadingService.setLoading(false);
          this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
  };

  handleAction(action: any, read: boolean) {
    // Assuming 'redirect_uri' contains the URL to redirect to
    if (action.redirect_uri) {
      this.notificationService.actionButton(action, this.dialogType).subscribe({
        next: (resp: any) => {
          if (this.dialogType == 'inbox') {
            window.open(resp.signed_url); //dowloading file from the signed url
          }
          if (read == true) {
            this.getNotificationsForDialog();
          } // this.getNotificationsCount();
          // this.snackbarService.showSnackbar(
          //   resp.detail,
          //   'check_circle',
          //   'success'
          // );
        },
        error: (error: HttpErrorResponse) => {
          this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
    }
  }
  /** mark all notification as read */
  markAllasRead = () => {
    if (this.unreadCount > 0) {
      this.notificationService.markAllasRead(this.dialogType).subscribe({
        next: (resp) => {
          this.getNotificationsForDialog();
          // this.getNotificationsCount();
          // this.snackbarService.showSnackbar(
          //   resp.status,
          //   'check_circle',
          //   'success'
          // );
        },
        error: (error: HttpErrorResponse) => {
          this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
        },
      });
    }
  };

  seeAllNotifications = (type: string) => {
    if (type == 'notification') {
      this.router.navigate(['/all-notifications']);
    } else if (type == 'inbox') {
      this.router.navigate(['/inbox']);
    } else {
      this.router.navigate(['/all-invites']);
    }
  };

  /** mark notification as read */
  markAsRead = (id: any) => {
    // this.notificationService.getUpdatedNotificationCount(true);
    this.notificationService.markAsRead(id).subscribe({
      next: (resp) => {
        // this.snackbarService.showSnackbar(
        //   resp.message,
        //   'check_circle',
        //   'success'
        // );
        // this.notifications[i].unread = false;
        // this.notificationService.getUpdatedNotificationCount(true);
        // this.getNotificationsCount();
        this.getNotificationsForDialog();
      },
      error: (error: HttpErrorResponse) => {
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };

  /** mark notification as read */
  markAsUnRead = (id: any) => {
    // this.notificationService.getUpdatedNotificationCount(true);
    this.notificationService.markAsUnRead(id).subscribe({
      next: (resp) => {
        // this.snackbarService.showSnackbar(
        //   resp.message,
        //   'check_circle',
        //   'success'
        // );
        // this.notifications[i].unread = false;
        // this.notificationService.getUpdatedNotificationCount(true);
        // this.getNotificationsCount();
        this.getNotificationsForDialog();
      },
      error: (error: HttpErrorResponse) => {
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };

  getNotificationsCount = () => {
    this.notificationService.getNotificationsCount(this.orgCode).subscribe({
      next: (resp: any) => {
        if (this.dialogType === 'notification') {
          this.unreadCount = resp.notification_unread_count;
        } else if (this.dialogType === 'inbox') {
          this.unreadCount = resp.download_unread_count;
        } else if (this.dialogType === 'invite') {
          this.unreadCount = resp.invite_unread_count;
        }
        // this.allCount = resp;
      },
      error: (error: HttpErrorResponse) => {
        this.loadingService.setLoading(false);
        this.snackbarService.showSnackbar(error.error.detail, ' ', 'error');
      },
    });
  };

  /*** on pagination event */
  onPaginateChange = (e: any) => {
    this.page = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getNotificationsForDialog();
  };
  /** jump page  */
  goToPage = (event: any) => {
    event.preventDefault();
    this.page = event.target.value;
    this.loadingService.setLoading(true);
    this.getNotificationsForDialog();
  };
}
