import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { SnackbarComponent } from '../_components/reused-components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  snackbarClass: string;

  constructor(private snackbar: MatSnackBar) {}
  /**
   * Displays a snack bar with the specified message, icon, and type.
   * @param message The message to display in the snack bar.
   * @param icon The icon for the snack bar.
   * @param type The type of the snack bar (error/success/info).
   * @param action Optional action text for the snack bar.
   * @param vertical_position The vertical position of the snack bar (default: 'top').
   */
  showSnackbar = (
    message: any,
    icon: any,
    type: string,
    action?: string,
    vertical_position: MatSnackBarVerticalPosition = 'top'
  ) => {
    // select snackbar class based on type - error/success/info 
    switch (type) {
      case 'error':
        this.snackbarClass = 'error-snackbar';
        break;
      case 'success':
        this.snackbarClass = 'success-snackbar';
        break;
      case 'customTagSnackbar':
        this.snackbarClass = 'custom-tag-snackbar';
        break;
      default:
        this.snackbarClass = 'info-snackbar';
    }
    if (!message) {
      message = 'Some error has occured. Please contact dataX support';
    }
    this.snackbar.openFromComponent(SnackbarComponent, {
      duration: 500000000,
      data: {
        message: message,
        icon: !icon ? 'close' : icon,
        type: type,
        action: action,
      },
      panelClass: [this.snackbarClass, 'snackbar-fixed-top-margin'],
      verticalPosition: vertical_position,
    });
  };
}
