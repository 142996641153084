import { Injectable } from '@angular/core';
import { Globals } from '../_globals/endpoints.global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private globals: Globals, private http: HttpClient) {}

  /**
   * Retrieves a batch list based on specified filters and pagination parameters.
   * Constructs the endpoint using the globals service to join the URL parts for batch list retrieval.
   * Executes an HTTP GET request to fetch the batch list.
   * @param search - The search query for filtering batches.
   * @param status - An array of status filters to apply.
   * @param channel - An array of channel filters to apply.
   * @param page - The current page number for pagination.
   * @param size - The number of items per page for pagination.
   * @param start_date - The start date filter for batches.
   * @param end_date - The end date filter for batches.
   * @returns An Observable containing the HTTP response for the batch list request.
   */
  getBatchList(
    search: any,
    status: any,
    channel: any,
    page: any,
    size: any,

    start_date: any,
    end_date: any
  ): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('q', search)
        // .set('status_filter', status)
        // .set('channel_filter', channel)
        .set('page', page)
        .set('page_size', size)
        .set('start_date', start_date)
        .set('end_date', end_date),
    };
    if (channel !== undefined && channel.length > 0) {
      channel.forEach((item: any) => {
        options.params = options.params.append('channel_filter', item);
      });
    }
    if (status !== undefined && status.length > 0) {
      status.forEach((item: any) => {
        options.params = options.params.append('status_filter', item);
      });
    }
    const Batchendpoint = this.globals.urlJoin('home', 'batchList');
    return this.http.get(Batchendpoint, options);
  }

  /**
   * Resends email verification for the specified email.
   * @param email - The email address for which to resend the verification email.
   * @returns An Observable containing the HTTP response for the email resend request.
   */
  resendEmail(email: any): Observable<any> {
    const endpoint = this.globals.urlJoin('home', 'resendEmail');
    return this.http.get(endpoint, { params: { email } });
  }

  /**
   * Retrieves batch list table data based on specified parameters.
   * Constructs the endpoint using the globals service to join the URL parts with the organization code.
   * Executes an HTTP GET request to fetch batch list table data with the provided parameters.
   * @param page - The page number for pagination.
   * @param size - The page size for pagination.
   * @param uploadedby - An array of uploaded by filters.
   * @param assignee - An array of assignee filters.
   * @param status - The status filter for batch items.
   * @param startDate - The start date filter for batch items.
   * @param endDate - The end date filter for batch items.
   * @param searchTaskTag - The search filter for task tags.
   * @param org_code - The organization code to retrieve batch list table data.
   * @returns An Observable containing the HTTP response for the batch list table request.
   */

  batchListTable(
    page: number,
    size: number,
    uploadedby: any,
    assignee: any,
    status: any,
    startDate: any,
    endDate: any,
    searchTaskTag: any,
    org_code: string
  ): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'batchListTable',
      org_code
    );

    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page);
    queryParams = queryParams.append('page_size', size);
    if (status) queryParams = queryParams.append('status', status);
    if (startDate) queryParams = queryParams.append('start_date', startDate);
    if (endDate) queryParams = queryParams.append('end_date', endDate);
    if (searchTaskTag) queryParams = queryParams.append('q', searchTaskTag);

    if (uploadedby) {
      uploadedby.forEach((ele: any) => {
        queryParams = queryParams.append('uploaded_by', ele);
      });
    }
    if (assignee) {
      assignee.forEach((ele: any) => {
        queryParams = queryParams.append('assignee', ele);
      });
    }

    return this.http.get(endpoint, { params: queryParams });
  }

  /**
   * Retrieves batch list table headers for the specified organization.
   * @param org_code - The organization code to retrieve batch list table headers.
   * @returns An Observable containing the HTTP response for the batch list table headers request.
   */
  batchListTableHeaders(org_code: any): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'batchListTableHeaders',
      org_code
    );
    return this.http.get(endpoint);
  }

  /**
   * Retrieves Assignee and Uploaded By filters for the specified organization.
   * @param org_code - The organization code to retrieve filters for Assignee and Uploaded By.
   * @returns An Observable containing the HTTP response for the Assignee and Uploaded By filters request.
   */
  assigneeUploadedbyFilters(org_code: any): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'assigneeuploadedbyFilters',
      org_code
    );
    return this.http.get(endpoint);
  }
  /**
   * Retrieves status filter options for the specified organization.
   * @param org_code - The organization code to retrieve status filter options.
   * @returns An Observable containing the HTTP response for the status filter options request.
   */
  statusFilter(org_code: any): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'statusFilter',
      org_code
    );
    return this.http.get(endpoint);
  }
  /**
   * Updates tags for the specified organization.
   * @param tag - The tag information to be updated.
   * @param org_code - The organization code to update tags.
   * @returns An Observable containing the HTTP response for the update tags request.
   */
  updateTags(tag: any, org_code: any): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'updateTags',
      org_code
    );
    return this.http.patch(endpoint, tag);
  }

   /**Update the batch name */
   updateBatchName(batchID: any, batchName: any, orgId: any): Observable<any> {
    const updateBatchNameEndpoint = this.globals.urlJoinWithParam(
      'home',
      'updateTaskName',
      orgId
    );
    // ====
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = {
      "batch_id": batchID,
      "task_name": batchName
    }
    return this.http.patch(updateBatchNameEndpoint, payload, {
      headers,
    });
    // ====
    // return this.http.patch(endpoint, batchName);
  }


  /**
   * Shares a batch using the specified organization code.
   * @param batchDetails - Details of the batch to be shared.
   * @param org_code - The organization code to share the batch.
   * @returns An Observable containing the HTTP response for the share batch request.
   */
  shareBatch(batchDetails: any, org_code: any): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'shareBatch',
      org_code
    );
    return this.http.post(endpoint, batchDetails);
  }
  /**
   * Adds or removes assignee from the side panel for the specified organization.
   * @param assigneeDetails - Details of the assignee operation to be performed.
   * @param org_code - The organization code to add or remove assignee.
   * @returns An Observable containing the HTTP response for the add or remove assignee request.
   */
  addRemoveAssignee(assigneeDetails: any, org_code: any): Observable<any> {
    const endpoint = this.globals.urlJoinWithParam(
      'home',
      'addAssignee',
      org_code
    );
    return this.http.patch(endpoint, assigneeDetails);
  }

  getTags(batch_id:any, org_code:any){
    const endpoint = this.globals.urlJoinWithTwoParam(
      'home',
      'getTags',
      org_code,
      batch_id
    );
    return this.http.get(endpoint);
  }
}
