import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'midTruncate',
})
export class MidTruncatePipe implements PipeTransform {
  transform(
    value: string,
    firstCharCount: number,
    endCharCount: number
  ): string {
    const dotCount = 3;
    if (value?.length <= firstCharCount + endCharCount) {
      return value;
    }

    const firstPortion = value.slice(0, firstCharCount);
    const endPortion = value.slice(-endCharCount);
    const dots = '.'.repeat(dotCount);
    return `${firstPortion}${dots}${endPortion}`;
  }
}
