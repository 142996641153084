import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';

@Component({
  selector: 'app-recipe-summary',
  templateUrl: './recipe-summary.component.html',
  styleUrls: ['./recipe-summary.component.scss'],
})
export class RecipeSummaryComponent {
  recipeSummary: any;
  tabSelected: string = 'new-columns-found';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RecipeSummaryComponent>,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.recipeSummary = this.data.recipeSummary;
  }

  onClose = () => {
    this.dialogRef.close();
  };
}
