<div class="shareDialogContainer">
  <div style="margin-bottom:-30px;padding: 20px 24px 0px 24px;" fxLayout="row" fxLayoutAlign="space-between center">
    <h2>Tags<span *ngIf="tags" style="font-size: 12px; color: #364861; margin-left: 4px;">({{tags.length}}/10)</span>
    </h2>
    <mat-icon style="cursor: pointer; margin-top: -28px;" (click)="onClose()">close</mat-icon>
  </div>
  <div mat-dialog-content style="overflow: hidden;">
    <form *ngIf="!loading" [formGroup]="form">
      <mat-form-field appearance="outline" style="width: 100%;">
        <div style="display: flex; align-items: center;">
          <input *ngIf="tags.length < 10" matInput formControlName="tag_name" (input)="validateInput(form.get('tag_name'))"
            [(ngModel)]="tagValue" (keydown.enter)="form.valid && tagOperation('add')"
            placeholder="Enter a tag" />
            <input *ngIf="tags.length >= 10" matInput
              placeholder="Enter a tag" [disabled]="tags.length >= 10"/>
        </div>
      </mat-form-field>
      <mat-error *ngIf="form.invalid && (tagName.dirty || tagName.touched)">
        <!-- Add the condition for showing error  -->
        <ul style="padding-left: 20px;
        margin-top: 0px;">
          <li *ngIf="tagName.hasError('pattern')">Special characters like !"%&'()*+,-./:;<=#>?@[$\]^_`&lcub;|}~ are not
            allowed.</li>
          <li *ngIf="tagName.hasError('minlength') && !tagName.hasError('pattern')">Minimum 02 characters are required. </li>
          <li *ngIf="tagName.hasError('maxlength')">Maximum 10 characters allowed.</li>
          <li *ngIf="tagName.hasError('whitespace')">Tag name can not be just white spaces.</li>
          <li *ngIf="tagName.hasError('consecutiveSpaces')">Tag name can not have consecutive space.</li>
        </ul>
        
      </mat-error>
      <span *ngIf="duplicateNameError" style="color: #f44336;
      font-size: 14px;">This tag already exists.</span>
    </form>
    <div class="tag-container">
      <ng-container *ngIf="!loading">
        <div *ngFor="let tag of tags;">
          <div *ngIf="tags.length" class="tags">{{ tag.tag_name }}
            <mat-icon style="cursor: pointer;     font-size: 16px;
            margin-bottom: -11px;
            margin-right: -8px;
            margin-left: 2px;" (click)="tagOperation('delete',tag.id)">close</mat-icon>
          </div>
        </div>
      </ng-container>
      <div style="margin: 0 auto;" *ngIf="loading">
        <mat-spinner mode="indeterminate" style="margin:auto" [diameter]="26"></mat-spinner>
      </div>
    </div>
  </div>
</div>
