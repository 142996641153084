<div style="width: 500px; padding: 24px;">
  <mat-icon style="float: right; top: -24px; right: -24px; color: gray; cursor: pointer" mat-dialog-close>close
  </mat-icon>
  <div fxLayout="column" fxLayoutAlign="space-between center">
    <mat-icon *ngIf="data?.from != 'invite'" style="width: 42px; height: 42px" svgIcon="DangerTriangle"></mat-icon>
    <mat-icon *ngIf="data?.from === 'invite'" style="width: 42px; height: 42px" svgIcon="info"></mat-icon>
    <h3 style="margin-bottom: 0px; margin-top: 16px; text-align: center" mat-dialog-title>
      {{ data?.content ? data?.content! : "Are you sure you want to Cancel?" }}
    </h3>

    <mat-dialog-actions style="width:100%;justify-content:center">
      <button *ngIf="data?.from != 'invite'" mat-stroked-button mat-dialog-close color="warn"
        (click)="leaveOrganaization()">
        Yes, I’m sure
      </button>
      <button mat-stroked-button *ngIf="data?.from === 'invite'" [mat-dialog-close]="true" style="margin-left:auto">
        Ok
      </button>
      <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
      <button *ngIf="data?.from != 'invite'" mat-stroked-button [mat-dialog-close]="true">No, Continue</button>
    </mat-dialog-actions>
  </div>
</div>
