import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-show-invites',
  templateUrl: './show-invites.component.html',
  styleUrls: ['./show-invites.component.scss']
})
export class ShowInvitesComponent implements OnInit {
  notifications: Array<any> = [
    {
      "id": 1,
      "message": "Lana Byrd has sent you an invite to join 'jeseleos@example.com Personal' workspace.",
      "timestamp": "2023-01-02T14:31:07Z",
      "highlight": {
        "text": ["Lana Byrd", "jeseleos@example.com"],
        // "type": "Distributor 1 SKUs_CAX_nov7.28"
      },
      "initial": "LB",
      "unread": true,
      "invited": true,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "1",
      "distributor_code": null
    },
    {
      "id": 2,
      "message": "Thomas Lean has rejected your invite to join 'jeseleos@example.com' Personal workspace.",
      "timestamp": "2023-01-01T06:00:00Z",
      "highlight": {
        "text": ["Thomas Lean", "jeseleos@example.com"],
        // "type": "supplier_signup"
      },
      "initial": "TL",
      "unread": false,
      "invited": false,
      "accepted": false,
      "send_reminder": "mayuri.jha@crowdanalytix.com",
      "supplier_code": "1",
      "distributor_code": null
    },
    {
      "id": 3,
      "message": "Karen Nelson accepted your invite to join 'jeseleos@example.com' Personal workspace.",
      "timestamp": "2023-01-02T12:00:00Z",
      "highlight": {
        "text": ["Karen Nelson", "jeseleos@example.com"],
        // "type": "batch_id"
      },
      "initial": "KN",
      "unread": false,
      "invited": false,
      "accepted": true,
      "send_reminder": null,
      "supplier_code": "2",
      "distributor_code": null
    },
    {
      "id": 4,
      "message": "Lana Byrd has sent you an invite to join 'jeseleos@example.com' Personal workspace.",
      "timestamp": "2022-12-22T14:30:15Z",
      "highlight": {
        "text": ["Lana Byrd", "jeseleos@example.com"],
        // "type": "batch_id"
      },
      "initial": "LB",
      "unread": false,
      "invited": true,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "1",
      "distributor_code": null
    },
    {
      "id": 5,
      "message": "Lana Byrd has sent you an invite to join 'jeseleos@example.com' Personal workspace.",
      "timestamp": "2022-12-22T14:38:46Z",
      "highlight": {
        "text": ["Lana Byrd", "jeseleos@example.com"],
        // "type": "supplier"
      },
      "initial": "LB",
      "unread": false,
      "accepted": false,
      "send_reminder": null,
      "supplier_code": "2",
      "distributor_code": null
    }
  ];
  isNewVersion: any;

  constructor(private appConfig: AppConfigService) {}

  ngOnInit(): void {
    // Implement any necessary initialization logic here
     this.isNewVersion = this.appConfig.getConfig().is_new_version;
  }
}
