import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText',
})
export class HighlightTextPipe implements PipeTransform {
  transform(
    value: any,
    args: string[] | string,
    type: string,
    routeId: any
  ): any {
    if (!args?.length) {
      return value;
    }
    const pattern = Array.isArray(args)
      ? args.filter((arg) => !!arg).join('|')
      : args;
    const regex = new RegExp(pattern.concat('|<[^>]*>'), 'gi');
    // returning the highlighte text
    // passing type as hidden attribute
    return value.replace(regex, (match: any) =>
      /<[^>]*>/g.test(match)
        ? match
        : `<span class="highlighted-text"><b (click)="goToRoute()">${match}</b><span class="hide">${type}<span *ngIf="routeId">${routeId}</span></span>`
    );
  }
}
