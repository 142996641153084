import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinStrings',
})
export class JoinStringsPipe implements PipeTransform {
  transform(inputArray: Array<any>, separator: any): any {
    if (inputArray) {
      if (inputArray.length > 1) {
        return inputArray.join(separator);
      } else {
        return inputArray.toString();
      }
    } else {
      return null;
    }
  }
}
