import { Component, Input } from '@angular/core';

@Component({
  selector: 'data-table-skeleton-loader',
  templateUrl: './data-table-skeleton-loader.component.html',
  styleUrls: ['./data-table-skeleton-loader.component.scss'],
})
export class DataTableSkeletonLoaderComponent {
  @Input() isHeaderLoading!: boolean;
  @Input() isTableContentLoading!: boolean;
  @Input() isInputDataTableLoading: boolean;
}
