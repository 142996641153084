<div style="padding: 24px">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="previously-uploaded-title">
    <span>{{ data?.title }}</span>
    <button mat-icon-button class="close-button" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-form-field class="search-field" appearance="outline">
    <input matInput placeholder="Search file name..." #searchInput />
    <mat-icon style="width: 16px; margin-right: 10px; margin-top: 10px" matPrefix svgIcon="search"></mat-icon>
  </mat-form-field>
  <div class="fileName">
    <div *ngIf="data?.from == 'ip'">
      <div class="chip" *ngFor="let each of inputFiles" [matTooltip]="each.file_name" matTooltipPosition="above" matTooltipClass="custom-tooltip" 
        (click)="onFileSelect(each)">
        <img *ngIf="each.extension=='csv'" src="../../../assets/images/file-format-icons/csv_icon.svg" alt="file"
          width="25" height="25" />
        <img *ngIf="each.extension=='xlsx'" src="../../../assets/images/file-format-icons/xlsx_icon.svg" alt="file"
          width="25" height="25" />
        <div class="text">
          {{ trimFileName(each.file_name, 15) }}
        </div>
      </div>
    </div>

    <div *ngIf="data.from == 'op'">
      <div class="chip" *ngFor="let each of outputFiles" [matTooltip]="each.file_name" matTooltipPosition="above" matTooltipClass="custom-tooltip" 
        (click)="onFileSelect(each)">
        <img *ngIf="each.extension=='csv'" src="../../../assets/images/file-format-icons/csv_icon.svg" alt="file"
          width="25" height="25" />
        <img *ngIf="each.extension=='xlsx'" src="../../../assets/images/file-format-icons/xlsx_icon.svg" alt="file"
          width="25" height="25" />
        <div class="text">
          {{ trimFileName(each.file_name, 15) }}
        </div>
      </div>
    </div>
    <div *ngIf="noFileFoundMsg" class="no-result-message">No Results Found</div>
  </div>
</div>

<!-- <div mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
    <button mat-stroked-button (click)="onClose()">Close</button>
</div> -->
