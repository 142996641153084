<app-top-nav></app-top-nav>
<div class="page-container" appScrollToBottom>
  <div class="content">
    <!--fullscreen info banner -->
    <div *ngIf="!isFullScreen" class="alert-success" fxLayout="row" fxLayoutAlign="center center">
      <!-- <img src="../../../.././assets/images/success-tick.svg" />Your account has -->
      For a better viewing experience, press "F11" or "fn+F" for full screen
      <span class="closebtn-success" onclick="this.parentElement.style.display='none';">&times;</span>
    </div>
    <!-- breadcrumb -->
    <nav class="breadcrumb" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <span class="link" routerLink="/dashboard">Dashboard</span>
      <mat-icon *ngIf="taskStatus != 'In Review' && taskStatus != 'new'">chevron_right</mat-icon>
      <span *ngIf="taskStatus != 'In Review' && taskStatus != 'new'" class="link" routerLink="/dashboard/view-output"
        [queryParamsHandling]="'preserve'">Output</span>
      <mat-icon>chevron_right</mat-icon>
      <span class="active">Review interim output</span>
    </nav>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start stretch" class="container">
      <div fxFlex="90%" class="column">
        <div class="title">
          <h1 class="page-title">
            <b><span matTooltipPosition="above" [matTooltip]="taskName">{{
                taskName
              }}</span>
              - Review Interim Output</b>
          </h1>
        </div>
      </div>
      <div fxFlex="10%" fxLayout="row" fxLayoutAlign="end end" class="column">
        <div class="button-section" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end end">
          <button *ngIf="isShowViewReport" (click)="openReportPanel()" class="reuse-btn action-btn" mat-stroked-button
            color="primary" tabindex="-1">
            View Report
          </button>
          <button (click)="saveMapping()" class="reuse-btn" mat-flat-button color="primary" tabindex="-1">
            Apply to all rows
          </button>
        </div>
      </div>
    </div>
    <!-- dynamic-table -->

    <div class="table-wrapper">
      <div class="table-container">
        <!-- Table Header -->
        <div fxLayout="row" class="table-row input-header sticky-input">
          <div fxLayoutAlign="space-between center" class="input-header-cell"
            *ngFor="let header of inputHeaders; let i = index">
            <ng-container *ngIf="i != 0">
              <div matTooltipPosition="above" [matTooltip]="
                  header.selected_values && header.selected_values.length > 0
                    ? concatenateValues(
                        header.selected_values,
                        header.delimiter
                      )
                    : 'Select input header'
                " class="headerSelectedValue" class="truncate" [ngStyle]="{
                  color: header.selected_values[0] ? '#364861' : '#757575',
                  fontWeight: header.selected_values[0] ? '600' : '400'
                }">
                {{
                  header.selected_values && header.selected_values.length > 0
                    ? truncateValues(
                        concatenateValues(
                          header.selected_values,
                          header.delimiter
                        ),
                        20
                      )
                    : "Select input header"
                }}
                <!-- <span *ngIf="header?.selected_values?.length - 1 >= 1"
                  >&nbsp; + {{ header?.selected_values?.length - 1 }}</span
                > -->
              </div>

              <!-- show edit if no change log, if change log, diced based on current viewing item-->
              <div *ngIf="
                  actionsList?.length == 0
                    ? true
                    : validMsgID == activeActionView
                " class="edit-btn" (click)="openSidePanel(i)" matTooltipPosition="above" matTooltip="Edit">
                <img src="../../../../assets/images/add-assignee.svg"
                  style="height: 16px; width: 16px; cursor: pointer" />
              </div>

            </ng-container>
            <span matTooltip="Input Header" matTooltipPosition="above" *ngIf="i == 0" style="color: #364861">Input
              Header</span>
          </div>
        </div>

        <!-- Table Rows -->
        <div fxLayout="row" class="table-row output-header sticky-output" *ngFor="let row of outputData; let i = index">
          <div fxLayoutAlign="space-between center" class="table-cell output-header-cell"
            *ngFor="let cell of row; let j = index" tra [style.font-weight]="i == 0 ? 'bold' : null">
            <!-- [ngClass]="{ 'highlight-cell': isValidValueColumn(j) && i == 0 }" -->
            <span matTooltipPosition="above" matTooltipClass="data-tooltip" [matTooltip]="cell" class="cell-value"
              *ngIf="!isEditing(i, j)">{{ cell }}</span>
            <!-- (click)="i !== 0 && j !== 0 && startEditing(i, j)" -->
            <input type="text" class="cell-input-field highlight-cell" *ngIf="isEditing(i, j)" [(ngModel)]="editedCell"
              (blur)="stopEditing(i, j)" (keyup.enter)="stopEditing(i, j)" />
          </div>
        </div>
      </div>
    </div>
    <!-- dynamic-table -->
    <!-- chat section -->

    <div class="sub-title" style="margin: 20px 0 0 0">
      Use chat wizard to instruct the dataX.ai for custom formatting. Use any
      applicable template (recommended) or type your own.
    </div>
    <div *ngIf="outputData.length" fxLayout="row" fxLayoutAlign="space-around stretch" class="chat-container">
      <div fxFlex="55" class="promt-section" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="end stretch">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-button-toggle-group #group="matButtonToggleGroup" [value]="activeTab"
            (change)="onPromptValChange(group.value)" tabindex="-1">
            <mat-button-toggle value="templates" tabindex="-1">Templates</mat-button-toggle>
            <mat-button-toggle value="chats" tabindex="-1">Chats</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <!-- default templates -->
        <div *ngIf="group.value == 'templates'" fxLayout="row wrap " class="default-prompts">
          <div fxFlex="48" *ngFor="let each of defaultPrompts" class="each-prompt"
            (click)="onClickOfEachPrompt(each.chat_request)" tabindex="-1">
            <strong>{{ each.template_name }}</strong> <br /><br />
            <p>{{ each.chat_request }}</p>
          </div>
        </div>
        <!-- default templates -->

        <!-- conversations -->
        <div *ngIf="group.value == 'chats'" fxLayout="column" class="chat-history-wrapper" appScrollToBottom
          #chatHistoryDiv>
          <div fxLayout="column" fxLayoutGap="20px" class="chat-history">
            <!-- dynamic chat history template -->

            <ng-container *ngFor="let each of chatHistoryList">
              <div fxLayout="row" fxLayoutAlign="end" class="outgoing">
                <span class="user-msg chip">{{ each.chat }}</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="start " fxLayoutGap="5px" class="incoming">
                <img src="../../../assets/images/bot-logo.svg" alt="file" width="35" height="35" />
                <div class="bot-reply chip">{{ each.reply }}</div>
              </div>
            </ng-container>

            <!-- dynamic chat history template -->

          </div>
        </div>
        <!-- conversations -->

        <div fxLayout="row" fxLayoutAlign="end end" class="prompt-input" *ngIf="group.value == 'chats'">
          <textarea tabindex="-1" [disabled]="isServiceBusy" [(ngModel)]="userPrompt" (input)="onPromptSet($event)"
            (keydown.enter)="onKeydownPrompt($event)" autofocus
            placeholder="Ask dataX.ai to implement the changes in the generated output." class="field"></textarea>
          <button tabindex="-1" [disabled]="disableSendBtn" [style.opacity]="!disableSendBtn ? '1' : '0.5'"
            (click)="sendMessage()" mat-icon-button>
            <mat-icon svgIcon="send-msg"></mat-icon>
          </button>
        </div>
      </div>
      <mat-divider vertical></mat-divider>
      <div fxFlex="45" class="history-section" fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="start stretch">
        <div class="title">
          <strong>Change Log</strong>
        </div>

        <!-- actions-list -->
        <ng-container *ngFor="let each of actionsList; let i = index">
          <div tabindex="-1" #eachlog fxLayout="row" fxLayoutAlign="start center " class="actions-list-wrapper"
            [class.view-action]="!each.undo" [ngClass]="{
              'inactive-action':
                each?.conversation_uid !== this.activeActionView,
              'active-action': each?.conversation_uid == this.activeActionView
            }" (click)="!each.undo ? onView(each) : ''">
            <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
                <div fxLayout="row" fxLayoutAlign="start center" style="margin-right: 10px;">
                  <div [class.blur-action]="each.undo" class="sl-no">
                    <span>{{ i + 1 }}</span>
                  </div>
                </div>
                <div fxFlex="90" fxLayout="row" fxLayoutAlign="center center">
                  <div [class.blur-action]="each.undo" class="task-title">
                    <span>{{ each.chat }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="20" fxLayout="column" fxLayoutAlign="start end">
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-around center" class="task-actions">
                <!-- <button
                  *ngIf="!each.undo"
                  (click)="onView(each)"
                  mat-flat-button
                  color="primary"
                >
                  View
                </button> -->
                <button tabindex="-1" class="action-btn" *ngIf="!each.disable_undo"
                  (click)="onUndo(each); $event.stopPropagation()" mat-stroked-button color="primary">
                  Undo
                </button>
                <button tabindex="-1" class="action-btn" *ngIf="!each.disable_revert"
                  (click)="onRevert(each); $event.stopPropagation()" [disabled]="" mat-stroked-button color="primary">
                  Revert
                </button>
                <button tabindex="-1" class="action-btn" *ngIf="!each.disable_rerun"
                  (click)="onRerun(each); $event.stopPropagation()" [disabled]="" mat-stroked-button color="primary">
                  Re-Run
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- actions-list -->
      </div>
    </div>
    <!-- chat section -->
  </div>
</div>

<app-side-panel [sidenavTemplateRef]="isViewReport ? report : navContent" [direction]="'right'" [navWidth]="400"
  [duration]="0.5" (close)="isViewReport ? closeReportPanel() : closeSidePanel()">
</app-side-panel>
<!-- column mapping contents -->
<ng-template #navContent>
  <div fxLayout="column" class="sidebar-container">
    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="instructions">
        <div>
          <span class="headers" *ngIf="isSelected; else showTextB">
            {{ selectedColumnHeader }} has been mapped to
            {{ selected?.slice(0, 1).join(", ") }}
          </span>
          <ng-template #showTextB>
            <span class="headers"> Please select Input Headers </span>
          </ng-template>
          <span *ngIf="selected?.length > 1">,</span>
          <span *ngIf="selected?.length > 1" class="headers-length" matTooltip="{{ selected?.slice(1) }}"
            matTooltipPosition="above">+{{ selected?.length - 1 }}</span>
        </div>
        <ol>
          <li>
            To remove this mapping, uncheck the column in the Input Header and
            click ‘Save’.
          </li>
          <li>
            To map a different input column to this, simply uncheck the original
            mapping, select the new column, and click ‘Save’.
          </li>
          <li>
            To combine more input columns into this mapping, select those
            columns and enter a delimiter. Then click ‘Save’.
          </li>
        </ol>
        <!-- <div style="font-size: 14px">
          {{ selectedColumnHeader }}
        </div> -->
      </div>

      <div class="header">Input Header</div>

      <div fxLayout="column" class="search-container" *ngIf="isSideModelOpen">
        <div class="placeholder-container" fxLayout="column">
          <div fxLayout="row" fxFlex="100">
            <mat-form-field appearance="outline" class="search-filter">
              <input style="width: 95%" type="text" matInput placeholder="Search Input Header..." #searchVal
                [(ngModel)]="columnSearchValue" (keyup.enter)="onSeachOfColumnVal($event.target.value)" />
              <mat-icon matPrefix svgIcon="search" style="margin: 0 5px"></mat-icon>
              <mat-icon matSuffix style="cursor: pointer; width: 20px" *ngIf="searchVal.value" (click)="clearSearch()">
                close</mat-icon>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <app-select-check-all [model]="selectionModel" [values]="columnsList"
              (childEvent)="getNumberOfOptionSelected()">
            </app-select-check-all>

            <div (click)="clearAll()" class="clear-all-btn">Clear all</div>
          </div>
        </div>
        <div class="list-container">
          <mat-selection-list multiple placeholder="Select" name="Select" [(ngModel)]="selected"
            #selectionModel="ngModel">
            <mat-list-option checkboxPosition="before" *ngFor="let list of columnsList" [value]="list"
              (click)="getNumberOfOptionSelected()" [matTooltip]="list.length >= 30 ? list : ''">
              {{ list | truncate : 30 }}</mat-list-option>
          </mat-selection-list>
        </div>

        <div *ngIf="columnsList?.length === 0" style="margin: auto; width: auto">
          No Results Found
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column" class="button-container">
    <form [formGroup]="delimiterForm">
      <mat-form-field appearance="outline">
        <input type="text" matInput placeholder="Enter your value here..." [(ngModel)]="delimeterValue"
          formControlName="delimeterValue" maxLength="1" />
      </mat-form-field>
    </form>

    <div class="output-header" fxFlex="row" fxLayoutAlign="start center">
      <span>Enter delimiter value to combine multiple columns</span>
      <span style="margin: 3px 10px 0">
        <mat-icon>info</mat-icon>
      </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="15px" style="margin-top: 24px">
      <button mat-button fxFlex (click)="closeSidePanel()" class="btn">
        Cancel
      </button>
      <button mat-flat-button class="btn" color="primary" fxFlex (click)="onUpdateOfColumnMapping()"
        [disabled]="delimeterValueControl.invalid || columnsList?.length === 0">
        Save
      </button>
    </div>
  </div>
</ng-template>

<!-- report contents -->
<ng-template #report>
  <div fxLayout="column" fxLayoutGap="10px" class="sidebar-container">
    <span class="report-title">Report: Settings applied from {{ prevTaskName }}</span>

    <div fxLayout="column" fxLayoutGap="10px" class="content-wrap">
      <div class="output-content">
        <div class="report-title">Output Data</div>
        <div class="count">
          {{
            reportData
              ? reportData["output_data"]["new_column_count"] +
                " " +
                "new columns found in the new file"
              : ""
          }}
        </div>
        <ul>
          <li *ngFor="let each of reportData['output_data']['new_columns']">
            {{ each }}
          </li>
        </ul>
        <div class="count">
          {{
            reportData
              ? reportData["output_data"]["missing_column_count"] +
                " " +
                "columns missing in the new file"
              : ""
          }}
        </div>
        <ul>
          <li *ngFor="let each of reportData['output_data']['missing_columns']">
            {{ each }}
          </li>
        </ul>
      </div>

      <div class="output-content">
        <div class="report-title">Transformation History</div>
        <div class="count">
          {{
            reportData
              ? reportData["transformation_history"]["failed_count"] +
                " " +
                "chat instruction(s) could not be applied on new file"
              : ""
          }}
        </div>
        <ul>
          <li *ngFor="
              let each of reportData['transformation_history'][
                'failed_transformations'
              ]
            ">
            {{ each }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="close-btn-wrap" fxLayout="column">
    <div fxFlex="100">
      <button fxFlex="100" mat-stroked-button (click)="closeReportPanel()" class="close-btn">
        Close
      </button>
    </div>
  </div>
</ng-template>
