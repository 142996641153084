import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  message: string;
  icon: string;
  type: string;
  action: string;
  snackbarClass: string;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public snackbarInputData: any,
    public _snackRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  ngOnInit(): void {
    this.message = this.snackbarInputData.message;
    this.icon = this.snackbarInputData.icon;
    this.type = this.snackbarInputData.type;
    this.action = this.snackbarInputData.action;
  }

  // dismiss snackbar
  dismissSnackbar = () => {
    this._snackRef.dismiss();
  };
}
