import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormBuilder,
} from '@angular/forms';
import { ValidationService } from 'src/app/_services/validation.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { UserService } from '../../../_services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  currentYear: any;
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  signUpForm: FormGroup;
  passwdStrengthCount: number;
  token: any;
  emailId: string;
  organization: string;
  lessHeightScreen = false;
  signinUrl = environment.default_return_url;
  inviteCode: string;
  isFormDisabled : boolean = true;
  organisationType = [
    { display: 'channel', value: 'distributor' },
    { display: 'supplier', value: 'supplier' },
  ];
  @ViewChild('confirmed') confirmed: any;
  isLocal: boolean = false;
  constructor(
    private fb: FormBuilder,
    private formValidation: ValidationService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {
    // get token from url
    this.activatedRoute.queryParams.subscribe({
      next: (params) => {
        this.token = params['token'];
        this.emailId = params['email'];
        this.organization = params['user_type'];
        this.inviteCode = params['invite'];
      },
    });
    this.signUpForm = this.fb.group(
      {
        email: new FormControl('', [
          Validators.required,
          Validators.maxLength(150),
          Validators.pattern(
            environment.feature_flags.LENIENT_EMAIL_VALIDATION
              ? '^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
              : '^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
          ),
        ]),
        password: new FormControl(
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(35),
            this.formValidation.patternValidator(),
            this.formValidation.patternValidatorChar(),
            this.formValidation.patternValidatorNum(),
            this.formValidation.patternValidatorSpecialChar(),
          ])
        ),
        confirm_password: new FormControl('', [Validators.required]),
        recaptcha: new FormControl(''),
        invite_code: new FormControl(''),
      },
      {
        validator: this.formValidation.matchPassword(
          'password',
          'confirm_password'
        ),
      }
    );
    // feed values to org and email field if coming through invitation link
    this.signUpForm.patchValue({
      organizsation_type: this.organization,
      email: this.emailId,
    });
    this.currentYear = new Date().getFullYear();
    this.onResize(Event);
    this.userService.getIsLocal().subscribe({
      next: (res) => {
        this.isLocal = res;
      },
    });
  }
  /**
   * HostListener that triggers when the window is resized.
   * Adjusts the 'lessHeightScreen' property based on the window height.
   * If the window height is less than 700 pixels, sets 'lessHeightScreen' to true; otherwise, sets it to false.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerHeight < 700) {
      this.lessHeightScreen = true;
    } else {
      this.lessHeightScreen = false;
    }
  }
  /** get form control */
  get signUpFormControl() {
    return this.signUpForm.controls;
  }
  /**
   * Handles the focus out event.
   * Triggers the 'onResize' method and trims and updates the corresponding form control value.
   * @param event - The focus out event object.
   */
  handleFocusOutAndResize(event: any) {
    this.onResize(Event);
    let val = event.target.value;
    let targetInput = event.target.getAttribute('formControlName');
    if (targetInput) {
      this.signUpForm.controls[targetInput].setValue(val.trim());
    }
  }
  /** show/hide password 1 */
  password1() {
    this.showPassword1 = !this.showPassword1;
  }

  /** show/hide password 2 */
  password2() {
    this.showPassword2 = !this.showPassword2;
  }

  /** on signup form submit */
  onSubmit() {
    this.send();
  }
  /**
   * Sends the user registration data after executing reCAPTCHA.
   */
  public send(): void {
    this.recaptchaV3Service
      .execute('create_user')
      .subscribe((token: string) => {
        this.signUpForm.get('recaptcha')?.setValue(token);
        this.signUpForm.get('invite_code')?.setValue(this.inviteCode);
        this.signUp();
      });
  }
  /**
   * Initiates the sign-up process by sending the user registration data.
   */
  signUp = () => {
    this.userService.signUp(this.signUpForm.value).subscribe({
      next: (resp) => {
        this.goToSigninPage();
        this.snackbarService.showSnackbar(
          'Your account has been created successfully',
          '',
          'success'
        );
      },
      error: (error) => {
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };
  /**
   * Returns a CSS class based on the validation status of a form control.
   * @param control - The name of the form control.
   * @param error - The specific error condition to check.
   * @returns CSS class ('text-red' for error, 'text-green' for success).
   */
  imageDependentText(control: string, error: string) {
    if (this.signUpForm.get(control)?.value) {
      if (this.signUpForm.get(control)?.hasError(error)) {
        return 'text-red';
      } else {
        return 'text-green';
      }
    }
    return '';
  }

  // /*** sign up using invite link */
  // signUpUsingLink = () => {
  //   this.userService
  //     .signUpUsingInviteLink(this.signUpForm.value, this.token)
  //     .subscribe({
  //       next: (resp) => {
  //         this.snackbarService.showSnackbar(resp.detail, '', 'success');
  //         this.goToSigninPage();
  //       },
  //       error: (error) => {
  //         this.snackbarService.showSnackbar(error.error.detail, '', 'error');
  //       },
  //     });
  // };

  // passwordStrengthCount(count: number) {
  //   this.passwdStrengthCount = count;
  // }
  /**
   * Redirects the user to the sign-in page, considering the presence of an invite code.
   */
  goToSigninPage = () => {
    // if invite code present, pass it into login url
    let url = this.signinUrl;
    if (this.inviteCode) {
      this.userService.logUserOut(this.inviteCode);
      this.isLocal
        ? `${window.location.href}?local=true&invite=${this.inviteCode}`
        : `${window.location.href}?invite=${this.inviteCode}`;
    } else {
      this.userService.logUserOut();
      window.location.href = this.isLocal ? `${url}?local=true` : url;
    }
  };
}
