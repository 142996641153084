import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  constructor() { }
  private message: string | null = null;

  setMessageForCustomTags(msg: string) {
    this.message = msg;
    // console.log('check the stored taskname: ', this.message);
    
  }

  getMessageForCustomTags(): string | null {
    return this.message;
  }

  clearMessageForCustomTags() {
    this.message = null;
  }
}
