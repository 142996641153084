<form class="form" [formGroup]="organizationInfoForm">
  <div fxLayout="row" fxLayoutGap="24px">
    <div fxLayout="column">
      <span class="form-header">Workspace Name</span>
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          placeholder="ABC Inc"
          formControlName="name"
        />
        <mat-error
          *ngIf="
            organizationInfoForm
              ?.get('name')
              .hasError('minLengthWithoutWhitespace')
          "
        >
          Workspace name should have atleast 3 characters.
        </mat-error>
        <mat-error
          *ngIf="organizationInfoForm?.get('name').hasError('maxlength')"
        >
          Workspace name cannot be longer than 100 characters.
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <span class="form-header">Role</span>
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          placeholder="ABC Inc"
          [readonly]="true"
          [value]="currentOrganizationRole"
          [disabled]="!showSaveButton"
          style="cursor: default"
        />
      </mat-form-field>
    </div>
    <button
      mat-stroked-button
      class="edit-btn"
      (click)="toggleEditButton()"
      *ngIf="!showSaveButton"
      [disabled]="!hasUpdatePermission || !isEmailVerified"
    >
      <img src="../../../../assets/images/add-assignee.svg" />
      <span>Edit</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      class="save-btn"
      *ngIf="showSaveButton"
      [disabled]="organizationInfoForm.invalid || !organizationInfoForm.dirty"
      (click)="saveEditedValue()"
    >
      Save
    </button>
  </div>

  <div fxLayout="column">
    <span class="form-header">Subscription</span>

    <div fxLayout="row">
      <mat-form-field appearance="outline">
        <input
          matInput
          placeholder="Basic Plan"
          formControlName="subscription"
          [readonly]="true"
          style="cursor: default"
        />
        <button
        style="margin: 4px -4px 0 0"
        matSuffix
        mat-raised-button
        color="primary"
        [disabled]="!allowUpgrade"
        (click)="openUpdradePlanDialog()"
      >
        Upgrade
      </button>
      </mat-form-field>

    
    </div>
  </div>
  <div fxLayout="column">
    <span class="form-header">Billing Address</span>
    <mat-form-field floatLabel="never" appearance="outline">
      <textarea
        matInput
        placeholder="Type here..."
        rows="5"
        formControlName="billing_address"
        style="cursor: default"
      ></textarea>
      <mat-error
        *ngIf="
          organizationInfoForm?.get('billing_address').hasError('maxlength')
        "
      >
        Billing address cannot be longer than 2500 characters.
      </mat-error>

      <mat-error
        *ngIf="
          organizationInfoForm?.get('billing_address').hasError('minlength')
        "
      >
        Billing address should have atleast 3 characters.
      </mat-error>
    </mat-form-field>
  </div>
</form>
