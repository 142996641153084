import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreAsSpace'
})
export class UnderscoreAsSpacePipe implements PipeTransform {
  
  transform(value: string,): string {
    return  value? value.replace(/_/g, " ") : value;
  }
  
}

@Pipe({
  name: 'am'
})
export class amPipe implements PipeTransform {
  
  transform(value: string,): string {
    return  value? value.replace(/__/g, " & ") : value;
  }
  
}