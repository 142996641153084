import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PrivacyPolicyService } from '../_services/privacy-policy.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorRouteDeactivateGuard implements CanDeactivate<any> {

  constructor(private privacyPolicyService: PrivacyPolicyService) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Check condition and open/close dialog when navigating from 'error' route
    const userData = JSON.parse(localStorage.getItem('user')!);
    if (JSON.parse(userData.privacy_policy_acceptance)) {
      // console.log('closing privacy policy dialog 2');

      this.privacyPolicyService.closeDialog();
    } else {
      const inviteToken = localStorage.getItem('inviteToken');
      // console.log('inviteToken: ', inviteToken);
      
      if (inviteToken) {
        // console.log('closing privacy policy dialog 3');
        this.privacyPolicyService.openPrivacyPolicyDialog(inviteToken);
      }
    }
    return true;
  }
}
