import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { BatchUploadService } from 'src/app/_services/batch-upload.service';
import { HeaderConfigService } from 'src/app/_services/header-config.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';

@Component({
  selector: 'app-header-rows',
  templateUrl: './header-rows.component.html',
  styleUrls: ['./header-rows.component.scss'],
})
export class HeaderRowsComponent implements OnInit {
  selectedFileType: string = 'csv';
  selectedHeader: any;
  inputData: any[];
  outputData: any[];
  organisation_id: any;
  batch_id: any;
  headers: string[] = [];
  selectedInputRow: number | null = null;
  selectedOutputRow: number | null = null;
  selectedInputRowData: any[] = [];
  selectedOutputRowData: any[] = [];
  outputTabs: any;
  inputTabs: any;
  fileType: any;
  singleInputTab: boolean = false;
  singleOutputTab: boolean = false;
  private batchIdSubscription: Subscription;
  selectedInputTabLabel: any;
  selectedOutputTabLabel: any;
  @ViewChild('outputTabGroup') outputTabGroup!: MatTabGroup;
  @ViewChild('outputTable') outputTable!: ElementRef;
  constructor(
    private headerConfigService: HeaderConfigService,
    private router: Router,
    private loadingService: LoadingService,
    private snackBar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.organisation_id = localStorage.getItem('currentOrganizationCode');
    this.batch_id = localStorage.getItem('currentBatchId');
    this.getInputSheets();
    this.getOutputSheets();
    this.loadingService.setLoading(true);
  }
  ngOnDestroy() {
    // localStorage.setItem('file_type', 'input_file');
  }

  //this observable is to reset the progress bar after failing
  private dataSubject = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();
  // changeFileType(fileType: string): void {
  //   // Update the button text and save it to local storage
  //   this.fileType = fileType;
  //   this.headerConfigService.setFileType(fileType);
  //   this.getSheets();
  // }

  /**
   * Fetches input sheets for a specific organization and batch.
   * Sets loading state, retrieves input sheets, and determines if there's only one input tab.
   * If a specific input tab label is selected, it also retrieves data for that tab.
   */
  getInputSheets() {
    this.loadingService.setLoading(true);
    this.headerConfigService
      .getSheets(this.organisation_id, this.batch_id, 'input_file')
      .subscribe({
        next: (res) => {
          this.inputTabs = res.result;
          if (this.inputTabs.length === 1) {
            this.singleInputTab = true;
          } else {
            this.singleInputTab = false;
          }
          if (this.selectedInputTabLabel) {
            this.getInputSheetData(this.selectedInputTabLabel);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loadingService.setLoading(false);
          this.snackBar.showSnackbar(
            'Previous batch creation failed',
            '',
            'error'
          );
          // Add a delay of 1000 milliseconds (1 second) before navigating to '/dashboard/upload-input-file'
          setTimeout(() => {
            this.router.navigate(['/dashboard/upload-input-file']);
          }, 1000);
          //this is to reset the progress bar after failing
          this.dataSubject.next(0);
        },
      });
  }

  /**
   * Fetches input sheet data for a specific organization, batch, and sheet name.
   * Sets the input data, and if output data is already available, it sets the loading state to false.
   * Note: The commented line is left for future reference.
   */
  getInputSheetData(sheet_name: any) {
    this.headerConfigService
      .getSheetData(
        this.organisation_id,
        this.batch_id,
        sheet_name,
        'input_file'
      )
      .subscribe({
        next: (res) => {
          this.inputData = res.result;
          if (this.outputData) {
            this.loadingService.setLoading(false);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loadingService.setLoading(false);
          this.snackBar.showSnackbar(
            'Previous batch creation failed',
            '',
            'error'
          );
          // Add a delay of 1000 milliseconds (1 second) before navigating to '/dashboard/upload-input-file'
          setTimeout(() => {
            this.router.navigate(['/dashboard/upload-input-file']);
          }, 1000);
          //this is to reset the progress bar after failing
          this.dataSubject.next(0);
        },
      });
  }

  /**
   * Retrieves and processes output sheets data from the server.
   * - Sets loading state to indicate data retrieval is in progress.
   * - Calls the headerConfigService to fetch sheets data based on organization_id, batch_id, and type ('template_output_file').
   * - Updates component state with the fetched outputTabs.
   * - Checks if only one output tab is available and updates the singleOutputTab flag accordingly.
   * - If a specific output tab is selected, triggers the retrieval of its data using getOutputSheetData.
   * - Handles errors by updating the loading state and providing appropriate error handling.
   */
  getOutputSheets() {
    this.loadingService.setLoading(true);
    this.headerConfigService
      .getSheets(this.organisation_id, this.batch_id, 'template_output_file')
      .subscribe({
        next: (res) => {
          this.outputTabs = res.result;
          if (this.outputTabs.length === 1) {
            this.singleOutputTab = true;
          } else {
            this.singleOutputTab = false;
          }
          // If a specific output tab is selected, trigger the retrieval of its data using getOutputSheetData
          if (this.selectedOutputTabLabel) {
            this.getOutputSheetData(this.selectedOutputTabLabel);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loadingService.setLoading(false);
          this.snackBar.showSnackbar(
            'Previous batch creation failed',
            '',
            'error'
          );
          // Add a delay of 1000 milliseconds (1 second) before navigating to '/dashboard/upload-input-file'
          setTimeout(() => {
            this.router.navigate(['/dashboard/upload-input-file']);
          }, 1000);
          //this is to reset the progress bar after failing
          this.dataSubject.next(0);
        },
      });
  }

  /**
   * Retrieves data for a specific output sheet from the server.
   * - Calls the headerConfigService to fetch sheet data based on organization_id, batch_id, sheet_name, and type ('template_output_file').
   * - Updates component state with the fetched outputData.
   * - If inputData is available, indicates that the loading operation is complete.
   * - Handles errors in the HTTP request by updating the loading state.
   *
   * @param sheet_name - The name of the output sheet for which data is to be retrieved.
   */
  getOutputSheetData(sheet_name: any) {
    this.headerConfigService
      .getSheetData(
        this.organisation_id,
        this.batch_id,
        sheet_name,
        'template_output_file'
      )
      .subscribe({
        next: (res) => {
          this.outputData = res.result;
          // this.getSavedConfig(sheet_name);
          if (this.inputData) {
            this.loadingService.setLoading(false);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loadingService.setLoading(false);
          this.snackBar.showSnackbar(
            'Previous batch creation failed',
            '',
            'error'
          );
          // Add a delay of 1000 milliseconds (1 second) before navigating to '/dashboard/upload-input-file'
          setTimeout(() => {
            this.router.navigate(['/dashboard/upload-input-file']);
          }, 1000);
          //this is to reset the progress bar after failing
          this.dataSubject.next(0);
        },
      });
  }

  /**
   * Handles the change event when switching between input tabs.
   * - Sets loading state to indicate data retrieval is in progress.
   * - Retrieves the selected input tab label from the inputTabs array.
   * - Calls the getInputSheetData function with the selected input tab label to fetch and update input sheet data.
   *
   * @param event - The change event object triggered when switching between input tabs.
   */
  onTabInputChange(event: any): void {
    this.loadingService.setLoading(true);
    this.selectedInputRowData = [];
    this.selectedInputRow = null;
    this.selectedInputTabLabel = this.inputTabs[event.index];
    // Call your getSheetData function with the selected tab label
    this.getInputSheetData(this.selectedInputTabLabel);
  }
  /**
   * Handles the selection of an input row.
   * - Sets the selectedInputRow and selectedInputRowData properties to the provided index and corresponding value.
   * - Triggers the scrollToOutputTable function to scroll to the corresponding row in the output table.
   *
   * @param index - The index of the selected input row.
   * @param value - The data associated with the selected input row.
   */
  selectInputRow(index: number, value: any): void {
    this.selectedInputRow = index;
    this.selectedInputRowData = value;
    this.scrollToOutputTable();
  }
  /**
   * Automatically scrolls to the output table when a specific condition is met.
   * - Checks if both the outputTabGroup and outputTable elements are available.
   * - Activates the tab containing the output table.
   * - Scrolls smoothly to the output table using nativeElement.scrollIntoView.
   */
  scrollToOutputTable() {
    if (this.outputTabGroup && this.outputTable) {
      // Activate the tab containing the second table
      this.outputTabGroup.selectedIndex = 0; // Adjust the index based on your tab structure

      // Scroll to the second table
      this.outputTable.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  /**
   * Handles the change event when switching between output tabs.
   * - Sets loading state to indicate data retrieval is in progress.
   * - Retrieves the selected output tab label from the outputTabs array.
   * - Calls the getOutputSheetData function with the selected output tab label to fetch and update output sheet data.
   *
   * @param event - The change event object triggered when switching between output tabs.
   */
  onTabOutputChange(event: any): void {
    this.loadingService.setLoading(true);
    this.selectedOutputRowData = [];
    this.selectedOutputRow = null;
    this.selectedOutputTabLabel = this.outputTabs[event.index];
    // Call your getSheetData function with the selected tab label
    this.getOutputSheetData(this.selectedOutputTabLabel);
  }
  /**
   * Handles the selection of an output row.
   * - Sets the selectedOutputRow and selectedOutputRowData properties to the provided index and corresponding value.
   *
   * @param index - The index of the selected output row.
   * @param value - The data associated with the selected output row.
   */
  selectOutputRow(index: number, value: any): void {
    this.selectedOutputRow = index;
    this.selectedOutputRowData = value;
  }
  /**
   * Handles the selection of an output row.
   * - Sets the selectedOutputRow and selectedOutputRowData properties to the provided index and corresponding value.
   *
   * @param index - The index of the selected output row.
   * @param value - The data associated with the selected output row.
   */
  clearSelection() {
    // Clear the selection by setting selectedRow to null
    this.selectedInputRow = null;
    this.selectedOutputRow = null;
    this.selectedInputRowData = [];
    this.selectedOutputRowData = [];
  }

  /**
   * Configures the input headers based on the selected input row.
   * - Checks if a specific input row is selected.
   * - If selected, triggers a request to the headerConfigService to configure headers for the input file.
   * - Upon successful configuration, invokes configureOutputHeader to configure headers for the corresponding output file.
   * - Handles errors in the HTTP request by updating the loading state.
   */
  configureInputHeader() {
    if (this.selectedInputRow != null) {
      this.loadingService.setLoading(true);
      this.headerConfigService
        .configureHeader(
          this.organisation_id,
          this.batch_id,
          'input_file',
          this.selectedInputTabLabel,
          this.selectedInputRow,
          this.selectedInputRowData
        )
        .subscribe({
          next: (res) => {
            this.configureOutputHeader();
            // console.log(res);
            // this.loadingService.setLoading(false);
            // if (this.fileType == 'template_output_file') {
            //   // localStorage.setItem('file_type', 'input_file');
            //   this.router.navigate(['/dashboard/upload-progress'], {
            //     queryParams: { headerSelection: true },
            //   });
            // } else {
            //   // this.changeFileType('template_output_file');
            // }
          },
          error: (error: HttpErrorResponse) => {
            this.loadingService.setLoading(false);
          },
        });
    }
  }
  /**
   * Configures the output headers based on the selected output row.
   * - Checks if a specific output row is selected.
   * - If selected, triggers a request to the headerConfigService to configure headers for the specified output file.
   * - Upon successful configuration, updates the loading state, navigates to the upload-progress page, and sets queryParams for headerSelection.
   * - Handles errors in the HTTP request by updating the loading state.
   */
  configureOutputHeader() {
    if (this.selectedOutputRow != null) {
      this.loadingService.setLoading(true);
      this.headerConfigService
        .configureHeader(
          this.organisation_id,
          this.batch_id,
          'template_output_file',
          this.selectedOutputTabLabel,
          this.selectedOutputRow,
          this.selectedOutputRowData
        )
        .subscribe({
          next: (res) => {
            this.loadingService.setLoading(false);
            this.router.navigate(['/dashboard/upload-progress'], {
              queryParams: { headerSelection: true },
            });
            // } else {
            //   // this.changeFileType('template_output_file');
            // }
          },
          error: (error: HttpErrorResponse) => {
            this.loadingService.setLoading(false);
          },
        });
    }
  }
  // getSavedConfig(current_sheet: any) {
  //   this.headerConfigService
  //     .getSavedConfig(this.organisation_id, this.batch_id, this.fileType)
  //     .subscribe({
  //       next: (res) => {
  //         if (current_sheet == res.result.selected_sheet) {
  //           this.selectedRow = res.result.selected_row_no;
  //           this.selectedRowData = this.data[res.result.selected_row_no];
  //         } else {
  //           this.clearSelection();
  //         }
  //       },
  //       error: (error: HttpErrorResponse) => {},
  //     });
  // }
}
