<div class="recipe-summary-dialog-container" fxLayout="row">
  <div fxLayout="column" fxLayoutGap="10px" fxFlex="98">
    <!-- header -->
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="recipe-summary-title">Recipe Summary</p>
      <span class="recipe-summary-subtitle"> Recipe 1 (RAD240001) has been applied</span>
    </div>
    <!-- tabs -->
    <div fxLayout="row" class="summary-tabs">
      <div class="each-tab" [ngClass]="{
        'active': tabSelected == 'functions-and-queries'
      }" (click)="tabSelected='functions-and-queries'" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="2px">
        <img
          [src]="tabSelected == 'functions-and-queries' ? '../../../../assets/images/outputDataview/checklist-active.svg':'../../../../assets/images/outputDataview/checklist.svg'" />
        <span>Functions and Queries</span>
        <span>(05)</span>
      </div>
      <div class="each-tab" [ngClass]="{
        'active': tabSelected == 'new-columns-found'
      }" (click)="tabSelected='new-columns-found'" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="2px">
        <img
          [src]="tabSelected == 'new-columns-found' ? '../../../../assets/images/outputDataview/frame-active.svg':'../../../../assets/images/outputDataview/frame.svg'" />
        <span>New Columns Found</span>
        <span>(60)</span>
      </div>
    </div>
    <!-- Functions and queries content -->
    <div fxLayout="column" fxLayoutGap="10px" class="functions-and-queries-wrapper"
      *ngIf="tabSelected == 'functions-and-queries'">
      <!-- each action -->
      <div class="action-element" fxLayout="column" fxLayoutGap="6px">
        <div class="description">
          <span>Output header "dimensions" has been manually mapped to "height","weight" with delimiter "-"</span>
        </div>
        <div class="count">
          <span><span class="col-count">40/40</span> columns, </span>
          <span><span class="row-count">03/40</span> rows </span>
          <span> affected</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="timestamp">July 24, 2023, 2:38 PM</span>
          <span class="manual-mapping-action-type">Manual Mapping</span>
        </div>
      </div>
      <!-- each action -->
      <div class="action-element" fxLayout="column" fxLayoutGap="6px">
        <div class="description" fxLayout="row" fxLayoutGap="2px">
          <span>classifier <span class="function-name">Classify</span></span>
          <span class="invoice-desc">Invoice description</span>
          <span class="short-desc">Short description</span>
        </div>
        <div class="count">
          <span><span class="col-count">40/40</span> columns, </span>
          <span><span class="row-count">33/40</span> rows </span>
          <span> affected</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="timestamp">July 24, 2023, 2:38 PM</span>
          <span class="functions-action-type">Functions</span>
        </div>
      </div>
    </div>

    <!-- new columns found content -->
    <div fxLayout="row" fxLayoutGap="10px" class="new-columns-found-wrapper" *ngIf="tabSelected == 'new-columns-found'">
      <div fxLayout="column" class="each-block">
        <div class="each-col" fxLayout="row" fxLayoutAlign="start center" *ngFor="let i of [1,2,3,4,5,3,4,4,4,4,4,4]">
          <div class="col-index">AB</div>
          <div class="col-name" fxLayoutAlign="start center">Mfg/DivName</div>
        </div>
      </div>

      <div fxLayout="column" class="each-block">
        <div class="each-col" fxLayout="row" fxLayoutAlign="start center" *ngFor="let i of [1,2,3,4,5,3,4,4,4,4,4,4]">
          <div class="col-index">AB</div>
          <div class="col-name" fxLayoutAlign="start center">Mfg/DivName</div>
        </div>
      </div>
    </div>

  </div>
  <!-- close icon -->
  <div fxLayout="column" class="close-dialog" fxFlex fxLayoutAlign="start start">
    <mat-icon style="cursor: pointer;" (click)="onClose()">close</mat-icon>
  </div>

</div>
