<div class="dialog-container" fxLayout="row" style="padding: 24px;">
  <div fxLayout="column" fxFlex="100">
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="message">Our team will be in touch with you shortly.</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-button class="interested-btn" (click)="intrestedToUpgrade()">
        I'm interested
      </button>
    </div>
  </div>

  <div fxLayout="column" fxFlex fxLayoutAlign="start start">
    <mat-icon (click)="onClose()">close</mat-icon>
  </div>
</div>
