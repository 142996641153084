<div class="shareDialogConatiner">
  <div fxlayout="row" fxLayoutAlign="space-between center">
    <h2>Share the Output
    </h2>
    <mat-icon style="cursor: pointer;margin-top: -28px;" (click)="closeDialog()">close</mat-icon>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="form">
      <ng-container formArrayName="inputFields">
        <div *ngFor="let input of inputFields.controls; let i = index" class="input-field">
          <mat-form-field  class="password-form-field" appearance="outline" style="width:100%;">
            <!-- <mat-label>Email {{ i + 1 }}</mat-label> -->
            <div style="display: flex;align-items: center;">
             <mat-icon matPrefix [svgIcon]="input.value.length===0?'mailInactive':'mailActive'"
              style="padding-right: 5px;"></mat-icon>
              <input matInput [formControl]="input" placeholder="example@abc.com " [errorStateMatcher]="matcher" />
             <button *ngIf="i!=0" mat-button (click)="removeInputField(i)"><mat-icon style="position: absolute;
              right: 2px;top:-12px;">remove</mat-icon></button> 
              <!-- <mat-icon matPrefix svgIcon="mailInactive"></mat-icon> -->
            </div>
            <!-- <mat-icon matSuffix svgIcon="arrowRightActive"></mat-icon> -->
            <!-- <mat-icon matSuffix svgIcon="arrowRightPlane"></mat-icon> -->
            <mat-error *ngIf="inputFields.controls[i].errors?.['pattern']">Please enter a valid email.</mat-error>
            <mat-error *ngIf="inputFields.controls[i].errors?.['myCustomError']">This user is your team member. So you
              dont have to share the output.</mat-error>
              <mat-error *ngIf="inputFields.controls[i].errors?.['duplicateEmailError']">You have already entered this email ID</mat-error>

              <mat-error *ngIf="inputFields.controls[i].errors?.['loggedinemailError']">You cannot enter logged in  email ID</mat-error>
          </mat-form-field>
          <button fxLayout="row" class="addNewEmail" *ngIf="i === inputFields.length - 1" mat-button
            (click)="addInputField()">
            <span fxLayoutAlign="start center">Add New Email<mat-icon style="position: absolute;
              right: 8px;">add</mat-icon>
             
            </span>
          </button>
        </div>
        <div fxlayout="row" fxLayoutAlign="space-between center" style=" margin-top: 20px;">
          <button style="width: 48%;" mat-stroked-button (click)="closeDialog()">Cancel</button><button
            style="width: 48%;" mat-raised-button color="primary" (click)="submit()"
            [disabled]="form.invalid">Share</button>
        </div>
      </ng-container>
    </form>

  </div>
  <!-- <div mat-dialog-actions>
  <button mat-button mat-dialog-close>No</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</div> -->
</div>
