import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { JssOutputService } from 'src/app/_services/jss-output.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { BatchDetailsComponent } from 'src/app/_dialogs/batch-details/batch-details.component';
import { MatDialog } from '@angular/material/dialog';
import { jsonData, tableData } from './sample-input-header-data';
import { RecipeSummaryComponent } from 'src/app/_dialogs/recipe-summary/recipe-summary/recipe-summary.component';
import { AppConfigService } from 'src/app/_services/app-config.service';
@Component({
  selector: 'app-view-output',
  templateUrl: './view-output-new.component.html',
  styleUrls: ['./view-output-new.component.scss'],
})
export class ViewOutputNewComponent implements OnInit {
  allColumns: Array<any> = []; //column headers
  allFilterColumns: Array<any> = []; //separate list of columns for mat options
  // Alphabetical labels for the new header
  alphabeticalLabels: string[] = [];

  page: number = 1;
  currentPageValue: number;
  size: number = 100;
  dataTablePage: number = 1;
  dataTableSize: number = 100;
  dataTableTotalRows: number;
  totalItems: number;
  total_pages: number;
  activeOrgID: string | null;
  batchID: string;
  disableSave: boolean = false;
  taskName: string;
  inputHeaders: any;
  dataTableRows: any[];
  // selectedCustomTab: string = 'inputData';
  selectedCustomTab: string = 'outputData';
  showVersionHistory: boolean = false;
  totalColsInAScreen: number;
  sortActive: boolean = false;
  sortActiveForColumn: string;
  sortActiveForColumnIndex: string;
  sortObject: any = {};
  filterObject: any = {};
  dataFilterSelectValue: any;
  leftOffset: any;
  topOffset: any;
  screenInnerWidth: number;
  screenHeight: number;
  filterForm = new FormGroup({
    selectACondition: new FormControl(''),
    text: new FormControl(''),
  });
  isBusy: boolean = false;
  isHeaderLoading: boolean;
  isTableContentLoading: boolean;
  sortActiveOnElement: any;
  sortedColumnList: any = {};
  chosenSortOrder: any;
  renameTask: boolean = false;
  taskNameValue: string;
  batchDetails: any;
  isInputDataTableLoading: boolean;
  inputDataTableContent: any[];
  inputDataTableRows: any[];
  inputDataTablePage: number = 1;
  inputDataTableSize: number = 100;
  inputFileDownloadUrl: any;
  sortOptions: any[] = [
    { display: 'Default', value: '', checked: true },
    { display: 'Sort ascending', value: 'asc', checked: false },
    { display: 'Sort descending', value: 'desc', checked: false },
  ];
  defaultSortValue = '';
  currentElement: any;
  isNewVersion: boolean;
  maxCharCountTaskName: number = 100;
  minCharCountTaskName: number = 3;
  isSampleTask: boolean;
  errorType: any;
  tasknameErrorMessageMapping: any = {
    atleast_3_characters_required:
      'Batch name needs to have at least 3 characters',
    max_limit_exceeded: 'Batch name cannot exceed 100 characters',
    no_special_charcters_in_beginning:
      "Batch name can't start with a number or a special character or space",
    only_underscore_allowed_in_between:
      "Batch name can't have any special characters except for '_'",
  };
  showTaskRelatedError: boolean = false;
  flagForDisabling: boolean = false;

  // =========

  constructor(
    private jssOutputService: JssOutputService,
    private loader: LoadingService,
    private activatedRoute: ActivatedRoute,
    private snackbar: SnackbarService,
    public dialog: MatDialog,
    public appConfig: AppConfigService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.isNewVersion = this.appConfig.getConfig().is_new_version;
    this.screenInnerWidth = window.innerWidth;
    this.screenHeight = screen.height;
    this.isBusy = true;
    this.currentPageValue = this.page;
    // this.loader.setLoading(true);
    this.activeOrgID = localStorage.getItem('currentOrganizationCode');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.batchID = params['batch_id'];
    });

    // get batch details
    this.getBatchDetails(this.activeOrgID, this.batchID);
    // output data headers
    this.getDataTableHeaders(this.activeOrgID, this.batchID);
    // input data
    this.getInputTableData(this.activeOrgID, this.batchID);

    // this.dataTableRows = tableData.output;
    // this.inputHeaders = jsonData.mapping;
    // this.isBusy = false;
    // this.isHeaderLoading = false;
    // this.isTableContentLoading = false;
    /***
     * get screen width to determine no of cols we can have
     * this is to have flexible width as well as truncate overflowing text
     * */
    let eachColSize = 200;
    this.totalColsInAScreen = Math.round(
      (this.screenInnerWidth - 200) / eachColSize
    );
    /***
     * set required text field validation for contain/does not contain filter in data table
     */
    this.filterForm.get('selectACondition')!.valueChanges.subscribe((val) => {
      if (
        this.filterForm.get('selectACondition')!.value == 'contains' ||
        this.filterForm.get('selectACondition')!.value == 'does not contain'
      ) {
        // for setting validations
        this.filterForm.get('text')!.setValidators(Validators.required);
      } else {
        // for clearing validations
        this.filterForm.get('text')!.clearValidators();
      }
      this.filterForm.get('text')!.updateValueAndValidity();
    });
  }
  /******* div reference for simultaneous horizontal scrolling ********* */
  @ViewChild('scrollOne') scrollOne: ElementRef;
  @ViewChild('scrollTwo') scrollTwo: ElementRef;

  /**** close the sort menu filter on click outside the menu  ************************************ */
  @ViewChild('sortFilterMenu') sortFilterMenuRef: ElementRef;
  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    const clickedInside =
      this.sortFilterMenuRef &&
      this.sortFilterMenuRef.nativeElement.contains(event.target);
    if (
      event.target.id != this.sortActiveForColumnIndex &&
      !clickedInside &&
      this.sortActive == true
    ) {
      this.sortActive = false;
      // reset `sortActiveForColumnIndex` for proper bg highlight
      this.sortActiveForColumnIndex = '';
    }
  }

  /*** close the rename task input on esc key event */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.renameTask) {
      this.renameTask = false;
    }
  }

  /***** function to scroll the header and content simultaneously */
  updateScroll = (event: any) => {
    if (this.currentElement === 'scrollTwo') {
      this.scrollOne.nativeElement.scrollLeft = event.target.scrollLeft;
    } else if (this.currentElement === 'scrollOne') {
      this.scrollTwo.nativeElement.scrollLeft = event.target.scrollLeft;
    }
  };

  /***** tracking current element being scrolled */
  updateCurrentElement(element: 'scrollOne' | 'scrollTwo') {
    this.currentElement = element;
  }

  openVersionHistory = () => {
    if (!this.flagForDisabling) {
      document.getElementById('flexibleDiv')!.style.display = 'block';
      this.showVersionHistory = true; 
    }
  };

  selectedSortColumn = '';
  searchQuery: string | null;
  selectedColumns: any = [];
  conditions: any = [
    { label: 'Contains', value: 'contains' },
    { label: 'Does not contain', value: 'does not contain' },
    { label: 'Is blank', value: 'is blank' },
  ];
  selectedCondition = 'contains';

  // Clearing the Sort form field
  clearAll() {
    this.selectedColumns = [];
    this.resetAllFilters();
  }

  // Clear filter
  clearSearchFilter(e: any) {
    e.stopPropagation();
    this.searchQuery = '';
    this.selectedColumns = null;
    this.selectedCondition = 'contains';
    // this.isSearchEntered = false;
  }

  // Reset filters and update output data
  resetAllFilters() {
    this.allFilterColumns.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled = false;
    });
    this.columnsList.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled = false;
    });
    this.selectedColumns = null;
    this.selectedSortColumn = '';
    if (this.searchQuery) {
      this.searchQuery = '';
    }
  }

  // Allow max 5 column selections
  onSelectSearchColumn() {
    this.allFilterColumns.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled =
        this.selectedColumns.length >= 5 &&
        !this.selectedColumns.includes(option.name);
    });
    this.columnsList.forEach((option: any) => {
      // Disable options if the limit is reached and the option is not selected
      option.disabled =
        this.selectedColumns.length >= 5 &&
        !this.selectedColumns.includes(option.name);
    });
  }

  // custom table

  columnsList: any[] = [];

  tableData: any[] = [];

  sorting: { [columnName: string]: 'asc' | 'desc' | null } = {};

  sortedColumnName: string | null;
  // Handle sort - defaults to ascending when active
  onSort(columnName: string) {
    this.sortedColumnName = columnName;
    if (!this.sorting[columnName]) {
      // Sort in ascending order
      this.sorting[columnName] = 'asc';
    } else if (this.sorting[columnName] === 'asc') {
      // Sort in descending order
      this.sorting[columnName] = 'desc';
    } else {
      // Deactivate sorting
      this.sorting[columnName] = null;
      this.sortedColumnName = null;
    }

    // Reset sorting for other columns
    Object.keys(this.sorting)
      .filter((col) => col !== columnName)
      .forEach((col) => (this.sorting[col] = null));
  }

  isSearchEntered: boolean = false;
  // Apply filter on enter press
  onEnterSearch() {
    this.isSearchEntered = true;
  }

  // filter columns
  // filterList(searchText: any) {
  //   const searchKey: string = searchText?.trim();
  //   this.allFilterColumns = this.columnsList.filter((option) =>
  //     option.name.toLowerCase().includes(searchKey.toLowerCase())
  //   );
  // }
  // =======
  filterList(searchText: any) {
    const searchKey: string = searchText?.trim().toLowerCase();

    this.allFilterColumns = this.columnsList.sort((a, b) => {
      // Compare the presence of searchText in each column name
      const aMatches = a.name.toLowerCase().includes(searchKey);
      const bMatches = b.name.toLowerCase().includes(searchKey);

      // Sort based on matches - bring matching columns to the top
      if (aMatches && !bMatches) {
        return -1;
      } else if (!aMatches && bMatches) {
        return 1;
      } else {
        // Preserve the original order for columns that have the same matching status
        return 0;
      }
    });
  }

  // =======

  @ViewChild('columnSelect') columnDropdown: MatSelect;
  openColumnDropdown() {
    if (!this.columnDropdown.panelOpen) this.columnDropdown.open();
  }

  // Apply filter on change of column selections
  onSelectCondition() {
    if (this.searchQuery) {
      this.isSearchEntered = true;
    }
    if (this.selectedCondition == 'is blank') {
      this.searchQuery = null;
      this.isSearchEntered = false;
    }
  }

  columnSearchText: string = '';
  // Apply filter when the panel closes
  onOpenCloseColumnPanel(isOpen: boolean) {
    if (!isOpen) {
      if (this.searchQuery) {
      }
      if (this.selectedCondition == 'is blank') {
      }
      this.columnSearchText = '';
      this.filterList(this.columnSearchText);
    }
  }

  // Switch to edit field
  editSearch() {
    this.isSearchEntered = false;
  }

  /************************************ Revised Output View *************************/

  switchTab = (targetTab: string) => {
    this.selectedCustomTab = targetTab;
    
    if (targetTab == 'inputData') {
      this.inputDataTablePage = 1;
      this.inputDataTableSize = 100;
      this.getInputTableData(this.activeOrgID, this.batchID);
    } else {
      this.dataTablePage = 1;
      this.dataTableSize = 100;
      this.getDataTableHeaders(this.activeOrgID, this.batchID);
    }
  };

  /************* get batch details  *************/

  getBatchDetails = (orgId: any, batchId: any) => {
    this.jssOutputService.getBatchDetails(orgId, batchId).subscribe({
      next: (resp) => {
        this.batchDetails = resp.batch_details;
        this.taskName = this.batchDetails.task_name;
        this.isSampleTask = this.batchDetails.is_sample;
        this.taskNameValue = this.taskName;
        this.inputFileDownloadUrl = this.batchDetails.input_file_url;
        this.flagForDisabling = this.batchDetails.disable_edit;
        // this.flagForDisabling = true;

      },
      error: (error: HttpErrorResponse) => {
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /*************** download input file  **************/

  downloadInputFile = () => {
    window.open(this.inputFileDownloadUrl, '_blank');
  };

  /*************** open batch details dialog *************/

  openBatchDetailsDialog = () => {
    const dialogRef = this.dialog.open(BatchDetailsComponent, {
      data: { batchDetails: this.batchDetails },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log('The dialog was closed');
    // });
  };

  openRecipeSummaryDialog = () => {
    const dialogRef = this.dialog.open(RecipeSummaryComponent, {
      data: { batchDetails: this.batchDetails },
    });
  };

  /********* get input table data  ******************/

  getInputTableData = (orgId: any, batchId: any) => {
    this.isInputDataTableLoading = true;
    let obj = {
      page: this.inputDataTablePage,
      page_size: this.inputDataTableSize,
    };
    this.jssOutputService.getInputDataTable(orgId, batchId, obj).subscribe({
      next: (resp) => {
        this.inputDataTableContent = resp;
        this.inputDataTableRows = resp.input_data;
        this.isInputDataTableLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
        this.isInputDataTableLoading = false;
      },
    });
  };

  /*** get table headers **********/

  getDataTableHeaders = (orgId: any, batchId: any) => {
    this.isHeaderLoading = true;
    this.isTableContentLoading = true;
    this.jssOutputService.getDataTableHeaders(orgId, batchId).subscribe({
      next: (resp) => {
        this.isHeaderLoading = false;
        this.inputHeaders = resp.mapping;
        // this.inputHeaders = []
        this.getDataTableContent(this.activeOrgID, this.batchID);
      },
      error: (error: HttpErrorResponse) => {
        this.isHeaderLoading = false;
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  /*** get table content  *********/

  getDataTableContent = (orgId: any, batchId: any) => {
    let obj = {
      page: this.dataTablePage,
      page_size: this.dataTableSize,
      sort: this.sortObject,
      column_filter: this.filterObject,
      conversation_uid: '',
    };
    this.isTableContentLoading = true;
    this.isBusy = true;
    this.jssOutputService.getDataTableContent(orgId, batchId, obj).subscribe({
      next: (resp) => {
        this.dataTableRows = resp.output;
        this.dataTablePage = resp.page;
        this.currentPageValue = this.dataTablePage;
        this.dataTableSize = resp.page_size;
        this.dataTableTotalRows = resp.total_records;
        // this.dataTableRows = [];
        this.isBusy = false;
        this.isTableContentLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.isBusy = false;
        this.snackbar.showSnackbar(error.error.detail, '', 'error');
        this.isTableContentLoading = false;
      },
    });
  };

  /*** on output data table pagination event */

  onPaginationEvent = (e: any) => {
    this.dataTablePage = e.pageIndex + 1;
    this.dataTableSize = e.pageSize;
    // this.batchDetails(this.batchID);
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /*** go to a page directly  */
  goToPage = (e: any) => {
    e.preventDefault();
    // to remove focus
    e.target.blur();
    window.getSelection()?.removeAllRanges();
    this.dataTablePage = this.currentPageValue;
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /***** on input table pagination event  */

  onInputTablePaginationEvent = (e: any) => {
    this.inputDataTablePage = e.page;
    this.inputDataTableSize = e.pageSize;
    this.getInputTableData(this.activeOrgID, this.batchID);
  };

  goToPageInputTable = (e: any) => {
    this.inputDataTablePage = e.page;
    this.inputDataTableSize = e.pageSize;
    this.getInputTableData(this.activeOrgID, this.batchID);
  };

  /*** on cell edit  */

  editCell = (ev: Event) => {
    let cellIndex = (ev.target as HTMLInputElement).dataset;
  };

  /*** set the active column and positions for sort filter menu once `sort` icon clicked  */

  sortAndFilterAppliedFor = (header: any, e: any) => {
    // check if sort btn was reclicked for same column
    if (e.target.id == this.sortActiveOnElement) {
      // toggle
      this.sortActive = !this.sortActive;
    } else {
      // sort menu initialised for new column
      this.sortActive = true;
    }
    // header.sort_active = !header.sort_active;
    // toggle sort & filter menu
    if (this.sortActive) {
      // get the position of the clicked element
      let elm = document.getElementById(header.column_index);
      let rect = elm!.getBoundingClientRect();
      // store the column for which sort menu initiated
      this.sortActiveOnElement = e.target.id;
      // to highlight icon for active sort
      this.sortActiveForColumn = header.output_column;
      this.sortActiveForColumnIndex = header.column_index;
      // prefill menu with previous selections if any
      this.feedSortDataForRespectiveColumn(this.sortActiveForColumn);
      // calculate position for sort & filter menu|245px is the width of the sort filter menu
      // this.topOffset = rect.y + 21;
      this.topOffset = 120;
      if (this.screenInnerWidth < rect.x + 245) {
        this.leftOffset = rect.x - 245 - 60;
      } else {
        this.leftOffset = rect.x - 60;
      }
    } else {
      // reset `sortActiveForColumnIndex` for proper bg highlight
      this.sortActiveForColumnIndex = '';
    }
  };

  /*** prepopulate active selections in the column filters */

  feedSortDataForRespectiveColumn = (col: string) => {
    if (this.sortedColumnList.hasOwnProperty(col)) {
      this.chosenSortOrder = this.sortedColumnList[col].sortOrder;
      this.filterForm.patchValue({
        selectACondition: this.sortedColumnList[col]['condition'],
        text: this.sortedColumnList[col]['text'],
      });
    } else {
      this.chosenSortOrder = null;
      this.filterForm.reset();
    }
  };

  /*** on change of sorting order   */

  getSortingOrder = (e: any) => {
    this.sortObject[this.sortActiveForColumn] = e.value;

    // add the sorted/filtered column to the `sortedColumnList` object
    if (!this.sortedColumnList.hasOwnProperty(this.sortActiveForColumn)) {
      this.sortedColumnList[this.sortActiveForColumn] = {};
    }
    this.sortedColumnList[this.sortActiveForColumn]['sortOrder'] = e.value;
    this.sortedColumnList[this.sortActiveForColumn]['columnIndex'] =
      this.sortActiveForColumnIndex;

    // close the sort filter menu
    this.sortActive = false;
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
    // highlight the column sort icon if sorting selected and value is not default
    if (
      this.sortObject[this.sortActiveForColumn] &&
      this.sortObject[this.sortActiveForColumn] != ''
    ) {
      (
        document.getElementById(
          this.sortActiveForColumnIndex
        ) as HTMLImageElement
      ).src = '../../../../assets/images/outputDataview/sort-blue.svg';
      // fill the background
      document.getElementById(
        'div' + this.sortActiveForColumnIndex
      )!.style.backgroundColor = '#D0DAFA';
    } else if (!this.filterObject[this.sortActiveForColumn]) {
      (
        document.getElementById(
          this.sortActiveForColumnIndex
        ) as HTMLImageElement
      ).src = '../../../../assets/images/outputDataview/sort.svg';
      // remove background color
      document.getElementById(
        'div' + this.sortActiveForColumnIndex
      )!.style.backgroundColor = '#F1F5F9';
    }
    // reset the `sortActiveForColumnIndex` to get rid of sort icon background
    this.sortActiveForColumnIndex = '';
  };

  /*** on change of filtering (contains/does not contain/is blank) conditions  */

  onChangeOfCondition(e: any) {
    this.dataFilterSelectValue = this.filterForm.get('selectACondition')?.value;
    this.filterForm.get('text')!.reset();
  }

  /*** clear and apply filters for each table column */
  clearColumnFilter = () => {
    delete this.filterObject[this.sortActiveForColumn];
    // delete filter records from `sortedColumnList`
    delete this.sortedColumnList[this.sortActiveForColumn]['condition'];
    delete this.sortedColumnList[this.sortActiveForColumn]['text'];
    // Reset sort order to default
    this.chosenSortOrder = null;
    // clear the form
    this.filterForm.reset();
    this.getDataTableContent(this.activeOrgID, this.batchID);
    // remove highlight from column sort icon if no filtering/sorting active
    if (
      !this.filterObject[this.sortActiveForColumn] &&
      !this.sortObject[this.sortActiveForColumn]
    )
      (
        document.getElementById(
          this.sortedColumnList[this.sortActiveForColumn]['columnIndex']
        ) as HTMLImageElement
      ).src = '../../../../assets/images/outputDataview/sort.svg';
    // remove background color
    document.getElementById(
      'div' + this.sortedColumnList[this.sortActiveForColumn]['columnIndex']
    )!.style.backgroundColor = '#F1F5F9';
    // close the menu
    this.sortActive = false;
  };

  /***** apply filters for column  */

  applyColumnFilter = () => {
    let obj: any = {};
    obj['condition'] = this.filterForm.value.selectACondition;
    obj['values'] =
      this.filterForm.value.selectACondition == 'is blank'
        ? ''
        : this.filterForm.value.text;
    this.filterObject[this.sortActiveForColumn] = obj;
    // add the sorted/filtered column to the `sortedColumnList` object
    if (!this.sortedColumnList.hasOwnProperty(this.sortActiveForColumn)) {
      this.sortedColumnList[this.sortActiveForColumn] = {};
    }
    this.sortedColumnList[this.sortActiveForColumn]['columnIndex'] =
      this.sortActiveForColumnIndex;
    this.sortedColumnList[this.sortActiveForColumn]['condition'] =
      this.filterForm.value.selectACondition;
    this.sortedColumnList[this.sortActiveForColumn]['text'] =
      this.filterForm.value.selectACondition == 'is blank'
        ? ''
        : this.filterForm.value.text;
    // close the sort filter menu
    this.sortActive = false;
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
    // highlight the column sort icon
    (
      document.getElementById(this.sortActiveForColumnIndex) as HTMLImageElement
    ).src = '../../../../assets/images/outputDataview/sort-blue.svg';
    // fill the background
    document.getElementById(
      'div' + this.sortActiveForColumnIndex
    )!.style.backgroundColor = '#D0DAFA';
  };

  /*** reset sorting for all columns */

  resetSortForAllColumns = () => {
    // reset `sortObject`
    this.sortObject = {};
    this.sortActiveForColumnIndex = '';
    for (const key in this.sortedColumnList) {
      // skip if column does not have sorting applied
      if (
        !this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        continue;
      }
      // delete only `sortOrder` key if other filters active
      else if (
        this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        delete this.sortedColumnList[key]['sortOrder'];
      } else {
        // remove sort icon highlight
        (
          document.getElementById(
            this.sortedColumnList[key]['columnIndex']
          ) as HTMLImageElement
        ).src = '../../../../assets/images/outputDataview/sort.svg';
        // remove background color
        document.getElementById(
          'div' + this.sortedColumnList[key]['columnIndex']
        )!.style.backgroundColor = '#F1F5F9';
        // delete the column altogether
        delete this.sortedColumnList[key];
      }
    }
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /*** reset filters for all columns */

  resetFilterForAllColumns = () => {
    this.filterObject = {};
    for (const key in this.sortedColumnList) {
      // delete only `condition and text` key if other filters active
      if (
        this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        !this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        continue;
      } else if (
        this.sortedColumnList[key].hasOwnProperty('sortOrder') &&
        this.sortedColumnList[key].hasOwnProperty('condition')
      ) {
        delete this.sortedColumnList[key]['condition'];
        delete this.sortedColumnList[key]['text'];
      } else {
        // remove sort icon highlight
        (
          document.getElementById(
            this.sortedColumnList[key]['columnIndex']
          ) as HTMLImageElement
        ).src = '../../../../assets/images/outputDataview/sort.svg';
        document.getElementById(
          'div' + this.sortedColumnList[key]['columnIndex']
        )!.style.backgroundColor = '#F1F5F9';
        // delete the column altogether
        delete this.sortedColumnList[key];
      }
    }
    // get the data table rows
    this.getDataTableContent(this.activeOrgID, this.batchID);
  };

  /****** save task name  *********************************/

  saveTaskName() {
    this.jssOutputService
      .renameTask(this.activeOrgID, this.batchID, this.taskNameValue)
      .subscribe({
        next: (resp) => {
          this.snackbar.showSnackbar(
            'Batch name updated successfully',
            '',
            'success'
          );
          this.renameTask = false;
          this.taskName = this.taskNameValue;
        },
        error: (error: HttpErrorResponse) => {
          this.snackbar.showSnackbar(error.error.detail, '', 'error');
        },
      });
  }

  /************* exit task name edit  */

  exitTaskEdit = () => {
    this.renameTask = false;
    this.taskNameValue = this.taskName;
    this.errorType = null;
  };

  setNewTaskName = (ev: any) => {
    this.taskNameValue = ev.target.textContent.trim();
    this.verifyTaskName();
  };

  /********* on task name input change *******************/

  onTaskNameChange = (name: string, event: any) => {
    if (name.length >= this.maxCharCountTaskName && event.code != 'Backspace') {
      event.preventDefault();
      this.taskNameValue.slice(0, 99);
      return false;
      // disable enter default action in content editable div
      // verify task name on enter
    } else if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  };

  /****** task name verification******** */
  verifyTaskName = () => {
    if (this.taskNameValue.length < this.minCharCountTaskName) {
      this.errorType = 'atleast_3_characters_required';
      return false;
    } else if (this.taskNameValue.length > this.maxCharCountTaskName) {
      this.errorType = 'max_limit_exceeded';
      return false;
    } else if (!/^[a-zA-Z](.*[a-zA-Z0-9])?$/.test(this.taskNameValue)) {
      this.errorType = 'no_special_charcters_in_beginning';
      return false;
    } else if (!/^([a-zA-Z0-9,:\s_]*)$/.test(this.taskNameValue)) {
      this.errorType = 'only_underscore_allowed_in_between';
      return false;
    } else {
      this.errorType = null;
      return true;
    }
  };

  isObjectEmpty = (objectName: any) => {
    // check if object has 0 keys
    // or object has keys with no value
    return (
      Object.keys(objectName).length === 0 ||
      Object.values(objectName).every((x) => x === null || x === '')
    );
  };

  /**** cell hover style */
  changeStyle = (event: any) => {
    let cellIndex: DOMStringMap;
    cellIndex = (event.target as HTMLInputElement).dataset;
    let rowIndex = cellIndex['rowIndex']!.toString();
    let colIndex = cellIndex['columnIndex']!.toString();
    if (event.type == 'mouseover') {
      document.getElementById(rowIndex)!.style.backgroundColor = '#E7ECFE';
      document.getElementById('div' + colIndex)!.style.backgroundColor =
        '#E7ECFE';
    } else {
      document.getElementById(rowIndex)!.style.backgroundColor = '#F1F5F9';
      document.getElementById('div' + colIndex)!.style.backgroundColor =
        '#F1F5F9';
    }
  };

  /******** generate output file  */

  generateOutputFile = () => {
    this.jssOutputService
      .generateOutputFile(this.activeOrgID, this.batchID)
      .subscribe({
        next: (resp: any) => {
          // 
          this.router.navigate(['/dashboard']);
          // 
          // this.isNewVersion
          //   ? this.router.navigate(['/dashboard-v1'])
          //   : this.router.navigate(['/dashboard']);
        },
        error: (error: HttpErrorResponse) => {
          this.snackbar.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };
}
