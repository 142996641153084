<app-top-nav></app-top-nav>
<div class="page-container" style="position: relative;">
  <div style="margin: 24px 60px 0px 60px">

    <!-- Inline actions -->
    <div fxLayout="row" fxLayoutAlign="space-between center" class=" container" *ngIf="taskName">
      <div class="title" fxLayoutGap="2px" fxLayout="row" fxLayoutAlign="start center">
        <!-- task name-->
        <span *ngIf="taskName && !renameTask" class="task-name-display" matTooltipPosition="above"
          [matTooltip]="taskName" [matTooltipDisabled]="taskName.length <= 20">{{
            taskName | midTruncate : 10 : 10
          }}</span>
        <!-- task rename field -->
        <div fxLayout="column" style="max-width: 100%; ">
          <!-- task edit field -->
          <div class="rename-task-field" style="min-width: 300px;" *ngIf="renameTask" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div fxLayout="row" class="field-error" *ngIf="errorType" (mouseenter)="showTaskRelatedError = true"
              (mouseleave)="showTaskRelatedError = false">
              <img src="../../../../assets/images/outputDataview/error-circle.svg" />
            </div>
            <div contenteditable fxFlex="70" class="text-input" max="20" (input)="setNewTaskName($event)"
              (keydown)="onTaskNameChange($event.target.innerHTML, $event)" [textContent]="taskNameValue">
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" style="margin-left: 6px">
              <div class="text-count">
                ({{taskNameValue?.length || 0}}/{{maxCharCountTaskName}})
              </div>
              <div class="action-btns" fxLayout="row">
                <button (click)="saveTaskName()" mat-button mat-icon-button
                  *ngIf="taskNameValue.length >= 3 && !errorType">
                  <mat-icon svgIcon="Check"></mat-icon>
                </button>
                <button mat-button mat-icon-button *ngIf="taskNameValue.length < 3  || errorType">
                  <mat-icon svgIcon="checkDisabled"></mat-icon>
                </button>
                <button mat-button mat-icon-button [disabled]="taskNameValue.length == 0">
                  <mat-icon (click)="exitTaskEdit()" svgIcon="Close"></mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="task-name-error" *ngIf="showTaskRelatedError">
            <span>{{tasknameErrorMessageMapping[errorType]}}</span>
          </div>
        </div>
        <!-- task related actions menu -->
        <img *ngIf="!renameTask && taskName"
          [src]="t.menuOpen ? '../../../../assets/images/outputDataview/close-menu.svg': '../../../../assets/images/outputDataview/open-menu.svg'"
          style="cursor: pointer" [matMenuTriggerFor]="taskMenu" #t="matMenuTrigger" />

        <mat-menu #taskMenu="matMenu" class="task-menu">
          <button mat-menu-item (click)="renameTask = true" [disabled]="isSampleTask">Rename</button>
          <button mat-menu-item (click)="downloadInputFile()" [disabled]="!inputFileDownloadUrl">Download Input
            file</button>
          <button mat-menu-item (click)="openBatchDetailsDialog()" [disabled]="!batchDetails">Details</button>
          <button mat-menu-item (click)="openRecipeSummaryDialog()" [disabled]="isSampleTask">Recipe Summary</button>
        </mat-menu>
      </div>

    </div>

    <!-- tabs -->
    <div class="custom-tabs" fxLayout="row">
      <div class="input-data" [ngClass]="{'activeTab': selectedCustomTab == 'inputData'}" fxlayout="row"
        fxLayoutAlign="center center" (click)="switchTab('inputData')">
        <span>Input Data</span>
      </div>
      <div class="spanner-one"></div>
      <div class="output-data" [ngClass]="{'activeTab': selectedCustomTab == 'outputData'}" fxlayout="row"
        fxLayoutAlign="center center" (click)="switchTab('outputData')">
        <span>Output Data
        </span>
      </div>
      <div class="spanner-two" fxLayoutAlign="end center">
        <div class="generate-file-btn" matTooltipPosition="above"
          matTooltip="To generate output file please clear all filters"
          [matTooltipDisabled]="isObjectEmpty(this.sortObject) && isObjectEmpty(this.filterObject)">
          <button mat-flat-button color="primary"
            [disabled]="!isObjectEmpty(this.sortObject) || !isObjectEmpty(this.filterObject) || flagForDisabling"
            (click)="generateOutputFile()">
            <!-- [disabled]="jssOutputService.isSampleBatch" -->
            Generate output file
          </button>
        </div>
      </div>
    </div>

    <div class="filters-table-wrapper">
      <!-- filter section -->
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center" class="filter-section"
        *ngIf="selectedCustomTab == 'outputData'">
        <!-- 1. filters left -->
        <div [style]="!selectedColumns?.length ? 'cursor: pointer;' : ''" fxLayout="row"
          fxLayoutAlign="space-between center" style="width: 100%">

          <!-- function search -->
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

            <div style="height: 42px" (click)="!selectedColumns?.length ? openColumnDropdown() : ''"
              class="appear-out query-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
                <!-- <img *ngIf="!selectedColumns?.length" class="search-img" src="../../../../assets/images/search.svg"
                alt="" /> -->
                <span style="font: italic 400 18px/27px IBM Plex Sans;">fx</span>
                <!-- <span class="search-placeholder" *ngIf="!selectedColumns?.length">Search here...</span> -->
                <mat-select #columnSelect placeholder="Type function or query here" class="custom-hide-arrow"
                  [class.select-col-active]="selectedColumns?.length" disableOptionCentering="true"
                  panelClass="drop-down-panel " (selectionChange)="onSelectSearchColumn()"
                  (openedChange)="onOpenCloseColumnPanel($event)" [(ngModel)]="selectedColumns" multiple>
                  <mat-select-trigger>
                    {{ selectedColumns?.[0] | truncate:25 || '' }}
                    <span *ngIf="(selectedColumns?.length || 0) > 1" class="example-additional-selection">
                      (+{{ (selectedColumns?.length || 0) - 1 }})
                    </span>
                  </mat-select-trigger>

                  <div fxLayout="column" fxLayoutGap="15px" class="filter-options-container"
                    fxLayoutAlign="space-around stretch">
                    <mat-form-field appearance="outline" class="inner-search">
                      <input matInput placeholder="Search column" #searchVal name="col-search"
                        (keyup)="filterList($event.target.value)" (keydown)="$event.stopPropagation()"
                        [(ngModel)]="columnSearchText" />
                      <mat-icon matPrefix svgIcon="search" style="margin: 0 5px; height: 20px"></mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="action-option" fxFlex="row" fxLayoutAlign="space-between center">
                    <button mat-button class="filter-reset-icon" (click)="clearAll()">
                      <span>Clear all</span>
                    </button>
                  </div>

                  <mat-option *ngFor="let option of allFilterColumns" [value]="option.name"
                    [disabled]="option.disabled">
                    {{ option.name }}
                  </mat-option>
                  <div *ngIf="allFilterColumns?.length == 0" class="no-result-message" fxLayout="row"
                    fxLayoutAlign="center center">
                    <span> No Results Found </span>
                  </div>
                </mat-select>

                <mat-select (selectionChange)="onSelectCondition()" class="custom-hide-arrow" style="width: unset"
                  [class.select-condtn-active]="selectedColumns?.length" panelClass="drop-down-panel condition-filter"
                  *ngIf="selectedColumns?.length" [(ngModel)]="selectedCondition" disableOptionCentering="true">
                  <mat-option [value]="each.value" *ngFor="let each of conditions">{{ each.label }}</mat-option>
                </mat-select>

                <div *ngIf="
                  selectedColumns?.length &&
                  selectedCondition !== 'none' &&
                  !isSearchEntered
                " fxLayout="row" fxLayoutAlign="space-between center" class="search-container-filter">
                  <!-- <mat-form-field appearance="none" class="search-filter"> -->
                  <input autofocus [(ngModel)]="searchQuery" [disabled]="!selectedColumns" id="search" matInput
                    [placeholder]="selectedColumns ? 'Search...' : ''" name="searchVal" autocomplete="off"
                    (keyup.enter)="searchQuery?.trim() ? onEnterSearch() : ''" />
                  <!-- <mat-icon matPrefix svgIcon="search"></mat-icon> -->
                  <!-- </mat-form-field> -->
                </div>
                <div (click)="editSearch()" *ngIf="isSearchEntered && searchQuery" class="search-input-active">
                  <span>{{ searchQuery }}</span>
                </div>
              </div>
              <div style="padding: 5px">
                <img src="../../../../assets/images/filled-close.svg" (click)="clearSearchFilter($event)"
                  *ngIf="selectedColumns?.length" class="clear-search" />
              </div>
            </div>
            <!--- Icons -->

            <button [disabled]="flagForDisabling" matTooltip="Custom edit" matTooltipPosition="above" matTooltipShowDelay="500" class="appear-out"
              fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" style="padding: 10.5px 12px;">
              <mat-icon svgIcon="custom-edit-icon"></mat-icon>
            </button>
            <button [disabled]="flagForDisabling" matTooltip="Manual mapping" matTooltipPosition="above" matTooltipShowDelay="500" class="appear-out"
              fxLayout="row" fxLayoutAlign="center center" style="padding: 9px 12px;">
              <mat-icon svgIcon="manual-mapping-icon"></mat-icon>
            </button>
            <button [disabled]="flagForDisabling" matTooltip="Version history" matTooltipPosition="above" matTooltipShowDelay="500" class="appear-out"
              fxLayout="row" fxLayoutAlign="center center" style="padding: 9px 12px;" (click)="openVersionHistory()">
              <mat-icon  svgIcon="version-history-icon"></mat-icon>
            </button>
          </div>

          <div fxLayout="row" class="data-table-actions" fxLayoutGap="10px">
            <button (click)="resetSortForAllColumns()" *ngIf="!isObjectEmpty(sortObject)" mat-stroked-button
              color="primary">
              Reset sort
            </button>
            <button (click)="resetFilterForAllColumns()" *ngIf="!isObjectEmpty(filterObject)" mat-stroked-button
              color="primary">
              Clear all filters
            </button>
          </div>
        </div>
      </div>

      <!--- input data table -->
      <app-input-data-table [inputDataTableContent]="inputDataTableContent"
        (inputTablePaginationEvent)="onInputTablePaginationEvent($event)"
        (inputTableGoToPageEvent)="goToPageInputTable($event)"
        *ngIf="selectedCustomTab == 'inputData' && !isInputDataTableLoading"></app-input-data-table>

      <!-- input data table skeleton -->
      <!-- <app-input-data-table-skeleton-loader *ngIf="selectedCustomTab == 'inputData' && isInputDataTableLoading">
      </app-input-data-table-skeleton-loader> -->

      <!-- output data table -->
      <div class="data-table" *ngIf="selectedCustomTab == 'outputData'">
        <div fxLayout="row" class="sticky-header" #scrollOne *ngIf="(!isHeaderLoading && inputHeaders?.length > 0)">
          <div fxLayout="column" class="index-column">
            <div class="header-type" matTooltipPosition="above" matTooltip="Input Header"
              matTooltipClass="dark-bg-tooltip">IH</div>
            <div class="header-type" matTooltipPosition="above" matTooltip="Output Header"
              matTooltipClass="dark-bg-tooltip">OH</div>
            <div class="header-type"></div>
          </div>
          <div *ngFor="let header of inputHeaders; let headerIndex = index" class="each-header-col" fxLayout="column"
            style="flex-grow: 1; flex-basis:0">
            <ng-container>
              <!-- header line 1-->
              <div matTooltipPosition="above" [matTooltip]="
                     header['input_columns']|joinStrings:header['delimiter']" contentOverflowTooltip
                #c="isContentOverflowing" [matTooltipDisabled]="!c.isContentOverflowing"
                class="cells truncate data-table-header-1"
                [ngStyle]="{'width.px': inputHeaders.length <= totalColsInAScreen? 'auto' : 200}">
                {{
                    header['input_columns']|joinStrings:header['delimiter']
                  }}
              </div>
              <!-- header line 2-->
              <div class="cells truncate data-table-header-2" fxLayout="row" fxLayoutAlign="space-between center"
                [ngStyle]="{'width.px': inputHeaders.length <= totalColsInAScreen? 'auto' : 200}">
                <div class="label" matTooltipPosition="above" [matTooltip]="
                header['output_column']" contentOverflowTooltip #d="isContentOverflowing"
                  [matTooltipDisabled]="!d.isContentOverflowing" matTooltipClass="dark-bg-tooltip">{{
                    header.output_column 
                  }}</div>

                <div *ngIf="header['data_quality'].toString() == '0' || header['data_quality']" class="fill-rate"
                  [ngClass]="{
                    'fill-red': (header['data_quality'] > -1 && header['data_quality'] < 50),
                    'fill-yellow': header['data_quality'] >= 50 && header['data_quality'] < 100,
                    'fill-green': header['data_quality'] >= 100
                  }" matTooltipPosition="above" matTooltip="Fill Rate" matTooltipClass="dark-bg-tooltip">
                  {{ header['data_quality'].toFixed(0) + '%' }}
                </div>

              </div>
              <!-- header line 3-->
              <div class="cells truncate data-table-header-3" [id]="'div'+header['column_index']" fxFlex
                fxLayoutAlign="space-between center"
                [ngStyle]="{'width.px': inputHeaders.length <= totalColsInAScreen? 'auto' : 200}">
                <span>{{
                    header['column_index'] 
                  }}</span>
                <button [disabled]="flagForDisabling" class="sort-icon-wrapper" [ngClass]="{
                  'sort-icon-bg': header['column_index'] == sortActiveForColumnIndex, 'disabled-sort-icon-wrapper': flagForDisabling
                }" (click)=" sortAndFilterAppliedFor(header, $event); ">
                  <img *ngIf="header['data_quality']" [id]="header['column_index']" #sortButton
                    
                    src="../../../../assets/images/outputDataview/sort.svg" matTooltipPosition="above"
                    matTooltip="Sort & Filter" matTooltipClass="dark-bg-tooltip" />
                </button>

              </div>
            </ng-container>
          </div>
        </div>
        <!-- body -->
        <div class="data-table-body" #scrollTwo (mouseenter)="updateCurrentElement('scrollTwo')"
          [ngStyle]="{'max-height.px': screenHeight>900 ? 525: 350}" (scroll)="updateScroll($event)" fxLayout="row"
          *ngIf="(!isTableContentLoading && dataTableRows?.length > 0)">
          <div class="row-cell-index-wrapper" fxLayout="column">
            <div *ngFor="let row of dataTableRows; let i=index" [id]="row.row_no" class="row-cell-index">
              {{row.row_no}}
            </div>
          </div>
          <div class="data-row" fxLayout="column">
            <div class="row-cell-wrapper" *ngFor="let row of dataTableRows; let i=index" fxLayout="row">
              <ng-container *ngFor="let header of inputHeaders">
                <!-- (mouseover)="changeStyle($event)"
                  (mouseout)="changeStyle($event)" -->
                <div class="row-cell truncate" [attr.data-column-index]="header.column_index" matTooltipPosition="above"
                  [matTooltip]="row[header.output_column]" contentOverflowTooltip #e="isContentOverflowing"
                  [matTooltipDisabled]="!e.isContentOverflowing" [attr.data-row-index]="row.row_no"
                  (click)="editCell($event)"
                  [ngStyle]="{'flex-grow': inputHeaders.length <= totalColsInAScreen? 1 : 0}">
                  {{row[header.output_column]}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- sort and filter menu for headers -->
        <!-- style="left: 200px; top: 50px;" -->
        <!-- [ngStyle]="{'left': leftOffset+ 'px', 'top': topOffset+ 'px'}" -->
        <div class="sort-and-filter-div" id="sortFilterMenu"
          [ngStyle]="{'left': leftOffset+ 'px', 'top': topOffset+ 'px', 'max-height.px': screenHeight<800 ? 200: auto}"
          *ngIf="sortActive" #sortFilterMenu>
          <div class="sort-part" fxLayout="column" fxLayoutGap="10px">
            <span>Sort by <span style="font-size: 12px; font-weight: 300;">(view only)</span></span>
            <mat-radio-group name="opList" fxLayout="column" class="radio-btns" fxLayoutGap="8px"
              [value]="chosenSortOrder ? chosenSortOrder: defaultSortValue">
              <mat-radio-button [value]=option.value (change)="getSortingOrder($event)"
                *ngFor="let option of sortOptions">{{option.display}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="filter-part" fxLayout="column" fxLayoutGap="10px">
            <span>Filter <span style="font-size: 12px; font-weight: 300;">(view only)</span></span>
            <form [formGroup]="filterForm" #filterFormTemplate>
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-select name="status" panelClass="top-offset-mat-select" id="sortFilterSelect"
                  placeholder="Select a condition" formControlName="selectACondition"
                  (selectionChange)="onChangeOfCondition($event)" disableOptionCentering
                  (click)="$event.stopPropagation()">
                  <mat-option (click)="$event.stopPropagation()" *ngFor="let condition of conditions"
                    [value]="condition.value">
                    {{ condition.label }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
              </mat-form-field>
              <mat-form-field appearance="outline" style="width: 100%"
                *ngIf="filterForm.controls['selectACondition'].value == 'contains' || filterForm.controls['selectACondition'].value == 'does not contain'">
                <input matInput placeholder="" value="" formControlName="text"
                  [attr.required]="filterForm.controls['selectACondition'].value == 'contains' || filterForm.controls['selectACondition'].value == 'does not contain'">
              </mat-form-field>
            </form>
            <div fxLayout="row" fxLayoutAlign="space-between center"
              *ngIf="filterForm.controls['selectACondition'].value">
              <button fxFlex="48" (click)="clearColumnFilter()" mat-stroked-button color="primary">
                Clear
              </button>
              <button fxFlex="48" [disabled]="!filterForm.valid" (click)="applyColumnFilter()" mat-flat-button
                color="primary">
                Apply
              </button>
            </div>
          </div>
        </div>
        <!-- </ng-template> -->
      </div>

      <!-- output data table skeleton -->
      <data-table-skeleton-loader [isInputDataTableLoading]="isInputDataTableLoading"
        [isHeaderLoading]="isHeaderLoading" [isTableContentLoading]="isTableContentLoading">
      </data-table-skeleton-loader>

      <!-- no results -->
      <div
        *ngIf="dataTableRows && dataTableRows.length == 0 && selectedCustomTab == 'outputData' && !isBusy || inputDataTableRows && inputDataTableRows.length == 0 && selectedCustomTab == 'inputData' && !isInputDataTableLoading"
        class="no-data-available" fxLayout="row" fxLayoutAlign="center center">
        <span> No Results Found </span>
      </div>

      <!-- error occured -->
      <div
        *ngIf="(!dataTableRows && !isBusy && selectedCustomTab == 'outputData') || (!inputDataTableRows && !isInputDataTableLoading && selectedCustomTab == 'inputData')"
        class="no-data-available" fxLayout="row" fxLayoutAlign="center center">
        <span> Some error occured. Please try again later. </span>
      </div>


      <!--2. pagination right-->
      <div class="pagination-wrapper" fxLayoutAlign="end center"
        *ngIf="selectedCustomTab == 'outputData' && !isHeaderLoading && !isTableContentLoading && dataTableRows && dataTableRows.length > 0">
        <div class=" page-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
          <span>Page: </span>
          <div class="page-number" fxLayout="row" fxLayoutAlign="center center" contenteditable="true"
            (input)="currentPageValue = $event.target.textContent" [textContent]="currentPageValue"
            (keyup.enter)="goToPage($event)" (blur)="goToPage($event)">
          </div>
        </div>
        <mat-paginator class="data-table-paginator" [length]="dataTableTotalRows" [pageSize]="dataTableSize"
          [pageIndex]="dataTablePage - 1" [pageSizeOptions]="[5, 10, 20, 25, 100]" (page)="onPaginationEvent($event)"
          [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </div>

    <!-- Version History   -->
    <app-version-history [ngStyle]="showVersionHistory?{'display': 'block'}: {'display': 'none'} ">
    </app-version-history>
  </div>
