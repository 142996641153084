import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  TemplateRef,
  Renderer2,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ScrollToBottomDirective } from 'src/app/_directives/scroll-to-bottom.directive';
import { InterimOutputService } from 'src/app/_services/interim-output.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { SidePanelService } from 'src/app/_services/side-panel.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

interface ColumnMap {
  column_map_id: number;
  value: string;
  valid_values: boolean;
}

@Component({
  selector: 'app-interim-output',
  templateUrl: './interim-output.component.html',
  styleUrls: ['./interim-output.component.scss'],
})
export class InterimOutputComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  outputData: string[][] = [];
  selectedColReq: string;
  selectedDatatype: string;
  selectedMinimumValue: number | null;
  selectedMaximumValue: number | null;
  opColumnList: Array<any> = [];
  selectedPromptVal: string;
  selectedColumnHeader: string;
  selected: Array<any> = [];
  isSideModelOpen: boolean;
  selectedColumnId: number;
  delimeterValue: string;
  delimiterForm: FormGroup;
  columnSearchValue: string;
  enableSaveBtnOnSingleSelection: boolean;
  activeTab: string;
  report: string | null;
  taskName: string | null;
  @ViewChild(ScrollToBottomDirective)
  private scrollToBottomDirective!: ScrollToBottomDirective;
  prevTaskName: string;
  activeColObj: any;
  isSelected: boolean = false;

  constructor(
    private router: Router,
    private interimService: InterimOutputService,
    private activatedRoute: ActivatedRoute,
    private loader: LoadingService,
    private snackbar: SnackbarService,
    private sidePanelService: SidePanelService,
    private fb: FormBuilder,
    private renderer2: Renderer2
  ) {}
  @ViewChild('vaildValueBottomSheet') vaildValueBottomSheet: TemplateRef<any>;
  @ViewChild('propertiesBottomSheet') propertiesBottomSheet: TemplateRef<any>;

  activeOrgID: string | null;
  batchID: string | null;
  taskStatus: string;
  isShowViewReport: boolean = false;
  checkRedisStatus: Subscription;
  ngOnInit(): void {
    this.loader.setLoading(true);
    this.activeOrgID = localStorage.getItem('currentOrganizationCode');
    this.report = localStorage.getItem('report');
    const isShowViewReport = localStorage.getItem('isPrevSetBatch');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.batchID = params['batch_id'];
      this.taskStatus = params['task_status'];
      this.taskName = params['task_name'];
      this.getStatus(this.activeOrgID, this.batchID);
      if (this.report) {
        this.openReportPanel();
      }
      if (isShowViewReport) {
        this.isShowViewReport = true;
      }
    });
    if (!this.taskName) {
      this.taskName = localStorage.getItem('task_name');
    }
    this.delimiterForm = this.fb.group({
      delimeterValue: ['', [Validators.required, Validators.maxLength(1)]],
    });
  }

  // Check redis status with a loading indicator
  holdStatusTimer: NodeJS.Timeout;
  // getStatus(orgID: any, batchID: any) {
  //   this.loader.setLoading(true);
  //   this.interimService.getStatus(orgID, batchID).subscribe({
  //     next: (res: any) => {
  //       console.log(res.input_redis_status);
  //       if (res.input_redis_status === 'completed') {
  //         clearInterval(this.holdStatusTimer);
  //         console.log('interval cleared');
  //         this.getActionHistory(this.activeOrgID, this.batchID);
  //         this.getTemplates(this.activeOrgID);
  //       } else {
  //         console.log('call after 5s');
  //         this.holdStatusTimer = setInterval(() => {
  //           this.getStatus(this.activeOrgID, this.batchID);
  //         }, 5000);
  //       }
  //     },
  //     error: (error: HttpErrorResponse) => {
  //       console.log(error);
  //       this.loader.setLoading(false);
  //       this.snackbar.showSnackbar(error.error.detail, '', 'error');
  //       setTimeout(() => {
  //         this.router.navigate(['/dashboard']);
  //       }, 1000);
  //     },
  //   });
  // }

  /** poll redis api ststus api every 5s until the status is completed */
  getStatus = (orgID: any, batchID: any) => {
    this.checkRedisStatus = timer(0, 5000)
      .pipe(switchMap(() => this.interimService.getStatus(orgID, batchID)))
      .subscribe({
        next: (res: any) => {
          if (res.input_redis_status === 'completed') {
            this.checkRedisStatus.unsubscribe();
            this.getActionHistory(this.activeOrgID, this.batchID);
            this.getTemplates(this.activeOrgID);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loader.setLoading(false);
          this.snackbar.showSnackbar(error.error.detail, '', 'error');
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1000);
        },
      });
  };

  isFullScreen: boolean = false;
  ngAfterViewInit(): void {
    this.eachlog.changes.subscribe(() => {
      this.findActiveLog();
    });
  }

  ngOnDestroy() {
    clearInterval(this.holdStatusTimer);
    if (this.holdSelected) {
      clearTimeout(this.holdStatusTimer);
    }
  }

  // Accessing the form control
  get delimeterValueControl() {
    return this.delimiterForm.get('delimeterValue');
  }

  columnsList: Array<any> = [];
  outputHeaders: Array<any> = [];
  opHeaderList: Array<any> = [];
  isNoIpFilter: boolean = false;
  isValidValueFilter: boolean = false;

  // Fetch list of columns for a task
  getColumns(
    orgID: any,
    batchID: any,
    noInputMapping?: boolean,
    validValues?: boolean,
    columnSearchValue?: any,
    msgID?: any
  ) {
    this.loader.setLoading(true);
    this.columnsList = [];
    this.inputHeaders = [];
    this.opHeaderList = [];
    this.outputHeaders = [];
    this.mappedColums = [];

    this.interimService
      .getColumns(
        orgID,
        batchID,
        noInputMapping,
        validValues,
        columnSearchValue,
        msgID
      )
      .subscribe({
        next: (res: any) => {
          this.columnsList = res.options_list;

          this.inputHeaders = [{}, ...res.input_columns];
          this.opColumnList = res.output_columns;

          this.opHeaderList = [
            {
              column_map_id: null,
              value: null,
              valid_values: null,
            },
            ...this.opColumnList,
          ];
          const outputList = res.output_columns.map((each: any) => each.value);
          this.outputHeaders = ['Output Header', ...outputList];
          this.getData(orgID, batchID, noInputMapping, validValues, msgID);
          //
          this.userPrompt = '';
          //
        },
        error: (error: HttpErrorResponse) => {
          this.loader.setLoading(false);
          this.snackbar.showSnackbar(error.error.detail, '', 'error');
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1000);
        },
      });
  }
  concatenateValues(values: string[], delimiter: string): string {
    if (!values || values.length === 0) {
      return '';
    }

    // Use slice to exclude the last element from join
    return (
      values.slice(0, -1).join(delimiter) +
      (values.length > 1 ? delimiter : '') +
      values.slice(-1)
    );
  }

  truncateValues(value: string, maxLength: number): string {
    if (value.length <= maxLength) {
      return value;
    } else {
      // Truncate the value and add ellipsis or any indicator
      return value.substring(0, maxLength - 3) + '...';
    }
  }
  defaultPrompts = [];
  chatHistoryList = [];
  actionsList = [];

  onPromptValChange(val: string) {
    this.activeTab = val;
  }

  // Fetch predefined templates
  getTemplates(orgID: any) {
    this.interimService.getTemplates(orgID).subscribe((res: any) => {
      this.defaultPrompts = res;
    });
  }

  // get list of conversations
  getAllConversations(orgID: any, batchID: any, isPromptSent: boolean = false) {
    this.interimService
      .getConversations(orgID, batchID)
      .subscribe((res: any) => {
        //
        // this.userPrompt = '';
        //
        this.chatHistoryList = res;
        if (this.chatHistoryList.length) {
          this.activeTab = 'chats';
        } else {
          this.activeTab = 'templates';
        }
        if (this.scrollToBottomDirective) {
          setTimeout(() => {
            this.scrollToBottomDirective.scrollToBottom();
          }, 1500);
        }
        if (isPromptSent) {
          //
          //
          // polling flow iff user sent a msg
          this.startPolling(res);
        }
        // next get all actions history
      });
  }

  startPolling(res: any) {
    // Check if the last entry in the conversation has interim output other than 'inprogress'
    const currentPrompt =
      this.chatHistoryList[res.length - 1]['interim_output_generated'];
    // Optionally, perform any final actions based on the interim output state
    if (currentPrompt === 'completed') {
      // this.loader.setLoading(false);
      //

      //
      this.getActionHistory(this.activeOrgID, this.batchID);
    } else if (currentPrompt !== 'in_progress') {
      // any other status off the loader
      this.getActionHistory(this.activeOrgID, this.batchID);
      // this.loader.setLoading(false);
    } else {
      // Recursively call the same method after 5 seconds
      setTimeout(() => {
        this.getAllConversations(this.activeOrgID, this.batchID, true);
      }, 5000);
    }
  }

  // Fetch list of actions
  getActionHistory(orgID: any, batchID: any) {
    this.interimService
      .getActionHistory(orgID, batchID)
      .subscribe((res: any) => {
        this.actionsList = res.actions;
        if (this.actionsList.length) {
          this.activeTab = 'chats';
          this.validMsgID = res.last_valid_conversation;
          this.activeActionView = JSON.parse(
            JSON.stringify(res.last_valid_conversation)
          );
          this.getLastValidAction();
        } else {
          this.getTemplates(this.activeOrgID);
          // this.activeTab = 'templates';
          this.getAllConversations(this.activeOrgID, this.batchID);
          this.getColumns(this.activeOrgID, this.batchID);
        }
      });
  }

  // Find the active log from list of change log
  @ViewChildren('eachlog') eachlog: QueryList<ElementRef>;
  findActiveLog() {
    if (this.eachlog) {
      this.eachlog.forEach((eachDiv) => {
        const hasActiveClass =
          eachDiv.nativeElement.classList.contains('active-action');

        if (hasActiveClass) {
          setTimeout(() => {
            this.scrollToActiveLog(eachDiv.nativeElement);
          }, 1500);
        }
      });
    }
  }

  // auto scroll to active log
  scrollToActiveLog(element: HTMLElement) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }

  // View interim output of an action
  activeActionView: any;
  onView(actionData: any) {
    this.activeActionView = actionData.conversation_uid;
    this.getColumns(
      this.activeOrgID,
      this.batchID,
      false,
      false,
      '',
      actionData.conversation_uid
    );
  }

  // Undo an action and update the interim output
  onUndo(actionData: any) {
    this.loader.setLoading(true);
    const payload = {
      batch_id: this.batchID,
      conversation_uid: actionData.conversation_uid,
    };
    this.interimService.undoAction(this.activeOrgID, payload).subscribe({
      next: (res: any) => {
        this.getAllConversations(this.activeOrgID, this.batchID, true);
        // persist flow. entry point
      },
      error: (err: any) => {},
    });
  }

  // Revert an action and update the interim output
  onRevert(actionData: any) {
    this.loader.setLoading(true);
    // console.log(actionData);
    const payload = {
      batch_id: this.batchID,
      conversation_uid: actionData.conversation_uid,
    };
    this.interimService.revertAction(this.activeOrgID, payload).subscribe({
      next: (res: any) => {
        this.getAllConversations(this.activeOrgID, this.batchID, true);
        // persist flow. entry point
      },
      error: (err: any) => {},
    });
  }

  // Rerun an action and update the interim output
  onRerun(actionData: any) {
    this.loader.setLoading(true);
    // console.log(actionData);
    const payload = {
      batch_id: this.batchID,
      conversation_uid: actionData.conversation_uid,
    };
    this.interimService.rerunAction(this.activeOrgID, payload).subscribe({
      next: (res: any) => {
        this.getAllConversations(this.activeOrgID, this.batchID, true);
        // persist flow. entry point
      },
      error: (err: any) => {},
    });
  }

  validMsgID = '';
  validAction: any;
  getLastValidAction() {
    if (this.validMsgID) {
      this.getAllConversations(this.activeOrgID, this.batchID);
      this.getColumns(
        this.activeOrgID,
        this.batchID,
        false,
        false,
        '',
        this.validMsgID
      );
    } else {
      // no valid id, get default data
      this.getTemplates(this.activeOrgID);
      this.getAllConversations(this.activeOrgID, this.batchID);
      this.getColumns(this.activeOrgID, this.batchID);
    }
  }

  disableSendBtn: boolean = true;
  // handle send button based by validating the prompt
  onPromptSet(e?: any) {
    e?.keycode == '13' && this.userPrompt?.trim() === ''
      ? e.preventDefault()
      : '';
    if (this.userPrompt?.trim() === '') {
      this.disableSendBtn = true;
      return;
    }
    if (this.userPrompt.length > 0) {
      this.disableSendBtn = false;
    } else {
      this.disableSendBtn = true;
    }
  }

  // switch the tab to Chats
  onClickOfEachPrompt = (chatRequest: any) => {
    this.activeTab = 'chats';
    this.userPrompt = chatRequest;
    this.onPromptSet();
  };
  userPrompt: string = '';
  isServiceBusy: boolean = false;

  onKeydownPrompt(event: KeyboardEvent) {
    if (
      event.key === 'Enter' &&
      this.userPrompt?.trim() !== '' &&
      !event.shiftKey
    ) {
      event.preventDefault(); // Prevents the default newline behavior

      // Enter key is pressed without Shift key
      this.sendMessage();
    }
  }

  // Save the user query
  sendMessage() {
    if (this.userPrompt?.trim() === '' || this.userPrompt === '\n') {
      return;
    }
    this.loader.setLoading(true);
    this.disableSendBtn = true;
    this.isServiceBusy = true;
    const payload = {
      batch_id: this.batchID,
      chat_request: this.userPrompt,
    };
    this.interimService.saveChat(this.activeOrgID, payload).subscribe({
      next: (res: any) => {
        this.onPromptSet();
        this.getAllConversations(this.activeOrgID, this.batchID, true);
        this.isServiceBusy = false;
      },
      error: (err) => {
        this.isServiceBusy = false;
      },
    });
  }

  // Fetch data for columns and hide the banner
  getData(
    orgID: any,
    batchID: any,
    noInputMapping?: boolean,
    validValues?: boolean,
    msgID?: any
  ) {
    this.outputData = [];
    this.interimService
      .getData(orgID, batchID, noInputMapping, validValues, msgID)
      .subscribe((res: any) => {
        this.outputData = [[...this.outputHeaders], ...res];
        this.loader.setLoading(false);
        setTimeout(() => {
          this.isFullScreen = true;
        }, 20000);
      });
  }

  mappedColums: Array<any> = [];

  // Save the mapping for entire task(all rows) and navigate to dashboard
  saveMapping() {
    this.loader.setLoading(true);
    const data = {
      input_columns: this.mappedColums,
    };
    this.interimService
      .saveMapping(this.activeOrgID, this.batchID, data)
      .subscribe((res: any) => {
        this.loader.setLoading(false);
        this.snackbar.showSnackbar(res.detail, '', 'success');
        setTimeout(() => {
          // this.getColumns(this.activeOrgID, this.batchID);
          this.router.navigate(['/dashboard']);
        }, 1000);
      });
  }

  inputHeaders: Array<any> = [];
  editedCell: string = '';
  editingRow: number | null = null;
  editingColumn: number | null = null;
  oldCellValue: string = '';

  isEditing(row: number, col: number): boolean {
    return this.editingRow === row && this.editingColumn === col;
  }

  @ViewChild('editInputField') editInputFieled: ElementRef<HTMLInputElement>;

  selectedOptions: string[][] = [];

  holdSelected: Array<any> = [];
  // Open sidepanel to display the mappings of a column
  openSidePanel(i: any) {
    this.columnSearchValue = '';
    this.isViewReport = false;
    this.isSideModelOpen = true;
    this.selectedColumnHeader = this.opColumnList[i - 1].value;
    this.selected = this.inputHeaders[i].selected_values;
    this.holdSelected = JSON.parse(
      JSON.stringify(this.inputHeaders[i].selected_values)
    );
    if (this.inputHeaders[i].selected_values.length) {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
    this.delimeterValue = this.inputHeaders[i].delimiter; // to populate delimeter in side panel
    this.selectedColumnId = this.inputHeaders[i].column_map_id;

    this.sidePanelService.setShowNav(true);
    this.getNumberOfOptionSelected();
    this.getColumns(
      this.activeOrgID,
      this.batchID,
      false,
      false,
      '',
      this.validMsgID
    );
  }

  closeSidePanel() {
    this.selected = [];
    this.delimeterValue;
    this.sidePanelService.setShowNav(false);
    this.delimiterForm.enable();
  }
  /**
   * Opens the report panel and calls the 'getReport' method to fetch and display the report for the specified organization ID and batch ID.
   */
  isViewReport: boolean = false;
  openReportPanel() {
    localStorage.removeItem('report');
    this.isViewReport = true;
    this.getReport(this.activeOrgID, this.batchID);
  }

  closeReportPanel() {
    this.sidePanelService.setShowNav(false);
  }

  clearAll() {
    this.selected = [];
    this.delimiterForm.reset();
    this.getNumberOfOptionSelected();
  }
  // Save mapping for a column
  onUpdateOfColumnMapping = () => {
    this.loader.setLoading(true);
    const colUpdateOjb = {
      batch_id: this.batchID,
      column_map_id: this.selectedColumnId.toString(),
      selected_input_columns: this.selected,
      delimiter: this.delimeterValue,
    };

    this.interimService
      .updateColumnMapping(
        localStorage.getItem('currentOrganizationCode'),
        colUpdateOjb
      )
      .subscribe({
        next: (resp: any) => {
          this.closeSidePanel();
          this.selected = [];
          this.getAllConversations(this.activeOrgID, this.batchID, true);
        },
        error: (error) => {},
      });
  };
  getNumberOfOptionSelected = () => {
    if (this.selected.length <= 1) {
      this.delimiterForm.disable();
      this.delimiterForm.reset();
    } else {
      this.delimiterForm.enable();
    }
    if (this.selected.length == 0) {
      this.isSelected = false;
    }
    if (this.selected.length > 0) {
      this.isSelected = true;
    }
  };

  onSeachOfColumnVal = (searchText: any) => {
    this.columnSearchValue = searchText;
    if (searchText.length >= 3) {
      this.getSearchedList(
        this.activeOrgID,
        this.batchID,
        this.isNoIpFilter,
        this.isValidValueFilter,
        this.columnSearchValue,
        this.validMsgID
      );
    }

    if (!searchText.length) {
      this.getSearchedList(
        this.activeOrgID,
        this.batchID,
        this.isNoIpFilter,
        this.isValidValueFilter,
        this.columnSearchValue,
        this.validMsgID
      );
    }
  };

  clearSearch() {
    this.columnSearchValue = ''; // Reset the search value
    this.onSeachOfColumnVal(this.columnSearchValue);
  }

  //search columns list
  getSearchedList(
    orgID: any,
    batchID: any,
    noInputMapping?: boolean,
    validValues?: boolean,
    columnSearchValue?: any,
    msgID?: any
  ) {
    this.loader.setLoading(true);
    this.columnsList = [];

    this.interimService
      .getColumns(
        orgID,
        batchID,
        noInputMapping,
        validValues,
        columnSearchValue,
        msgID
      )
      .subscribe((res: any) => {
        this.columnsList = res.options_list;
        if (this.holdSelected.length) {
          this.selected = JSON.parse(JSON.stringify(this.holdSelected));
        }
        this.loader.setLoading(false);
      });
  }

  //get report
  reportData: any = {};
  getReport(orgID: any, batch_id: any) {
    this.loader.setLoading(true);
    this.interimService.getReport(orgID, batch_id).subscribe((res: any) => {
      this.reportData = res;
      this.prevTaskName = res.previous_settings_task;
      this.loader.setLoading(false);
      this.sidePanelService.setShowNav(true);
    });
  }
}
