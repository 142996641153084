import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  queryParam: any;
  inviteCode: any;
  orgList: any[] = [];
  currentUserOrg: any;
  currentUserOrgode: any;
  user: any;
  constructor(
    private userService: UserService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountSettingsService: AccountSettingsService,
    private snackbarService: SnackbarService,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.queryParam = params;
      this.inviteCode = params.invite;
    });
    this.currentUserOrgode = localStorage.getItem('currentOrganizationCode');
    this.userService.me().subscribe({
      next: (resp: any) => {
        this.user = resp;
        localStorage.setItem('user', JSON.stringify(resp));
        // Continue with your logic after fetching user data
        this.handleUserLoaded();
      },
      error: (error: any) => {
        // Handle error here
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  }

  handleUserLoaded = () => {
    // console.log(this.appConfig.getConfig().is_new_version);

    if (this.inviteCode) {
      this.router.navigate(['/invite-request'], {
        queryParams: { code: this.inviteCode },
      });
    } else {
      this.getMemberships();
      // 
      // console.log(this.appConfig.getConfig().is_new_version);
      // 
      this.router.navigate(['/dashboard']);
      // 
      // this.appConfig.getConfig().is_new_version ?  this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
      // 
    }
  };

  /**
   * When user login, first save the logged-in users organizationCode in LS.  And call permissions based on that orgnanization code.
   */

  getMemberships = () => {
    this.accountSettingsService.listMemberships().subscribe({
      next: (resp: any) => {
        this.orgList = resp.result;
        this.currentUserOrg = this.orgList[0];
        this.currentUserOrgode = this.currentUserOrg.organisation_code;

        if (!this.currentUserOrgode) {
          localStorage.setItem(
            'currentOrganizationCode',
            this.currentUserOrgode
          );
        }
        this.getPermissions();
      },
      error: (error: HttpErrorResponse) => {},
    });
  };

  getPermissions = () => {
    this.accountSettingsService
      .getPermissions(this.currentUserOrgode)
      .subscribe({
        next: (resp: any) => {
          localStorage.setItem('permissions', resp.permissions);
          localStorage.setItem('currentUserRole', resp.role);
        },
        error: (error: HttpErrorResponse) => {},
      });
  };
}
