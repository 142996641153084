import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { AppConfigService } from 'src/app/_services/app-config.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-invite-request-dialog',
  templateUrl: './invite-request-dialog.component.html',
  styleUrls: ['./invite-request-dialog.component.scss'],
})
export class InviteRequestDialogComponent implements OnInit {
  inviteCode: any;
  enableInviteActions: boolean = false;
  orgName: string;
  invitedBy: string;
  orgList: any;
  currentUserOrg: any;
  currentUserOrgode: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountSettingsService: AccountSettingsService,
    private router: Router,
    private snackbarService: SnackbarService,
    private appConfig: AppConfigService,
    public dialogRef: MatDialogRef<InviteRequestDialogComponent>
  ) {}

  ngOnInit(): void {
    this.enableInviteActions = this.data.isInviteRequest;
    this.inviteCode = this.data.code;
    this.getDetailsFromToken();
  }
  getDetailsFromToken = () => {
    this.accountSettingsService.getInvitationDetails(this.data.code).subscribe({
      next: (resp: any) => {
        this.orgName = resp.organisation_name;
        this.invitedBy = resp.invited_by;
      },
      error: (error: HttpErrorResponse) => {
        // 
        this.router.navigate(['/dashboard']);
        // this.appConfig.getConfig().is_new_version ?  this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
        // 
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };
  /**
   * accept/reject invite
   * @param obj
   */
  acceptOrRejectInvite = (is_accepted: boolean) => {
    let obj = {
      is_accepted: is_accepted,
    };
    this.accountSettingsService
      .acceptOrRejectInvite(this.inviteCode, obj)
      .subscribe({
        next: (resp: any) => {
          this.snackbarService.showSnackbar(resp.detail, '', 'success');
          this.dialogRef.close();
          // 
          this.router.navigate(['/dashboard']);
          // this.appConfig.getConfig().is_new_version ?  this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
          // 
          if (is_accepted) {
            this.getMemberships();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.snackbarService.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };
  getMemberships = () => {
    this.accountSettingsService.listMemberships().subscribe({
      next: (resp: any) => {
        this.orgList = resp.result;
        this.currentUserOrg = this.orgList[0];
        this.currentUserOrgode = this.currentUserOrg.organisation_code;
        localStorage.setItem('currentOrganizationCode', this.currentUserOrgode);
        this.getPermissions();
      },
      error: (error: HttpErrorResponse) => {
        // console.log(error, '.get() membership API error');
      },
    });
  };

  getPermissions = () => {
    this.accountSettingsService
      .getPermissions(this.currentUserOrgode)
      .subscribe({
        next: (resp: any) => {
          localStorage.setItem('permissions', resp.permissions);
          localStorage.setItem('currentUserRole', resp.role);
          this.router.navigate(['/account-settings'], {
            state: { showPopup: this.currentUserOrg.organisation_name },
          });

          // window.location.reload();
        },
        error: (error: HttpErrorResponse) => {
          // console.log(error);
        },
      });
  };
}
