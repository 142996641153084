import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnderscoreAsSpacePipe } from 'src/app/_pipes/underscore-as-space.pipe';
import { FileNamePipe } from 'src/app/_pipes/file-name.pipe';
import { HighlightTextPipe } from 'src/app/_pipes/highlight-text.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TruncatePipe } from 'src/app/_pipes/truncate.pipe';
import { SidePanelComponent } from 'src/app/_components/reused-components/side-panel/side-panel.component';

@NgModule({
  declarations: [
    UnderscoreAsSpacePipe,
    FileNamePipe,
    HighlightTextPipe,
    TruncatePipe,
    SidePanelComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    NgxDropzoneModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UnderscoreAsSpacePipe,
    FileNamePipe,
    HighlightTextPipe,
    TruncatePipe,
    NgxDropzoneModule,
    SidePanelComponent,
  ],
})
export class SharedModule {}
