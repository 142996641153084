<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content" fxLayout="column">
    <div class="email-verification-alert" *ngIf="emailVerify === false" fxLayout="row" fxLayoutAlign="center center">
      <img src="../../../.././assets/images/danger-icon.svg" />A verification
      link has been emailed to you. Please verify to start using the app. To
      resend link, &nbsp;
      <a (click)="reSendEmail()" class="app-click-link">click here</a>
      <span class="email-verification-alert-closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
    </div>
    <!-- Title and subtile -->
    <div class="header" fxLayout="row" fxFlex>
      <div fxFlex="30">
        <h1 class="title">Dashboard</h1>
        <p class="subtitle">View and filter batch details.</p>
      </div>
      <div fxFlex="70" fxLayoutAlign="end center">
        <!--  -->
        <button style="height: 42px;
        padding: 10.5px 12px 10.5px 12px;
        width: 136px;
        border-radius: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        max-width: 18%;
        line-height: 21px;"   matTooltip=" Upload files for transformation" matTooltipPosition="above"
        mat-raised-button color="primary" type="button" class="upload-btn" [disabled]="!emailVerify"
        [routerLink]="['/dashboard/upload-input-file']">
        
        Upload input file
        </button>
        <!-- skeleton loader for upload input file -->
        <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '150px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
        <!--  -->
        <!--  -->
      </div>
    </div>
    <!-- table skeleton loader start -->
    <!-- <app-dashboard-table-skeleton-loader></app-dashboard-table-skeleton-loader> -->
    <!-- table skeleton loader ends -->
    <div fxLayout="column" class="table-container">
      <form [formGroup]="filtersForm" fxLayout="column">
        <!-- Filters -->
        <div class="filter-section-conatiner" fxLayout="row" fxFlex="100" fxLayoutGap="15px"
          fxLayoutAlign="start center">
          <div class="search-container-filter" fxLayout="row" fxFlex="90" fxLayoutGap="8px" fxLayoutAlign="start center">
            <!-- skeleton loaders -->
            <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '500px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '40px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '40px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
            <!--  -->
            <!-- Search Field -->
            <mat-form-field style="padding-bottom: 0px !important ;
            border-radius: 8px;" appearance="outline" class="search-filter" fxFlex="40">
              <input style="    color: var(--neutral-50, #5D6778);
              font-family: 'IBM Plex Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
          " id="search" matInput placeholder="Search by Batch name, Batch ID or Tag" #searchVal name="searchVal"
                formControlName="searchFilter" (keyup)="search(getValue($event))" />
              <mat-icon matPrefix svgIcon="search"></mat-icon>
            </mat-form-field>
            <button [disabled]="disableForEmptyDataTable || isNotImplementedYet" fxLayout="row" fxLayoutAlign="center center"  matTooltip="Recipe list (coming soon..)" matTooltipPosition="above"
        [ngClass]="{'active': activeButton === 'notes', 'diabled-date-range-text': disableForEmptyDataTable}"
        style="height: 42px; padding: 22px 10px; border: 1px solid #DFE0E2; border-radius: 8px;"   
        mat-raised-button type="button" 
        (click)="setActiveButton('notes')">
            <img src="../../../../assets/images/updated-dashboard/notes.svg" style="margin-top: -3px;" />
          </button>

          <button [disabled]="disableForEmptyDataTable || isNotImplementedYet" fxLayout="row" fxLayoutAlign="center center"  matTooltip="Functions file upload (coming soon..)" matTooltipPosition="above"
                  [ngClass]="{'active': activeButton === 'frame', 'diabled-date-range-text': disableForEmptyDataTable}"
                  style="height: 42px; padding: 22px 10px; border: 1px solid #DFE0E2; border-radius: 8px;"   
                  mat-raised-button type="button" 
                  (click)="setActiveButton('frame')">
            <img src="../../../../assets/images/updated-dashboard/frame.svg" style="margin-top: -3px;" />
          </button>

          </div>

          <div fxLayout="row" fxFlex="10" fxLayoutAlign="end start">
            <ng-container *ngIf="!disableForEmptyDataTable">
              <button (click)="refreshDashboardTableContent()" fxLayout="row" fxLayoutAlign="center center"  matTooltip="Refresh" matTooltipPosition="above"
                  
                  style="height: 42px; padding: 22px 10px; border: 1px solid #DFE0E2; border-radius: 8px;"   
                  mat-raised-button type="button" 
                  >
            <img src="../../../../assets/images/updated-dashboard/restart.svg" style="margin-top: -3px;" />
          </button>
            </ng-container>
          <!-- skeleton loader for refresh button -->
          <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '42px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
          <!--  -->
            <!-- <button *ngIf="!showFullPageSkeletonLoader" style="height: 42px;
            width: 44px;
            border: 1px solid #DFE0E2;
            border-radius: 8px;"   
              mat-raised-button  type="button" >
              <img  src="../../../../assets/images/updated-dashboard/Linear/Arrows/restart.svg" style="margin-top: -3px;"  />
            </button> -->
            <!-- <div fxLayout="row" fxLayoutAlign="center center" style="height: 42px;
            width: 44px;
            padding: 12px 10px;
            border: 1px solid #DFE0E2;
            border-radius: 8px;">
              <img src="../../../../assets/images/updated-dashboard/Linear/Arrows/restart.svg"  />
            </div> -->
          </div>
        </div>

        <!-- new filter section starts ===================================== -->
         <div class="filter-section-conatiner" fxLayout="row" fxFlex="100" fxLayoutGap="15px"
          fxLayoutAlign="start center">
          <div style="margin-top: -4px !important;" fxLayout="row" fxFlex="80" fxLayoutGap="10px" fxLayoutAlign="start center">
            <!-- Uploaded by field -->
            <mat-form-field appearance="outline" class="auto-width" >
              <mat-select disableOptionCentering="true" name="uploaded-users" placeholder="Uploaded by"
                panelClass="drop-down-panel-upload-assignee" formControlName="uploadFilter"
                (closed)="onDropdownClosed()" multiple>
                <div fxLayout="row" fxLayoutGap="15px" class="filter-options-container"
                  fxLayoutAlign="space-around center">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <input style="margin-top: 5px;" id="search" matInput placeholder="Search by email" #searchUploadedByVal
                      name="searchUploadedByVal" [formControl]="name"
                      (keyup)="searchInUploadedBySelectDropdown($event)" />
                    <mat-icon matPrefix svgIcon="search"></mat-icon>
                    <mat-icon matSuffix style="cursor: pointer;" *ngIf="searchUploadedByVal.value"
                      (click)="clearSearchUploadedBy()">close</mat-icon>
                  </mat-form-field>
                </div>
                <div fxLayout="row" *ngIf="filteredUsers.length>0" fxLayoutAlign="space-between center"
                  class="select-and-clear-all">
                  <mat-checkbox class="custom-checkbox" [disableRipple]="true" formControlName="selectAll"
                    (change)="$event ? selectAllUsersUnderUploadedby($event) : null" [ngStyle]="{
                      color:
                        selectedUploadedByUserIds?.length == 0
                          ? '#506480'
                          : '#1042F1',
                      fontWeight:
                        selectedUploadedByUserIds?.length == 0 ? '500' : '600'
                    }" fxLayoutAlign="start center">
                    Select all
                  </mat-checkbox>
                  <span  class="filter-reset-btn" (click)="clearAllUploadedBy()"
                    [ngClass]="{ 'clear-all-disabled': selectedUploadedByUserIds.length == 0 }">Clear all</span>

                </div>

                <div *ngIf="filteredUsers?.length === 0" class="no-search-results-found-msg">
                  No Results Found
                </div>
                <mat-option (onSelectionChange)="getIndividualSelectedValuesForUploadedBy($event)"
                  *ngFor="let user of filteredUsers; let i = index" [value]="user.id">
                  <span matTooltip="{{ user.name }}" matTooltipPosition="above">{{ user.name }}</span>
                </mat-option>
              </mat-select>
              <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
            </mat-form-field>
            <!-- skeleton loader for uploaded by -->
            <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '150px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
            <!--  -->
            <!-- Uploaded by field end -->

            <!-- Assignee field -->
            <mat-form-field appearance="outline" class="auto-width">
              <mat-select name="assignee" placeholder="Assignee" panelClass="drop-down-panel-upload-assignee"
                formControlName="assigneeFilter" disableOptionCentering="true" (closed)="onAssigneeDropdownClosed()"
                multiple>
                <div fxLayout="row" fxLayoutGap="15px" class="filter-options-container"
                  fxLayoutAlign="space-around center">
                  <mat-form-field appearance="outline" fxFlex="100">
                    <input style="margin-top: 5px;" id="search" placeholder="Search by email" matInput #searchAssigneeVal
                      name="searchAssigneeVal" [formControl]="assignee" (keyup)="searchAssignee($event)" />
                    <mat-icon matPrefix svgIcon="search"></mat-icon>
                    <mat-icon matSuffix style="cursor: pointer;" *ngIf="searchAssigneeVal.value"
                      (click)="clearSearchAssignee()">close</mat-icon>
                  </mat-form-field>
                </div>

                <div class="select-and-clear-all" *ngIf="filteredAssignees.length>0" fxLayout="row"
                  fxLayoutAlign="space-between center">
                  <mat-checkbox class="custom-checkbox" [disableRipple]="true" formControlName="selectAllAssignee"
                    (change)="$event ? selectAllAssignee($event) : null" [ngStyle]="{
                      color:
                        selectedAssigniUserIds?.length == 0
                          ? '#506480'
                          : '#1042F1',
                      fontWeight:
                        selectedAssigniUserIds?.length == 0 ? '500' : '600'
                    }" fxLayoutAlign="start center">
                    Select all
                  </mat-checkbox>
                  <span class="filter-reset-btn" (click)="clearAllAssignee()"
                    [ngClass]="{ 'clear-all-disabled': selectedAssigniUserIds.length == 0 }">Clear all</span>

                </div>

                <div *ngIf="filteredAssignees?.length === 0" class="no-search-results-found-msg">
                  No Results Found
                </div>

                <mat-option (onSelectionChange)="getIndividualSelectedValuesForAssignee($event)"
                  *ngFor="let assignee of filteredAssignees; let i = index" [value]="assignee.id">
                  <span matTooltip="{{ assignee.name }}" matTooltipPosition="above">{{ assignee.name }}</span>
                </mat-option>
              </mat-select>
              <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
            </mat-form-field>
            <!-- skeleton loader for Assignee -->
            <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '150px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
            <!--  -->
            <!-- Assignee field ends-->
            <!-- status dropdown -->
            <mat-form-field appearance="outline" class="auto-width">
              <mat-select name="status" panelClass="drop-down-panel" placeholder="Status" formControlName="statusFilter"
                (selectionChange)="onChangeStatus()" disableOptionCentering>
                <mat-option *ngFor="let status of statusList" [value]="status.slug">
                  {{ status.display_name }}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
            </mat-form-field>
            <!-- skeleton loader for status -->
            <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '150px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
            <!--  -->
            <!-- Status Drop down ends -->
            <!-- Date Range-->
            <mat-form-field appearance="none" class="date-range-filter">
              <div (click)="picker.open()" fxLayout="row" fxLayoutAlign="start center" style="padding: 0 2px">
                
                <span fxLayout="row" *ngIf="!selectedStartDate || !selectedEndDate"
                  class="data-range-pseudo-placeholder" fxFlex="100">
                  <span [ngClass]="{'diabled-date-range-text': disableForEmptyDataTable, 'enabled-date-range-text': !disableForEmptyDataTable}" fxFlex="80" fxLayoutAlign="start center" fxFlex.md="70">Updated on</span>
                  <span fxFlex="20" fxLayoutAlign="center center">
                    <mat-icon fxLayoutAlign="end center" svgIcon="DownArrow" (click)="picker.open()" fxFlex fxFlex.md>
                    </mat-icon>
                  </span>
                </span>
                <span class="selected-date-display" *ngIf="selectedStartDate || selectedEndDate" matTooltip=" {{ selectedStartDate }} -
                  {{ selectedEndDate }}" matTooltipPosition="above">
                  {{ selectedStartDate }} -
                  {{ selectedEndDate }}
                </span>

                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                    <button mat-raised-button color="primary" matDateRangePickerApply
                      (click)="submitSelectedDateRange()" [disabled]="disableForEmptyDataTable">
                      Ok
                    </button>
                    <button mat-button matDateRangePickerCancel (click)="clearDate()" [disabled]="disableForEmptyDataTable">
                      Cancel
                    </button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>

                <mat-date-range-input style="display: none" [formGroup]="filtersForm" [rangePicker]="picker"
                  [max]="maxDate" [disabled]="disableForEmptyDataTable">
                  <input matStartDate readonly formControlName="startDate" />

                  <input matEndDate readonly formControlName="endDate" />
                </mat-date-range-input>
              </div>
            </mat-form-field>
            <!-- skeleton loader for updated on -->
            <!-- <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '150px',
                      height: '42px',
                      'border-radius': '8px',
                    }"
                  >
                  </ngx-skeleton-loader> -->
            <!--  -->
            <!-- Date range ends -->
            <!-- Clear -->
            <!-- <button fxFlex="4" fxFlex.md="4" type="button" mat-raised-button [disabled]="!emailVerify" class="clear-btn"
              (click)="clearAllFilter()">
              Clear
            </button> -->
            <!-- Clear button ends -->
            <!-- Refresh -->
            
            <!-- Refresh button ends -->
            <!-- Upload button -->
            <!-- <button fxFlex="18" fxFlex.md="18" matTooltip=" Upload files for transformation" matTooltipPosition="above"
              mat-raised-button color="primary" type="button" class="upload-btn" [disabled]="!emailVerify"
              [routerLink]="['/dashboard/upload-input-file']">
              <img src="../../../../assets/images/upload.svg" />
              Upload input file
            </button> -->
            <!-- Upload button ends -->
          </div>
          <ng-container *ngIf="!disableForEmptyDataTable">
            <div fxLayout="row" fxFlex="20" fxLayoutAlign="end center">
              <button *ngIf="selectedUploadedByUserIds.length!=0 || selectedAssigniUserIds.length!=0 || filtersForm.get('statusFilter')?.value || selectedStartDate!='' || selectedEndDate!=''" style="height: 42px;
                padding: 8px 12px;
                border-radius: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                line-height: 21px;color: #AA1414;
      border: 1px solid var(--error-70, #AA1414);"   
                mat-raised-button  type="button"  (click)="clearAllFilter()">
                Clear all
                </button>
            </div>
          </ng-container>
          
        </div>
        <!-- new filter section added =======================================-->
        <!-- Table Begins -->
      </form>
      <div class="table-section">
        <div style="
        width: 94vw;
        max-width: 100%;
        overflow: auto;">
          <!--  -->
          <!-- table skeleton loader start -->
          <!-- <app-dashboard-table-skeleton-loader></app-dashboard-table-skeleton-loader> -->
          <!-- table skeleton loader ends -->
          <!-- ========================= -->
          <table style="width: 100vw;" mat-table [dataSource]="dashboardDataSource">
            <!-- Task Name Column -->
            <ng-container matColumnDef="task_name" sticky>
              <th fxFlex="25vw" fxFlex.lg="25vw" fxFlex.xl="25vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[0]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td fxLayout="column" fxLayoutGap="4px" style="padding-top: 7px; padding-right: 16px;" fxFlex="25vw" fxFlex.lg="25vw" fxFlex.xl="25vw" mat-cell *matCellDef="let element">
                <!-- Edit mode -->
                <!-- == task name editing feature ===  -->
                <form style="width: 100%; padding-top: 16px;" class="taskname-edit-form-class" *ngIf="activeTaskId === element.id" [formGroup]="uploadForm" (ngSubmit)="saveTaskName(element.batch_id)">
                  <mat-form-field class="edit-task-name-form-field" appearance="outline" fxLayout="row" style="width: 100%;">
                    <div style="position: relative;" [ngClass]="{'active-container': true, 'error-border': hasError('taskName') && interacted}" fxLayout="row">
                      <span fxFlex="85" fxLayout="row" fxLayoutAlign="start center">
                        <span *ngIf="hasError('taskName') && interacted" fxFlex="10" fxLayoutAlign="center center">
                          <mat-icon *ngIf="hasError('taskName') && interacted"
                                    [@errorIconTrigger]="hasError('taskName') && interacted ? 'visible' : 'hidden'"
                                    (mouseenter)="showErrorIconMessage()"
              (mouseleave)="hideErrorIconMessage()"
                                    class="error-icon">error</mat-icon>
                        </span>
                        <!--  -->
                        <!-- <div class="task-name-error" *ngIf="showBatchNameRelatedError">
                          <span>{{getErrorTooltip('taskName')}}</span>
                        </div> -->
                        <!--  -->
                        <span fxFlex="90">
                          <input style="padding-left: 5px;" matInput formControlName="taskName" placeholder="Enter the batch name" (blur)="onBlur()" />
                        </span>
                      </span>
                      <span fxFlex="15" fxLayoutAlign="space-around center">
                        <button type="submit" [disabled]="hasError('taskName') || !interacted" style="height: 21px !important; width: 21px !important;" mat-icon-button matTooltip="Save" matTooltipPosition="above">
                          <mat-icon style="margin-top: -20px;" svgIcon="taskEditSave"></mat-icon>
                        </button>
                        <button (click)="cancelTaskNameEdit()" style="height: 21px !important; width: 21px !important;" mat-icon-button matTooltip="Cancel" matTooltipPosition="above">
                          <mat-icon style="margin-top: -20px;" svgIcon="cancelTaskNameEdit"></mat-icon>
                        </button>
                      </span>
                      <!--  -->
                      <div class="task-name-error" *ngIf="showBatchNameRelatedError">
                        <span>{{getErrorTooltip('taskName')}}</span>
                      </div>
                      <!--  -->
                    </div>
                    <!--  -->
                    <!-- <div class="task-name-error" *ngIf="showBatchNameRelatedError">
                      <span>{{getErrorTooltip('taskName')}}</span>
                    </div> -->
                    <!--  -->
                  </mat-form-field>
                </form>
                
                
                
                
                
                <!-- == editing task name ends here === -->
                <!-- <span *ngIf="activeTaskId === element.id" style="border-radius: 4px; border: 0.75px solid var(--Primary-500, #1644E7);" fxLayout="row" fxLayoutAlign="center space-between" fxLayoutGap="4px">
                  <span fxFlex="80" fxLayout="column">
                    <input type="text" name="taskEdit" maxlength="100" (keyup)="checkCharacterLength(activeTaskNameForEdit)" [(ngModel)]="activeTaskNameForEdit" style="width: 100%; padding: 1px 3px; border: none;">
                    
                  </span>
                  <span fxFlex="20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2px">
                    <button style="height: 21px !important; width: 21px !important;" mat-icon-button matTooltip="Save" matTooltipPosition="above">
                      <mat-icon style="margin-top: -20px;" svgIcon="taskEditSave" (click)="saveTaskName(element.batch_id)"></mat-icon>
                    </button>
                    <button style="height: 21px !important; width: 21px !important;" mat-icon-button matTooltip="Cancel" matTooltipPosition="above">
                      <mat-icon style="margin-top: -20px;" svgIcon="cancelTaskNameEdit" (click)="cancelTaskNameEdit()"></mat-icon>
                    </button>
                  </span>
                </span> -->
            
                <!-- Display mode -->
                <span *ngIf="activeTaskId !== element.id" fxLayout="row" [ngClass]="{ 'disable-profile': !emailVerify }" fxLayoutAlign="center space-between" fxLayoutGap="8px">
                  <!-- ========== -->
                  <button class="task-name"  fxFlex="85" fxLayoutAlign="start center" 
                    [disabled]="(element.status != 'In Review' && element.status != 'Completed') || element.custom_function_name" 
                    [style.opacity]="(element.status != 'In Review' && element.status != 'Completed') || element.custom_function_name ? '0.5' : '1'" 
                    [style.cursor]="(element.status != 'In Review' && element.status != 'Completed') || element.custom_function_name ? 'not-allowed' : 'pointer'" 
                    style="text-decoration: none;text-overflow: ellipsis;" 
                    [routerLink]="
                      (element.status == 'In Review' || element.status == 'Completed') 
                        ? (isNewVersion ? '/dashboard/view-output-new' : '/dashboard/view-output') 
                        : '/dashboard/interim-output'
                    " 
                    [queryParams]="{
                          batch_id: element.batch_id,
                          task_name: element.task_name,
                          task_status: element.status,
                          disable_edit: element.disable_edit
                        }" 
                    matTooltip="{{ element.task_name.length > 7 ? element.task_name : '' }}" 
                    matTooltipPosition="above" 
                    matTooltipClass="custom-tooltip" 
                    (click)="onTaskClick(element)">
                      <span *ngIf="!showFullPageSkeletonLoader">{{ element.task_name | truncate : 23 }}</span>
                      <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                        height: '12px',
                      'border-radius': '4px',
                      'width': '250px',
                    }"></ngx-skeleton-loader>
                    </button>
                  <!-- ========== -->
                  <!-- <span class="task-name" fxFlex="85" fxLayoutAlign="start center" [ngClass]="{
                    disable: (element.status != 'In Review' && element.status != 'Completed') || element.custom_function_name
                  }">
                  <a style="text-decoration: none;text-overflow: ellipsis;" [routerLink]="
                  (element.status == 'In Review' || element.status == 'Completed') 
                    ? (isNewVersion ? '/dashboard/view-output-new' : '/dashboard/view-output') 
                    : '/dashboard/interim-output'
                  " [queryParams]="{
                        batch_id: element.batch_id,
                        task_name: element.task_name,
                        task_status: element.status,
                        disable_edit: element.disable_edit
                      }" matTooltip="{{ element.task_name.length > 7 ? element.task_name : '' }}" matTooltipPosition="above" matTooltipClass="custom-tooltip" (click)="onTaskClick(element)">
                      <span *ngIf="!showFullPageSkeletonLoader">{{ element.task_name }}</span>
                      <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                        height: '12px',
                      'border-radius': '4px',
                      'width': '250px',
                    }"></ngx-skeleton-loader>
                    </a>
                  </span> -->
                  <!-- ========== -->
                  <div *ngIf="showFullPageSkeletonLoader" fxFlex="15" fxLayoutAlign="center center">
                    <ngx-skeleton-loader  appearance="circle" animation="progress" [theme]="{
                      'height': '35px',
                     'width': '35px',
                   }"></ngx-skeleton-loader>
                  </div>
                  <!-- ====== -->
                  <!-- <button *ngIf="!showFullPageSkeletonLoader"  mat-icon-button aria-label="batch edit button" (click)="editThisTask(element.id)" fxFlex="15" fxLayoutAlign="center center">
                    <img src="../../../../assets/images/updated-dashboard/edit.svg" style="padding-top: 2px" matTooltip="Edit batch name" matTooltipPosition="above" />
                    <img *ngIf="element.is_sample" src="../../../../assets/images/updated-dashboard/disabled_edit.svg" style="padding-top: 2px" matTooltip="Edit batch name" matTooltipPosition="above" />
                  </button> -->
                  <!-- ====== -->
                  <button *ngIf="!showFullPageSkeletonLoader"  mat-icon-button aria-label="batch edit button" [disabled]="element.is_sample" (click)="editThisTask(element.id)" fxFlex="15" fxLayoutAlign="center center">
                    <img *ngIf="!element.is_sample" src="../../../../assets/images/updated-dashboard/edit.svg" style="padding-top: 2px" matTooltip="Edit batch name" matTooltipPosition="above" />
                    <img *ngIf="element.is_sample" src="../../../../assets/images/updated-dashboard/disabled_edit.svg" style="padding-top: 2px" matTooltip="Edit batch name" matTooltipPosition="above" />
                  </button>
                </span>

                <!--  -->
                <!-- <span style="font-size: 11px;color: red;" *ngIf="activeTaskId === element.id && activeTaskNameForEdit.length>=100">You have reached the maximum character length 100</span> -->
                <!--  -->
            
                <span style="margin-top: -8px !important;">
                  <span *ngIf="!showFullPageSkeletonLoader" class="task-email" matTooltip="{{ element.recipe_name != null ? element.recipe_name: '' }}" matTooltipPosition="above">
                    {{ element.custom_function_name != null ? element.custom_function_name : ' Recipe coming soon....' }}
                    <span *ngIf="element.custom_function_name">
                      <img style="height: 14px;
    width: 14px;" src="../../../../assets/images/custom-tags/recipe_description_info-icon.png" matTooltip="{{element.custom_function_description}}" matTooltipPosition="below" matTooltipClass="recipe_description_tooltip_style" />
                    </span>
                  </span>
                  <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                    height: '12px',
                   'border-radius': '4px',
                   'width': '200px',
                 }"></ngx-skeleton-loader>
                </span>
              </td>
            </ng-container>
            

             <!-- Batch ID Column -->
             <ng-container matColumnDef="batch_id">
              <th style="padding-left: 16px;" fxFlex="14vw" fxFlex.lg="14vw" fxFlex.xl="14vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[1]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px;padding-left: 16px;" fxFlex="14vw" fxFlex.lg="14vw" fxFlex.xl="14vw" mat-cell *matCellDef="let element">
                <span *ngIf="!showFullPageSkeletonLoader">{{ element.batch_id }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                  height: '12px',
                 'border-radius': '4px',
                 'width': '100px',
               }"></ngx-skeleton-loader>
              </td>
            </ng-container>
  
            <!-- Uploaded On Column -->
            <ng-container matColumnDef="uploaded_by">
              <th fxFlex="22vw" fxFlex.lg="22vw" fxFlex.xl="22vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[3]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px;" fxFlex="22vw" fxFlex.lg="22vw" fxFlex.xl="22vw" mat-cell *matCellDef="let element">
                <span *ngIf="!showFullPageSkeletonLoader">{{ element.uploaded_by }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                  height: '12px',
                 'border-radius': '4px',
                 'width': '100px',
               }"></ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- Updated On Column -->
            <ng-container matColumnDef="updated_on">
              <th fxFlex="22vw" fxFlex.lg="22vw" fxFlex.xl="22vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[4]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px;" fxFlex="22vw" fxFlex.lg="22vw" fxFlex.xl="22vw" mat-cell *matCellDef="let element">
                <span *ngIf="!showFullPageSkeletonLoader">{{ element.updated_on | date }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '150px',
               }"></ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th fxFlex="12vw" fxFlex.lg="12vw" fxFlex.xl="12vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[2]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px;" fxFlex="12vw" fxFlex.lg="12vw" fxFlex.xl="12vw" class="status-cell" mat-cell
                *matCellDef="let element" >
                <span *ngIf="!showFullPageSkeletonLoader" [ngClass]="{
                  error_status_button_style:element.status === 'Cancelled' || element.status === 'Error',
                  completed_status_button_style: element.status === 'Completed',
                  inreview_status_button_style: element.status === 'In Review',
                  inprogress_status_button_style: element.status === 'In Progress',
                  generating_output_status_button_style: element.status === 'Generating output'  
                    
                }">
                  {{ element.status }}
                  
                </span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '150px',
               }"></ngx-skeleton-loader>
              </td>
            </ng-container>
  
            <!-- Tags Column -->
            <ng-container matColumnDef="tags">
              <th fxFlex="18vw" fxFlex.lg="18vw" fxFlex.xl="18vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[5]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px;" fxFlex="18vw" fxFlex.lg="18vw" fxFlex.xl="18vw" mat-cell
                *matCellDef="let element; let indx = index">
                <div [ngClass]="{ 'disable-profile': !emailVerify }">
                  <ng-container *ngIf="!showFullPageSkeletonLoader">
                    <div class="tags-main-container" style="display:flex; flex-direction:row">
                      <div class="tag" *ngFor="let chip of element.tags.visibleChips[0]">{{ chip.tag_name }}</div>
                    </div>
                    <div fxLayout="row">
                      <span class="more-tags" *ngIf="element.tags.hiddenChips[0].length > 0"
                        matTooltip="{{ generateTooltipContent(element.tags.hiddenChips[0]) }}"
                        matTooltipClass="tag-tooltip" matTooltipPosition="above">
                        +{{ element.tags.hiddenChips[0].length }}
                      </span>
                      <span class="tag-edit"
                        [ngStyle]="{'margin-top': element.tags.visibleChips[0].length === 0 ? '0px' : '8px'}"
                        >
                        <!--  -->
                        <!-- <button  mat-icon-button aria-label="Edit/Delete tag button" (click)="openTagDialog(element.batch_id)">
                          <img height="22px" width="22px" src="../../../../assets/images/edit-tag.svg" matTooltip="Edit/Delete Tag" matTooltipPosition="above" />
                          <img *ngIf="element.is_sample" height="22px" width="22px" src="../../../../assets/images/updated-dashboard/disabled-add-tag-2.svg" matTooltip="Edit/Delete Tag" matTooltipPosition="above" />
                        </button> -->
                        <!--  -->
                        <button  mat-icon-button aria-label="Edit/Delete tag button" (click)="openTagDialog(element.batch_id)" [disabled]="element.is_sample">
                          <img *ngIf="!element.is_sample" height="22px" width="22px" src="../../../../assets/images/edit-tag.svg" matTooltip="Edit/Delete Tag" matTooltipPosition="above" />
                          <img *ngIf="element.is_sample" height="22px" width="22px" src="../../../../assets/images/updated-dashboard/disabled-add-tag-2.svg" matTooltip="Edit/Delete Tag" matTooltipPosition="above" />
                        </button>
                        <!--  -->
                      </span></div>
                  </ng-container>
                  <ng-container *ngIf="showFullPageSkeletonLoader">
                    <!--  -->
                    <ngx-skeleton-loader  appearance="line" animation="progress" [theme]="{
                      'height': '12px',
                     'border-radius': '4px',
                     'width': '150px',
                   }"></ngx-skeleton-loader>
                    <!--  -->
                  </ng-container>
                  <br>
                </div>
              </td>
            </ng-container>
  
  
            <!-- Assignee Column -->
            <ng-container matColumnDef="assignee">
              <th fxFlex="23vw" fxFlex.lg="20vw" fxFlex.xl="20vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[6]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px;" fxFlex="23vw" fxFlex.lg="20vw" fxFlex.xl="20vw" mat-cell *matCellDef="let element">
                <span *ngIf="!showFullPageSkeletonLoader" class="assignee-email" matTooltip="{{ element.assignee[0][0] }}" matTooltipPosition="above">
                {{
                    element?.assignee[0][0]
                      ? (element?.assignee[0][0])
                      : ""
                  }}
                </span>
                  <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                    'height': '12px',
                   'border-radius': '4px',
                   'width': '150px',
                 }"></ngx-skeleton-loader>
  
                <div *ngIf="!showFullPageSkeletonLoader" class="assignee avatars" fxLayoutGap="8px"
                  [ngStyle]="{'margin-top': element?.assignee[0][0] ? '8px' : '0px'}">
                  <span *ngIf="element?.assignee[0]?.length > 1">
                    <div class="circle">
                      <span class="tooltip" matTooltip="{{ element.assignee[0].slice(1).join('\n') }}"
                        matTooltipPosition="above"
                        matTooltipClass="my-custom-tooltip">+{{ element?.assignee[0]?.length - 1 }}</span>
                    </div>
                  </span>
  
                  <span fxLayout="row" fxLayoutAlign="center center"
                    class="avatar assignee-profile"
                    [ngClass]="{
                      'disable-assignee': !emailVerify || listOfAssignees?.length <= 1
                    }"
                    matTooltip="{{ disabledEditAssignee(listOfAssignees?.length <= 1) }}"
                    matTooltipPosition="above"
                  >
                    <button fxLayout="row" fxLayoutAlign="center center"
                      mat-icon-button
                      aria-label="Add assignee button"
                      (click)="openSidePanel(element?.assignee[0], element?.batch_id)"
                      [disabled]="element.is_sample"
                    >
                      <img *ngIf="!element.is_sample"
                        height="22px"
                        width="22px"
                        src="../../../../assets/images/edit-assignee-col.svg"
                        matTooltip="{{ addEditAssignee(listOfAssignees?.length > 1) }}"
                        matTooltipPosition="above"
                      />
                      <img *ngIf="element.is_sample"
                        height="22px"
                        width="22px"
                        src="../../../../assets/images/updated-dashboard/disabled-assignee-avatar.svg"
                        matTooltip="{{ addEditAssignee(listOfAssignees?.length > 1) }}"
                        matTooltipPosition="above"
                      />
                    </button>
                  </span>


                </div>
              </td>
            </ng-container>
  
  
            <!-- Total Rows -->
            <ng-container matColumnDef="final_output_row_count">
              <th fxFlex="8vw" fxFlex.lg="8vw" fxFlex.xl="8vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[7]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '80px',
               }"></ngx-skeleton-loader>
  
              </th>
              <td style="padding-top: 16px;" fxFlex="8vw" fxFlex.lg="8vw" fxFlex.xl="8vw" mat-cell *matCellDef="let element">
                <span *ngIf="!showFullPageSkeletonLoader">{{ element.final_output_row_count }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '100px',
               }"></ngx-skeleton-loader>
              </td>
            </ng-container>
  
            
  
            <!-- Action Column  -->
            <ng-container matColumnDef="actions" stickyEnd>
              <th fxFlex="23vw" fxFlex.md="22vw"  fxFlex.lg="19vw" fxFlex.xl="13vw" mat-header-cell *matHeaderCellDef>
                <span *ngIf="!showFullPageSkeletonLoader">{{ tableHeaders[8]?.display_name }}</span>
                <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader" appearance="line" animation="progress-dark" [theme]="{
                  'height': '12px',
                 'border-radius': '4px',
                 'width': '120px',
               }"></ngx-skeleton-loader>
              </th>
              <td style="padding-top: 16px; padding-right: 0px;" fxFlex.md="22vw" fxFlex="23vw" fxFlex.lg="19vw" fxFlex.xl="13vw" mat-cell
                *matCellDef="let element">
                <div class="actionbtn-container">
                  <button *ngIf="!showFullPageSkeletonLoader" [ngClass]="{ disable: element.status != 'Completed' }" type="button" (click)="openShareDialog(element?.batch_id)" mat-stroked-button
                    [disabled]="!emailVerify || (element.status != 'Completed')" class="share-btn">
                    <span>Share</span>
                  </button>
                  <!--  -->
                  <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '70px',
                      height: '40px',
                      'border-radius': '8px',
                      'margin-top': '-2px'
                    }"
                  >
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader *ngIf="showFullPageSkeletonLoader"
                    appearance="circle"
                    [theme]="{
                      width: '100px',
                      height: '40px',
                      'border-radius': '8px',
                      'margin-top': '-2px'
                    }"
                  >
                  </ngx-skeleton-loader>
                  <!--  -->
                  <!-- ======= -->
                  <button *ngIf="!showFullPageSkeletonLoader" style="padding: 8px 12px;height: 42px;
                  border-radius: 8px;
                  background-color: #1644E7;
                  color: #fff;
                  line-height: 14px;
                  width: 130px;" fxLayout="row" mat-stroked-button [matMenuTriggerFor]="menu">
                    <span style="margin-right: 20px;">Download</span>
                    <span>
                      <mat-icon style="margin-top: 7px;" matSuffix svgIcon="downloadFileDownArrow" class="selection-arrow-icon"></mat-icon>
                    </span>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button [ngClass]="{ disable: element.status != 'Completed' }" mat-menu-item>
                      <a style="text-decoration: none;
                      font-family: 'IBM Plex Sans';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 21px;
                      color: #1B263B;" [href]="element.output_file_url">Download generated file</a>
                    </button>
                    <button mat-menu-item><a style="text-decoration: none;
                      font-family: 'IBM Plex Sans';
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 21px;
                      color: #1B263B;" [href]="element.input_file_url">Download input file</a></button>
                  </mat-menu>
                  <!-- ======= -->
                  <!-- <mat-form-field appearance="outline" class="download-button-class-style">
                    <mat-select name="downloadOption" panelClass="drop-down-option-panel" placeholder="Download"
                                (selectionChange)="onChangeDownloadOption(element.input_file_url, element.output_file_url)" disableOptionCentering>
                      <mat-option [ngClass]="{ disable: isOptionDisabled(downloadOption, element) }" 
                                  *ngFor="let downloadOption of downloadOptionsList" 
                                  [value]="downloadOption.slug">
                        {{ downloadOption.display_name }}
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix svgIcon="DownArrow" class="selection-arrow-icon"></mat-icon>
                  </mat-form-field> -->
                  <!-- ======= -->
                  <!-- <button type="button" fxFlex="row" fxLayoutGap="2px" mat-raised-button color="primary" class="download-btn" [disabled]="!emailVerify"
                    (click)="
                      downloadMyOutputFile(element.output_file_url, $event)
                    ">
                        <div fxFlex="70">
                          <span>Download </span>
                        </div>
                        <div fxFlex="30">
                          <img class="download-btn-icon" src="../../../../assets/images/Tables/Arrows/alt_arrow_down.svg" />
                        </div>
                  </button> -->
                </div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <!-- ========================= -->
        </div>

        <div *ngIf="modifiedData?.length === 0" class="no-result-message">
          No Results Found
        </div>

        <div *ngIf="!showFullPageSkeletonLoader" class="paginator" fxLayoutAlign="space-between center">
          <div class="skip-to-paginator" fxLayoutGap="10px"></div>
          <mat-paginator [length]="totalItems" [pageSize]="size" [pageIndex]="page - 1"
            [pageSizeOptions]="[5, 10, 12, 25, 50]" (page)="onPaginateChange($event)"
            [disabled]="!emailVerify || modifiedData?.length == 0">
          </mat-paginator>
        </div>
        <!-- skeleton loader for paginator section -->
        <div *ngIf="showFullPageSkeletonLoader" class="paginator" fxLayoutAlign="end center">
          <!-- skeleton loader for paginator -->
          <ngx-skeleton-loader 
          appearance="line"
          [theme]="{
            width: '50px',
            height: '12px',
            'border-radius': '8px',
            'margin-top': '11px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            width: '50px',
            height: '24px',
            'border-radius': '8px',
          }"
        >
        </ngx-skeleton-loader>&nbsp;&nbsp;
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            width: '50px',
            height: '12px',
            'border-radius': '8px',
            'margin-top': '11px'
          }"
        >
        </ngx-skeleton-loader>
        <ngx-skeleton-loader 
          appearance="circle"
          [theme]="{
            width: '24px',
            height: '24px',
            'border-radius': '8px',
          }"
        >
        </ngx-skeleton-loader>
        
        <ngx-skeleton-loader 
          appearance="circle"
          [theme]="{
            width: '24px',
            height: '24px',
            'border-radius': '8px',
          }"
        >
        </ngx-skeleton-loader>
      <!--  -->
        </div>
              
        <!--  -->
      </div>
    </div>
  </div>
</div>
<app-side-panel [sidenavTemplateRef]="navContent" [direction]="'right'" [navWidth]="400" [duration]="0.5"
  *ngIf="loading">
</app-side-panel>
<ng-template #navContent>
  <div class="side-panel-heading">Add/Remove Assignee</div>
  <!-- <div class="assignee-side-pln-container"> -->
  <div fxLayout="column" fxLayoutAlign="start start">
    <div class="filters-section">
      <div class="search-container-filters">
        <mat-form-field style="width: 98%" appearance="outline" class="search-filter">
          <input (keyup)="searchAssigneeInPanel($event)" id="search" matInput placeholder="Search Assignee..."
            #searchVal name="searchVal" [(ngModel)]="searchAssigneeAddRemove" appNoWhitespace />
          <mat-icon matPrefix svgIcon="search"></mat-icon>
          <mat-icon matSuffix style="cursor: pointer;" *ngIf="searchAssigneeAddRemove" (click)="clearSearch()">close
          </mat-icon>

          <!-- Display validation error message -->
        </mat-form-field>
      </div>
      <div class="list-of-members" *ngFor="let item of assigneeList; let i = index" fxLayout="row"
        fxLayoutAlign="space-between center" [ngClass]="item.added ? 'email-added' : 'email-not-added'">
        <div fxFlex="75">{{ item.email }}</div>
        <div fxFlex="25">
          <button class="add-btn" *ngIf="!item.added" mat-button (click)="addRemoveAssignee(item, 'add', i)">
            Add
          </button>
          <button *ngIf="showRemove(item)" mat-button class="remove-btn" (click)="addRemoveAssignee(item, 'remove', i)">
            Remove
          </button>
        </div>
      </div>

      <div class="no-search-addOrRemove" *ngIf="assigneeList?.length == 0">
        This assignee is not in your team.
      </div>
      <!-- </div> -->
    </div>
  </div>
  <button (click)="invitemembDialog()" mat-raised-button color="primary" class="invite-btn" [disabled]="
      !hasTeamMembersCreatePermission || !emailVerify || featureFlagInviteButton
    ">
    <span>Invite members</span>
  </button>
</ng-template>
