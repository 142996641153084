import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  ViewChildren,
  ViewChild,
  Inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OnboardingService } from 'src/app/_services/onboarding.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { NavigationExtras, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/_services/user.service';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent implements OnInit {
  @ViewChild('confirmed') confirmed: any;
  @Output() nextActiveStep = new EventEmitter<number>();
  termsOfService: any = {
    privacy_policy_acceptance: false,
  };
  disableBtn: boolean = true;
  finishBtn: boolean = false;
  privacyPolicySrc: any = '/static/privacy-policy.pdf';
  pdfForm: FormGroup;
  inviteToken: string;
  isLocal: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private onBoardingService: OnboardingService,
    private router: Router,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<TermsOfServiceComponent>,
    private userService: UserService,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.userService.getIsLocal().subscribe({
      next: (res) => {
        this.isLocal = res;
      },
    });
    this.inviteToken = this.data.code;
    this.pdfForm = this.fb.group({
      privacyPolicyAgree: new FormControl(''),
      tosAgree: new FormControl(''),
    });
  }

  /** sending copy of mail  **/
  sendMail = () => {
    this.onBoardingService.sendMail().subscribe({
      next: (resp) => {
        this.snackbarService.showSnackbar(resp.detail, '', 'info');
      },
      error: (error) => {
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  getBtnValue = (data: boolean) => {
    this.disableBtn = data;
  };

  acceptTermsofService = () => {
    this.privacyPolicySrc;
    ('/static/email/terms-of-service.pdf');
    // this.finishBtn = true;
    this.disableBtn = true;

    this.onBoardingService.privacyPolicyAcceptance().subscribe({
      next: (resp: any) => {
        this.dialogRef.close();
        this.userService.me().subscribe({
          next: (resp) => {
            localStorage.setItem('user', JSON.stringify(resp));
          },
        });
        const queryParams = this.isLocal
          ? { invite: this.inviteToken, local: true }
          : { invite: this.inviteToken };

        const navigationExtras: NavigationExtras = {
          skipLocationChange: true,
          queryParams,
        };

        if (this.inviteToken) {
          // 
          this.router.navigate(['/dashboard'], navigationExtras);
          // 
          // this.appConfig.getConfig().is_new_version ? this.router.navigate(['/dashboard-v1'], navigationExtras) : this.router.navigate(['/dashboard'], navigationExtras);
        } else {
          // 
          this.router.navigate(['/dashboard']);
          // 
          // this.appConfig.getConfig().is_new_version ?  this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
        }
      },
      error: (error: HttpErrorResponse) => {
        // console.log(error);
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  };

  // navigate to home-dashboard after clicking finish button
  finishbtnClick = () => {
    if (this.inviteToken) {
      // 
      this.router.navigate(
        ['/dashboard'],
        this.isLocal
          ? {
              queryParams: { invite: this.inviteToken, local: true },
            }
          : { queryParams: { invite: this.inviteToken } }
      )
      // 
      // this.appConfig.getConfig().is_new_version ?  this.router.navigate(
      //   ['/dashboard-v1'],
      //   this.isLocal
      //     ? {
      //         queryParams: { invite: this.inviteToken, local: true },
      //       }
      //     : { queryParams: { invite: this.inviteToken } }
      // ) : this.router.navigate(
      //   ['/dashboard'],
      //   this.isLocal
      //     ? {
      //         queryParams: { invite: this.inviteToken, local: true },
      //       }
      //     : { queryParams: { invite: this.inviteToken } }
      // );
      // 
    } else {
      // 
      this.router.navigate(['/dashboard']);
      // 
      // this.appConfig.getConfig().is_new_version ?  this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
      // 
    }
    this.dialogRef.close();
  };

  logout() {
    if (this.inviteToken) {
      this.userService.logUserOut(this.inviteToken);
    } else {
      this.userService.logUserOut();
    }
  }
}
