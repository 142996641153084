<pdf-viewer
  (after-load-complete)="afterLoadComplete($event)"
  (pagechanging)="pagechanging($event)"
  [src]="src"
  [rotation]="0"
  [original-size]="false"
  [show-all]="true"
  [fit-to-page]="true"
  [stick-to-page]="false"
  [render-text]="true"
  [external-link-target]="'blank'"
  [autoresize]="true"
  [show-borders]="false"
  style="height: 100%; margin: auto"
>
</pdf-viewer>
<mat-spinner style="margin:-28% auto;" mode="indeterminate" [diameter]="90" strokeWidth="3" *ngIf="!policyLoaded"></mat-spinner>
