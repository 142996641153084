import { S } from '@angular/cdk/keycodes';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  concat,
  concatMap,
  map,
  mergeMap,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Globals } from '../_globals/endpoints.global';

@Injectable({
  providedIn: 'root',
})
export class BatchUploadService {
  private httpOptions: HttpHeaders;
  private chunkSize: number = 8 * 1024 * 1024;
  private totalChunk: number;
  startSize: number;
  endSize: number;
  totalSent = 0;
  signedURL: string;
  sessionUri: any;
  file: any;
  batchUploadCompletePayload: any;

  constructor(
    private globals: Globals,
    private http: HttpClient,
    private router: Router
  ) {
    this.httpOptions = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'x-goog-resumable': 'start',
    });
  }

  getInputOutputFile(organisation_id: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'input_output_list',
      organisation_id
    );
    return this.http.get(endpoint);
  }

  getBatchList(organisation_id: any) {
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'batch_list',
      organisation_id
    );
    return this.http.get(endpoint);
  }

  /** get dist-supl list **/
  getDistSupList(q = ''): Observable<any> {
    const endpoint = this.globals.urlJoin('home', 'filterList');
    return this.http.get(endpoint, { params: { q, type: 'batch_upload' } });
  }

  /** get offline-supl list [distributor logged in] **/
  // getOfflineSuppliersList(): Observable<any> {
  //   const endpoint = this.globals.urlJoin('batch_upload', 'offline_suppliers');
  //   return this.http.get(endpoint);
  // }

  /** get dist-supl list **/
  createBatch(batchDetails: any): Observable<any> {
    const endpoint = this.globals.urlJoin('batch_upload', 'create');
    return this.http.post(endpoint, batchDetails);
  }
  inputFileId: any;
  outputFileId: any;
  /** get signed url **/
  getSignedUrl = (
    file: any,
    file_type: string, // main or additional
    // network_type: any,
    organisation_id: any
  ): Observable<any> => {
    // 
    // console.log('inside getSignedUrl function');
    
    // console.log('check file_type inside getSignedUrl function: ', file_type );
    // console.log('check file inside getSignedUrl function: ', file);
    // console.log('check file.name inside getSignedUrl function: ', file.name);
    


    
    // 
    const payload = {
      file_name: file.name,
      file_type: file_type,
    };
    const options = {
      headers: this.httpOptions,
      observe: 'response' as 'body',
    };
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'signed_url',
      organisation_id
    );
    return this.http.post(endpoint, payload).pipe(
      mergeMap((res: any) => {
        if (file_type == 'input_file') {
          this.inputFileId = res.file_id;
        } else {
          this.outputFileId = res.file_id;
        }
        return this.http.post(res['signed_url'], null, options);
      }),
      catchError((err) => throwError(err))
    );
  };
  progress: any = 0;
  progressResp: any;
  mainProgress: any = 0;
  updateString$: any;
  /** upload process
   * get signed url, set session uri, create chunk and upload file to gcs, call uploadcomplete
   **/
  uploadFile(
    file: any,
    file_type: string,
    organisation_id: any,
    fileCount: number
  ) {
    // if (!file) {
    //   console.error('File is undefined or null');
    //   // You might want to throw an error or return early here
    //   return;
    // }
    this.file = file;
    // console.log('check file inside uploadFile function: ', file);
    // console.log('check file_type inside uploadFile function: ', file_type);
    // console.log('check fileCount inside uploadFile function: ', fileCount);


    
    this.startSize = 0;
    this.endSize = 0;
    this.totalSent = 0;
    return this.getSignedUrl(this.file, file_type, organisation_id).pipe(
      mergeMap((resp) => {
        this.sessionUri = resp.headers.get('location');
        return this.createAndUploadChunk(file, this.sessionUri);
      }),
      concatMap((r) => r),
      map((res) => {
        this.totalSent += 1;
        this.progress = (this.totalSent / this.totalChunk) * 100;
        this.fileCount.next(fileCount); //send file count into observable
        this.dataSubject.next(this.progress); //send pogress into observable
        this.progressResp = {
          progress: (this.totalSent / this.totalChunk) * 100,
          inputFileId: this.inputFileId,
          outputFileId: this.outputFileId,
        };
        return this.progressResp;
      }),
      catchError((err) => throwError(err))
    );
  }
  //observables to send progress and file count into diff component
  private dataSubject = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();
  private fileCount = new BehaviorSubject<any>(null);
  public count$: Observable<any> = this.fileCount.asObservable();
  /**
   *
   * @param file
   * @param sessionUri
   * @returns chunks of file|sends chunks to server
   */
  createAndUploadChunk = (file: File, sessionUri: string): Observable<any> => {
    const chunks = [];
    let totalFileSize = file.size;
    let contentType = file.type;
    this.totalChunk = Math.ceil(totalFileSize / this.chunkSize);
    let headers;
    while (totalFileSize > this.endSize) {
      let slice: Blob;
      let contentRangeHeader: string;
      this.endSize = this.startSize + this.chunkSize;
      // for file size bigger than chunk size
      if (totalFileSize > this.chunkSize) {
        if (totalFileSize - this.endSize < 0) {
          this.endSize = totalFileSize;
          slice = file.slice(this.startSize, this.endSize, contentType);
          contentRangeHeader = `bytes ${this.startSize}-${this.endSize}/*`;
        } else {
          slice = file.slice(this.startSize, this.endSize + 1, contentType);

          contentRangeHeader = `bytes ${this.startSize}-${this.endSize}/${file.size}`;
        }
        headers = new HttpHeaders({
          'Content-Range': contentRangeHeader,
          'Chunk-Size': this.chunkSize.toString(),
        });
      } else {
        // for file size smaller than chunk size
        // no need to send content range
        slice = file.slice(this.startSize, totalFileSize, contentType);
        headers = new HttpHeaders({
          'Chunk-Size': this.chunkSize.toString(),
        });
      }
      chunks.push(
        this.http
          .put(sessionUri, slice, {
            headers: headers,
          })
          .pipe(
            map((resp: any) => {}),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 308) {
                return of(undefined);
              }
              return throwError(err);
            })
          )
      );
      this.startSize = this.endSize;
    }

    return concat(chunks);
  };

  /**
   *
   *upload file complete
   */
  uploadCompelte = (uid: any, file_type: any) => {
    const payload: any = {
      completed: true,
      uid: uid,
      file_type: file_type,
    };
    const endpoint = this.globals.urlJoin('batch_upload', 'complete');
    return this.http.put(endpoint, payload).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  };

  batchUploadComplete = (
    organisation_id: any,
    batch: any,
    inputFileId: any,
    outputFileId: any,
    selectedCustomFunctionID: any,
    isPreDefinedTagSelected: boolean,
    isZippedInputFileUploaded: boolean
  ) => {
    // console.log('batch: ', batch);
    // console.log('batch: ', batch.batch);
    // console.log('task_name: ', batch.taskName);
    // console.log('short_description: ', batch.batch.description);
    
    if (isPreDefinedTagSelected) {
      // console.log('11');
      
      this.batchUploadCompletePayload = {
        task_name: batch.taskName,
        short_description: batch.batch.description,
        setting_from_batch: null,
        input_file: inputFileId,
        template_output_file: null,
        custom_function: selectedCustomFunctionID
      };
    } else {
      // console.log('12');

      if (isZippedInputFileUploaded) {
      // console.log('12-1');

        // 
        this.batchUploadCompletePayload = {
          task_name: batch.taskName,
          // short_description: batch.batch.description,
          setting_from_batch: batch.batch,
          input_file: inputFileId,
          template_output_file: null,
        };
        // 
      } else {
        // 
        this.batchUploadCompletePayload = {
          task_name: batch.taskName,
          // short_description: batch.batch.description,
          setting_from_batch: batch.batch,
          input_file: inputFileId,
          template_output_file: outputFileId,
        };
        // 
      }

      
    }
    // 
    // const payload: any = {
    //   organisation_id: organisation_id,
    //   task_name: batch.taskName,
    //   short_description: batch.batch.description,
    //   setting_from_batch: batch.batch,
    //   input_file: inputFileId,
    //   template_output_file: outputFileId,
    //   custom_function: selectedCustomFunctionID
    // };
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'batch_upload_complete',
      organisation_id
    );
    return this.http.post(endpoint, this.batchUploadCompletePayload).pipe(
      map((response: any) => {
        localStorage.setItem('currentBatchId', response.batch_id);
        return response;
      }),
      catchError((error) => throwError(error))
    );
  };

  // delete batch
  deleteBatch = (uid: any, file_type: any) => {
    const payload: any = {
      completed: false,
      uid: uid,
      file_type: file_type,
    };
    const endpoint = this.globals.urlJoin('batch_upload', 'complete');
    return this.http.put(endpoint, payload).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  };
  checkNameUniqueness(task_name: string, organisation_id: any) {
    const options = {
      params: new HttpParams().set('task_name', task_name),
    };
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'task_name_check',
      organisation_id
    );
    return this.http.get(endpoint, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  /**
   * 
   * @param organisation_id 
   * @returns 
   */
  fetchCustomTagsAndRecipes(organisation_id: any) {
    
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'custom_tags',
      organisation_id
    );
    return this.http.get(endpoint).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  //<------ search existing files------>//
  searchFiles(
    query: string,
    organisation_id: any,
    fileType: string
  ): Observable<any> {
    const options = {
      params: new HttpParams().set('q', query).set('file_type', fileType),
    };
    const endpoint = this.globals.urlJoinWithParam(
      'batch_upload',
      'input_output_list',
      organisation_id
    );
    return this.http.get(endpoint, options);
  }

  /** Interim Output status **/
  interimStatus(organisation_id: any, batch_id: string): Observable<any> {
    const endpoint = this.globals.urlJoinWithTwoParam(
      'batch_upload',
      'interim_output_status',
      organisation_id,
      batch_id
    );
    return this.http.get(endpoint);
  }

  // Cancell batch upload
  cancelBatchUpload() {
    this.unsubscribe.next(false);
  }
  private unsubscribe = new BehaviorSubject<any>(true);
  public call$: Observable<any> = this.unsubscribe.asObservable();
}


