import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '@auth0/auth0-angular';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { OnboardingService } from 'src/app/_services/onboarding.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { UserService } from 'src/app/_services/user.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { AccountSettingsComponent } from '../account-settings.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpgradePlanComponent } from 'src/app/_dialogs/upgrade-plan/upgrade-plan.component';
import { environment } from 'src/environments/environment';
interface User {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'organization-info',
  templateUrl: './organization-info.component.html',
  styleUrls: ['./organization-info.component.scss'],
})
export class OrganizationInfoComponent implements OnInit {
  currentOrganizationCode: any;
  users: User[] = [
    { value: 'admin', viewValue: 'Admin' },
    { value: 'user', viewValue: 'User' },
  ];
  organizationInfoForm: FormGroup;
  organizationprofileForm!: FormGroup;
  user: any;
  organisation: string;
  showSaveButton = false;
  orgDetails: any;
  currentOrganizationRole: string | null;
  isEmailVerified: string | null;
  profileJson: any;
  isFormEdited: boolean;
  upgradePlanDialog: MatDialogRef<UpgradePlanComponent, any>;
  allowUpgrade: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private snackbarService: SnackbarService,
    private formValidation: ValidationService,
    private accountSettingsService: AccountSettingsService,
    private loadingService: LoadingService,
    private accSettingCompo: AccountSettingsComponent,
    public auth: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currentOrganizationCode = localStorage.getItem(
      'currentOrganizationCode'
    );

    this.currentOrganizationRole = localStorage.getItem('currentUserRole');

    // get user profile json
    this.auth.user$.subscribe((profile) => {
      this.profileJson = profile;
      this.isEmailVerified = this.profileJson?.email_verified;
    });

    this.organizationInfoForm = this.fb.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        this.formValidation.minLengthWithoutWhitespace(3),
      ]),
      subscription: new FormControl('Basic Plan', []),
      billing_address: new FormControl('', [
        Validators.maxLength(2500),
        Validators.minLength(3),
      ]),
    });
    this.organizationInfoForm.disable();
    this.getOrganizationDetails(this.currentOrganizationCode);
  }
  /**
   * Checks if the user has permission to update the organization.
   * @returns {boolean} True if the user has update permission, otherwise false.
   */
  get hasUpdatePermission(): boolean {
    return this.userService.hasPermission('organisation:update');
  }
  /**
   * Checks if the user has permission to delete the organization.
   * @returns {boolean} True if the user has delete permission, otherwise false.
   */
  get hasDeletePermission(): boolean {
    return this.userService.hasPermission('organisation:delete');
  }
  /**
   * Checks if the user has permission to delete members.
   * @returns {boolean} True if the user has member delete permission, otherwise false.
   */
  get hasMemberDeletePermission(): boolean {
    return this.userService.hasPermission('member:delete');
  }
  /**
   * Retrieves organization details based on the provided organization code.
   * Sets the loading state during the operation.
   * Updates the organization information form with the received details.
   *
   * @param {any} active_org_code - The organization code for which details are requested.
   */
  getOrganizationDetails(active_org_code: any) {
    this.loadingService.setLoading(true);
    this.accountSettingsService.getOrganization(active_org_code).subscribe({
      next: (resp) => {
        this.loadingService.setLoading(false);
        this.orgDetails = resp;
        this.organizationInfoForm.patchValue(this.orgDetails); // Values are assigned
      },
      error: (error: HttpErrorResponse) => {
        this.loadingService.setLoading(false);
        this.snackbarService.showSnackbar(error.error.detail, '', 'error');
      },
    });
  }
  /**
   * Toggles the edit button, enabling or disabling the organization information form.
   * If the form is enabled, it resets and patches the values from the organization details.
   */
  toggleEditButton() {
    this.showSaveButton = !this.showSaveButton;
    this.organizationInfoForm.enable();

    // this.organizationInfoForm.get('billing_address')?.disable();
    this.organizationInfoForm.get('subscription')?.disable();
    this.organizationInfoForm.reset();
    this.organizationInfoForm.patchValue(this.orgDetails);
    this.allowUpgrade = environment.feature_flags.UPGRADE_PLAN;
  }
  /**
   * Saves the edited values from the organization information form.
   * Disables the form, sets loading state, and sends the edited values to the server.
   * Updates organization details and triggers necessary actions upon success.
   */
  saveEditedValue() {
    this.organizationInfoForm.disable();
    this.loadingService.setLoading(true);
    this.showSaveButton = !this.showSaveButton;
    // const formGroup = this.organizationInfoForm;
    // const nonNullValues: any = {};
    // // Extract non-null and non-empty values from the form
    // Object.keys(formGroup.controls).forEach((key) => {
    //   const control = formGroup.get(key);
    //   const value = control?.value;
    //   // Check if the value is not null (or undefined) and not an empty string
    //   if (value !== null && value !== undefined && value !== '') {
    //     nonNullValues[key] = value;
    //   }
    // });

    // Send edited values to the server
    this.accountSettingsService
      .editOrganization(
        this.currentOrganizationCode,
        this.organizationInfoForm.value
      )
      .subscribe((resp) => {
        this.userService.me().subscribe({
          next: (resp: any) => {
            this.getOrganizationDetails(this.currentOrganizationCode);
            this.snackbarService.showSnackbar(
              'Workspace information edited successfully',
              '',
              'success'
            );
            this.allowUpgrade = environment.feature_flags.UPGRADE_PLAN;
            this.accSettingCompo.getMemberships();
          },
        });
      });
  }

  /**
   * Opens the dialog for upgrading the plan.
   */

  openUpdradePlanDialog = (): void => {
    const dialogRef = this.dialog.open(UpgradePlanComponent, {
      width: '450px',
      disableClose: true,
      hasBackdrop: true,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  };
}
