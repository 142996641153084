<div class="batch-details-dialog-container" fxLayout="row">
  <div fxLayout="column" fxFlex="100">
    <p class="batch-detail-title">Details</p>
    <div fxLayout="column" fxLayoutGap="10px">
      <div class="info-row" fxLayout="column" fxLayoutGap="5px">
        <span class="title">Uploaded by</span>
        <span class="row-content">{{batchDetails.uploaded_by}}</span>
      </div>
      <div class="info-row" fxLayout="column" fxLayoutGap="5px">
        <span class="title">Uploaded on</span>
        <span class="row-content">{{batchDetails.uploaded_on}}</span>
      </div>
      <div class="info-row" fxLayout="column" fxLayoutGap="5px">
        <span class="title">Last updated on</span>
        <span class="row-content">{{batchDetails.updated_on}}</span>
      </div>
      <div class="info-row" fxLayout="column" fxLayoutGap="5px">
        <span class="title">Assignee</span>
        <div *ngFor="let assignee of batchDetails.assignee" fxLayout="column">
          <span class="row-content">{{assignee}}</span>
        </div>
      </div>
      <div class="info-row" fxLayout="column" fxLayoutGap="5px">
        <span class="title">Rows</span>
        <span class="row-content">{{batchDetails.row_count}}</span>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="close-dialog" fxFlex fxLayoutAlign="start start">
    <mat-icon (click)="onClose()">close</mat-icon>
  </div>
</div>
