/**** Directive to check if text is overflowing to show tooltip */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[contentOverflowTooltip]',
  exportAs: 'isContentOverflowing',
})
export class ContentOverflowTooltipDirective {
  public isContentOverflowing: boolean;
  constructor(private elementRef: ElementRef) {}

  // @Input('isOutOfBound') isContentOverflowing = false;
  @HostListener('mouseenter')
  onMouseEnter() {
    this.checkOverflow();
  }

  private checkOverflow() {
    const element = this.elementRef.nativeElement;
    const isOverflowing = element.scrollWidth > element.clientWidth;

    if (isOverflowing) {
      this.isContentOverflowing = true;
    } else {
      this.isContentOverflowing = false;
    }
  }
}
