import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {
  private onlineSubject: BehaviorSubject<boolean>;

  constructor() {
    this.onlineSubject = new BehaviorSubject<boolean>(navigator.onLine);
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  private updateOnlineStatus() {
    this.onlineSubject.next(navigator.onLine);
  }

  isOnline(): Observable<boolean> {
    return this.onlineSubject.asObservable();
  }
}
