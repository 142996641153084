import { Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HomeComponent } from 'src/app/_components/dashboard/home/home.component';
import { HomeService } from 'src/app/_services/home.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { environment } from 'src/environments/environment';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  form: FormGroup;
  userEmail: any;
  //membership
  orgList: any[] = [];
  selectedOrg: any;
  selectedOrgCode: any;
  orgCode: any;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private homeService: HomeService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.orgCode = localStorage.getItem('currentOrganizationCode');
    // this.orgCode = 10;
    let user_details = localStorage?.getItem('user');
    if (user_details) {
      this.userEmail = JSON.parse(user_details).email;
    }
    this.form = this.formBuilder.group({
      inputFields: this.formBuilder.array([this.createInputField()]),
    });
  }

  get inputFields(): FormArray {
    return this.form.get('inputFields') as FormArray;
  }

  // create the custom validator function
  MyCustomValidator(control: AbstractControl) {
    let isValid = true;
    if (this.data) {
      if (this.data?.assignee?.includes(control.value)) {
        isValid = false;
      }
    }
    // returns null if value is valid, or an error message otherwise
    return isValid
      ? null
      : {
          myCustomError:
            'This user is your team member. So you dont have to share the output.',
        };
  }
  gte(val: any, email: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid = true;
      // console.log(val.assignee);
      if (val?.assignee?.includes(control.value) && email != control.value) {
        isValid = false;
      }

      // returns null if value is valid, or an error message otherwise
      return isValid
        ? null
        : {
            myCustomError:
              'This user is your team member. So you dont have to share the output.',
          };
    };
  }

  duplicateemailCheck(val: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid = true;

      if (val?.includes(control.value)) {
        isValid = false;
      }

      // returns null if value is valid, or an error message otherwise
      return isValid
        ? null
        : {
            duplicateEmailError: true,
          };
    };
  }

  loggedinemailCheck(userEmail: any): ValidatorFn {
    // if(userEmail){
    //   userEmail=userEmail[0];
    // }
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid = true;
      // console.log(userEmail)
      if (userEmail) {
        if (userEmail === control.value) {
          isValid = false;
        }
      }

      // returns null if value is valid, or an error message otherwise
      return isValid
        ? null
        : {
            loggedinemailError: true,
          };
    };
  }

  createInputField(): FormControl {
    return this.formBuilder.control('', [
      Validators.required,
      Validators.maxLength(150),
      /* validator for no special char at beginning/end + domain name ***/
      /** commenting for dev */
      Validators.pattern(
        environment.feature_flags.LENIENT_EMAIL_VALIDATION
          ? '^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
          : '^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
      ),
      this.loggedinemailCheck(this.userEmail),
      this.gte(this.data, this.userEmail),
      this.duplicateemailCheck(this.form?.get('inputFields')?.value),
    ]);
  }

  addInputField(): void {
    this.inputFields.push(this.createInputField());
  }

  removeInputField(i: number): void {
    this.inputFields.removeAt(i);
  }

  closeDialog() {
    this.dialogRef.close('close');
  }

  //share a batch
  submit() {
    let payload = {
      batch_id: this.data.batch_id,
      email_ids: this.form.get('inputFields')?.value,
    };
    this.homeService.shareBatch(payload, this.orgCode).subscribe({
      next: (resp) => {
        // this.loadingService.setLoading(false);
        this.closeDialog();
        this.snackbarService.showSnackbar(
          'You have successfully shared the output',
          '',
          'success'
        );
      },
      error: (error) => {
        // this.loadingService.setLoading(false);
        // this.snackbarService.showSnackbar(
        //   error.error.detail,
        //   ' ',
        //   'error'
        // );
      },
    });

    // console.log(this.form.get('inputFields')?.value)
  }
}
