import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss'],
})
export class InviteMembersComponent implements OnInit {
  invitemembForm: FormGroup;
  orgCode: any;
  defMembers = [
    {
      email: '',
    },
  ];
  addedMemberForm: any;

  constructor(
    private fb: FormBuilder,
    private accountSettingsService: AccountSettingsService,
    private snackbarService: SnackbarService,
    public inviteMembersDialogRef: MatDialogRef<InviteMembersComponent>
  ) {
    this.invitemembForm = this.fb.group({
      members: this.fb.array(this.defMembers.map((mem) => this.addEmail(mem))),
    });
  }

  ngOnInit(): void {
    this.orgCode = localStorage.getItem('currentOrganizationCode');
  }

  get members() {
    return this.invitemembForm.get('members') as FormArray;
  }

  getTeamMembersCtrl() {
    return (this.invitemembForm.get('members') as FormArray).controls;
  }

  addEmail(member: any): FormGroup {
    this.addedMemberForm = this.fb.group({
      email: [
        member.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            environment.feature_flags.LENIENT_EMAIL_VALIDATION
              ? '^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
              : '^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
          ),
          Validators.maxLength(150),
        ],
      ],
    });

    return this.addedMemberForm;
  }

  addNewEmail(): void {
    this.members.push(
      this.fb.group({
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              environment.feature_flags.LENIENT_EMAIL_VALIDATION
                ? '^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
                : '^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\\.[a-z]{2,3}$'
            ),
            Validators.maxLength(150),
          ],
        ],
      })
    );
  }

  inviteAllEmails = () => {
    let emailList = this.members.value.map((member: any) => member.email);
    this.accountSettingsService
      .inviteUserByEmail(this.orgCode, emailList)
      .subscribe({
        next: (resp: any) => {
          this.inviteMembersDialogRef.close('success');
          // should fetch from resp.detai
          this.snackbarService.showSnackbar(
            'Invite sent to user',
            '',
            'success'
          );
        },
        error: (error: HttpErrorResponse) => {
          this.inviteMembersDialogRef.close('cancel');
          this.snackbarService.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };

  removeEmail() {
    this.members.removeAt(this.members.length - 1);
  }

  removeInputField(i: number): void {
    this.members.removeAt(i);
  }

  deleteEmail(index: number) {
    this.members.removeAt(index);
  }

  onCancel = () => {
    this.inviteMembersDialogRef.close('cancel');
  };
}
