import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'app-select-check-all',
  template: `
    <mat-checkbox
      class="mat-option"
      [disableRipple]="true"
      [indeterminate]="isIndeterminate()"
      [checked]="isChecked()"
      (click)="$event.stopPropagation()"
      (change)="toggleSelection($event)"
      [ngStyle]="{
        color: isChecked() || isIndeterminate() ? '#1042F1' : '#506480',
        fontWeight: isChecked() || isIndeterminate() ? '600' : ''
      }"
    >
      {{ text }}
    </mat-checkbox>
  `,
  styles: [
    `
      ::ng-deep.mat-checkbox-inner-container {
        opacity: 0;
        position: absolute;
        height: 0px;
        width: 0px;
        overflow: hidden;
        display: hidden;
        margin-right: -11px !important;
      }

      .mat-checkbox-label {
        cursor: pointer;
      }
      .mat-option {
        font-size: 14px;
      }
      .mat-option:hover {
        background: none !important;
      }
    `,
  ],
})
export class SelectCheckAllComponent implements OnInit {
  @Input() model: NgModel;
  @Input() values = [];
  @Input() text = 'Select All';
  @Output() childEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  isChecked(): boolean {
    this.childEvent.emit();
    return (
      this.model?.value &&
      this.values.length &&
      this.model.value.length === this.values.length
    );
  }

  isIndeterminate(): boolean {
    return (
      this.model.value &&
      this.values.length &&
      this.model.value.length &&
      this.model.value.length < this.values.length
    );
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.update.emit(this.values);
    } else {
      this.model.update.emit([]);
    }
  }
}
