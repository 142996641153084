<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <!-- input file template -->
    <h2>Review input file</h2>
    <span class="input-file-desc">Please select sheet and header from the input file. The header row refers to the
      column header, which typically contains attribute titles.</span>
    <div class="header-rows-wrapper">
      <!-- Table -->
      <div class="table-section">
        <mat-tab-group (selectedTabChange)="onTabInputChange($event)">
          <mat-tab [selectedIndex]="singleInputTab ? 0 : null" [disableRipple]="true" *ngFor="let inputTab of inputTabs"
            [disabled]="singleInputTab">
            <ng-template mat-tab-label>{{ inputTab }}</ng-template>
            <!-- Add tab content here for each tab -->
            <div class="list-heading">Select header row</div>
            <!-- horizontal-table.component.html -->
            <div class="table-container">
              <table>
                <tbody>
                  <tr *ngFor="let row of inputData; let i = index" [class.selected]="selectedInputRow === i">
                    <td>
                      <input style="cursor: pointer" type="radio" [name]="'rowRadio1'"
                        [checked]="selectedInputRow === i" (click)="selectInputRow(i, row)" />
                    </td>
                    <td *ngFor="let cell of row">{{ cell }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <!-- Output file template -->
    <h2 #outputTable style="margin-top: 32px">Review output file</h2>
    <span class="input-file-desc">Please select sheet and header from the output file. The header row refers to the
      column header, which typically contains attribute titles.</span>
    <div class="header-rows-wrapper">
      <!-- Table -->
      <div class="table-section">
        <mat-tab-group #outputTabGroup (selectedTabChange)="onTabOutputChange($event)">
          <mat-tab [selectedIndex]="singleOutputTab ? 0 : null" [disableRipple]="true"
            *ngFor="let outputTab of outputTabs" [disabled]="singleOutputTab">
            <ng-template mat-tab-label>{{ outputTab }}</ng-template>
            <!-- Add tab content here for each tab -->
            <div class="list-heading">Select header row</div>
            <!-- horizontal-table.component.html -->
            <div class="table-container">
              <table>
                <tbody>
                  <tr *ngFor="let row of outputData; let i = index" [class.selected]="selectedOutputRow === i">
                    <td>
                      <input style="cursor: pointer" type="radio" [name]="'rowRadio2'"
                        [checked]="selectedOutputRow === i" (click)="selectOutputRow(i, row)" />
                    </td>
                    <td *ngFor="let cell of row">{{ cell }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <!-- Action button -->
      <div class="template-buttons" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px">
        <div>
          <button (click)="clearSelection()" class="clear-button" mat-stroked-button type="submit">
            Clear
          </button>
        </div>
        <div>
          <button [disabled]="
              selectedInputRowData.length == 0 ||
              selectedOutputRowData.length == 0
            " (click)="configureInputHeader()" class="continue-button" mat-raised-button type="submit" color="primary">
            <span>Save and continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
