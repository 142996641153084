<div fxFlex fxLayout="row" fxLayoutAlign="start center" class="error-wrapper">
  <div class="error-content" fxLayout="column" fxLayoutGap="10px">
    <span class="error-heading">Oops! Something went wrong</span>
    <span class="error-subheading">There could be a misconfiguration in the system or a service outrage. We track these
      errors automatically, but if the problem persists please use the feedback button at the bottom right of your
      screen.</span>
    <div class="action-btns" fxLayout="row" fxLayoutGap="20px">
      <button mat-flat-button color="warn" (click)="backClicked()">Go back</button>
      <button mat-stroked-button class="hide-details-btn" (click)="toggleVisibilityErrorDetails()"
        *ngIf="trackingId || auth0ErrorDescription || auth0ErrorTitle">{{showAuth0ErrorDetails? 'Hide':
        'Show'}} details</button>
    </div>
    <div class="error-description" *ngIf="trackingId || auth0ErrorDescription || auth0ErrorTitle"
      id="auth0-error-detail" fxLayout="column" fxLayoutGap="5px">
      <span class="tracking-id" *ngIf="trackingId"><span class="label">TRACKING ID: </span><span
          class="value">{{trackingId}}</span> </span>
      <span class="error-detail"><span *ngIf="auth0ErrorTitle">{{auth0ErrorTitle}}:</span>
        <span *ngIf="auth0ErrorDescription">{{auth0ErrorDescription}}</span></span>
      <span class="report-issue-link" (click)="triggerMarkerIo()">Report this issue</span>
    </div>
  </div>
</div>
