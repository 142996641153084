import {
  Component,
  ElementRef,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ExistingFilesComponent } from 'src/app/_dialogs/existing-files/existing-files.component';
import { BatchUploadService } from 'src/app/_services/batch-upload.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSelectChange } from '@angular/material/select';
import { DataSharingService } from 'src/app/_services/data-sharing.service';

@Component({
  selector: 'app-upload-input-file',
  templateUrl: './upload-input-file.component.html',
  styleUrls: ['./upload-input-file.component.scss'],
})
export class UploadInputFileComponent implements OnInit {
  // ======================== input file
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl('');
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

  announcer = inject(LiveAnnouncer);

  // ========================
  uploadForm: FormGroup;
  inputFile?: File;
  outputFile?: File;
  showError: boolean;
  organisation_id: string | null;
  batchList: any[] = [
    {
      task_name: 'Ayushman',
      file_type: 'tagName.csv'
    },
    {
      task_name: 'Ayushrerman',
      file_type: 'tagName.xlsx'
    },
    {
      task_name: 'Ayusdsdshman',
      file_type: 'tagName.zip'
    }
  ];
  inputFileName: string | null = null;
  outputFileName: string | null = null;
  isNameUnique: boolean | null = null;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('fileOutput') fileOutput!: ElementRef<HTMLInputElement>;
  private searchTerms$ = new Subject<string>();
  private cancelBatchCreation: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();
  moveForward: boolean = true;
  batchId: number | null;
  taskNameQueryParam: string | null;
  isZippedInputFileUploaded: boolean = false;
  taggedFileExtension: any = ['.csv', '.xlsx', '.zip'];
  inputFileUploadAllowedExtensions: string[] = [];
  inputFileExtension: any;
  isPreDefinedTagSelected: boolean = false;
  customTagObject: any;
  selectedCustomFunctionID: any;
  fileExtensionHolder: any;
  selectedTemplateValue: any;
  enableGuidelineIcon: boolean = false;
  
// batch: any;
  // isNetworkOnline: boolean = navigator.onLine;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private batchUploadService: BatchUploadService,
    private router: Router,
    private route: ActivatedRoute,
    private formValidation: ValidationService,
    private loadingService: LoadingService,
    private dataSharingService: DataSharingService
  ) {
    // =====================
    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => (fruit ? this._filter(fruit) : this.allFruits.slice())),
    );
    // =====================
    this.cancelBatchCreation = this.batchUploadService.call$.subscribe(
      (resp: boolean) => {
        this.moveForward = resp;
        if (resp == false) {
          this.unsubscribe$.next();
          this.unsubscribe$.complete();
        }
      }
    );
  }

  // =====================================
  selectedValue: null;

  onSelectionChange(event: MatSelectChange) {
    // console.log('event: ', event);
    this.selectedValue = event.value;
    this.selectedCustomFunctionID = event.value.id;
    this.fileExtensionHolder = event.value.input_file_format;

    this.taggedFileExtension = event.value.input_file_format.split('.')[1];
    this.inputFileUploadAllowedExtensions = [];
    this.inputFileUploadAllowedExtensions.push(this.taggedFileExtension);
    // console.log('check the array: ', this.inputFileUploadAllowedExtensions);
    this.isPreDefinedTagSelected = true;
    this.clearInputFile();
    this.clearOutputFile();
  }

  getAcceptedFileTypes = () => {
    // console.log('Allowed ext: ', this.taggedFileExtension);
    
    return this.taggedFileExtension;
  }

  removeSelection() {
    const defaultExtensionList = ['.csv', '.xlsx', '.zip'];
    this.selectedValue = null;
    this.uploadForm.get('batch')?.reset(null);
    this.inputFileUploadAllowedExtensions = [];
    for (let index = 0; index < defaultExtensionList.length; index++) {
      this.inputFileUploadAllowedExtensions.push(defaultExtensionList[index]);
    }
    // console.log('check the array: ', this.inputFileUploadAllowedExtensions);
    this.clearInputFile();
    this.taggedFileExtension = defaultExtensionList;
    this.isZippedInputFileUploaded = false;
    this.isPreDefinedTagSelected = false;
  }
  // 
  add(event: any): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);

      this.announcer.announce(`Removed ${fruit}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }


  // =====================================

  ngOnInit(): void {
    this.taskNameQueryParam =
      this.route.snapshot.queryParamMap.get('task_name');
    this.organisation_id = localStorage.getItem('currentOrganizationCode');
    if (this.organisation_id) {
      this.getCustomTagsAndRecipes(this.organisation_id);
    }
    // this.isNetworkOnline = navigator.onLine;

    // window.addEventListener('online', () => {
    //   this.isNetworkOnline = true;
    //   if (this.batchList.length == 0) {
    //     this.getBatchList(this.organisation_id, this.taskNameQueryParam);
    //   }
    // });

    window.addEventListener('offline', () => {
      // this.isNetworkOnline = false;
    });
    // this.getBatchList(this.organisation_id, this.taskNameQueryParam);

    this.uploadForm = this.formBuilder.group({
      taskName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        this.formValidation.TaskNamePatternValidation(),
        this.formValidation.minLengthWithoutWhitespace(3),
      ]),
      // description: new FormControl('', Validators.maxLength(1000)),
      batch: [this.batchId],
    });
    // 
    // this.uploadForm.addControl('chooseSonepar', new FormControl(false));
    // this.uploadForm.addControl('chooseHomeDepot', new FormControl(false));
    // 
    // Subscribe to changes in the batch control
    this.uploadForm.get('batch')?.valueChanges.subscribe((batchId) => {
      // 
      // console.log('check batchId: ', batchId);
      
      // 
      // Update the batchId property
      this.batchId = batchId;
    });

    // Add this to hide the error message when typing
    this.uploadForm.get('taskName')?.valueChanges.subscribe(() => {
      this.showError = false;
    });
    // These are being set while uplaoding a file with prev setiings and clearing here because user might not reach to interim due to any issue in progress page and these will be remain and cause issue to next batch upload
    localStorage.removeItem('task_name');
    localStorage.removeItem('report');
    localStorage.removeItem('isPrevSetBatch');
  }

  ngAfterViewInit(): void {
    //<--------To check valid task name------->
    // this.uploadForm
    //   .get('taskName')
    //   ?.valueChanges.pipe(
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     switchMap((value: string) =>
    //       this.batchUploadService
    //         .checkNameUniqueness(value, this.organisation_id)
    //         .pipe(
    //           catchError((error) => {
    //             this.isNameUnique = false;
    //             this.showError = true;
    //             // Handle error as needed
    //             return of(null);
    //           })
    //         )
    //     )
    //   )
    //   .subscribe({
    //     next: (resp) => {
    //       this.trimWhitespace();
    //       if (resp !== null) {
    //         // Only set isNameUnique to true when the response is successful
    //         this.isNameUnique = true;
    //       }
    //     },
    //   });
  }

  ngOnDestroy() {}

  // onKeyUp(event: any) {
  //   const taskName = event.target.value;
  //   this.searchTerms$.next(taskName);
  // }

  getCustomTagsAndRecipes = (organisation_id: string) => {
    this.batchUploadService.fetchCustomTagsAndRecipes(organisation_id).subscribe({
      next: (resp) => {
        // console.log(resp);
        this.customTagObject = resp.custom_tags;
        
      },
      error: (error) => {
        // console.log(error);
        
      }
    });
  }

  // chooseThisAsOutputTemplate(ev: any, templateControlName: any) {
  //   console.log('ev: ', ev);
  //   console.log('templateControlName: ', templateControlName);
  //   this.selectedTemplateValue = this.uploadForm.get(`${templateControlName}`)?.value;
  //   console.log('selectedTemplateValue: ', this.selectedTemplateValue);

  //   if (templateControlName==='chooseSonepar') {
  //     if (this.selectedTemplateValue) {
  //       console.log('chooseSonepar got selected');
  //     } else {
  //       console.log('chooseSonepar got de-selected');
  //     }     
  //   }
    
  //   if(templateControlName==='chooseHomeDepot'){
  //     if (this.selectedTemplateValue) {
  //       console.log('chooseHomeDepot got selected');
  //     } else {
  //       console.log('chooseHomeDepot got de-selected');
  //     }  

  //   }
  // }
  chooseThisAsOutputTemplate(ev: any, templateControlName: string) {
    this.selectTemplate(templateControlName);
  }
  
  selectTemplate(templateControlName: string) {
    const otherTemplateName = templateControlName === 'chooseSonepar' ? 'chooseHomeDepot' : 'chooseSonepar';
    
    this.uploadForm.get(templateControlName)?.setValue(true);
    this.uploadForm.get(otherTemplateName)?.setValue(false);
  }

  async taskNameUniqueness(taskName: string): Promise<void> {
    try {
      const resp = await this.batchUploadService
        .checkNameUniqueness(taskName, this.organisation_id)
        .toPromise();

      // Only set isNameUnique to true when the response is successful
      if (resp !== null) {
        this.isNameUnique = true;
      }
    } catch (error) {
      this.isNameUnique = false;
      this.showError = true;
      this.snackBar.showSnackbar('Batch name is not unique', '', 'error');
    } finally {
      this.loadingService.setLoading(false);
    }
  }

  // Method to be called on button click to trim whitespace from both start and end
  trimWhitespace() {
    const taskNameControl = this.uploadForm.get('taskName') as FormControl;

    if (taskNameControl.value && typeof taskNameControl.value === 'string') {
      const trimmedValue = taskNameControl.value.trim(); // Trim from both start and end

      if (trimmedValue !== taskNameControl.value) {
        // Update the control value with the trimmed value
        taskNameControl.setValue(trimmedValue);
      }
    }
  }

  onInputFileSelect(event: Event) {
    this.inputFile = (event.target as HTMLInputElement).files?.[0];
    // this.inputFile = file;

    // 
    // console.log('check file inside onInputFileSelect function: ', this.inputFile);
    
    // 
    if (!this.inputFile) return;

    const fileExtension = this.inputFile.name.split('.').pop()?.toLowerCase();
    // console.log('check file ext: ', fileExtension);
    const maxSizeInBytes = 100 * 1024 * 1024; // 100MB


    // 
    if (fileExtension === 'zip' && !this.isPreDefinedTagSelected) {
      this.snackBar.showSnackbar(`Uploading a zip file not allowed without selecting custom tags`, '', 'error');
      this.clearInputFile();
      return;
    }
    // 
    

    const isValidExtension = fileExtension && (
      this.isPreDefinedTagSelected
        ? this.inputFileUploadAllowedExtensions.includes(fileExtension)
        : ['csv', 'xlsx', 'zip'].includes(fileExtension)
    );

    if (!isValidExtension) {
      this.showErrorAndClear(this.isPreDefinedTagSelected
        ? `Please select a '.${this.inputFileUploadAllowedExtensions[0]}' file`
        : 'Please select a csv, xlsx or zip file'
      );
      return;
    }

    if (this.isFileEmpty(this.inputFile.size)) {
      this.showErrorAndClear("File can't be empty");
      return;
    }

    if (this.inputFile.size > maxSizeInBytes) {
      this.showErrorAndClear('Input file size should not exceed 100MB');
      return;
    }

    this.inputFileName = this.inputFile.name;
    this.isZippedInputFileUploaded = fileExtension === 'zip';
  }

  private showErrorAndClear(message: string) {
    this.snackBar.showSnackbar(message, '', 'error');
    this.clearInputFile();
  }

  clearInputFile() {
    this.fileInput.nativeElement.value = '';
    this.inputFileName = null;
  }

  onOutputFileSelect(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    if (!fileExtension || !['csv', 'xlsx'].includes(fileExtension)) {
      this.showErrorAndClearOutput('Please select a CSV or Excel file');
    } else if (this.isFileEmpty(file.size)) {
      this.showErrorAndClearOutput("File can't be empty");
    } else if (file.size > maxSizeInBytes) {
      this.showErrorAndClearOutput('Sample output file size should not exceed 10MB');
    } else {
      this.outputFile = file;
      this.outputFileName = file.name;
    }
  }

  private showErrorAndClearOutput(message: string) {
    this.snackBar.showSnackbar(message, '', 'error');
    this.clearOutputFile();
  }

  clearOutputFile() {
    this.fileOutput.nativeElement.value = '';
    this.outputFileName = null;
  }

  isFileEmpty(size: number) {
    if (size === 0) {
      return true;
    }
  }

  truncateFileName(fileName: string, maxLength: number): string {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      const truncatedName = fileName.substring(0, maxLength - 3);
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      return truncatedName + '..' + extension;
    }
  }

  //dialog
  openFileSelector(type: string) {
    const isInputFile = type === 'ip';
    const dialogRef = this.dialog.open(ExistingFilesComponent, {
      width: '826px',
      autoFocus: false,
      disableClose: true,
      data: {
        title: `${isInputFile ? 'Input' : 'Sample Output'} File - Upload`,
        from: type,
      },
    });

    dialogRef.afterClosed().subscribe(({ data }) => {
      if (data) {
        // console.log('data: ', data);
        // console.log(typeof data.file_name);
        
        // console.log('check1: ', this.inputFileUploadAllowedExtensions);
        // console.log('check2: ', this.isPreDefinedTagSelected);
        this.inputFileExtension = data.extension;
        // console.log('inputFileExtension: ', this.inputFileExtension);

        // 
        if (this.inputFileExtension === 'zip' && !this.isPreDefinedTagSelected) {
          this.snackBar.showSnackbar(`Uploading a zip file not allowed without selecting custom tags`, '', 'error');
          this.clearInputFile();
          return;
        }
        // 
        
        if (isInputFile) {
          if (this.isPreDefinedTagSelected) {
            if (this.inputFileExtension === this.inputFileUploadAllowedExtensions[0]) {
              this.inputFileId = data.id;
              this.inputFileName = data.file_name;
            } else {
              this.snackBar.showSnackbar(
                `Please select a \'\.${this.inputFileUploadAllowedExtensions[0]}\' file`,
                '',
                'error'
              );
              this.clearInputFile();
            }
          } else {
            
            this.inputFileUploadAllowedExtensions = ['csv', 'xlsx', 'zip'];
            // console.log('check4: ', this.inputFileExtension);
            // console.log('check5: ', this.inputFileUploadAllowedExtensions);
            if (!this.inputFileExtension || this.inputFileUploadAllowedExtensions.indexOf(this.inputFileExtension) === -1) {
              this.snackBar.showSnackbar(
                `Please select a csv, xlsx or zip file`,
                '',
                'error'
              );
              this.clearInputFile();
            } else {
              this.inputFileId = data.id;
              this.inputFileName = data.file_name;
              this.inputFileExtension = data.extension;
            }
            
          }
          // 
         
          // 
          
          
        } else {
          this.outputFileId = data.id;
          this.outputFileName = data.file_name;
        }
      }
    });
  }
  //to clear the id of previously selected files
  clearPreviousSelection(from: string) {
    if (from == 'ip') {
      this.inputFileId = null;
      this.isZippedInputFileUploaded = false;
    } else {
      this.outputFileId = null;
    }
  }
  // ======
  trimFileName(fileName: string, maxLength: number): string {
    // Extract the file extension from the fileName
    const extensionIndex = fileName.lastIndexOf('.');
    
    // Handle cases where there is no extension
    if (extensionIndex === -1) {
        return fileName.length > maxLength ? fileName.substring(0, maxLength) + '...' : fileName;
    }
    
    const extension = fileName.substring(extensionIndex);
    const nameWithoutExtension = fileName.substring(0, extensionIndex);
    
    // Calculate the maximum length for the base name
    const maxLengthForBaseName = maxLength;

    // Trim the base name if necessary and add ellipsis
    const trimmedBaseName = nameWithoutExtension.length > maxLengthForBaseName
        ? nameWithoutExtension.substring(0, maxLengthForBaseName) + '...'
        : nameWithoutExtension;

    // Return the trimmed base name with the ellipsis (if added) and extension
    return trimmedBaseName + extension;
}




  // ======

  fileCount: number;
  // async onSubmit(): Promise<void> {
  //   this.loadingService.setLoading(true);
  //   this.trimWhitespace();
  //   // Wait for the result of taskNameUniqueness before proceeding
  //   await this.taskNameUniqueness(this.uploadForm.value.taskName);
  //   if (this.isNameUnique) {
  //     this.loadingService.setLoading(false);
  //     this.moveForward = true;
  //     if (this.uploadForm.valid) {
  //       this.moveForward = true;
  //       if (this.inputFileId == null && this.outputFileId != null) {
  //         //when input file is selected from previous upload
  //         this.uploadInputFile((this.fileCount = 1));
  //       } else if (this.outputFileId == null && this.inputFileId != null) {
  //         //when output file is selected from previous upload
  //         this.uploadOutputFile((this.fileCount = 1));
  //       } else if (this.inputFileId != null && this.outputFileId != null) {
  //         //when no input and output files are selected from previous upload
  //         this.batchUploadComplete(this.uploadForm.value);
  //       } else if (this.inputFileId == null && this.outputFileId == null) {
  //         this.uploadInputFile((this.fileCount = 2));
  //       }
  //       this.router.navigate(['/dashboard/upload-progress']);
  //       // Do something with the form data, e.g., save to backend
  //       // console.log(this.uploadForm.value);
  //     }
  //   }
  // }
  // 
  showGuidelines = () => {
    this.enableGuidelineIcon = !this.enableGuidelineIcon;
  };
  // 
  async onSubmit(): Promise<void> {
    this.loadingService.setLoading(true);
    try {
      this.trimWhitespace();
      await this.taskNameUniqueness(this.uploadForm.value.taskName);

      // console.log('check upload form: ', this.uploadForm.valid);
      // console.log('inputFileId: ', this.inputFileId);
      // console.log('outputFileId: ', this.outputFileId);

      
      
      
      if (this.isNameUnique && this.uploadForm.valid) {
        this.loadingService.setLoading(true);
        this.moveForward = true;
        if (this.inputFileId == null && this.outputFileId != null) {
          // console.log('one');
          
          await this.uploadInputFile((this.fileCount = 1));
          
        } else if (this.outputFileId == null && this.inputFileId != null) {
          // console.log('two');

          // await this.uploadInputFile((this.fileCount = 1));
          await this.batchUploadComplete(this.uploadForm.value, this.isPreDefinedTagSelected, this.isZippedInputFileUploaded);

        } else if (this.inputFileId != null && this.outputFileId != null) {
          // console.log('three');

          await this.batchUploadComplete(this.uploadForm.value, this.isPreDefinedTagSelected, this.isZippedInputFileUploaded);
        } else if (this.inputFileId == null && this.outputFileId == null) {
          // console.log('four');
          // console.log(this.isZippedInputFileUploaded);
          await this.uploadInputFile((this.fileCount = 2));
          
        }
        // 
        // if (!this.isPreDefinedTagSelected) {
        //   console.log('five');

        //   this.router.navigate(['/dashboard/upload-progress']);
        // } else {
        //   console.log('six');

        //   this.router.navigate(['/dashboard-v1']);
        // }
      }
    } catch (error) {
      // Handle error (e.g., show error message)
      console.error('Error during submission:', error);
      this.loadingService.setLoading(false);
      
    } finally {
      // this.loadingService.setLoading(false);
    }
  }
  
  outputFileId: number | null = null;
  inputFileId: number | null = null;
  mainFileProgress: number = 0;

  // main file upload
  uploadInputFile(fileCount: number) {
    // 
    // console.log('check inputFile inside uploadInputFile function: ', this.inputFile);
    if (!this.inputFile) {
      console.error('File is undefined or null');
      // You might want to throw an error or return early here
      return;
    }

    // console.log('inputFile: ', this.inputFile);
    
    
    // 
    this.batchUploadService
      .uploadFile(this.inputFile, 'input_file', this.organisation_id, fileCount)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (resp) => {
          this.inputFileId = resp.inputFileId;
          this.mainFileProgress = Math.round(resp.progress);
          // console.log(
          //   this.mainFileProgress,
          //   '=inputProgress',
          //   this.inputFileId
          // );
          if (this.mainFileProgress === 100) {
            // 
            if (this.isPreDefinedTagSelected) {
              // console.log('1');
              
              this.batchUploadComplete(this.uploadForm.value, this.isPreDefinedTagSelected, this.isZippedInputFileUploaded);
            } else {
              // console.log('1-1');

              // ==========================================
              if (this.isZippedInputFileUploaded) {
              // console.log('1-2');

                // this step will create issue bcoz it doesn't have neither batch nor output file id
                this.batchUploadComplete(this.uploadForm.value, this.isPreDefinedTagSelected, this.isZippedInputFileUploaded);
              } else {
              // console.log('1-3');

                // 
                if (this.outputFileId == null) {
                  this.uploadOutputFile((this.fileCount = 2));
                } else {
                  this.batchUploadComplete(this.uploadForm.value, this.isPreDefinedTagSelected, this.isZippedInputFileUploaded);
                }
                // 
              }
              // ==========================================
            }
            // 
          }
        },
        error: (error) => {
          this.snackBar.showSnackbar(
            'Previous task creation failed',
            '',
            'error'
          );
          this.router.navigate(['/dashboard/upload-input-file']);
        },
      });
  }

  uploadOutputFile(fileCount: number) {
    this.batchUploadService
      .uploadFile(
        this.outputFile,
        'template_output_file',
        this.organisation_id,
        fileCount
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (resp) => {
          this.outputFileId = resp.outputFileId;
          this.mainFileProgress = Math.round(resp.progress);
          // console.log(
          //   this.mainFileProgress,
          //   '=OutputProgress',
          //   this.outputFileId
          // );
          if (this.mainFileProgress === 100) {
            this.batchUploadComplete(this.uploadForm.value, this.isPreDefinedTagSelected, this.isZippedInputFileUploaded);
          }
        },
        error: (error) => {
          this.snackBar.showSnackbar(
            'Previous task creation failed',
            '',
            'error'
          );
          this.router.navigate(['/dashboard/upload-input-file']);
        },
      });
  }

  batchUploadComplete(batchData: any, isPreDefinedTagSelected: boolean, isZippedInputFileUploaded: boolean) {
    // 
    // console.log('upload form data: ', batchData);
    // console.log('moveForward: ', this.moveForward);
    
    
    // 
    if (this.moveForward) {
      if (batchData.batch) {
        localStorage.setItem('report', 'default');
        localStorage.setItem('isPrevSetBatch', 'true');
      }
      localStorage.setItem('task_name', batchData.taskName);
      this.batchUploadService
        .batchUploadComplete(
          this.organisation_id,
          batchData,
          this.inputFileId,
          this.outputFileId,
          this.selectedCustomFunctionID,
          isPreDefinedTagSelected,
          isZippedInputFileUploaded
        )
        .subscribe({
          next: (resp) => {
            // console.log('batchUploadComplete resp =========================================>: ', resp);
            if (resp.status==="success" && isPreDefinedTagSelected) {
              const taskNameControl = this.uploadForm.get('taskName');
              if (taskNameControl && taskNameControl.value) { // Check for null
                this.dataSharingService.setMessageForCustomTags(taskNameControl.value);
              }
              this.onReset();
              this.loadingService.setLoading(false);

              this.router.navigate(['/dashboard']);
            } else if(resp.status==="success" && !isPreDefinedTagSelected){
              
              this.onReset();
              this.loadingService.setLoading(false);

              this.router.navigate(['/select-header']);
            }
            
            // 
            // this.onReset();
            // this.router.navigate(['/select-header']);
            // 
          },

          error: (error) => {
            this.onReset();
            this.loadingService.setLoading(false);

            this.snackBar.showSnackbar(
              'Previous task creation failed',
              '',
              'error'
            );
            this.router.navigate(['/dashboard/upload-input-file']);
          },
        });
    }
  }

  onReset(): void {
    this.uploadForm.reset();
    if (this.inputFileName) {
      this.fileInput.nativeElement.value = '';
      this.inputFileName = null;
    }
    if (this.outputFileName) {
      this.fileOutput.nativeElement.value = '';
      this.outputFileName = null;
    }
  }

  //<-----getting the batch list for previous batch setting--->

  getBatchList(
    organisation_id: string | null,
    taskNameQueryParam: string | null
  ) {
    this.batchUploadService.getBatchList(organisation_id).subscribe({
      next: (data: any) => {
        this.batchList = data.batch_list;
        // console.log('batchList', this.batchList);
        // Find the batch with a matching task_name
        const matchingBatch = this.batchList.find(
          (batch) => batch.task_name === taskNameQueryParam
        );
        if (matchingBatch) {
          this.batchId = matchingBatch.id;
          this.uploadForm.get('batch')?.setValue(this.batchId);
        } else {
          // Handle the case where no match is found, you can set a default value if needed
          this.batchId = null; // Set a default value or handle the case as per your requirements
        }
      },
      error: (error: any) => {
        // console.log('error', error);
      },
    });
  }
}
