import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'position',
    'name',
    'amount',
    'no_rows',
    'action',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}

export interface PeriodicElement {
  name: string;
  position: any;
  amount: any;
  no_rows: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 'Aug 1,2023',
    name: 'Invoice #002 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #003 - Aug  2',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #004 - Aug  3',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #005 - Aug  4',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #009 - Aug  11',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  12',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #008 - Aug  14',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
  {
    position: 'Aug 1,2023',
    name: 'Invoice #007 - Aug  1',
    amount: 'USD $1500',
    no_rows: '10000',
  },
];
