import { Component, HostListener, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationDialogComponent } from 'src/app/_dialogs/confirmation-dialog/confirmation-dialog.component';
import { BatchUploadService } from 'src/app/_services/batch-upload.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent implements OnInit {
  receivedData: any = 0;
  fileCount: number = 0;
  nextFileStartFrom: any = 0; //to add progress for next file upload
  private dataSubscription: Subscription;
  private countSubscription: Subscription;
  isNewVersion: boolean;

  constructor(
    public dialog: MatDialog,
    private batchUploadService: BatchUploadService,
    private router: Router,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private appConfig: AppConfigService
  ) {
    this.countSubscription = this.batchUploadService.count$.subscribe(
      (count) => {
        this.fileCount = count;
        // console.log(count, 'fileCount');
      }
    );
    this.dataSubscription = this.batchUploadService.data$.subscribe((data) => {
      if (data != null) {
        if (this.fileCount == 2) {
          this.receivedData = data / 2 + this.nextFileStartFrom;
          if (data == 100) {
            this.nextFileStartFrom = 50;
          }
        } else {
          this.receivedData = data;
        }
      }
    });
  }

  completed: any = true;
  inProgress: any = true;
  org_id: any;
  batch_id: any;
  stepCompleted: any = 0;
  headerSelection: boolean = false;
  interval: any;
  ngOnInit(): void {
    this.isNewVersion = this.appConfig.getConfig().is_new_version;
    this.nextFileStartFrom = 0;
    this.receivedData = 0;
    this.org_id = localStorage.getItem('currentOrganizationCode');
    this.batch_id = localStorage.getItem('currentBatchId');
    this.route.queryParams.subscribe((params) => {
      this.headerSelection = params.headerSelection;
    });

    if (this.batch_id && this.headerSelection) {
      this.receivedData = 100;
      this.interval = setInterval(() => {
        if (this.stepCompleted < 4) {
          this.interimStatus();
          // this.stepCompleted++;
        } else {
          clearInterval(this.interval); // Clear the interval when stepCompleted reaches 4
        }
      }, 5000);
    }
  }
  /**
   * Tab/Browser exit alert
   * @param event
   * @returns
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (this.receivedData != 0) {
      return false;
    }
  }
  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.countSubscription.unsubscribe();
    this.receivedData = 0;
    this.nextFileStartFrom = 0;
    clearInterval(this.interval);
  }

  /** To get interim output status **/
  interimStatus() {
    this.batchUploadService
      .interimStatus(this.org_id, this.batch_id)
      .subscribe({
        next: (res) => {
          this.stepCompleted = res.step_no;
          if (this.stepCompleted == 4) {
            this.snackbarService.showSnackbar(
              'Configuration successfully completed',
              '',
              'success'
            );
            clearInterval(this.interval); // Clear the interval when stepCompleted reaches 4
            this.isNewVersion
              ? this.router.navigate(['/dashboard/view-output-new'], {
                  queryParams: {
                    batch_id: this.batch_id,
                  },
                })
              : this.router.navigate(['/dashboard/interim-output'], {
                  queryParams: { batch_id: this.batch_id, task_status: 'new' },
                });
          }
        },
        error: (err) => {
          clearInterval(this.interval);
          localStorage.removeItem('report');
          // 
          this.router.navigate(['/dashboard']);
          // 
          // this.appConfig.getConfig().is_new_version ? this.router.navigate(['/dashboard-v1']) : this.router.navigate(['/dashboard']);
          // 
          this.snackbarService.showSnackbar(err.error.detail, '', 'error');
        },
      });
  }
  openConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '421px',
      height: '211px',
    });
  }
  confirmationPopUp() {
    if (
      window.confirm(
        'Changes that you made may not be saved. Are you sure you want to leave?'
      )
    ) {
      this.batchUploadService.cancelBatchUpload();
      this.router.navigate(['/dashboard/upload-input-file']);
      // this.batchUploadService.unsubscribeCalls(); // Calling the function from batch service to unsubscribe the calls
    }
  }
}
