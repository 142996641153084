import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './batch-details.component.html',
  styleUrls: ['./batch-details.component.scss'],
})
export class BatchDetailsComponent implements OnInit {
  currentOrganizationCode: string | null;
  batchDetails: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BatchDetailsComponent>,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.batchDetails = this.data.batchDetails;
  }

  onClose = () => {
    this.dialogRef.close();
  };
}
