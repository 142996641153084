import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountSettingsService: AccountSettingsService,
    private userService: UserService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {}

  leaveOrganaization = () => {
    this.accountSettingsService
      .leaveOrganization(localStorage.getItem('currentOrganizationCode'))
      .subscribe({
        next: (resp: any) => {
          this.userService.logUserOut();
        },
        error: (error: HttpErrorResponse) => {
          this.snackbarService.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };
}
