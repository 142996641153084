import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from 'src/app/_services/snackbar.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AppConfigService } from 'src/app/_services/app-config.service';

@Component({
  selector: 'app-invite-request',
  templateUrl: './invite-request.component.html',
  styleUrls: ['./invite-request.component.scss'],
})
export class InviteRequestComponent implements OnInit {
  inviteToken: any;
  inviteeExists: boolean;
  inviteeEmail: string;
  user: any;
  enableInviteActions: boolean = false;
  signinUrl = environment.default_return_url;
  isLocal: boolean = false;
  email: any;
  constructor(
    private accountSettingsService: AccountSettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private location: Location,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    /**
     *  Retrieves the invite token from the query parameters.
     */
    this.route.queryParams.subscribe((params) => {
      this.inviteToken = params.code;
    });
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user')!);
    }
    this.getDetailsFromToken();
    this.userService.getIsLocal().subscribe({
      next: (res) => {
        this.isLocal = res;
      },
    });
  }

  /**
   * Retrieves details of the invitee using the invite token.
   * Handles navigation based on the invitee's login status and email.
   */

  getDetailsFromToken = () => {
    this.accountSettingsService
      .getInvitationDetails(this.inviteToken)
      .subscribe({
        next: (resp: any) => {
          this.inviteeExists = resp.user_exists;
          this.inviteeEmail = resp.user_email;
          // Check if there is a valid login session
          if (this.user) {
            // If invitee email is not same as logged in user email
            if (this.user.email !== this.inviteeEmail) {
              this.snackbarService.showSnackbar(
                'Please login using correct credentials to be able to accept the Invite',
                '',
                'error'
              );
              // If there is no valid login session active
              if (this.inviteeExists == false) {
                this.router.navigate(
                  ['/signup'],
                  this.isLocal
                    ? {
                        queryParams: { invite: this.inviteToken, local: true },
                      }
                    : { queryParams: { invite: this.inviteToken } }
                );
                // If user account exists - take the user to the login page | Pass invite code in query params
              } else if (this.inviteeExists == true) {
                this.userService.logUserOut(this.inviteToken);
                this.isLocal
                  ? `${window.location.href}?local=true&invite=${this.inviteToken}`
                  : `${window.location.href}?invite=${this.inviteToken}`;
              }
            } else {
              this.router.navigate(
                ['/dashboard'],
                this.isLocal
                  ? {
                      queryParams: { invite: this.inviteToken, local: true },
                    }
                  : { queryParams: { invite: this.inviteToken } }
              )
              // this.appConfig.getConfig().is_new_version ? this.router.navigate(
              //   ['/dashboard-v1'],
              //   this.isLocal
              //     ? {
              //         queryParams: { invite: this.inviteToken, local: true },
              //       }
              //     : { queryParams: { invite: this.inviteToken } }
              // ): this.router.navigate(
              //   ['/dashboard'],
              //   this.isLocal
              //     ? {
              //         queryParams: { invite: this.inviteToken, local: true },
              //       }
              //     : { queryParams: { invite: this.inviteToken } }
              // );
              // 
              
              // 
            }
          } else {
            // if user account doesn't exist - take the user to sign up page | pass invite code in query param
            if (this.inviteeExists == false) {
              this.router.navigate(
                ['/signup'],
                this.isLocal
                  ? {
                      queryParams: {
                        invite: this.inviteToken,
                        local: true,
                        email: this.inviteeEmail,
                      },
                    }
                  : {
                      queryParams: {
                        invite: this.inviteToken,
                        email: this.inviteeEmail,
                      },
                    }
              );
              // If user account exists - take user to login page | Pass invite code in query params
            } else if (this.inviteeExists == true) {
              this.userService.logUserOut(this.inviteToken);
              this.isLocal
                ? `${window.location.href}?local=true&invite=${this.inviteToken}`
                : `${window.location.href}?invite=${this.inviteToken}`;
            }
          }
        },
        error: (error: HttpErrorResponse) => {
          // Navigate to the dashboard in case of an error
          // 
          this.router.navigate(['/dashboard']);
          // 
          // this.appConfig.getConfig().is_new_version ? this.router.navigate(['/dashboard-v1']): this.router.navigate(['/dashboard']);
          
          this.snackbarService.showSnackbar(error.error.detail, '', 'error');
        },
      });
  };
}
