<app-top-nav></app-top-nav>
<div class="page-container">
  <div class="content">
    <div class="upload-progress-wrapper" fxLayout="column" fxLayoutAlign=" space-between start">
      <div fxLayout="row" fxLayoutAlign="space-evenly center" class="upload-progress">
        <div fxLayout="column" fxLayoutAlign="space-evenly start">
          <div class="stages completed">
            <div>
              <mat-icon svgIcon="successTick"></mat-icon>
              <span>Upload</span> <br /><span style="margin-left: 30%">Data</span>
            </div>
          </div>
          <div class="info-text" style="margin-left: 30%">Completed</div>
        </div>
        <div>
          <div fxLayout="row" fxLayoutAlign="space-evenly start">
            <mat-progress-bar *ngIf="receivedData == 100" mode="determinate" value="{{ receivedData }}"
              style="width: 100%;margin-bottom: 29px;"></mat-progress-bar>
            <mat-progress-bar *ngIf="receivedData != 100" mode="indeterminate" value="{{ receivedData }}"
              style="width: 100%">
            </mat-progress-bar>
            <div *ngIf="receivedData == 100" style="
                margin-top: -8px;
                margin-left: 14px;
                color: rgba(5, 122, 85, 1);
              "></div>
            <div *ngIf="receivedData != 100" style="
                margin-top: -8px;
                margin-left: 14px;
                color: rgba(5, 122, 85, 1);
              ">
              {{ receivedData | number : "1.0-0" }}%
            </div>
          </div>
          <div class="info-text" *ngIf="receivedData != 100" style="margin-left: 13%; width: 137.58px">
            Ingesting data
          </div>
          <div class="info-text" *ngIf="receivedData == 100" style="margin-left: 13%; width: 137.58px"></div>
        </div>
        <div class="stages in-progress" fxLayout="column" fxLayoutAlign="space-evenly ">
          <div *ngIf="!headerSelection">
            <mat-icon svgIcon="planeCheckCircle"></mat-icon>
            <span>Configure Data - Select Sheets</span><br />
            <span style="margin-left: 9%"> & Header Rows </span>
          </div>
          <div *ngIf="headerSelection" style="margin-bottom:24px">
            <mat-icon svgIcon="successTick"></mat-icon>
            <span style="color: rgba(5, 122, 85, 1)">Configure Data - Select Sheets</span><br />
            <span style="margin-left: 9%; color: rgba(5, 122, 85, 1)">
              & Header Rows
            </span>
          </div>
          <div *ngIf="!headerSelection" class="info-text" style="margin-left: 10%;">
            Yet to start
          </div>
        </div>
        <div style="padding-top: 61px">
          <div fxLayout="row" fxLayoutAlign="space-evenly start" style="width: 137.58px">
            <mat-progress-bar *ngIf="stepCompleted * 25 != 100 && headerSelection" mode="indeterminate"
              [value]="stepCompleted * 25 | number : '1.0-0'" style="width: 100%;margin-top:3px">
            </mat-progress-bar>
            <mat-progress-bar *ngIf="stepCompleted * 25 == 100 || !headerSelection" mode="determinate"
              [value]="stepCompleted * 25 | number : '1.0-0'" style="width: 100%;margin-top:3px">
            </mat-progress-bar>
            <div style="
                margin-top: -8px;
                margin-left: 14px;
                color: rgba(5, 122, 85, 1);
              ">
              {{ stepCompleted * 25 | number : "1.0-0" }}%
            </div>
          </div>
          <div style="height: 80px" class="info-text">
            <ol style="padding-left: 14px">
              <li [ngClass]="{ 'step-completed': stepCompleted >= 1 }" class="step-incomplete">
                Processing input file
              </li>
              <li [ngClass]="{
                  'step-completed': stepCompleted >= 2,
                  'step-in-progress': stepCompleted == 1
                }" class="step-incomplete">
                Evaluating output sample
              </li>
              <li [ngClass]="{
                  'step-completed': stepCompleted >= 3,
                  'step-in-progress': stepCompleted == 2
                }" class="step-incomplete">
                Transforming
              </li>
              <li [ngClass]="{
                  'step-completed': stepCompleted == 4,
                  'step-in-progress': stepCompleted == 3
                }" class="step-incomplete">
                Generating output
              </li>
            </ol>
          </div>
        </div>
        <div class="stages yet-to-start" fxLayout="column" fxLayoutAlign="space-evenly ">
          <div>
            <mat-icon svgIcon="planeCheckCircle"></mat-icon>
            <span>Review & Confirm</span> <br />
            <span style="margin-left: 15%">Output</span>
          </div>
          <div class="info-text" style="margin-left: 15%">Yet to start</div>
        </div>
      </div>
      <div style="
          width: 423px;
          margin: 0 auto;
          text-align: center;
          padding-top: 40px;
          padding-bottom: 20px;
          font-size: 12px;
          line-height: 18px;
          color: rgba(27, 38, 59, 1);
        ">
        Please don't refresh or go back, we'll have everything ready for you
        shortly. Your patience is appreciated!
      </div>
      <!-- <div style="width:80px;"> -->
      <button *ngIf="!headerSelection" (click)="confirmationPopUp()" mat-stroked-button color="warn"
        style="border: 1px solid red; margin: 0 auto">
        Cancel
      </button>
    </div>

    <!-- </div> -->
  </div>
</div>
