import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';
import { ConfirmationDialogComponent } from 'src/app/_dialogs/confirmation-dialog/confirmation-dialog.component';
import { InviteMembersComponent } from 'src/app/_dialogs/invite-members/invite-members.component';
import { RemoveUserDialogComponent } from 'src/app/_dialogs/remove-user-dialog/remove-user-dialog.component';
import { AccountSettingsService } from 'src/app/_services/account-settings.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';
export interface PeriodicElement {
  name: string;
  role: number;
  action: any;
}
interface Toppings {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss'],
})
export class TeamMembersComponent implements OnInit {
  @Input() selectedOrg: any;
  displayedColumns: string[] = ['name', 'role', 'status', 'action'];

  totalItems: number;
  total_pages: number;

  toppingList: Toppings[] = [
    { value: 'admin', viewValue: 'Admin' },
    { value: 'user', viewValue: 'User' },
  ];

  teamdataSource: any;
  page: number;
  size: number;
  pagination: boolean;
  selectedOrgCode: any;
  teamMemberSearch: string;
  currentUserRole: string | null;
  inviteCode: string | null;
  exitConformationDialog: MatDialogRef<ConfirmationDialogComponent, any>;
  removeConformationDialog: MatDialogRef<RemoveUserDialogComponent, any>;

  profileJson: import('@auth0/auth0-angular').User | null | undefined;
  isEmailVerified: boolean | undefined;
  userData: any;

  constructor(
    public dialog: MatDialog,
    private accountSettingsService: AccountSettingsService,
    private loadingService: LoadingService,
    private userService: UserService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.selectedOrgCode = this.selectedOrg?.organisation_code;
    this.page = 1;
    this.size = 10;
    this.pagination = true;
    this.teamMemberSearch = '';
    this.getMembersList(
      this.page,
      this.size,
      this.teamMemberSearch,
      this.pagination,
      this.selectedOrgCode
    );

    this.currentUserRole = localStorage.getItem('currentUserRole')!;
    // get user profile json
    this.auth.user$.subscribe((profile) => {
      this.profileJson = profile;
      this.isEmailVerified = this.profileJson?.email_verified;
    });
    this.userData = JSON.parse(localStorage.getItem('user')!);
  }
  /**
   * Checks whether the user has permission to delete team members.
   * Returns a boolean value.
   */
  get hasTeamMembersDeletePermission(): boolean {
    return this.userService.hasPermission('member:delete');
  }
  /**
   * Checks whether the user has permission to create team members.
   * Returns a boolean value.
   */
  get hasTeamMembersCreatePermission(): boolean {
    return this.userService.hasPermission('member:create');
  }
  /**
   * Checks the feature flag for inviting members.
   * Returns a boolean value.
   */
  get featureFlagInviteMembers(): boolean {
    return environment.feature_flags.INVITE_MEMBERS;
  }
  /**
   * Retrieves a list of organization members based on specified parameters.
   * @param page - The page number for pagination.
   * @param size - The number of items per page.
   * @param search - The search criteria for filtering members.
   * @param pagination - A boolean flag indicating whether to enable pagination.
   * @param selectedOrgCode - The organization code for which members are retrieved.
   */

  getMembersList = (
    page: number,
    size: number,
    search: any,
    pagination: boolean,
    selectedOrgCode: any
  ) => {
    this.loadingService.setLoading(true);
    this.accountSettingsService
      .orgMembersList(page, size, search, pagination, selectedOrgCode)
      .subscribe((resp: any) => {
        if (resp) {
          this.loadingService.setLoading(false);
          this.teamdataSource = new MatTableDataSource(resp.result);
          this.totalItems = resp.total_items;
          this.page = resp.page;
          this.size = resp.page_size;
        }
      });
  };
  /**
   * Opens the exit confirmation dialog.
   * This dialog prompts the user with a confirmation message before leaving the page.
   */
  openExitDialog(): void {
    this.exitConformationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      {
        // width: '416px',
        data: {
          content: 'Are you sure you want to depart?.',
          from: 'teamMembers',
        },
      }
    );
  }

  openInviteMemberDialog(): void {
    const dialogRef = this.dialog.open(InviteMembersComponent, {
      width: '440px',
      disableClose: true,
      autoFocus: true,
      hasBackdrop: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getMembersList(
        this.page,
        this.size,
        this.teamMemberSearch,
        this.pagination,
        this.selectedOrgCode
      );
    });
  }

  /**
   * Handles the search functionality for team members.
   * @param value - The search value entered by the user.
   */
  onSeachOfTeamMembers = (value: any) => {
    this.teamMemberSearch = value;
    this.getMembersList(
      this.page,
      this.size,
      this.teamMemberSearch,
      this.pagination,
      this.selectedOrgCode
    );
  };

  // cancelInvite = (invitation_code: any) => {
  //   this.accountSettingsService
  //     .cancelInvite(
  //       localStorage.getItem('currentOrganizationCode'),
  //       invitation_code
  //     )
  //     .subscribe({
  //       next: (resp: any) => {
  //         // console.log(resp);
  //       },
  //       error: (error: HttpErrorResponse) => {
  //         // console.log(error);
  //       },
  //     });
  // };
  /**
   * Opens the remove user confirmation dialog.
   * This dialog prompts the user with a confirmation message before removing a user.
   * @param username The username of the user to be removed.
   */
  openRemoveUserConformationDialog(name: string, username: string): void {
    const dialogRef = this.dialog.open(RemoveUserDialogComponent, {
      width: 'auto',
      disableClose: true,
      autoFocus: false,
      hasBackdrop: false,
      restoreFocus: false,

      data: {
        name: name,
        username: username,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != 'close') {
        this.getMembersList(
          this.page,
          this.size,
          this.teamMemberSearch,
          this.pagination,
          this.selectedOrgCode
        );
      }
    });
  }

  /**
   * Handles pagination events.
   * Updates page and size variables based on the event's page index and page size.
   * Sets the loading state and triggers an update of the batch list table.
   * @param e - The pagination event object.
   */
  onPaginateChange = (e: any) => {
    this.page = e.pageIndex + 1;
    this.size = e.pageSize;
    this.loadingService.setLoading(true);
    this.getMembersList(
      this.page,
      this.size,
      this.teamMemberSearch,
      this.pagination,
      this.selectedOrgCode
    );
  };
}
