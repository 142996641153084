import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from '../_globals/endpoints.global';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private globals: Globals, private http: HttpClient) {}

  /**
   * Sends a copy of an email using the GET method.
   */
  sendMail = (): Observable<any> => {
    const mailEndPoint = this.globals.urlJoin('onBoarding', 'send_mail');
    return this.http.get(mailEndPoint);
  };

  /**
   * Sends a privacy policy acceptance request to the server.
   */
  privacyPolicyAcceptance() {
    {
      let privacyPolicyAcceptanceEndpoint = this.globals.urlJoin(
        'user',
        'accept_privacy_policy'
      );
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const payload = {
        acceptance: true,
      };

      return this.http.patch(privacyPolicyAcceptanceEndpoint, payload, {
        headers,
      });
    }
  }
}
